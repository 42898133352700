import React, { useState, useEffect } from "react";
import "./OwnerDetail.css";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import Edit from "../../images/icons/edit.svg";
import profile_image from "../../images/user/Default_pfp.png";
import UserIcon from "../../images/icons/single-user-icon.svg";
import EmailIcon from "../../images/icons/email.svg";
import PassportlIcon from "../../images/icons/passport.svg";
import location from "../../images/icons/location.svg";
import PostalCode from "../../images/icons/postal-code.svg";
import Tele from "../../images/icons/tele.svg";
import { logout, reset } from "../../features/auth/authSlice";
import { reset as invoiceReset } from "../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../features/appSettings/appSettingsSlice.js";
import OwnerDetailsEdit from "./OwnerDetailEdit";
import TruncateFieldView from "../helpers/truncateFieldView";

const OwnerDetails = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ownerEdit, setOwnerEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [ownerDetailView, setOwnerDetailView] = useState([]);
  const user = useSelector(
    (state) => state.auth.user
  );
  const { appSettings } = useSelector((state) => state.appSettings);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  useEffect(() => {
    global.config.activityLog(
      window.location.href,
      "User",
      `Entered into User (${props.id}) view page`
    );
    async function fetchUser() {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/view/owner/64c89bd10621103b92796544`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOwnerDetailView(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        navigate("/");
      }
    }

    fetchUser().then(() => {
    });
  }, [props.id, ownerEdit]);

  const goToEditProfile = (e) => {
    setOwnerEdit(true);
  };

  const handleCancelEdit = () => {
    setOwnerEdit(false);
  };

  return (
    <>
      {ownerEdit ? (
        <>
          <OwnerDetailsEdit id={props.id} onCancel={handleCancelEdit} />
        </>
      ) : (
        <>
          <div className="profile-details">
            <div className="profile-photo-outer">
              <div className="profile-photo">
                <div className="profile-photo-inner">
                  <div className="profile-photo-sec">
                    {isLoading ? (
                      <div>
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="profile-image">
                        {ownerDetailView.company_logo !== "null" &&
                          ownerDetailView.company_logo !== null ? (
                          <img
                            src={`/uploads/orgiOwnerCompyLog/${ownerDetailView.company_logo}`}
                            alt="Company Logo"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/uploads/orgiOwnerCompyLog/user_default.png';
                            }}
                          />
                        ) : (
                          <img
                            src={profile_image}
                            alt="Company Logo"
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="profile-name">
                    {isLoading ? (
                      <div>
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="profile-name-sec">
                        {ownerDetailView.commercial_name && (
                          <>
                            {ownerDetailView.commercial_name}{" "}
                          </>
                        )}
                      </div>
                    )}
                    {isLoading ? (
                      <div>
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <>
                        {ownerDetailView.role && (
                          <div className="profile-type">
                            {ownerDetailView.role}
                          </div>
                        )}
                      </>
                    )}
                    <div className="profile-status">
                      {isLoading ? (
                        <div>
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <>
                          {ownerDetailView.is_active === false ? (
                            <div className="inactive">
                              {global.config.locate(appSettings && appSettings.language, 'Inactive')}
                            </div>
                          ) : ownerDetailView.is_active === true ? (
                            <div className="active">
                              {global.config.locate(appSettings && appSettings.language, 'Active')}
                            </div>
                          ) : (
                            ownerDetailView.is_active
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <div className="profile-button">
                  <a onClick={goToEditProfile} className="edit-profile">
                    <span class="bt-ico">
                      <img src={Edit} alt="edit" />
                    </span>
                    {global.config.locate(appSettings && appSettings.language, 'Edit_Profile')}
                  </a>{" "}
                </div>
              )}
            </div>

            <div className="information-outer-section">
              <div className="information-inner">
                <div className="information">
                  <div className="information-title">{global.config.locate(appSettings && appSettings.language, 'Company_Information')}</div>
                  <div className="information-outer">
                    <div className="information-left">
                      {ownerDetailView.commercial_name && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={UserIcon} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Commercial_Name')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.commercial_name}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {ownerDetailView.legal_entity_name && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={UserIcon} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Legal_Entity_Name')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.legal_entity_name}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="information-right">
                      {ownerDetailView.email && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={EmailIcon} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Email')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.email}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {ownerDetailView.company_id && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={PassportlIcon} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Company_ID_number')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.company_id}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="information-inner">
                <div className="information">
                  <div className="information-title">{global.config.locate(appSettings && appSettings.language, 'Address')}</div>
                  <div className="information-outer">
                    <div className="information-left">
                      {ownerDetailView.city && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={location} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'City')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.city}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {ownerDetailView.country && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={location} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Country')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.country}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {ownerDetailView.address && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={location} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Address')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field"><TruncateFieldView textContent={ownerDetailView.address} /></div>)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="information-right">
                      {ownerDetailView.pincode && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={PostalCode} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Postal_Code')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.pincode}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {ownerDetailView.mobile_no && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={Tele} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Mobile_No')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.mobile_no}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OwnerDetails;
