import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from "react-loader-spinner";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, LabelList } from 'recharts';
import typeOfContract from '../../../../../../constants/typeOfContract';
import { clientTotalRevenueHistoryFilter } from '../../Services/reportRevenue';
import { reportingFilterComparisonConst } from '../../../../../../constants/reportingFilterComparisonConst';
import { reportingFilterDateRangesConst } from '../../../../../../constants/reportingFilterDateRangesConst';

const RevenueHistoryFiterGraph = ({
  clientInvoices,
  clientInvoicesLoading,
  clientInvoicesError,
  trackReport,
  trackReportLoading,
  trackReportError,
  projectCost,
  expenses,
  selectedDateRange,
  selectedComparison,
  selectedFrequency,
  selectedYearRange,
  selectedMonthRange,
  selectedComparisonYear,
  selectedComparisonMonth,
}) => {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const processedData = useMemo(() => {
    return clientTotalRevenueHistoryFilter(
      trackReport,
      projectCost,
      clientInvoices,
      reportingRevenue,
      settings.currency,
      forex,
      typeOfContract,
      expenses,
      selectedDateRange,
      selectedComparison,
      selectedFrequency,
      selectedYearRange,
      selectedMonthRange,
      selectedComparisonYear,
      selectedComparisonMonth,
    );
  }, [
    trackReport,
    projectCost,
    clientInvoices,
    reportingRevenue,
    settings.currency,
    forex,
    typeOfContract,
    expenses,
    selectedDateRange,
    selectedComparison,
    selectedFrequency,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
  ]);

  if (clientInvoicesLoading || trackReportLoading) {
    return (
      <div className="outter-load-table">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#6479f9"
          ariaLabel="three-dots-loading"
          visible={true}
        />
      </div>
    );
  }

  if (clientInvoicesError || trackReportError) {
    return <div>Error loading data.</div>;
  }

  const calculateYTicks = () => {
    if (!processedData.length) return [];
  
    // Find the maximum and minimum data values across both data keys
    const maxDataValue = Math.max(...processedData.map(item => Math.max(item.currentYear, item.lastYear)));
    const minDataValue = Math.min(...processedData.map(item => Math.min(item.currentYear, item.lastYear)));
  
    // Set a base interval based on the range
    const range = maxDataValue - minDataValue;
    const tickInterval = Math.ceil(range / 10);
  
    // Start ticks array with the minimum value (rounded down to nearest multiple of tickInterval)
    const ticks = [];
    let startTick = Math.floor(minDataValue / tickInterval) * tickInterval;
  
    // Generate ticks from the start tick to the maximum value
    for (let i = startTick; i <= maxDataValue + tickInterval; i += tickInterval) {
      ticks.push(i);
    }
  
    return ticks;
  };  

  const yAxisTicks = calculateYTicks();

  const getBarName = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
    const rangeNames = {
      [reportingFilterDateRangesConst.This_Year]: 'This Year',
      [reportingFilterDateRangesConst.This_Quarter]: 'This Quarter',
      [reportingFilterDateRangesConst.First_Half]: 'First Half',
      [reportingFilterDateRangesConst.This_Month]: 'This Month',
      [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
      [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
    };

    const comparisonNames = {
      [reportingFilterComparisonConst.Last_Year]: 'Last Year',
      [reportingFilterComparisonConst.Last_Quarter]: 'Last Quarter',
      [reportingFilterComparisonConst.Second_Half]: 'Second Half',
      [reportingFilterComparisonConst.Last_Month]: 'Last Month',
      [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
      [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
    };

    const rangeName = rangeNames[selectedRange] || 'Current Range';
    const comparisonName = comparisonNames[selectedComparison] || 'Comparison Range';

    return isCurrent
      ? `${rangeName}`
      : `${comparisonName}`;
  };

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: 542 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="horizontal" // Change to horizontal
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: 10, // Adjust the left margin to remove white space
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
          <XAxis
            type="category"
            dataKey="name"
            angle={-55}
            // width={150}
            tickMargin={10}
          />
          <YAxis
            type="number"
            domain={['auto', 'auto']}
            tickFormatter={(tick) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency || 'USD' }).format(tick)}`}
            ticks={yAxisTicks}
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(value)}`} />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="currentYear" name={getBarName(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} fill="#87ABE2">
            {selectedFrequency === '1' && (
              <LabelList dataKey="currentYear" position="top" fill="#87ABE2" formatter={(value) => value !== 0 ? `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(value)}` : null} />
            )}
          </Bar>
          <Bar dataKey="lastYear" name={getBarName(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} fill="#C7B6F6">
            {selectedFrequency === '1' && (
              <LabelList dataKey="lastYear" position="top" fill="#C7B6F6" formatter={(value) => value !== 0 ? `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(value)}` : null} />
            )}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default RevenueHistoryFiterGraph;