import React from 'react';
import { useSelector } from 'react-redux';
import { convertCurrencyHelper } from '../../../../helpers/forexConvertor';
import { calculateTotalExpense } from './Services/reportExpense';

// Functional component
function TotalExpense({ expenseData }) {
  const reportingFinancialExpense = useSelector((state) => state.reportingExpense);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  // Mockup for reportingFinancialExpense, replace this with actual data source
  // const reportingFinancialExpense = useSelector((state) => state.reportingFinancialExpense) || {
  //   expCategory: ["6569ad18fa83420ec4729a75", "6569ad82fa83420ec4729a81", "6618bbfcdae3f084f3155198"]
  // };

  const setReportingCurrency = useSelector((state) => state.reportingExpense.expCurrency);
  var currencies = "USD";
  if(setReportingCurrency && setReportingCurrency.length===1){
     currencies = setReportingCurrency[0];
  }else{
    currencies = settings.currency;
  }


  const totalExpense = calculateTotalExpense(expenseData, reportingFinancialExpense, currencies, forex);

  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">
            Total Expenses
          </div>
          <div className="overview-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
              <g filter="url(#filter0_d_372_113)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <defs>
                <filter id="filter0_d_372_113" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_372_113" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_372_113" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>

        <div className="overview-rice">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currencies,
          }).format(totalExpense)}
        </div>
      </div>

    </div>
  );
}

export default TotalExpense;
