import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
//get user from localStaorage

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  user: user ? user : null,
  token: user ? user.token : null,
  loginTemp: null,
  isError: false,
  isSucess: false,
  isLoading: false,
  message: "",
};
//Register user

export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      return await authService.register(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk("auth/login", async (user) => {
  await authService.login(user);
  return user;
});

export const logout = createAsyncThunk("auth/logout", async () => {
  localStorage.clear();
  await authService.logout();
});

export const loginTemp = createAsyncThunk("auth/loginTemp", async (user) => {
  return user;
});

export const toggleNotification = createAsyncThunk(
  "auth/toggleNotification",
  async (_, thunkAPI) => {
    const { user } = thunkAPI.getState().auth;
    const updatedUser = {
      ...user,
      is_notification_enabled: !user.is_notification_enabled,
    };

    localStorage.setItem('user',JSON.stringify(updatedUser))
   
    try {
      return updatedUser;
    } catch (error) {
      // Handle any errors and reject with an error message
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateProfileImage = createAsyncThunk(
  "auth/updateProfileImage",
  async (newProfileImage, thunkAPI) => {
    const { user } = thunkAPI.getState().auth;
    const updatedUser = {
      ...user,
      profile_image: newProfileImage,
    };
    localStorage.setItem('user',JSON.stringify(updatedUser))
    // Assuming you have an API endpoint to update the profile image
    try {
      return updatedUser;
    } catch (error) {
      // Handle any errors and reject with an error message
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.user = null;
      state.token = null;
      state.loginTemp = null;
      state.isLoading = false;
      state.isError = false;
      state.isSucess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.user = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.loginTemp = null;
        state.token = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.user = action.payload;
        state.token = action.payload.token;
        state.loginTemp = action.payload;
      })
      .addCase(loginTemp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.loginTemp = action.payload;
      })
      .addCase(toggleNotification.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateProfileImage.fulfilled, (state, action) => {
        state.user = action.payload;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;