import axios from "axios";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { ResizeObserver } from 'resize-observer';
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { logout, reset } from "../../../features/auth/authSlice";
import userTypeConsts from "../../../constants/userTypeConsts.js";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { setAutomaticInvoiceUser } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";

const InvoiceModal = ({ isAutomaticInv }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userId, setUserId] = useState([]);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [invTypeDropdownOpen, setInvTypeDropdownOpen] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [userSelectedId, setUserSelectedId] = useState('');
  const [selUserEmail, setSelUserEmail] = useState([]);
  const [selUserCategory, setSelUserCategory] = useState([]);
  const [invType, setInvType] = useState("Regular");
  const [isUserLoading, setIsUserLoading] = useState(true);
  const userDropdownRef = useRef(null);
  const invTypeDropdownRef = useRef(null);
  const dropdownListRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState({ message: null, field: null });
  const { appSettings } = useSelector((state) => state.appSettings);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  let token = null;
  const user = useSelector(
    (state) => state.auth.user
  )
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const handleSelect = (ranges) => {
    setDateRange(ranges);
    setErrorMessage({ message: null, field: null });
  };
  const SUPER_ADMIN = 1;
  const ADMIN = 2;
  const ref = useRef(null);
  let resizeObserver = null;


  useEffect(() => {
    // Create a new ResizeObserver instance
    resizeObserver = new ResizeObserver(entries => {
      // Handle resize event
    });

    if (ref.current) {
      // Observe the target element
      resizeObserver.observe(ref.current);
    }

    // Cleanup function
    return () => {
      if (resizeObserver) {
        // Disconnect the observer when component unmounts
        resizeObserver.disconnect();
        resizeObserver = null;
      }
    };
  }, []);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        setIsUserLoading(true);
        const response = await axios.get("/api/user/user-id", {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setUserId(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          await dispatch(logout());
          await dispatch(reset());
          await dispatch(invoiceReset());
          await dispatch(headerReset());
          await dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } finally {
        setIsUserLoading(false);
      }
    };

    fetchUserId();
  }, []);

  const handleToggleUserDropdown = () => {
    setUserSearchTerm('');
    setUserDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleUserSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const filteredUsers = userId.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ''} ${user.last_name}`
      : `${user.company_first_name ? `${user.company_first_name} ${user.company_last_name || ''}` : user.company_name}`;
    return fullName.toLowerCase().includes(userSearchTerm.toLowerCase());
  });

  const handleSelectUser = (selectedUserId) => {
    setErrorMessage({ message: null, field: null });
    setUserSelectedId(selectedUserId);
    const userEmail = userId.find(
      (item) => item._id === selectedUserId
    )?.email;
    setSelUserEmail(userEmail);
    const userCategory = userId.find(
      (item) => item._id === selectedUserId
    )?.user_category;
    setSelUserCategory(userCategory);
    setUserSearchTerm('');
    setUserDropdownOpen(false);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideUserDropdown = userDropdownRef.current && userDropdownRef.current.contains(event.target);
    const isClickInsideInvTypeDropdown = invTypeDropdownRef.current && invTypeDropdownRef.current.contains(event.target);
    if (!isClickInsideUserDropdown) {
      setUserDropdownOpen(false);
    }
    if (!isClickInsideInvTypeDropdown) {
      setInvTypeDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    // Get the selected item
    const selectedItem = document.querySelector('.selected-item');
    // Get the container element
    const dropdownListContainer = dropdownListRef.current;

    // Scroll to the selected item and center it in the view
    if (selectedItem && dropdownListContainer) {
      selectedItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // selectedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [userDropdownOpen, userSearchTerm, filteredUsers, userSelectedId]);

  const automaticInvoiceSubmit = async () => {
    var autoData = [];

    if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
      if (userSelectedId === "" || Object.keys(userSelectedId).length === 0) {
        toast.error("Please select user", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setErrorMessage({ message: "Please Select different date", field: "both" })
        return;
      } else {
      }
    }
    if (dateRange === null) {
      toast.error("Please select date", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (dateRange[0] === dateRange[1]) {
      toast.error("Please select date", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (dateRange[0].getTime() === dateRange[1].getTime()) {
      toast.error("Please select different date", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else {
      autoData.push(dateRange);
      if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {

        autoData.push(userSelectedId);
      } else {
        autoData.push(user._id);
      }

      autoData.push(invType);
      await dispatch(setAutomaticInvoiceUser(autoData));
      navigate("/user/invoice/auto/create");
    }
  };

  const manualInvoiceSubmit = async () => {
    var autoData = [];

    if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
      if (userSelectedId === "" || Object.keys(userSelectedId).length === 0) {
        toast.error("Please select User", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setErrorMessage({ message: "Please select different date", field: "both" })
        return;
      } else {
      }
    }
    if (dateRange === null) {
      toast.error("Please select date", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (dateRange[0] === dateRange[1]) {
      toast.error("Please select date", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (dateRange[0].getTime() === dateRange[1].getTime()) {
      toast.error("Please select different date", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else {
      autoData.push(dateRange);
      if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
        autoData.push(userSelectedId);
        autoData.push(selUserEmail);
        autoData.push(selUserCategory);
      } else {
        autoData.push(user._id);
        autoData.push(user.email);
        autoData.push(user.userCategory);
      }
      autoData.push(invType);
      await dispatch(setAutomaticInvoiceUser(autoData));
      navigate("/user/invoice/manual/create");
    }
  };

  return (
    <div className="invoice-modal" ref={ref}>
      <h2 className="invoice-modal-main-head">Type of Invoice</h2>
      {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
        <div className="form-group-1modal userseloutter-1">
          {isUserLoading ?
            null :
            <label htmlFor="user-select">User *</label>
          }
          {isUserLoading ?
            <div className="outter-load-table">
              <ThreeDots
                height="37"
                width="39"
                radius="9"
                color="#6479f9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div> :
            // <select className={errorMessage && errorMessage.field === 'both' ? "select-modal-mainM error" : "select-modal-mainM"}
            //   id="user-select"
            //   value={userSelectedId}
            //   onChange={(e) => {
            //     setErrorMessage({ message: null, field: null });
            //     setUserSelectedId(e.target.value);
            //     const userEmail = userId.find(
            //       (item) => item._id === e.target.value
            //     )?.email;
            //     setSelUserEmail(userEmail);
            //     const userCategory = userId.find(
            //       (item) => item._id === e.target.value
            //     )?.user_category;
            //     setSelUserCategory(userCategory);
            //   }}
            //   required
            // >
            //   <option value="">Select User</option>
            //   {userId.length > 0
            //     ? userId
            //       .slice() // Create a copy of the array to avoid mutating the original array
            //       .sort((a, b) => {
            //         // Sort the array alphabetically based on user names
            //         const nameA = a.first_name
            //           ? `${a.first_name} ${a.middle_name || ""} ${a.last_name}`
            //           : a.company_name;
            //         const nameB = b.first_name
            //           ? `${b.first_name} ${b.middle_name || ""} ${b.last_name}`
            //           : b.company_name;
            //         return nameA.localeCompare(nameB);
            //       })
            //       .map((item, index) => (
            //         <option value={item._id} key={index}>
            //           {item.first_name ? (
            //             <>
            //               {item.first_name}{" "}
            //               {item.middle_name && item.middle_name !== "null"
            //                 ? item.middle_name + " "
            //                 : ""}
            //               {item.last_name}{" "}
            //             </>
            //           ) : (
            //             <>
            //               {item.company_name} {" (Company) "}
            //             </>
            //           )}
            //         </option>
            //       ))
            //     : ""}
            // </select>
            <div ref={userDropdownRef} className={errorMessage && errorMessage.field === 'both' ? "select-modal-mainM error custom-dropdown-user-new-outer" : "select-modal-mainM custom-dropdown-user-new-outer"}>
              <span
                id="user-select"
                onClick={handleToggleUserDropdown}
                className="custom-dropdown-user-new"
              >
                {userSelectedId ? (
                  userId.find((user) => user._id === userSelectedId)?.first_name ? (
                    <>
                      {userId.find((user) => user._id === userSelectedId).first_name}{' '}
                      {userId.find((user) => user._id === userSelectedId).middle_name &&
                        userId.find((user) => user._id === userSelectedId).middle_name !== 'null'
                        ? userId.find((user) => user._id === userSelectedId).middle_name + ' '
                        : ''}
                      {userId.find((user) => user._id === userSelectedId).last_name}{' '}
                    </>
                  ) : (
                    <>
                      {userId.find((user) => user._id === userSelectedId)?.company_first_name
                        ? `${userId.find((user) => user._id === userSelectedId).company_first_name} ${userId.find((user) => user._id === userSelectedId).company_last_name
                          ? userId.find((user) => user._id === userSelectedId).company_last_name
                          : ''
                        }`
                        : `${userId.find((user) => user._id === userSelectedId)?.company_name || ''}(Company)`}
                    </>
                  )
                ) : (
                  'Select User'
                )}
              </span>
              {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && userDropdownOpen && (
                <div className="custom-dropdown-new" ref={dropdownListRef}>
                  <input
                    type="text"
                    className="search-bar"
                    placeholder="Search..."
                    value={userSearchTerm}
                    onChange={handleUserSearchInputChange}
                  />
                  <ul className="client-list">
                    {filteredUsers.length > 0 &&
                      filteredUsers
                        .slice()
                        .sort((a, b) => {
                          const nameA = a.first_name
                            ? `${a.first_name} ${a.middle_name || ''} ${a.last_name}`
                            : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
                          const nameB = b.first_name
                            ? `${b.first_name} ${b.middle_name || ''} ${b.last_name}`
                            : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
                          return nameA.localeCompare(nameB);
                        })
                        .map((item) => (
                          <li key={item._id} onClick={() => handleSelectUser(item._id)} className={userSelectedId === item._id ? 'selected-item' : ''}>
                            {item.first_name ? (
                              <>
                                {item.first_name}{' '}
                                {item.middle_name && item.middle_name !== 'null' ? item.middle_name + ' ' : ''}
                                {item.last_name}{' '}
                              </>
                            ) : (
                              <>
                                {item.company_first_name
                                  ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ''}`
                                  : `${item.company_name || ''}(Company)`}
                              </>
                            )}
                          </li>
                        ))
                    }
                  </ul>
                </div>
              )
              }
            </div>
          }
        </div>
      )}
      {isAutomaticInv === false && (
        <div className="form-group-1modal userseloutter-1">
          {isUserLoading ?
            null :
            <label htmlFor="user-select">Invoice Type *</label>
          }
          {isUserLoading ?
            <div className="outter-load-table">
              <ThreeDots
                height="37"
                width="39"
                radius="9"
                color="#6479f9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div> :
            // <select className={errorMessage && errorMessage.field === 'both' ? "select-modal-mainM error" : "select-modal-mainM"}
            //   id="user-select"
            //   value={invType}
            //   onChange={(e) => {
            //     setErrorMessage({ message: null, field: null });
            //     setInvType(e.target.value);
            //   }}
            //   required
            // >
            //   <option value="" disabled hidden></option>
            //   <option value="Regular">Create Fees Invoice</option>
            //   <option value="Reimbursement">
            //     Create Reimbursement Invoice
            //   </option>
            // </select>
            <div ref={invTypeDropdownRef} className={errorMessage && errorMessage.field === 'both' ? "select-modal-mainM error custom-dropdown-user-new-outer" : "select-modal-mainM custom-dropdown-user-new-outer"}>
              <div className="selected-option custom-dropdown-user-new" onClick={() => setInvTypeDropdownOpen(!invTypeDropdownOpen)}>
                {invType ? (invType === 'Regular' ? 'Create Fees Invoice' : 'Create Reimbursement Invoice') : 'Select Invoice Type'}
              </div>
              {invTypeDropdownOpen && (
                <div className="custom-dropdown-new">
                  <ul className="invoice-type-list">
                    <li className={invType === 'Regular' ? 'selected-item' : ''} onClick={() => { setInvType('Regular'); setInvTypeDropdownOpen(false); }}>Create Fees Invoice</li>
                    <li className={invType === 'Reimbursement' ? 'selected-item' : ''} onClick={() => { setInvType('Reimbursement'); setInvTypeDropdownOpen(false); }}>Create Reimbursement Invoice</li>
                  </ul>
                </div>
              )}
            </div>
          }
        </div>
      )}
      <div className="form-group-1modal range-picinmodal-1">
        {isUserLoading ?
          null :
          <label htmlFor="date-range">Date Range *</label>
        }
        {isUserLoading ?
          <div className="outter-load-table">
            <ThreeDots
              height="37"
              width="39"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div> :
          <div className="date-picker-outer date-picker-outer-all">
            <div className="custom-picker-icon custom-picker-icon-all">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 14 14"
                fill="currentColor"
                aria-hidden="true"
                focusable="false"
                class="rs-picker-toggle-caret rs-icon"
                aria-label="calendar"
                data-category="legacy"
              >
                <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
              </svg>
            </div>
            <div className="custoom-icon-calender custoom-icon-calender-all">
              <DateRangePicker
                showOneCalendar
                className={errorMessage && errorMessage.field === 'both' ? "errorDate" : ""}
                id="date-range"
                format="dd/MM/yyyy"
                placeholder="Select a date range"
                value={dateRange}
                onChange={handleSelect}
                placement="auto"
                isoWeek={appSettings.weekStartDay === 'Monday'}
                timeZone={global.config.getTimeZone()}
                renderStaticRangeLabel={({ range }) => {
                  const startDate = range.startDate.toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                  });
                  const endDate = range.endDate.toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                  });

                  const label = `${startDate} to ${endDate}`;

                  return label.replace("~", "to");
                }}
              />
            </div>
          </div>

        }

      </div>
      <div className="form-group-1modal submittseloutter-1">
        {isAutomaticInv === true ? (
          <button
            className="generate-button modalsubmitbtn-1"
            onClick={automaticInvoiceSubmit}
          >
            Generate Invoice
          </button>
        ) : (
          <button className="create-button modalsubmitbtn-1" onClick={manualInvoiceSubmit}>
            Create Invoice
          </button>
        )}
      </div>
    </div>
  );
};

export default InvoiceModal;