import React from "react";

function DateConverter({ date }) {
  const formatDate = (dateString) => {
    const [isoDate, desiredFormat] = dateString.split("/");
    const dateObj = new Date(isoDate);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return <>{formatDate(date)}</>;
}

export default DateConverter;