import React, { useRef, useState, useEffect } from "react";
import "./ReportingServicesFilter.css";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import Modal from "react-modal";
import axios from "axios";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import TruncateFilterFieldView from "../../helpers/truncateFilterFieldView.js";
import { convertCurrencyHelper } from "../../../helpers/forexConvertor";
import Calendar from "../../../helpers/dateComponents/Calendar.jsx";
import typeOfContract from '../../../constants/typeOfContract';
import DownloadImage from "../../../images/icons/download-icon.svg";
import {
  setReportServicesClient,
  setReportServicesCurrency,
  setReportServicesTag,
  setReportServicesDateRange,
} from "../../../features/reporting/reportingServices/reportingServicesSlice.js";
import { setDashboardDate, setDashboardCurrencyRx, setDashboardCurrencySum } from "../../../features/invoice/invoiceSlice";
import { setForex } from "../../../features/forex/forexSlice.js";

function ServicesReportFilter(props) {
  const dispatch = useDispatch();
  const clientDropdownRef = useRef(null);
  const tagDropdownRef = useRef(null);
  const currencyDropdownRef = useRef(null);
  const clientModalDropdownRef = useRef(null);
  const tagModalDropdownRef = useRef(null);
  const currencyModalDropdownRef = useRef(null);
  const calendarRef = useRef(null);
  const calendarModalRef = useRef(null);

  const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
  const [isTagDropdownOpen, setIsTagDropdownOpen] = useState(false);
  const [isCurrencyDropdownOpen, setIsCurrencyDropdownOpen] = useState(false);
  const [isClientModalDropdownOpen, setIsClientModalDropdownOpen] = useState(false);
  const [isTagModalDropdownOpen, setIsTagModalDropdownOpen] = useState(false);
  const [isCurrencyModalDropdownOpen, setIsCurrencyModalDropdownOpen] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectAllClient, setSelectAllClient] = useState(true);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectAllTag, setSelectAllTag] = useState(true);
  const [selectedTag, setSelectedTag] = useState([]);
  const [sortedTags, setSortedTags] = useState([]);
  const firstDayOfYear = moment.tz(global.config.getTimeZone()).startOf("year").toDate();
  const [showCalendar, setShowCalendar] = useState(false);
  const [showModalCalendar, setShowModalCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([firstDayOfYear, new Date()]);
  const currentYear = new Date().getFullYear();
  // const [year, setYear] = useState(new Date(currentYear, 0, 1));
  const [sortedCurrencies, setSortedCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [selectAllCurrency, setSelectAllCurrency] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const reportingServices = useSelector((state) => state.reportingServices);
  const { forex } = useSelector((state) => state.forex);
  const { appSettings } = useSelector((state) => state.appSettings);
  const [allCurrency, setAllCurrency] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const [selectedForex, setSelectedForex] = useState([]);

  // const dummyClients = [
  //   { _id: 1, client_name: 'Client A' },
  //   { _id: 2, client_name: 'Client B' },
  //   { _id: 3, client_name: 'Client C' }
  // ];

  // const dummyTages = [
  //   { _id: 1, tag: 'Braintrust' },
  //   { _id: 2, tag: 'TalenOn' },
  //   { _id: 3, tag: 'Gruporesidencial' },
  //   { _id: 4, tag: 'Mudhouse' }
  // ];

  const dummyCurrencies = [
    { _id: 1, currency: "INR" },
    { _id: 2, currency: "DOllar" },
    { _id: 3, currency: "EURO" },
    { _id: 4, currency: "MYR" },
  ];

  // const dummyRanges = [
  //   { label: 'Today', value: new Date() },
  //   { label: 'Yesterday', value: new Date(new Date().setDate(new Date().getDate() - 1)) },
  //   { label: 'Last 7 Days', value: new Date(new Date().setDate(new Date().getDate() - 7)) }
  // ];


  const sumAllCurrencies = async (val) => {
    setAllCurrency(val);
    setSelectedCurrency(global.config.appCurrency());
    await dispatch(setDashboardCurrencyRx(global.config.appCurrency()));
    await dispatch(setDashboardCurrencySum(val));
  }


  const getForex = async (currency) => {

    const response = await axios.post('/api/forex/get', { base_currency: "USD" }, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.status === 200) {
      return response.data ? response.data.json : null;
    } else {
      console.error(`Request failed with status code: ${response.status}`);
    }

  }


  const handleCheckboxTagChange = (tagId) => {
    const updatedSelection = [...selectedTag];
    const index = updatedSelection.indexOf(tagId);

    if (index === -1) {
      updatedSelection.push(tagId);
    } else {
      updatedSelection.splice(index, 1);
    }

    setSelectedTag(updatedSelection);
  };

  const handleSelectAllTagChange = () => {
    if (!selectAllTag) {
      // setSelectedTag(sortedTags.map((tag) => tag._id));
      const allTagIds = sortedTags.map((tag) => tag._id);
      setSelectedTag(allTagIds);
    } else {
      setSelectedTag([]);
    }
    setSelectAllTag(!selectAllTag);
  };

  useEffect(() => {
    setSelectAllTag(selectedTag.length === sortedTags.length);
    if (selectedTag.length === sortedTags.length) {
      selectTag(selectedTag);
    } else {
      selectTag(selectedTag);
    }
  }, [selectedTag, props.tag]);

  const handleCheckboxClientChange = (clientId) => {
    const updatedSelection = [...selectedClients];
    const index = updatedSelection.indexOf(clientId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(clientId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    // Update sorted currencies based on selected clients
    const selectedClientObjects = props.clients.filter((client) =>
      updatedSelection.includes(client._id)
    );
    const uniqueCurrencies = Array.from(
      new Set(selectedClientObjects.map((client) => client.preferred_currency))
    );
    setSortedCurrencies(uniqueCurrencies);
    setSelectedCurrency(uniqueCurrencies);
    selectCurrency(uniqueCurrencies);
    setSelectedClients(updatedSelection);
  };

  const handleSelectAllClientChange = () => {
    if (!selectAllClient) {
      const allClientIds = props.clients.map((client) => client._id);

      setSelectedClients(allClientIds);

      // Update sorted currencies based on all clients
      const uniqueCurrencies = Array.from(
        new Set(props.clients.map((client) => client.preferred_currency))
      );
      setSortedCurrencies(uniqueCurrencies);
      setSelectedCurrency(uniqueCurrencies);
      selectCurrency(uniqueCurrencies);
    } else {
      setSelectedClients([]);
      setSelectedCurrency([]);
      setSortedCurrencies([]);
    }
    setSelectAllClient(!selectAllClient);
  };

  useEffect(() => {
    setSelectAllClient(selectedClients.length === props.clients.length);
    if (selectedClients.length === props.clients.length) {
      selectClient(selectedClients);
    } else {
      selectClient(selectedClients);
    }
  }, [selectedClients, props.clients]);

  const handleCheckboxCurrencyChange = (currency) => {
    const updatedSelection = [...selectedCurrency];
    const index = updatedSelection.indexOf(currency);

    if (index === -1) {
      updatedSelection.push(currency);
    } else {
      updatedSelection.splice(index, 1);
    }
    setSelectedCurrency(updatedSelection);

    const updatedClients = [
      ...new Set(
        props.clients
          .filter((client) =>
            updatedSelection.includes(client.preferred_currency)
          )
          .map((client) => client._id)
      ),
    ];

    setSelectedClients(updatedClients);
  };

  const handleCheckboxCurrencyChangeSingle = (currency) => {
    // const updatedSelection = [...selectedCurrency];
    // const index = updatedSelection.indexOf(currency);

    // if (index === -1) {
    //   updatedSelection.push(currency);
    // } else {
    //   updatedSelection.splice(index, 1);
    // }
    setSelectedCurrency([currency]);

    const updatedClients = [...new Set(
      props.clients
        .filter(client => client.preferred_currency===currency)
        .map(client => client._id)
    )];

    setSelectedClients(updatedClients);
    // // Update selected projects and sorted projects based on the selected clients
    // const filteredProjects = props.projects.filter(project => updatedClients.includes(project.client_name._id));
    // const updatedProjectIds = filteredProjects.map(project => project._id);

    // setSelectedProject(updatedProjectIds);
    // setSortedProjects(filteredProjects);
    // selectProject(updatedProjectIds);
  };

  const handleSelectAllCurrencyChange = () => {
    if (selectedCurrency.length === sortedCurrencies.length) {
      setSelectedCurrency([]);
      setSelectedClients([]);
    } else {
      setSelectedCurrency(sortedCurrencies);
      // Update selected clients based on all sorted currencies with unique IDs
      const allClientIds = [
        ...new Set(
          props.clients
            .filter((client) =>
              sortedCurrencies.includes(client.preferred_currency)
            )
            .map((client) => client._id)
        ),
      ];

      setSelectedClients(allClientIds);
    }
  };

  useEffect(() => {
    setSelectAllCurrency(selectedCurrency.length === sortedCurrencies.length);
    if (selectedCurrency.length === sortedCurrencies.length) {
      selectCurrency(selectedCurrency);
    } else {
      selectCurrency(selectedCurrency);
    }
  }, [selectedCurrency, props.clients]);

  const toggleClientDropdown = () => {
    setIsClientDropdownOpen(!isClientDropdownOpen);
  };

  const toggleTagDropdown = () => {
    setIsTagDropdownOpen(!isTagDropdownOpen);
  };

  const toggleCurrencyDropdown = () => {
    setIsCurrencyDropdownOpen(!isCurrencyDropdownOpen);
  };

  const toggleClientModalDropdown = () => {
    setIsClientModalDropdownOpen(!isClientModalDropdownOpen);
  };

  const toggleTagModalDropdown = () => {
    setIsTagModalDropdownOpen(!isTagModalDropdownOpen);
  };

  const toggleCurrencyModalDropdown = () => {
    setIsCurrencyModalDropdownOpen(!isCurrencyModalDropdownOpen);
  };

  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    setSelectedClients(props.clients.map((client) => client._id));
    setSelectedTag(props.tag.map((tag) => tag._id));
    setSelectedCurrency([]);
    setSelectedDate(null);
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const onDateChange = (ranges) => {
    // moment.tz(item.date,'YYYY-MM-DD HH:mm:ss',global.config.getTimeZone()).format()
    const dateRangeArray = [ranges.startDate, ranges.endDate];
    setDateRange(dateRangeArray);
    selectDateRange(dateRangeArray);
    if (ranges === null) {
      const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
      setDateRange([firstDayOfYear, new Date()]);
      selectDateRange([firstDayOfYear, new Date()]);
    }
    setShowCalendar(false);
    setShowModalCalendar(false);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const cancelCalendar = () => {
    setShowCalendar(false);
  };

  const toggleModalCalendar = () => {
    setShowModalCalendar(!showModalCalendar);
  };

  const cancelModalCalendar = () => {
    setShowModalCalendar(false);
  };

  const checkAndShowConfirmationModal = () => {
    if (selectedClients.length || selectedTag.length || selectedDate) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const selectClient = async (client) => {
    await dispatch(setReportServicesClient(client));
  };

  const selectTag = async (tag) => {
    await dispatch(setReportServicesTag(tag));
  };

  const selectDateRange = async (date) => {
    await dispatch(setReportServicesDateRange(date));
  };

  const selectCurrency = async (currency) => {
    await dispatch(setReportServicesCurrency(currency));
  };

  useEffect(() => {
    const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
    setDateRange([firstDayOfYear, new Date()]);
    selectDateRange([firstDayOfYear, new Date()]);
    setSelectedClients(props.clients.map((client) => client._id));
    if (Array.isArray(props.tag)) {
      setSelectedTag(props.tag.map((tag) => tag._id));
    }
    setSortedTags(props.tag);

    const uniqueCurrencies = Array.from(
      new Set(props.clients.map((client) => client.preferred_currency))
    );
    setSortedCurrencies(uniqueCurrencies);
    setSelectedCurrency(uniqueCurrencies);
  }, [props.clients, props.tag]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideTagDropdown = tagDropdownRef.current && tagDropdownRef.current.contains(event.target);
    const isClickInsideTagModalDropdown = tagModalDropdownRef.current && tagModalDropdownRef.current.contains(event.target);
    const isClickInsideClientDropdown = clientDropdownRef.current && clientDropdownRef.current.contains(event.target);
    const isClickInsideClientModalDropdown = clientModalDropdownRef.current && clientModalDropdownRef.current.contains(event.target);
    const isClickInsideCurrencyDropdown = currencyDropdownRef.current && currencyDropdownRef.current.contains(event.target);
    const isClickInsideCurrencyModalDropdown = currencyModalDropdownRef.current && currencyModalDropdownRef.current.contains(event.target);
    const isClickInsideCalendarDropdown = calendarRef.current && calendarRef.current.contains(event.target);
    const isClickInsideModalCalendarDropdown = calendarModalRef.current && calendarModalRef.current.contains(event.target);

    if (!isClickInsideTagDropdown) {
      setIsTagDropdownOpen(false);
    }
    if (!isClickInsideTagModalDropdown) {
      setIsTagModalDropdownOpen(false);
    }
    if (!isClickInsideClientDropdown) {
      setIsClientDropdownOpen(false);
    }
    if (!isClickInsideClientModalDropdown) {
      setIsClientModalDropdownOpen(false);
    }
    if (!isClickInsideCurrencyDropdown) {
      setIsCurrencyDropdownOpen(false);
    }
    if (!isClickInsideCurrencyModalDropdown) {
      setIsCurrencyModalDropdownOpen(false);
    }
    if (!isClickInsideCalendarDropdown) {
      setShowCalendar(false);
    }
    if (!isClickInsideModalCalendarDropdown) {
      setShowModalCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    if (props.trackReport && reportingServices) {
      const filtered = props.trackReport.filter(entry => {
        const timeZone = global.config.getTimeZone();
        const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
        const dateRange = reportingServices.servicesDateRange || [firstDayOfYear, new Date()];
        const trackEndDate = moment.tz(entry?.endTime, timeZone);
        const tagId = entry?.tag?._id;
        const clientId = entry.clientId?._id;
        const clientPreferredCurrency = entry.clientId?.preferred_currency || 'USD';
        const startDate = moment.tz(dateRange[0], timeZone);
        const endDate = moment.tz(dateRange[1], timeZone);
        const serviceName = entry?.tag?.tag_name;
        return (
          tagId &&
          (reportingServices.servicesTag && reportingServices.servicesTag.includes(tagId)) &&
          clientId &&
          (reportingServices.servicesClient && reportingServices.servicesClient.includes(clientId)) &&
          trackEndDate.isBetween(startDate, endDate, null, '[]') &&
          (reportingServices.servicesCurrency && reportingServices.servicesCurrency.includes(clientPreferredCurrency))
        );
      });
      setFilteredData(filtered);
    }
  }, [props.trackReport, reportingServices]);

  const changeDurationFormat = (sec) => {
    let second = sec;
    const hours = Math.floor(second / 3600);
    const minutes = Math.floor((second % 3600) / 60);
    const seconds = second % 60;
    const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    return formattedDuration;
  };

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const final = filteredData.map((item) => {
        const userCost = item.user.cost || 0;
        const projectSpecificCost = props.projectCost.find(cost => cost.userId === item.user?._id && cost.projectId === item.project?._id && !cost.is_delete);
        const finalUserCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : userCost;
        const userOverheadCost = item.user.overhead_cost || 0;
        const userTypeOfContract = item.user.typeOfContract || typeOfContract.Hourly_Rate;
        const paymentCurrency = item.user.payment_currency || 'USD';

        let finalCost;
        if (userTypeOfContract === typeOfContract.Hourly_Rate) {
          finalCost = finalUserCost;
        } else {
          finalCost = finalUserCost / parseInt(item.user?.monthly_hours ? item.user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
        }

        const cost = (finalCost * (item.duration / 3600)) + (userOverheadCost * (item.duration / 3600));
        const costConverted = convertCurrencyHelper(paymentCurrency, settings.currency, cost, forex);

        return {
          Service: item.tag?.tag_name || '',
          Hours: changeDurationFormat(item.duration),
          [`Cost (${settings.currency})`]: costConverted.toFixed(2)
        };
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(final);
      const colWidths = [
        { wpx: 120 },
        { wpx: 100 },
        { wpx: 100 }
      ];
      worksheet["!cols"] = colWidths;

      // Set alignment and font size for header row
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < colWidths.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center !important";
        style.alignment.vertical = "center !important";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set font size for data rows
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }

      global.config.activityLog(
        window.location.href,
        "Timer",
        "Timer Data exported to Excel"
      );

      const fileName = `TimeTracker_Report_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "TimeTracker Report");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Invoice",
        "Error occurred while exporting to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };

  return (
    <>
      <div className="report-top-button">
        <div className="button-inner-report button-inner-report-all-currency">
          <a onClick={exportToExcel}>
            <span className="icon-export">
              <img src={DownloadImage} alt="Export" />
            </span>
            Export Data
          </a>

           {/* sum of all currency */}

           <p className="all-currency-sec-inner">{global.config.locate(appSettings && appSettings.language, 'Sum all Currencies')}
              <input type="checkbox" checked={allCurrency && (sortedCurrencies.length ===selectedCurrency.length)} name="allCurrency" value={allCurrency} onChange={async (e) => {
                
                sumAllCurrencies(!allCurrency);
                if (!allCurrency === true) {
                 
                  setSelectedCurrency([sortedCurrencies]);
                  handleSelectAllCurrencyChange();
                  
                  
                  
                } else {
                
                 
                  const forex = await getForex(appSettings && appSettings.currency);
                  setSelectedForex(forex);
                  await dispatch(setForex(forex));

                  setSelectedCurrency([global.config.appCurrency()]);
                  handleCheckboxCurrencyChangeSingle(global.config.appCurrency());
                  // const allClientIds = [
                  //   ...new Set(
                  //     props.clients
                  //       .filter((client) => global.config.appCurrency() === client.preferred_currency
                  //       )
                  //       .map((client) => client._id)
                  //   ),
                  // ];

                  // setSelectedClients(allClientIds);
                  // // Update selected projects and sorted projects based on all clients
                  // const allProjects = props.project.filter((project) =>
                  //   allClientIds.includes(project.client_name._id)
                  // );
                  // const allProjectIds = allProjects.map((project) => project._id);

                  // setSelectedProject(allProjectIds);
                  // setSortedProjects(allProjects);
                  // selectProject(allProjectIds);
                  // Update selected clients based on all sorted currencies with unique IDs

                  // const allClientIds = [...new Set(
                  //   props.clients
                  //     .filter(client => sortedCurrencies.includes(client.preferred_currency))
                  //     .map(client => client._id)
                  // )];
            
                  // setSelectedClients(allClientIds);
                  // Update selected projects and sorted projects based on all clients
                  // const allProjects = props.project &&  props.project.filter(project => allClientIds.includes(project.client_name._id));
                  // const allProjectIds = allProjects.map(project => project._id);
            
                  // setSelectedProject(allProjectIds);
                  // setSortedProjects(allProjects);
                  // selectProject(allProjectIds);


                }

              }} />
            </p>


          {/* sum of all currency */}
        </div>
      </div>
      <div className="invoice-filter invoice-filter-report">
        <div className="invoice-filter-inner-section no-flter-all">
          <div className="filter-inner invoice-status">
            <label htmlFor="invoiceproject">Services</label>
            <div className="custom-dropdown1" ref={tagDropdownRef}>
              <div className="drop-down-inner">
                <div
                  className="selected-options1"
                  onClick={toggleTagDropdown}
                >
                  {selectedTag.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <div className="selected-option1" key={selectedTag[0]}>
                      {selectedTag.length === sortedTags.length ? (
                        "All"
                      ) : (
                        <>
                          <TruncateFilterFieldView
                            textContent={
                              sortedTags &&
                              Array.isArray(sortedTags) &&
                              sortedTags.find(
                                (item) => item._id === selectedTag[0]
                              )?.tag_name
                            }
                          />
                          {selectedTag.length > 1 &&
                            selectedTag.length !==
                            sortedTags.length && (
                              <div className="selected-option-count">
                                {selectedTag.length - 1}+
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  )}
                </div>
                {isTagDropdownOpen && (
                  <div className="dropdown-list1">
                    {sortedTags.length > 0 ? (
                      <>
                        <label className="checkbox-label1" key="selectAll">
                          <input
                            type="checkbox"
                            checked={selectAllTag}
                            onChange={handleSelectAllTagChange}
                          />
                          All
                        </label>
                        {sortedTags
                          .slice()
                          .sort((a, b) =>
                            a.tag_name
                              .toLowerCase()
                              .localeCompare(b.tag_name.toLowerCase())
                          )
                          .map((tag) => (
                            <label
                              className="checkbox-label1"
                              key={tag._id}
                            >
                              <input
                                type="checkbox"
                                checked={selectedTag.includes(tag._id)}
                                onChange={() =>
                                  handleCheckboxTagChange(tag._id)
                                }
                              />
                              {tag.tag_name &&
                                `${tag.tag_name}`}
                            </label>
                          ))}
                      </>
                    ) : (
                      <label className="checkbox-label1">
                        No tags found
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="filter-inner users">
            <label htmlFor="fname">Client Name</label>
            <div className="custom-dropdown1" ref={clientDropdownRef}>
              <div className="drop-down-inner">
                <div
                  className="selected-options1"
                  onClick={toggleClientDropdown}
                >
                  {selectedClients.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <div className="selected-option1" key={selectedClients[0]}>
                      {selectedClients.length === props.clients.length ? (
                        "All"
                      ) : (
                        <>
                          <TruncateFilterFieldView
                            textContent={
                              props.clients.find(
                                (item) => item._id === selectedClients[0]
                              )?.client_name
                            }
                          />
                          {selectedClients.length > 1 &&
                            selectedClients.length !== props.clients.length && (
                              <div className="selected-option-count">
                                {selectedClients.length - 1}+
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  )}
                </div>
                {isClientDropdownOpen && (
                  <div className="dropdown-list1">
                    {props.clients.length > 0 && (
                      <>
                        <label className="checkbox-label1" key="selectAll">
                          <input
                            type="checkbox"
                            checked={selectAllClient}
                            onChange={handleSelectAllClientChange}
                          />
                          All
                        </label>
                        {props.clients
                          .slice()
                          .sort((a, b) =>
                            a.client_name
                              .toLowerCase()
                              .localeCompare(b.client_name.toLowerCase())
                          )
                          .map((client) => (
                            <label className="checkbox-label1" key={client._id}>
                              <input
                                type="checkbox"
                                checked={selectedClients.includes(client._id)}
                                onChange={() =>
                                  handleCheckboxClientChange(client._id)
                                }
                              />
                              {client.client_name && `${client.client_name}`}
                            </label>
                          ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="filter-inner due-date">
            <label htmlFor="dueDate" className="calendar-icon">
              Timeframe
            </label>
            <div
              className="date-picker-outer date-picker-outer-all"
              ref={calendarRef}
            >
              <div className="custom-picker-icon custom-picker-icon-all">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class="rs-picker-toggle-caret rs-icon"
                  aria-label="calendar"
                  data-category="legacy"
                >
                  <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                </svg>
              </div>
              <div className="custoom-icon-calender custoom-icon-calender-all">
                <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range ">
                  <input
                    type="text"
                    value={
                      dateRange
                        ? `${new Date(dateRange[0]).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )} to ${new Date(dateRange[1]).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}`
                        : null
                    }
                    id="invoiceId"
                    name="invoiceId"
                    placeholder="All Time"
                    onClick={toggleCalendar}
                    autocomplete="off"
                    readOnly
                  />
                </div>
                <div className="date-range-new right-align">
                  {showCalendar && (
                    <Calendar
                      onCancel={cancelCalendar}
                      onChange={onDateChange}
                      initialRange={{
                        startDate: dateRange ? dateRange[0] : null,
                        endDate: dateRange ? dateRange[1] : null,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="filter-inner invoice-status currency-select">
            <label htmlFor="invoiceCurrency">Currency</label>
            <div className="custom-dropdown1" ref={currencyDropdownRef}>
              <div className="drop-down-inner">
                <div
                  className="selected-options1"
                  onClick={toggleCurrencyDropdown}
                >
                  {selectedCurrency.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <>
                      <div className="selected-option1">
                        {selectedCurrency.length === sortedCurrencies.length
                          ? "Sum All"
                          : sortedCurrencies.find(
                            (currency) => currency === selectedCurrency[0]
                          )}
                      </div>
                      {selectedCurrency.length > 1 &&
                        selectedCurrency.length !== sortedCurrencies.length && (
                          <div className="selected-option-count">
                            {selectedCurrency.length - 1}+
                          </div>
                        )}
                    </>
                  )}
                </div>
                {isCurrencyDropdownOpen && (
                  <div className="dropdown-list1">
                    {sortedCurrencies.length > 0 ? (
                      <>
                        <label className="checkbox-label1" key="selectAll">
                          <input
                            type="checkbox"
                            checked={
                              selectedCurrency.length ===
                              sortedCurrencies.length
                            }
                            onChange={handleSelectAllCurrencyChange}
                          />
                          Sum All
                        </label>
                        {sortedCurrencies.map((currency, index) => (
                          <label className="checkbox-label1" key={index}>
                            <input
                              type="checkbox"
                              checked={selectedCurrency.includes(currency)}
                              onChange={() =>
                                handleCheckboxCurrencyChange(currency)
                              }
                            />
                            {currency}
                          </label>
                        ))}
                      </>
                    ) : (
                      <label className="checkbox-label1">
                        No currencies found
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="truncate-filters-outer">
          <TruncateFilterFieldView
            clients={dummyClients}
            selectedClients={selectedClients}
            selectedTag={selectedTag}
            invTagList={dummyTages}
            handleClientFilterRemoval={(clientId) => handleCheckboxClientChange(clientId)}
            handleTagFilterRemoval={(tagId) => handleCheckboxTagChange(tagId)}
            clearFilters={clearFilters}
          />
        </div> */}

        <div className="filter-invoice-right apply-fliter-right">
          <button onClick={toggleFilterModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="17"
              viewBox="0 0 13 17"
              fill="none"
            >
              <path
                d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                fill="#6479F8"
              />
              <path
                d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                fill="#6479F8"
              />
              <path
                d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                fill="#6479F8"
              />
            </svg>
            All Filters
          </button>
        </div>

        <Modal
          isOpen={showFilterModal}
          onRequestClose={toggleFilterModal}
          contentLabel="Filter Modal"
          className="new-filter-modal"
          style={{
            content: {
              height: "50%",
              width: "50%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-25%, -25%)",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              padding: "20px",
              // overflow: "auto",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <div className="new-nodal-invoice-outer-sec">
            <div className="new-modal-top-sec">
              <div className="new-modal-heading">All filters</div>
              <a
                className="close-image-clr1"
                onClick={checkAndShowConfirmationModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path d="M1 13L13 1" stroke="black" />
                  <path d="M1 0.999999L13 13" stroke="black" />
                </svg>{" "}
              </a>
            </div>

            

            <div className="invoice-filter-inner-section">
            <div className="filter-inner invoice-project">
              <label htmlFor="invoiceproject">Services</label>
              <div className="custom-dropdown1" ref={tagModalDropdownRef}>
                <div className="drop-down-inner">
                  <div
                    className="selected-options1"
                    onClick={toggleTagModalDropdown}
                  >
                    {selectedTag.length === 0 ? (
                      <div className="placeholder">Select</div>
                    ) : (
                      <>
                        <div
                          className="selected-option1"
                          key={selectedTag[0]}
                        >
                          {selectedTag.length === sortedTags.length ? (
                            "All"
                          ) : (
                            <>
                              <TruncateFilterFieldView
                                textContent={
                                  sortedTags &&
                                  Array.isArray(sortedTags) &&
                                  sortedTags.find(
                                    (item) => item._id === selectedTag[0]
                                  )?.tag_name
                                }
                              />
                              {selectedTag.length > 1 &&
                                selectedTag.length !==
                                sortedTags.length && (
                                  <div className="selected-option-count">
                                    {selectedTag.length - 1}+
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {isTagModalDropdownOpen && (
                    <div className="dropdown-list1">
                      {sortedTags.length > 0 ? (
                        <>
                          <label className="checkbox-label1" key="selectAll">
                            <input
                              type="checkbox"
                              checked={selectAllTag}
                              onChange={handleSelectAllTagChange}
                            />
                            All
                          </label>
                          {sortedTags
                            .slice()
                            .sort((a, b) =>
                              a.tag_name
                                .toLowerCase()
                                .localeCompare(b.tag_name.toLowerCase())
                            )
                            .map((tag) => (
                              <label
                                className="checkbox-label1"
                                key={tag._id}
                              >
                                <input
                                  type="checkbox"
                                  checked={selectedTag.includes(
                                    tag._id
                                  )}
                                  onChange={() =>
                                    handleCheckboxTagChange(tag._id)
                                  }
                                />
                                {tag.tag_name &&
                                  `${tag.tag_name}`}
                              </label>
                            ))}
                        </>
                      ) : (
                        <label className="checkbox-label1">
                          No tags found
                        </label>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

              <div className="filter-inner users">
                <label htmlFor="fname">Client Name</label>
                <div className="custom-dropdown1" ref={clientModalDropdownRef}>
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleClientModalDropdown}
                    >
                      {selectedClients.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div
                            className="selected-option1"
                            key={selectedClients[0]}
                          >
                            {selectedClients.length === props.clients.length ? (
                              "All"
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    props.clients.find(
                                      (item) => item._id === selectedClients[0]
                                    )?.client_name
                                  }
                                />
                                {selectedClients.length > 1 &&
                                  selectedClients.length !==
                                  props.clients.length && (
                                    <div className="selected-option-count">
                                      {selectedClients.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {isClientModalDropdownOpen && (
                      <div className="dropdown-list1">
                        {props.clients.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllClient}
                                onChange={handleSelectAllClientChange}
                              />
                              All
                            </label>
                            {props.clients
                              .slice()
                              .sort((a, b) =>
                                a.client_name
                                  .toLowerCase()
                                  .localeCompare(b.client_name.toLowerCase())
                              )
                              .map((client) => (
                                <label
                                  className="checkbox-label1"
                                  key={client._id}
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectedClients.includes(
                                      client._id
                                    )}
                                    onChange={() =>
                                      handleCheckboxClientChange(client._id)
                                    }
                                  />
                                  {client.client_name &&
                                    `${client.client_name}`}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="filter-inner due-date">
                <label htmlFor="dueDate" className="calendar-icon">
                  Timeframe
                </label>
                <div
                  className="date-picker-outer date-picker-outer-all"
                  ref={calendarModalRef}
                >
                  <div className="custom-picker-icon custom-picker-icon-all">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 14 14"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class="rs-picker-toggle-caret rs-icon"
                      aria-label="calendar"
                      data-category="legacy"
                    >
                      <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                    </svg>
                  </div>
                  <div className="custoom-icon-calender custoom-icon-calender-all">
                    <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                      <input
                        type="text"
                        // onChange={}
                        value={
                          dateRange
                            ? `${new Date(dateRange[0]).toLocaleDateString("en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )} to ${new Date(dateRange[1]).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}`
                            : null
                        }
                        id="invoiceId"
                        name="invoiceId"
                        placeholder="All Time"
                        onClick={toggleModalCalendar}
                        autocomplete="off"
                      />
                    </div>

                    {/* Date range selector */}
                    <div className="date-range-new">
                      {showModalCalendar && (
                        <Calendar
                          onCancel={cancelModalCalendar}
                          onChange={onDateChange}
                          initialRange={{
                            startDate: dateRange ? dateRange[0] : null,
                            endDate: dateRange ? dateRange[1] : null,
                          }}
                        />
                      )}
                    </div>
                    {/* Date range selector */}
                  </div>
                </div>
              </div>
              <div className="filter-inner invoice-status">
                <label htmlFor="invoiceCurrency">Currency</label>
                <div
                  className="custom-dropdown1"
                  ref={currencyModalDropdownRef}
                >
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleCurrencyModalDropdown}
                    >
                      {selectedCurrency.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1">
                            {selectedCurrency.length === sortedCurrencies.length
                              ? "Sum All"
                              : sortedCurrencies.find(
                                (currency) => currency === selectedCurrency[0]
                              )}
                          </div>
                          {selectedCurrency.length > 1 &&
                            selectedCurrency.length !==
                            sortedCurrencies.length && (
                              <div className="selected-option-count">
                                {selectedCurrency.length - 1}+
                              </div>
                            )}
                        </>
                      )}
                    </div>
                    {isCurrencyModalDropdownOpen && (
                      <div className="dropdown-list1">
                        {sortedCurrencies.length > 0 ? (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={
                                  selectedCurrency.length ===
                                  sortedCurrencies.length
                                }
                                onChange={handleSelectAllCurrencyChange}
                              />
                              Sum All
                            </label>
                            {sortedCurrencies.map((currency, index) => (
                              <label className="checkbox-label1" key={index}>
                                <input
                                  type="checkbox"
                                  checked={selectedCurrency.includes(currency)}
                                  onChange={() =>
                                    handleCheckboxCurrencyChange(currency)
                                  }
                                />
                                {currency}
                              </label>
                            ))}
                          </>
                        ) : (
                          <label className="checkbox-label1">
                            No currencies found
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="filter-buttons">
              <button className="clear-all" onClick={clearFilters}>
                Clear All
              </button>
              <button className="apply-filter" onClick={applyFilters}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                >
                  <path
                    d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                    fill="white"
                  />
                  <path
                    d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                    fill="white"
                  />
                  <path
                    d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                    fill="white"
                  />
                </svg>
                Apply Filters
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={showConfirmationModal}
          onRequestClose={() => setShowConfirmationModal(false)}
          contentLabel="Confirmation Modal"
          ariaHideApp={false}
          className="confirmation-modal"
          overlayClassName="confirmation-modal-overlay"
        >
          <div className="modal-content">
            {/* <h2>Are you sure?</h2> */}
            <p>Are you sure you want to clear the filters?</p>
            <div className="modal-footer">
              <button
                className="cancel-btn"
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </button>
              <button className="confirm-btn" onClick={handleApplyConfirm}>
                Yes, Clear
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ServicesReportFilter;
