import axios from "axios";
import moment from "moment";
import "./ClientDocument.css";
import Modal from "react-modal";
import { saveAs } from "file-saver";
import { AES, enc } from 'crypto-js';
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { ResizeObserver } from 'resize-observer';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PlusIcon from "../../../images/icons/plus-icon.svg";
import LoadingSpinner from "../../../components/Animations/LoadingSpinner";
import React, { useState, useEffect, useRef } from "react";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import LeftArrow from "../../../images/icons/blue-left-arrow.svg";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { useProjectList } from "../../../features/projects/ProjectList.jsx";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "../../../images/icons/delete-blue.svg";
import DownArrow from "../../../images/icons/line-down-arrow.svg";
import EyeIcon from "../../../images/icons/blue-eye-icon.svg";
import Device from "../../../images/icons/my-device.svg";
import Drive from "../../../images/icons/drive.svg";
import Dropbox from "../../../images/icons/dropbox.svg";
import OneDriveImg from "../../../images/icons/onedrive.svg";
import GoogleDriveModal from "./Modal/GoogleDriveModal";
import { postDocument, useDocumentList, deviceFileUpload } from "../../../features/client/document/documentService.js";
import OD from "./OneDrive.jsx";
// 
import useDrivePicker from 'react-google-drive-picker';
import DropboxChooser from "react-dropbox-chooser";
import TruncateFieldView from "../../helpers/truncateFieldView.js";
const APP_KEY = "ux1n99h22w28h1q"//"peoq7vpk18mo309";

// 
const handleDownload = async (rowData, token) => {
    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    // Logic to handle download
    // For example, you can use window.location.href to navigate to the download link
    if (rowData.doc_from === "myDevice") {

        // window.open(`./uploads/documents/client-documents/${rowData.doc_name}`, '_blank');
        try {
            const response = await axios.get(`/api/client/doc/download/${rowData.doc_name}`, {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const pdfBlob = new Blob([response.data], { type: "application/pdf" });
            saveAs(pdfBlob, `${rowData.doc_name}`);
        } catch (error) {
            console.error(error);
            global.config.slackMessage(error.toString());
            // if (error.response?.data?.status === "deleted") {
            //     localStorage.removeItem("user");
            //     dispatch(logout());
            //     dispatch(reset());
            //     dispatch(invoiceReset());
            //     dispatch(headerReset());
            //     dispatch(appSettingsReset());
            //     toast.error("This user is deleted!", {
            //         position: "bottom-right",
            //         autoClose: 1500,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: "light",
            //     });
            //     navigate("/");
            //     return;
            // }
            toast.error("Error while downloading file!", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            // Handle error
        }

    } else {

        window.open(rowData.doc_url, '_blank');

    }

};

const handleView = async (rowData) => {
    if (rowData.doc_from === "myDevice") {
        window.open(`../../uploads/documents/client-documents/${rowData.doc_name}`, '_blank');
    } else {
        window.open(rowData.doc_url, '_blank');
    }
};

export function downArrow(rowData, token) {
    return (
        <a onClick={() => handleDownload(rowData, token)} download>
            <img src={DownArrow} alt="down-arrow-icon" />
        </a>
    );
}
export function eyeIcon(rowData) {
    return (
        <div className="data-table-eye-icon" onClick={() => handleView(rowData)} >
            <a onClick={() => handleView(rowData)} target="_blank">
                <img src={EyeIcon} alt="down-arrow-icon" />
            </a>
        </div>
    );
}

const ClientDocument = (props) => {

    const [openPicker, data, authResponse] = useDrivePicker();

    const { encryptedId } = useParams();
    const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
    const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
    const id = decryptedBytes.toString(enc.Utf8);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isGoogleDriveOpen, setIsGoogleDriveOpen] = useState(false);
    const [filter, setFilter] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectAllByPage, setSelectAllByPage] = useState({});
    const [isDocDeleteModalOpen, setIsDocDeleteModalOpen] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isOneDriveSubmitLoading, setIsOneDriveSubmitLoading] = useState(false);
    const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
    const targetRef = useRef(null);
    const showingDropdownRef = useRef(null);
    const fileInputRef = useRef(null);
    const { appSettings } = useSelector((state) => state.appSettings);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(0); // Reset page to the first page
    };

    let token = null;
    const { user } = useSelector((state) => state.auth);
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    const { data: documents = [], isLoading: isFetchingDocument, refetch: documentRefetch } = useDocumentList(token, id);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            // Your resize observer logic here
        });

        if (targetRef.current) {
            resizeObserver.observe(targetRef.current);
        }
        // Clean up the observer on component unmount
        return () => {
            if (targetRef.current) {
                resizeObserver.unobserve(targetRef.current);
            }
        };
    }, []);

    // useEffect(() => {
    //     dispatch(setHeaderText("Client Overview"));
    // }, [dispatch, token, user._id, user.userType]);


    const handleClickOutsideDropdown = (event) => {
        const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);

        if (!isClickInsideShowingDropdown) {
            setShowingDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);

    const deleteIcon = (rowData) => {
        return (
            <a onClick={() => handleDocDelete(rowData, token)} download>
                <img src={DeleteIcon} alt="down-arrow-icon" />
            </a>
        );
    };

    const handleDocDelete = async (rowData) => {
        setSelectedId(rowData._id);
        setIsDocDeleteModalOpen(true);
    };

    const handleConfirmDocDelete = async () => {
        try {
            setIsSubmitLoading(true);
            const response = await axios.get(`/api/client/document/delete/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            toast.success("Document removed successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // dispatch(setDocAdded());
            global.config.activityLog(window.location.href, 'Doc', `Doc (${selectedId}) deleted successfully`);
            documentRefetch();
            // navigate(`/client/projects/${encodeURIComponent(AES.encrypt(props.id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`);
        } catch (error) {
            console.error(error);
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setIsSubmitLoading(false);
            handleCancelDocDelete();
        }
    };

    const handleCancelDocDelete = () => {
        setSelectedId("");
        setIsDocDeleteModalOpen(false);
    };


    const onPageChange = (e) => {
        const newPage = e.page;
        // Store the current "Select All" state for the current page
        setSelectAllByPage((prevSelectAllByPage) => ({
            ...prevSelectAllByPage,
        }));
        setCurrentPage(e.page);
    };

    const handleOpenCreateModal = () => {
        setIsCreateModalOpen(true);
    };

    const handleCloseCreateModal = () => {
        setIsCreateModalOpen(false);
    };
    const handleDriveClick = () => {
        setIsCreateModalOpen(false);
        setIsGoogleDriveOpen(true);
    };

    const filteredData = documents.filter((item) => {
        const searchTerm = filter.trim().toLowerCase();
        const hasMatch = item.doc_name.toLowerCase().includes(searchTerm) ||
            item.doc_id.toLowerCase().includes(searchTerm) ||
            item.doc_type.toLowerCase().includes(searchTerm);
        return hasMatch;
    });

    const totalResults = filteredData.length;

    const handleFileUpload = async (event) => {
        try {
            setIsSubmitLoading(true); // Set loading state to true to indicate file upload is in progress
            const file = event.target.files[0]; // Get the first file selected by the user
            const fileSizeLimit = 20 * 1024 * 1024;
            if (file.size > fileSizeLimit) {
                toast.error("File size exceeds the limit of 20 MB!!", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setIsCreateModalOpen(false);
                return;
            }
            const client_id = id;
            await deviceFileUpload(token, file, client_id);
            setIsCreateModalOpen(false);
            documentRefetch();
        } catch (error) {
            console.error('Error uploading file:', error);
            global.config.slackMessage(error.toString());
        } finally {
            setIsSubmitLoading(false); // Set loading state to false when file upload process is complete
        }
    };

    // google drive
    const handleCloseGoogleDiveModal = () => {
        setIsGoogleDriveOpen(false);
    };
    const handleOpenGoogleDrivePicker = () => {
        openPicker({
            clientId: "142781427319-sg39thdofg0cjgjp3bh58is28qr0dd03.apps.googleusercontent.com",
            developerKey: "AIzaSyBFnddMepqQXNxvIswA5jNv0CMQrZj9qY0",
            viewId: "DOCS",
            // token: token, // pass oauth token in case you already have one
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: true,
            mimeType: "*/*",
            // customViews: customViewsArray, // custom view
            callbackFunction: async (data) => {
                if (data.action === 'cancel') {
                    console.log('User clicked cancel/close button')
                }
                if (data.action === 'picked') {
                    const doc_embedUrl = data.docs[0].embedUrl;
                    const doc_iconUrl = data.docs[0].iconUrl;
                    const doc_name = data.docs[0].name;
                    const doc_organizationDisplayName = data.docs[0].organizationDisplayName;
                    const doc_type = data.docs[0].type;
                    const doc_url = data.docs[0].url;
                    const doc_id = "DOC1";
                    const doc_from = "gDrive";
                    const is_delete = false;
                    const expired_at = null;
                    const client_id = id;
                    const res = await postDocument(token, doc_id, client_id, doc_from, doc_type, doc_name, doc_embedUrl,
                        doc_url, doc_organizationDisplayName, doc_iconUrl, is_delete, expired_at);
                    setIsCreateModalOpen(false);
                    documentRefetch();
                }

            },
        });
    }
    // google drive

    const handleSuccess = async (files) => {

        if (files.length > 0) {
            const bytes = files[0].bytes;
            const icon = files[0].icon;
            // const id = id;
            const link = files[0].link;
            const linkType = files[0].linkType;
            const name = files[0].name;
            const thumbnailLink = files[0].thumbnailLink;

            const doc_embedUrl = link;
            const doc_iconUrl = icon;
            const doc_name = name;
            const doc_organizationDisplayName = name;
            const doc_type = null;
            const doc_url = link;
            const doc_id = id;
            const doc_from = "dropbox";
            const is_delete = false;
            const expired_at = null;
            const client_id = id;

            await postDocument(token, doc_id, client_id, doc_from, doc_type, doc_name, doc_embedUrl,
                doc_url, doc_organizationDisplayName, doc_iconUrl, is_delete, expired_at);
            setIsCreateModalOpen(false);
            documentRefetch();
        }

    }

    const handleOpenOneDrive = async () => {
        // if (!oneDriveScriptLoaded.current) {
        //     toast.error("OneDrive script is not loaded yet.", {
        //         position: "bottom-right",
        //         autoClose: 2000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //     return;
        // }

        const odOptions = {
            clientId: "0c0164c9-d434-449d-8c55-3316d55f87a4",
            action: "share",
            multiSelect: true,
            advanced: {
                filter: ".pdf,.docx,.pptx"
            },
            success: async (files) => {
                const file = files.value[0];
                const doc_embedUrl = file['@microsoft.graph.downloadUrl'];
                const doc_iconUrl = file.thumbnails[0].small.url;
                const doc_name = file.name;
                const doc_organizationDisplayName = file.parentReference.driveId;
                const doc_type = file.file.mimeType;
                const doc_url = file.webUrl;
                const doc_id = file.id;
                const doc_from = "oneDrive";
                const is_delete = false;
                const expired_at = null;
                const client_id = id;
                const res = await postDocument(token, doc_id, client_id, doc_from, doc_type, doc_name, doc_embedUrl,
                    doc_url, doc_organizationDisplayName, doc_iconUrl, is_delete, expired_at);
                setIsCreateModalOpen(false);
                documentRefetch();
            },
            cancel: function () {
                console.log("File picker cancelled");
            },
            error: function (error) {
                console.error("OneDrive picker error: ", error);
            },
        };

        try {
            window.OneDrive.open(odOptions);
        } catch (error) {
            console.error('Error opening OneDrive picker:', error);
            global.config.slackMessage(error.toString());
        }
    };

    // oneDrive
    const KEY = "0c0164c9-d434-449d-8c55-3316d55f87a4";  //"3377c05f-d7bb-46e4-9008-23c7397c3d20";

    const handleCancel = () => console.log("CANCELLED");

    const handleOneDriveSuccess = (files) => console.log("SUCCESS: ", files);

    const handleError = (err) => console.log("ERROR: ", err);

    const launchOneDrivePicker = async (
        oneDriveApplicationId,
        action = 'download',
        multiSelect = true,
        advancedOptions = {}
    ) => {
        const odOptions = {
            clientId: "0c0164c9-d434-449d-8c55-3316d55f87a4",
            action: action,
            multiSelect: multiSelect,
            openInNewWindow: true,
            advanced: advancedOptions,
            success: (files) => handleOneDriveSuccess(files),
            cancel: () => handleCancel(),
            error: (e) => handleError(e),
        };
        window.OneDrive.open(odOptions);
    };

    const handleOneDriveBoxClick = () => {
        if ((!isOneDriveSubmitLoading && !isSubmitLoading) && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleOneDriveFileUpload = async (event) => {
        try {
            setIsOneDriveSubmitLoading(true);
            const file = event.target.files[0];
            const fileSizeLimit = 50 * 1024 * 1024;
            if (file.size > fileSizeLimit) {
                toast.error("File size exceeds the limit of 50 MB!!", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setIsCreateModalOpen(false);
                return;
            }

            const formData = new FormData();
            formData.append("file", file);
            formData.append("client_id", id);
            formData.append("doc_name", file.name);
            formData.append("doc_type", file.type);

            await axios.post("/api/client/onedrive-document", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            });

            toast.success("Document uploaded file to OneDrive successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsCreateModalOpen(false);
            documentRefetch();
        } catch (error) {
            console.error("Error uploading file to OneDrive:", error);
            global.config.slackMessage(error.toString());
            toast.error(`Error while uploading file to OneDrive! ${error}`, {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsOneDriveSubmitLoading(false);
        }
    };

    // const handleClick = () => {
    //     launchOneDrivePicker(KEY, 'share');
    // };

    // oneDrive


    const convertCreatedDate = (rowData) => {
        const timestamp = rowData.created_at;
        const formattedDate = moment(timestamp).format('MMMM DD, YYYY');
        return formattedDate;
    }

    const getFileType = (rowData) => {
        var fileType = null;
        if (rowData.doc_from) {
            fileType = rowData?.doc_type?.replace(/\./g, "");
        } else {
            fileType = rowData.doc_type;
        }
        return fileType;
    }

    return (
        <div className="client-document-outer">
            <div className="overview-top-right client-document-outer-top-section" ref={targetRef} >
                <div className="range-timeoff">
                    <div className="text-arrow-outer">
                        <div className="text-arrow-inner">
                            <Link to={`/client/details/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`}>
                                <div className="text-arrow">
                                    <div className="left-arrow">
                                        <img src={LeftArrow} alt="left-arrow" />
                                    </div>
                                    <div className="top-text">
                                        Back to Overview
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="showing-count showing-count-timeoff">
                    <div className="filter-inner showing-result show-result-team-fill">
                        <label>Showing</label>

                        <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                            <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
                                {totalResults < 10 ? totalResults.toString() : rowsPerPage.toString()}
                            </div>
                            {showingDropdownOpen && (
                                <div className="custom-dropdown-new">
                                    <ul className="showing-options-list">
                                        {totalResults >= 10 && <li className={rowsPerPage === 10 ? 'selected-item' : ''} onClick={() => { setRowsPerPage(10); setCurrentPage(0); setShowingDropdownOpen(false); }}>10</li>}
                                        {totalResults >= 20 && <li className={rowsPerPage === 20 ? 'selected-item' : ''} onClick={() => { setRowsPerPage(20); setCurrentPage(0); setShowingDropdownOpen(false); }}>20</li>}
                                        {totalResults >= 30 && <li className={rowsPerPage === 30 ? 'selected-item' : ''} onClick={() => { setRowsPerPage(30); setCurrentPage(0); setShowingDropdownOpen(false); }}>30</li>}
                                        {totalResults >= 40 && <li className={rowsPerPage === 40 ? 'selected-item' : ''} onClick={() => { setRowsPerPage(40); setCurrentPage(0); setShowingDropdownOpen(false); }}>40</li>}
                                        {totalResults >= 10 && <li className={totalResults >= 10 && rowsPerPage === totalResults ? 'selected-item' : ''} onClick={() => { setRowsPerPage(totalResults); setCurrentPage(0); setShowingDropdownOpen(false); }}>ALL</li>}
                                        {/* Add more conditions as needed */}
                                        {totalResults < 10 ? (
                                            <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { setRowsPerPage(totalResults); setCurrentPage(0); setShowingDropdownOpen(false); }}>{totalResults}</li>
                                        ) : null}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <label>of {totalResults} Results</label>
                    </div>
                </div>
            </div>

            <div className="user-outer-timeoff document-client-top-sec"  >
                <div className="invoice-top">
                    <div className="button-sec-invoice">
                        <a className="btn-forpop-initem" onClick={handleOpenCreateModal}>
                            <span className="icon-invoice">
                                <img src={PlusIcon} alt="Request Time Off" />
                            </span>
                            New Document
                        </a>
                        <div className="modal-invite-main-outter">
                            <Modal
                                className="document-upoload-modal"
                                isOpen={isCreateModalOpen}
                                onRequestClose={(!isOneDriveSubmitLoading && !isSubmitLoading) ? handleCloseCreateModal : undefined}
                                contentLabel="Request Time Off"
                                style={{
                                    content: {
                                        height: "41%",
                                        width: "30%",
                                        position: "fixed",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-40%, -50%)",
                                        parent: document.querySelector(".admin-outer.time.tracker"),
                                    },
                                }}
                            >
                                <div className="modal timeoff-modal">
                                    <div className="modal-top-sec-with-close">
                                        <div className="modal-client-head">
                                            <h2 className="">Upload Document</h2>
                                        </div>
                                        <div className="modal-close-invite outter-modal-main">
                                            <a className="" onClick={handleCloseCreateModal}>
                                                <img src={crssrImage} alt="Edit Icon" />{" "}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="form-field-client-outer">
                                        <div className="subtitle-document">Please choose a service to connect</div>
                                        <div className="document-upload-outer">
                                            <div className="document-upload-inner">

                                                {/* My device */}
                                                <div className="document-upload-box" onChange={handleFileUpload}>
                                                    <div className="icon-upload">
                                                        <img src={Device} alt="My Device" />
                                                    </div>
                                                    <div className="upload-name">
                                                        {isSubmitLoading ? (
                                                            <div className="outter-load-table">
                                                                <ThreeDots
                                                                    height="20"
                                                                    width="20"
                                                                    radius="9"
                                                                    color="#405BFF"
                                                                    ariaLabel="three-dots-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClassName=""
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        ) : (
                                                            "My Device"
                                                        )}
                                                        <input type="file" disabled={isSubmitLoading || isOneDriveSubmitLoading} />
                                                    </div>
                                                </div>
                                                {/* My device */}

                                                {/* google drive */}
                                                <div className="document-upload-box" onClick={() => handleOpenGoogleDrivePicker()}>
                                                    <div className="icon-upload">
                                                        <img src={Drive} alt="My Device" />
                                                    </div>
                                                    <div className="upload-name">
                                                        Drive
                                                    </div>
                                                </div>
                                                {/* google drive */}

                                                {/* Dropbox */}
                                                <DropboxChooser appKey={APP_KEY}
                                                    success={handleSuccess}
                                                    cancel={() => console.log("closed")}
                                                >
                                                    <div className="document-upload-box">
                                                        <div className="icon-upload">
                                                            <img src={Dropbox} alt="Dropbox" />
                                                        </div>
                                                        <div className="upload-name">
                                                            Dropbox
                                                        </div>
                                                    </div>
                                                </DropboxChooser>
                                                {/* Dopbox */}

                                                {/* one drive */}
                                                <div className="document-upload-box" onClick={handleOneDriveBoxClick}>
                                                    <div className="icon-upload">
                                                        <img src={OneDriveImg} alt="OneDrive" />
                                                    </div>

                                                    <div className="upload-name">
                                                        <label>
                                                            {isOneDriveSubmitLoading ? (
                                                                <div className="outter-load-table">
                                                                    <ThreeDots
                                                                        height="20"
                                                                        width="20"
                                                                        radius="9"
                                                                        color="#405BFF"
                                                                        ariaLabel="three-dots-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClassName=""
                                                                        visible={true}
                                                                    />
                                                                </div>
                                                            ) : ("OneDrive")}
                                                            <input
                                                                type="file"
                                                                ref={fileInputRef}
                                                                style={{ display: "none" }}
                                                                onChange={handleOneDriveFileUpload}
                                                                disabled={isSubmitLoading || isOneDriveSubmitLoading}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <div className="document-upload-box" onClick={handleOpenOneDrive}>
                                                    <div className="icon-upload">
                                                        <img src={OneDriveImg} alt="OneDrive" />
                                                    </div>
                                                    <div className="upload-name">
                                                        OneDrive
                                                    </div>
                                                </div> */}
                                                {/* one drive */}
                                                {/* <OD/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                isOpen={isGoogleDriveOpen}
                                onRequestClose={() => setIsGoogleDriveOpen(false)}
                                contentLabel="Google Dive"
                                className="document-upoload-modal google-drive-modal"
                                style={{
                                    content: {
                                        height: "41%",
                                        width: "30%",
                                        position: "fixed",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-40%, -50%)",
                                    },
                                }}
                            >
                                <div className="modal timeoff-modal">
                                    <div className="modal-top-sec-with-close">
                                        <div className="modal-client-head">
                                            <h2 className="">Upload Document</h2>
                                        </div>
                                        <div className="modal-close-invite outter-modal-main">
                                            <a className="" onClick={handleCloseGoogleDiveModal}>
                                                <img src={crssrImage} alt="Edit Icon" />{" "}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="form-field-client-outer">
                                        <GoogleDriveModal />
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div className="search-sec">
                        <div class="search-container">
                            <input
                                type="text"
                                onChange={handleFilterChange}
                                value={filter}
                                class="search-input"
                                placeholder="Search by document name"
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    className="delete-modal"
                    isOpen={isDocDeleteModalOpen}
                    onRequestClose={handleCancelDocDelete}
                    contentLabel="Confirm Delete"
                    style={{
                        content: {
                            height: "41%",
                            width: "30%",
                            position: "fixed",
                            top: "36%",
                            left: "50%",
                            transform: "translate(-19.75%, 0%)",
                            parent: document.querySelector(".admin-outer.time.tracker"),
                        },
                    }}
                >
                    <div className="delete-modal-content">
                        <h2>Confirm Delete</h2>
                        <p>
                            Are you sure you want to delete this Document?
                        </p>
                        <div className="delete-modal-buttons">
                            <button className="buttons-rejected" onClick={handleConfirmDocDelete} disabled={isSubmitLoading}>
                                {isSubmitLoading ?
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="white"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div> : "Delete"}
                            </button>
                            <button onClick={handleCancelDocDelete} disabled={isSubmitLoading}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal>
                {isFetchingDocument == true ? (
                    <div className="outter-load-table">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#405BFF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <div className="datatable-team-maintb">
                        <DataTable
                            value={filteredData}
                            removableSort
                            tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
                            paginator={filteredData.length > 10}
                            paginatorClassName="recent-invoices-pagination"
                            rows={rowsPerPage}
                            first={currentPage * rowsPerPage}
                            onPage={onPageChange}
                            emptyMessage="No document found"
                        >
                            <Column
                                field="doc_id"
                                header="Doc ID"
                                sortable
                                style={{ width: "19.5%", paddingRight: "20px" }}
                            ></Column>
                            <Column
                                field="doc_type"
                                header="Type"
                                body={getFileType}
                                sortable
                                style={{ width: "16.4%", paddingRight: "20px" }}
                            ></Column>
                            <Column
                                field="doc_name"
                                header="Name"
                                body={(rowData) => (
                                    <TruncateFieldView textContent={rowData.doc_name} />
                                )}
                                sortable
                                style={{ width: "19%", paddingRight: "20px" }}
                            ></Column>
                            <Column
                                field="created_at"
                                body={convertCreatedDate}
                                header="Issue Date"
                                sortable
                                style={{ width: "20.4%", paddingRight: "20px" }}
                            ></Column>
                            {/* <Column
                                field="expired_at"
                                //   body={dateConvert}
                                header="Expiration Date"
                                sortable
                                style={{ width: "17.4%", paddingRight: "20px" }}
                            ></Column> */}
                            <Column
                                field="_id"
                                body={eyeIcon}
                                style={{ width: "3%", paddingRight: "20px" }}
                            ></Column>
                            <Column
                                field="_id"
                                body={(rowData) => downArrow(rowData, token)}
                                style={{ width: "2%", paddingRight: "20px" }}
                            ></Column>
                            <Column
                                field="_id"
                                body={(rowData) => deleteIcon(rowData, token)}
                                style={{ width: "3%", paddingRight: "20px" }}
                            ></Column>
                        </DataTable>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClientDocument;