import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { reportingFilterDateRangesConst } from '../../../../../../constants/reportingFilterDateRangesConst';
import { reportingFilterComparisonConst } from '../../../../../../constants/reportingFilterComparisonConst';
import { clientTotalRevenueComparisonFilter } from '../../Services/reportRevenue';
import typeOfContract from '../../../../../../constants/typeOfContract';

const data = [
  {
    name: 'January',
    currentYear: 4000,
    lastYear: 2400,
    amt: 2400,
  },
  {
    name: 'February',
    currentYear: 3000,
    lastYear: 1398,
    amt: 2210,
  },
  {
    name: 'March',
    currentYear: 2000,
    lastYear: 800,
    amt: 2290,
  },
  {
    name: 'April',
    currentYear: 2780,
    lastYear: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    currentYear: 1890,
    lastYear: 4800,
    amt: 2181,
  },
  {
    name: 'June',
    currentYear: 2390,
    lastYear: 3800,
    amt: 2500,
  },
  {
    name: 'July',
    currentYear: 3490,
    lastYear: 4300,
    amt: 2100,
  },
  {
    name: 'August',
    currentYear: 1490,
    lastYear: 4300,
    amt: 2100,
  },
  {
    name: 'September',
    currentYear: 2490,
    lastYear: 1300,
    amt: 2100,
  },
  {
    name: 'October',
    currentYear: 3490,
    lastYear: 2300,
    amt: 2100,
  },
  {
    name: 'November',
    currentYear: 3490,
    lastYear: 1300,
    amt: 2100,
  },
  {
    name: 'December',
    currentYear: 2490,
    lastYear: 2300,
    amt: 2100,
  },
];

const TotalRevenueFiterGraph = ({
  clientInvoices,
  clientInvoicesLoading,
  clientInvoicesError,
  trackReport,
  trackReportLoading,
  trackReportError,
  projectCost,
  expenses,
  selectedDateRange,
  selectedComparison,
  selectedFrequency,
  selectedYearRange,
  selectedMonthRange,
  selectedComparisonYear,
  selectedComparisonMonth,
}) => {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const processedData = useMemo(() => {
    return clientTotalRevenueComparisonFilter(
      trackReport,
      projectCost,
      clientInvoices,
      reportingRevenue,
      settings.currency,
      forex,
      typeOfContract,
      expenses,
      selectedDateRange,
      selectedComparison,
      selectedFrequency,
      selectedYearRange,
      selectedMonthRange,
      selectedComparisonYear,
      selectedComparisonMonth,
    );
  }, [
    trackReport,
    projectCost,
    clientInvoices,
    reportingRevenue,
    settings.currency,
    forex,
    typeOfContract,
    expenses,
    selectedDateRange,
    selectedComparison,
    selectedFrequency,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
  ]);

  const currentYear = reportingRevenue.revenueYear || new Date().getFullYear();
  const previousYear = currentYear - 1;
  const isCurrentYear = currentYear === new Date().getFullYear();

  if (clientInvoicesLoading || trackReportLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (clientInvoicesError || trackReportError) return <div>Error loading data.</div>;

  const getBarName = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
    const rangeNames = {
      [reportingFilterDateRangesConst.This_Year]: 'This Year',
      [reportingFilterDateRangesConst.This_Quarter]: 'This Quarter',
      [reportingFilterDateRangesConst.First_Half]: 'First Half',
      [reportingFilterDateRangesConst.This_Month]: 'This Month',
      [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
      [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
    };

    const comparisonNames = {
      [reportingFilterComparisonConst.Last_Year]: 'Last Year',
      [reportingFilterComparisonConst.Last_Quarter]: 'Last Quarter',
      [reportingFilterComparisonConst.Second_Half]: 'Second Half',
      [reportingFilterComparisonConst.Last_Month]: 'Last Month',
      [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
      [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
    };

    const rangeName = rangeNames[selectedRange] || 'Current Range';
    const comparisonName = comparisonNames[selectedComparison] || 'Comparison Range';

    return isCurrent
      ? `${rangeName}`
      : `${comparisonName}`;
  };

  return (
    <div style={{ width: '100%', height: 542 }}>
      <ResponsiveContainer>
        <LineChart
          layout="horizontal"
          data={processedData}
          margin={{
            top: 20,
            right: 30,
            left: -15,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" type="category" padding={{ left: 50 }} tickMargin={20} />
          <YAxis type="number" />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(value)}`} />
          <Legend />
          <Line dataKey="currentYear" name={getBarName(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} stroke="#96A8BA" />
          <Line dataKey="lastYear" name={getBarName(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} stroke="#6479F8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default TotalRevenueFiterGraph;
