import css from "./User.css";
import Modal from "react-modal";
import axios from "axios";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { ThreeDots } from "react-loader-spinner";
import { DataTable } from "primereact/datatable";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import addicon from "../../images/icons/addicon.svg";
import BankAccountVerification from "./elements/Bank";
import InviteIcon from "../../images/icons/invite.svg";
import { useUserProfiles, useUserType } from "./userProfileQueries";
import { useSelector, useDispatch } from "react-redux";
import UserIcon from "../../images/icons/user-icon.svg";
import { RenderUserCTA } from "../../helpers/invoiceHelpers";
import profile_image from "../../images/user/Default_pfp.png";
import dletImage from "../../images/singleinvoice/delete.svg";
import crssrImage from "../../images/singleinvoice/cross-red.svg";
import Plus from "../../images/icons/plus-black.svg";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { setNotification } from "../../features/notification/notificationSlice.js";
import userCategory from "../../constants/userCategory";
import userTypeName from "../../constants/userType";
import DownloadImage from "../../images/icons/download-icon.svg";
import { motion } from 'framer-motion';
import moment from 'moment-timezone';
import { useContractTypes } from "./UserDetails/UserProfile/useUserView.jsx";
import TruncateFilterFieldView from "../helpers/truncateFilterFieldView.js";

const useRenderMemberName = (rowData) => {


  const [showFullname, setShowFullname] = useState(false);

  const fullName = rowData?.user_category && [userCategory.Business, userCategory.Vendor, userCategory.Client].includes(rowData.user_category) ? (rowData.company_first_name ? `${rowData.company_first_name} ${rowData.company_last_name ? rowData.company_last_name : ''}` : `${rowData.company_name} (Company)`) : rowData.first_name + " " + rowData.last_name;
  const displayName = showFullname
    ? fullName
    : fullName && fullName.length <= 21
      ? fullName
      : fullName && fullName.slice(0, 21) + "...";

  return (
    <div
      className={`member-name${showFullname ? " active" : ""}`}
      // onMouseEnter={toggleShowFullname}
      onMouseEnter={() => {
        if (fullName.length > 21) {
          setShowFullname(true);
        }
      }}
      onMouseLeave={() => setShowFullname(false)}
    >
      {rowData.profile_image !== null && rowData.profile_image !== "null" ? (
        <img
          src={`/uploads/lowProfileImage/${rowData.profile_image}`}
          alt={fullName}
          className="member-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/uploads/lowProfileImage/user_default.png';
          }}
        />
      ) : (
        <img src={profile_image} alt={fullName} className="member-image" />
      )}
      <span>{displayName}</span>
    </div>
  );
};

const Users = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [filter, setFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [emails, setEmails] = useState([]);
  const [userType, setUserType] = useState("");
  const statusDropdownRef = useRef(null);
  const roleDropdownRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMailLoading, setIsMailLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [roleDropdownOpen, setRoleDropdownOpen] = useState(false);
  const userTypeDropdownRef = useRef(null);
  const [bulkImportFile, setBulkImportFile] = useState(null);
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [userFilter, setUserFilter] = useState("All");
  const [selectAllTeam, setSelectAllTeam] = useState(true);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);
  const [isTeamModalDropdownOpen, setIsTeamModalDropdownOpen] = useState(false);
  const [isBulkImportLoading, setIsBulkImportLoading] = useState(false);
  const [userTypeDropdownOpen, setUserTypeDropdownOpen] = useState(false);
  const [bulkImportFileName, setBulkImportFileName] = useState("");
  const [isBulkImportModalOpen, setIsBulkImportModalOpen] = useState(false);
  const [alreadySentEmailsList, setAlreadySentEmailsList] = useState([]);
  const teamDropdownRef = useRef(null);
  const teamModalDropdownRef = useRef(null);

  let token = null;
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  useEffect(() => {
    dispatch(setHeaderText("My Team"));
  }, []);
  const { data: userProfiles = [], refetch: refetchUserProfiles, isLoading, isError, error } = useUserProfiles(token);
  const { data: typeContract = [], isLoading: contractTypesLoading, isError: contractTypesError } = useContractTypes();
  const { data: roleId = [] } = useUserType(token);

  useEffect(() => {
    if (userProfiles) {
      // setUsers(prevUsers => [...prevUsers, ...userProfiles]);
      setUsers(userProfiles);
    }
  }, [userProfiles]);


  const renderUserStatus = (rowData) => {
    // Determine the class based on the payment_ssdstatus value
    let statusClass;
    let statusName;
    if (rowData.is_active === true) {
      statusClass = "active";
      statusName = "Active";
    } else if (rowData.is_active === false) {
      statusClass = "inactive";
      statusName = "Inactive";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{statusName}</span>
    );
  };
  const [emailValidation, setEmailValodation] = useState(false);
  const [selectValidation, setSelectValodation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("emails", emails && emails.length);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = emails.every((obj) => (!obj.email || emailRegex.test(obj.email)));
    if (email === "") {
      setEmailValodation(true);
    } else {
      setEmailValodation(false);
    }
    if (userType === "") {
      setSelectValodation(true);
    } else {
      setSelectValodation(false);
    }
    if (!isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseModal();
      return;
    }
    var allEmails = emails;
    if (email !== "") {
      allEmails = [...emails, { email: email, id: Date.now(), userType: userType }]; // combine all emails including the last added one
    }
    // Validate email and userType fields
    if (!email || !userType) {
      toast.error("Email and user type cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (allEmails.some(obj => !obj.email || !obj.userType)) {
      toast.error("Email and user type cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsMailLoading(true);

      const response = await fetch("/api/user-invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: allEmails.map((obj) => ({ email: obj.email, userType: obj.userType })),
          invite_status: "0",
        }),
      });

      const data = await response.json();
      setEmails([]);
      setEmail("");
      setIsModalOpen(false);
      if (response.ok) {
        if (data.alreadySentEmails.length > 0 || data.alreadyRegisteredEmails.length > 0) {
          const alreadySentEmails = data.alreadySentEmails.join(", ");
          const alreadyRegisteredEmails = data.alreadyRegisteredEmails.join(", ");
          const successSentEmails = data.successSentEmails.join(", ");

          if (alreadySentEmails) {
            setAlreadySentEmailsList(data.alreadySentEmails);
            const sentEmailsList = data.alreadySentEmails.map((entry) => entry.email).join(", ");
            handleOpenResendModal();
            toast.success(`Invitation already sent to ${sentEmailsList}`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (alreadyRegisteredEmails) {
            toast.warning(`User with email ${alreadyRegisteredEmails} is already registered`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (successSentEmails) {
            dispatch(setNotification(Date.now()));
            toast.success(`Invitation successfully sent to ${successSentEmails}`, {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            global.config.activityLog(
              window.location.href,
              "User Invite",
              `Invitation successfully sent to ${successSentEmails}`
            );
          }
        } else {
          const successSentEmails = data.successSentEmails.join(", ");
          dispatch(setNotification(Date.now()));
          toast.success("Mail sent successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "User Invite",
            `Invitation successfully sent to ${successSentEmails}`
          );
        }
      } else {
        toast.error("Failed to send mail.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsMailLoading(false);
      handleCloseModal();
    }
  };

  const handleResendEmails = async () => {
    try {
      setIsMailLoading(true);
      const response = await fetch("/api/resend-invitations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: alreadySentEmailsList, // List of emails to resend
        }),
      });

      if (response.ok) {
        // Handle success
        // Close the resend modal
        setIsResendModalOpen(false);
        // Show a success message
        toast.success("Invitations resent successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "User Invite",
          `Invitation successfully resent to ${alreadySentEmailsList}`
        );
      } else {
        // Handle failure
        // Show an error message
        toast.error("Failed to resend invitations.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      // Handle network or server error
      // Show an error message
      toast.error("Failed to resend invitations.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsMailLoading(false);
      handleCloseResendModal();
    }
  };

  const handleAddEmail = (e) => {
    e.preventDefault();
    // if (email !== "") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = emailRegex.test(email);
    if (email && !isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // if (email) {
    const newEmailEntry = { email: email, id: Date.now(), userType: userType };
    setEmails([...emails, newEmailEntry]);
    // }
    setEmail("");
    setUserType("");
    // }
  };

  const handleToggleRoleDropdown = () => {
    setRoleDropdownOpen(!roleDropdownOpen);
  };

  const handleToggleUserTypeDropdown = () => {
    setUserTypeDropdownOpen(!userTypeDropdownOpen);
  };

  const handleUserTypeOptionClick = (selectedValue) => {
    setUserType(selectedValue);
    setSelectValodation(false);
    setUserTypeDropdownOpen(false);
  };

  const handleCheckboxTeamChange = (teamId) => {
    const updatedSelection = [...selectedTeams];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setFirst(0);
    setSelectedTeams(updatedSelection);
  };

  const handleSelectAllTeamChange = () => {
    if (!selectAllTeam) {
      setSelectedTeams(userProfiles.map((team) => team._id));
    } else {
      setSelectedTeams([]);
    }
    setSelectAllTeam(!selectAllTeam);
  };

  useEffect(() => {
    setSelectAllTeam(selectedTeams.length === userProfiles.length);
    if (selectedTeams.length === userProfiles.length) {
      setUserFilter("All");
    } else {
      setUserFilter(selectedTeams);
    }
  }, [selectedTeams, userProfiles]);

  const toggleTeamDropdown = () => {
    setIsTeamDropdownOpen(!isTeamDropdownOpen);
  };

  const toggleTeamModalDropdown = () => {
    setIsTeamModalDropdownOpen(!isTeamModalDropdownOpen);
  };

  const toggleStatusDropdown = () => {
    setStatusDropdownOpen(!statusDropdownOpen);
  };

  const selectStatusOption = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    setStatusDropdownOpen(false);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideRoleDropdown = roleDropdownRef.current && roleDropdownRef.current.contains(event.target);
    const isClickInsideUserTypeDropdown = userTypeDropdownRef.current && userTypeDropdownRef.current.contains(event.target);
    const isClickInsideTeamDropdown = teamDropdownRef.current && teamDropdownRef.current.contains(event.target);
    const isClickInsideTeamModalDropdown = teamModalDropdownRef.current && teamModalDropdownRef.current.contains(event.target);
    const isClickInsideStatusDropdown = statusDropdownRef.current && statusDropdownRef.current.contains(event.target);

    if (!isClickInsideRoleDropdown) {
      setRoleDropdownOpen(false);
    }
    if (!isClickInsideUserTypeDropdown) {
      setUserTypeDropdownOpen(false);
    }
    if (!isClickInsideTeamDropdown) {
      setIsTeamDropdownOpen(false);
    }
    if (!isClickInsideTeamModalDropdown) {
      setIsTeamModalDropdownOpen(false);
    }
    if (!isClickInsideStatusDropdown) {
      setStatusDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleEditEmail = (id, field, value) => {
    // Find the index of the email entry with the given id
    const index = emails.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      // Create a copy of the emails array to avoid mutating state directly
      const updatedEmails = [...emails];
      // Update the specified field of the email entry
      updatedEmails[index][field] = value;
      // Update the state with the new array
      setEmails(updatedEmails);
    }
  };

  const handleRemoveEmail = (id) => {
    const filteredEmails = emails.filter((obj) => obj.id !== id);
    setEmails(filteredEmails);
  };

  const handleRemoveLatestEmail = () => {
    const updatedEmails = [...emails];
    const lastEmail = updatedEmails.pop(); // Remove the last element
    setEmails(updatedEmails);

    // Set state variables with the latest values
    setEmail(lastEmail.email);
    setUserType(lastEmail.userType);
  };

  // Function to remove an email from the list
  const handleRemoveResendEmail = (emailToRemove) => {
    const updatedEmails = alreadySentEmailsList.filter((email) => email !== emailToRemove);
    setAlreadySentEmailsList(updatedEmails);
  };

  const handleOpenResendModal = () => {
    // Set the list of already sent emails here
    setIsResendModalOpen(true);
  };

  const handleCloseResendModal = () => {
    setIsResendModalOpen(false);
    setAlreadySentEmailsList([]);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEmails([]);
    setEmail("");
    setUserType("");
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    setIsBulkImportLoading(true);
    if (!bulkImportFile) {
      toast.error("Please select a file.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(true);
      return;
    }

    const fileExtension = bulkImportFile.name.split(".").pop();
    if (fileExtension !== "xlsx") {
      toast.error("Only xlsx files are supported.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", bulkImportFile);

    try {
      const response = await axios.post("/api/import/user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === "requiredValidationError") {
        toast.error(response.data.message, {
          position: "bottom-right",
          autoClose: 5000, // Adjust as needed
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        if (response.data.existingUserEmails && response.data.existingUserEmails.length > 0) {
          toast.error(`The following emails are already registered: ${response.data.existingUserEmails.join(", ")}`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          if (response.data.filteredUsers && response.data.filteredUsers.length > 0) {
            toast.success(`Inserted ${response.data.filteredUsers.length} records`, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } else {
          toast.success("File uploaded successfully!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        refetchUserProfiles();
        handleBulkImportCloseModal();
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      toast.error('Error uploading file. Please try again.', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsBulkImportLoading(false); // Reset loading state after upload process completes
    }
  };

  const handleBulkImportOpenModal = () => {
    setIsBulkImportModalOpen(true);
  };

  const handleBulkImportCloseModal = () => {
    setBulkImportFile(null)
    setBulkImportFileName("")
    setIsBulkImportModalOpen(false);
  };

  const handleBulkImportUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBulkImportFile(e.target.files[0])
      setBulkImportFileName(file.name)
    } else {
      setBulkImportFile(null)
      setBulkImportFileName("")
    }
  };

  const filteredData = users.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    const fullName =
      (item.first_name || (item.company_first_name ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ''}` : `${item.company_name} (Company)`)) +
      (item.middle_name && item.middle_name !== "null"
        ? " " + item.middle_name
        : "") +
      " " +
      item.last_name;
    const hasMatch =
      (fullName?.toLowerCase().includes(searchTerm) ||
        item.job_title?.toLowerCase().includes(searchTerm) ||
        item.email?.toLowerCase().includes(searchTerm) ||
        item.user_wx_comapny?.wx_company?.toLowerCase().includes(searchTerm)) &&
      (selectedStatus === "Active" ? item.is_active : true) &&
      (selectedStatus === "Inactive" ? !item.is_active : true);

    // Check if the current user is an Admin and exclude Super_Admin users
    if (user.role_id === userTypeName.Admin) {
      return hasMatch && item?.role_id?._id !== userTypeName.Super_Admin && (item.user_category === userCategory.Individual || item.user_category === userCategory.Business);
    }
    return hasMatch &&
      (item.user_category === userCategory.Individual || item.user_category === userCategory.Business);
  });

  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const modifiedLog = filteredData.map((item, index) => ({
        SlNo: index + 1,
        "User Name": item.first_name
          ? `${item.first_name} ${item.middle_name && item.middle_name !== 'null'
            ? item.middle_name + ' '
            : ''
          }${item.last_name}`
          : item.company_first_name
            ? `${item.company_first_name} ${item.company_last_name || ''}`
            : `${item.company_name || ''} (Company)`,
        Email: item.email,
        "Company Name": item.company_name,
        Role: item.role_id.user_type,
        "Job Title": item.job_title,
        "Department": item.department.user_department,
        "Date of Birth": item.dob ? moment(item.dob)
          .tz(global.config.getTimeZone())
          .format("DD/MM/YYYY") : "",
        "Passport Number": item.passport_no,
        "Registration Number": item.register_number,
        Mobile: item.mobile_no,
        Address: item.address,
        Pincode: item.pincode,
        Country: item.country,
        State: item.state,
        City: item.city,
        Currency: item.payment_currency,
        "Type of contract": item.typeOfContract ? item.typeOfContract.typeOfContract : " ",
        "Monthly Hours": `${item.monthly_hours || 168} hrs`,
        "Compensation": item.cost ? item.cost.toLocaleString("en-US", {
          style: "currency",
          currency: item.payment_currency || 'USD',
        }) : new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: item.payment_currency || 'USD',
        }).format(0),
        "Overhead Cost per Hour": item.overhead_cost ? item.overhead_cost.toLocaleString("en-US", {
          style: "currency",
          currency: item.payment_currency || 'USD',
        }) : new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: item.payment_currency || 'USD',
        }).format(0),
        "VAT (%)": `${item.vat} %`,
        "Created at": moment(parseInt(item.created_at))
          .format("DD/MM/YYYY, hh:mm:ss A"),
      }));

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);

      // Calculate column widths based on the maximum length of the data in each column
      const getMaxLength = (arr, field) =>
        Math.max(...arr.map(item => (item[field] || "").toString().length), field.length);

      const headers = Object.keys(modifiedLog[0]);
      const colWidths = headers.map(header => ({
        wch: getMaxLength(modifiedLog, header) + 5, // Add padding to each column
      }));

      worksheet["!cols"] = colWidths;

      // Add alignment and font styling for header
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < headers.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center";
        style.alignment.vertical = "center";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set border for active area and increase font size for data
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }

      const fileName = `Users_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Clients Log");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Clients Log",
        "Error occurred while exporting to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };

  return (
    <motion.div
      ref={componentRef}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
      transition={{ duration: 0.3 }}
    >

      <div className="user-outer-overview">
        <div className="invoice-top">
          <div className="button-sec-invoice">
            <Link to="../register">
              <span className="icon-invoice">
                <img src={UserIcon} alt="Create Invoice" onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/uploads/lowProfileImage/user_default.png';
                }} />
              </span>
              Create User
            </Link>
            <a className="btn-forpop-initem" onClick={handleOpenModal}>
              <span className="icon-invoice">
                <img src={InviteIcon} alt="Export" />
              </span>
              Invite Users
            </a>

            <Modal
              isOpen={isModalOpen}
              onRequestClose={!isMailLoading ? handleCloseModal : undefined}
              contentLabel="Invite a User"
              className="user-invite-modal-main-outer"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -50%)",
                },
              }}
            >
              <div className="modal">
                <div className="modal-top-section-user-invite">
                  <div className="modal-invite-head">
                    <h2 className="">Invite a new user</h2>
                  </div>
                  <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                    <a className="" onClick={handleCloseModal} disabled={isMailLoading}>
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>

                </div>

                <form onSubmit={handleSubmit}>
                  <div>
                    {emails.map((obj) => (
                      <div key={obj.id} className="the-modal-inuser">

                        <div className="user-add-outer-innner">
                          <div className="invite-user">
                            <label htmlFor="grid-password" className="">
                              Email Address
                            </label>
                            <input
                              type="email"
                              id={`email-${obj.id}`}
                              className=""
                              name="email"
                              required
                              placeholder="Enter Email ID"
                              value={obj.email}
                              onChange={(e) => handleEditEmail(obj.id, 'email', e.target.value)}
                            />
                            <label htmlFor="grid-password" className="type-of-acc">
                              Role
                            </label>

                            <div className={`custom-dropdown-user-new-outer ${roleDropdownOpen ? 'open' : ''}`} ref={roleDropdownRef}>
                              <div className="selected-option custom-dropdown-user-new" onClick={handleToggleRoleDropdown}>
                                {obj.userType ? roleId.find((option) => option._id === obj.userType)?.user_type : 'Select Role'}
                              </div>
                              {roleDropdownOpen && (
                                <div className="custom-dropdown-new">
                                  <ul className="role-list">
                                    {roleId.map((option) => (
                                      <li className={obj.userType === option._id ? 'selected-item' : ''} key={option._id} onClick={() => { handleEditEmail(obj.id, 'userType', option._id); setRoleDropdownOpen(false); }}>
                                        {option.user_type}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="invite-user-delete"
                            onClick={() => handleRemoveEmail(obj.id)}
                          >
                            <img src={dletImage} alt="delete-icon" />{" "}
                          </div>
                        </div>

                      </div>
                    ))}
                    <div className="the-modal-inuser">
                      <div className="user-add-outer-innner">
                        <div className="invite-user">
                          <label htmlFor="grid-password" className="">
                            Email Address
                          </label>
                          <input
                            type="email"
                            name="email"
                            className={emailValidation && "error-input"}
                            placeholder="Enter Email ID"
                            value={email}
                            onChange={(e) => { setEmail(e.target.value); setEmailValodation(false); }}
                          />
                          <label htmlFor="grid-password" className="type-of-acc">
                            Role
                          </label>

                          <div className={`custom-dropdown-user-new-outer ${userTypeDropdownOpen ? 'open' : ''}`} ref={userTypeDropdownRef}>
                            <div className={selectValidation ? "selected-option custom-dropdown-user-new error-input" : "selected-option custom-dropdown-user-new"} onClick={handleToggleUserTypeDropdown}>
                              {userType ? roleId.find((option) => option._id === userType)?.user_type : 'Select Role'}
                            </div>
                            {userTypeDropdownOpen && (
                              <div className="custom-dropdown-new">
                                <ul className="role-list">
                                  {roleId.map((option) => (
                                    <li className={userType === option._id ? 'selected-item' : ''} key={option._id} onClick={() => handleUserTypeOptionClick(option._id)}>
                                      {option.user_type}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                        {emails.length > 0 && (
                          <div className="invite-user-delete" onClick={handleRemoveLatestEmail}>
                            <img src={dletImage} alt="delete-icon" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="add-email-sec-modal" onClick={handleAddEmail} style={{ cursor: "pointer" }}>
                      <img src={addicon} alt="Edit Icon" /><div className="add-icon-main-text">Add new email</div>
                    </div>
                  </div>
                  <div className="invite-mail-popup-btn" >
                    <button type="submit" className="" disabled={isMailLoading}>
                      {isMailLoading ?
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div> : "Send Invite"}
                    </button>
                  </div>
                </form>
              </div>
            </Modal>


            <Modal
              isOpen={isResendModalOpen}
              onRequestClose={!isMailLoading ? handleCloseResendModal : undefined}
              contentLabel="Resend Invitations"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -50%)",
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a
                    className=""
                    onClick={handleCloseResendModal}
                    disabled={isMailLoading}
                  >
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="modal-invite-head">
                  <h2 className="">Resend Invitations</h2>
                </div>
                {/* List the already sent emails here */}
                <div>
                  <div className="the-modal-inuser">
                    <label htmlFor="grid-password" className="">
                      Email
                    </label>
                    {alreadySentEmailsList.map((email) => (
                      <div key={email.email} className="the-modal-inusers">

                        <div className="resend-invitation">
                          <input
                            type="email"
                            className=""
                            name="email"
                            required
                            placeholder="Enter Email ID"
                            value={email.email}
                            disabled
                          />
                          <label htmlFor="grid-password" className="type-of-acc">
                            Role
                          </label>
                          <select
                            value={email.userType}
                            disabled
                          >
                            <option value="">Select Role</option>
                            {roleId.map((role) => (
                              <option key={role._id} value={role._id}>
                                {role.user_type}
                              </option>
                            ))}
                          </select>
                          {alreadySentEmailsList.length > 1 && (
                            <button className="del-resend" onClick={() => handleRemoveResendEmail(email)}>
                              <img src={dletImage} alt="delete-icon" />{" "}
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="sprint-btn-outer resend-invoice">
                  <button
                    type="button"
                    onClick={handleCloseResendModal}
                    disabled={isMailLoading}
                    className="submit-client cancel-sprint"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="submit-client save-sprint"
                    disabled={isMailLoading}
                    onClick={handleResendEmails}
                  >
                    {isMailLoading ?
                      <div className="outter-load-table">
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div> : "Resend Invitations"}
                  </button>
                </div>
              </div>
            </Modal>

            <a className="btn-forpop-initem bulk-import" onClick={handleBulkImportOpenModal}>
              <span className="icon-invoice">
                <img src={DownloadImage} alt="Export" />
              </span>
              Bulk Import
            </a>


            <a className="btn-forpop-initem bulk-export" onClick={exportToExcel}>
              <span className="icon-invoice">
                <img src={DownloadImage} alt="Export" />
              </span>
              Export Data
            </a>

            <Modal
              isOpen={isBulkImportModalOpen}
              onRequestClose={!isBulkImportLoading ? handleBulkImportCloseModal : undefined}
              contentLabel="Invite a User"
              className="bulk-import-popup"
              style={{
                content: {
                  height: "50%",
                  width: "50%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-17.5%, -46%)",
                },
              }}
            >
              <div className="modal">
                <div className="outter-modal-main">
                  <a
                    className="close-image-clr1"
                    onClick={handleBulkImportCloseModal} disabled={isBulkImportLoading}
                  >
                    <img src={crssrImage} alt="Close Bulk Import" />{" "}
                  </a>
                </div>

                <div className="outter-modal-main">

                  <p>Bulk Import</p>
                  {/* <div className="modal-top-section-user-invite">
                  <div className="modal-invite-head">
                    <h2 className="">BULK IMPORT</h2>
                  </div>
                  <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                    <a className="" onClick={handleBulkImportCloseModal} disabled={isBulkImportLoading}>
                      <img src={crssrImage} alt="Close Bulk Import" />{" "}
                    </a>
                  </div>
                </div> */}

                  <form>
                    <div className="relative w-full mb-3" >
                      <label className="gray-label">Select client list</label>
                      <div className="file-input-wrapper">

                        <input
                          type="text"
                          className="form-input form-input-file"
                          value={bulkImportFileName}
                          readOnly
                        />
                        <input
                          type="file"
                          id="expFileAttchEdit"
                          name="expFileAttchEdit"
                          className="form-input hidden-input"
                          onChange={handleBulkImportUpload}
                        // required
                        />
                        <label
                          htmlFor="expFileAttch"
                          className="file-input-label"
                        >
                          <img src={Plus} alt="Browse" />Browse
                        </label>
                      </div>
                      <div className="text-sm bulk-text">
                        Demo File: {"    "}
                        <a
                          href="https://docs.google.com/spreadsheets/d/1rCCZmtrRoUs2wL-58OzTunfEYZdiVRQnvKYrTYQIhoQ/edit?usp=sharing"
                          className="form-textarea"
                          target="_blank"
                        >
                          Import_demo.xlsx
                        </a>
                      </div>
                    </div>

                    <div className="bulk-btn-section sprint-btn-outer">
                      <button
                        className="submit-client cancel-sprint"
                        type="button"
                        onClick={handleBulkImportCloseModal}
                        disabled={isBulkImportLoading}
                      >
                        Close
                      </button>
                      <button
                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleUpload}
                        disabled={isBulkImportLoading}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Modal>

          </div>
          <div className="search-sec search-sec-user-overview">
            <div class="search-container">
              <input
                type="text"
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
                class="search-input"
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        {/* <div className="filter-inner invoice-status">
          <label for="user">User</label>
          <div className="custom-dropdown1" ref={teamDropdownRef}>
            <div className="drop-down-inner">
              <div className="selected-options1" onClick={toggleTeamDropdown} >
                {selectedTeams.length === 0 ? (
                  <div className="placeholder">Select</div>
                ) : (
                  <>
                    <div
                      className="selected-option1"
                      key={selectedTeams[0]}
                    >
                      {selectedTeams.length === userProfiles.length ? (
                        `All`
                      ) : (
                        <>
                          <TruncateFilterFieldView
                            textContent={
                              userProfiles.find((item) => item._id === selectedTeams[0])?.first_name
                                ? `${userProfiles.find((item) => item._id === selectedTeams[0]).first_name} ${userProfiles.find((item) => item._id === selectedTeams[0]).middle_name
                                  ? userProfiles.find((item) => item._id === selectedTeams[0]).middle_name : ""} ${userProfiles.find((item) => item._id === selectedTeams[0]).last_name
                                    ? userProfiles.find((item) => item._id === selectedTeams[0]).last_name : ""}`
                                : userProfiles.find((item) => item._id === selectedTeams[0]).company_first_name ?
                                  `${userProfiles.find((item) => item._id === selectedTeams[0]).company_first_name} ${userProfiles.find((item) => item._id === selectedTeams[0]).company_last_name ?
                                    userProfiles.find((item) => item._id === selectedTeams[0]).company_last_name : ""}`
                                  : `${userProfiles.find((item) => item._id === selectedTeams[0]).company_name} (Company)`
                            }
                          />
                          {selectedTeams.length > 1 &&
                            selectedTeams.length !== userProfiles.length && (
                              <div className="selected-option-count">
                                {selectedTeams.length - 1}+
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              {isTeamDropdownOpen && (
                <div className="dropdown-list1">
                  {userProfiles.length > 0 && (
                    <>
                      <label className="checkbox-label1" key="selectAll" >
                        <input type="checkbox" checked={selectAllTeam} onChange={handleSelectAllTeamChange} />
                        All Teams
                      </label>
                      {userProfiles.map((team) => (
                        <label className="checkbox-label1" key={team._id} >
                          <input
                            type="checkbox"
                            checked={selectedTeams.includes(team._id)}
                            onChange={() =>
                              handleCheckboxTeamChange(team._id)
                            }
                          />
                          {team.first_name
                            ? `${team.first_name} ${team.middle_name ? team.middle_name : ""
                            } ${team.last_name}`
                            : team.company_first_name
                              ? `${team.company_first_name} ${team.company_last_name
                                ? team.company_last_name
                                : ""
                              }`
                              : `${team.company_name} (Company)`}
                        </label>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div> */}
        <div className="top-filter-client-status top-filter-client-status-user">
          <label className="top-filter-client-status-label">Status</label>
          <div className="custom-dropdown">
            <div ref={statusDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${statusDropdownOpen ? 'open' : ''}`}>
              <div className="selected-option custom-dropdown-user-new" onClick={toggleStatusDropdown}>
                {selectedStatus ? global.config.locate(appSettings && appSettings.language, selectedStatus) : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'ALL') : null)}
              </div>
              {statusDropdownOpen && (
                <div className="custom-dropdown-new">
                  <ul className="type-list">
                    <li onClick={() => selectStatusOption(null)}>All</li>
                    <li onClick={() => selectStatusOption('Active')}>{global.config.locate(appSettings && appSettings.language, 'Active')}</li>
                    <li onClick={() => selectStatusOption('Inactive')}>{global.config.locate(appSettings && appSettings.language, 'Inactive')}</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card-outer1">
          <div className="card-outer">
            <div className="card-outer-user">
              <h3>Recent Users</h3>
            </div>
            <div className="card">
              {isLoading === true ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#405BFF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <DataTable
                  value={filteredData}
                  first={first}
                  rows={rows}
                  removableSort
                  paginator={true}
                  paginatorPosition="bottom"
                  totalRecords={filteredData.length}
                  onPage={(e) => setFirst(e.first)}
                  tableStyle={{
                    minWidth: "50rem",
                    width: "100%",
                    textAlign: "left",
                  }}
                  paginatorClassName="recent-invoices-pagination"
                >
                  <Column
                    body={useRenderMemberName}
                    field="fullName"
                    sortField="fullName"
                    header="User Name"
                    sortable
                    style={{ minWidth: "300px", paddingRight: "44px" }}
                    sortFunction={(e) => {
                      const dataCopy = [...filteredData];
                      dataCopy.sort((rowData1, rowData2) => {
                        const fullName1 =

                          rowData1?.user_category && [userCategory.Business, userCategory.Vendor, userCategory.Client].includes(rowData1.user_category)
                            ? (rowData1.company_first_name ? `${rowData1.company_first_name} ${rowData1.company_last_name ? rowData1.company_last_name : ''}` : `${rowData1.company_name} (Company)`)
                            : rowData1.first_name + " " + rowData1.last_name;

                        const fullName2 =
                          rowData2?.user_category && [userCategory.Business, userCategory.Vendor, userCategory.Client].includes(rowData2.user_category)
                            ? (rowData2.company_first_name ? `${rowData2.company_first_name} ${rowData2.company_last_name ? rowData2.company_last_name : ''}` : `${rowData2.company_name} (Company)`)
                            : rowData2.first_name + " " + rowData2.last_name;

                        // Implement your custom comparison logic here
                        return e.order === 1
                          ? fullName1.localeCompare(fullName2)
                          : fullName2.localeCompare(fullName1);
                      });
                      return dataCopy;
                    }}
                  ></Column>
                  <Column
                    field="job_title"
                    header="Job Title"
                    sortable
                    style={{ paddingRight: "20px" }}
                  ></Column>
                  <Column
                    field="email"
                    header="Email"
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="user_wx_comapny.wx_company"
                    header="Recipient"
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="bank_account_verified"
                    body={(rowData) => (
                      <BankAccountVerification email={rowData.email} />
                    )}
                    header="Bank Account"
                    // sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="is_active"
                    body={(rowData) => renderUserStatus(rowData)}
                    header="Status"
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="_id"
                    body={RenderUserCTA}
                    style={{ paddingRight: "8px", width: "80px" }}
                  ></Column>
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default Users;