import React, { PureComponent, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { convertCurrencyHelper } from '../../../helpers/forexConvertor';
import typeOfContract from '../../../constants/typeOfContract';

const data = [
  {
    name: 'January',
    currentYear: 4000,
    lastYear: 2400,
    amt: 2400,
  },
  {
    name: 'February',
    currentYear: 3000,
    lastYear: 1398,
    amt: 2210,
  },
  {
    name: 'March',
    currentYear: 2000,
    lastYear: 800,
    amt: 2290,
  },
  {
    name: 'April',
    currentYear: 2780,
    lastYear: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    currentYear: 1890,
    lastYear: 4800,
    amt: 2181,
  },
  {
    name: 'June',
    currentYear: 2390,
    lastYear: 3800,
    amt: 2500,
  },
  {
    name: 'July',
    currentYear: 3490,
    lastYear: 4300,
    amt: 2100,
  },
  {
    name: 'August',
    currentYear: 1490,
    lastYear: 4300,
    amt: 2100,
  },
  {
    name: 'September',
    currentYear: 2490,
    lastYear: 1300,
    amt: 2100,
  },
  {
    name: 'October',
    currentYear: 3490,
    lastYear: 2300,
    amt: 2100,
  },
  {
    name: 'November',
    currentYear: 3490,
    lastYear: 1300,
    amt: 2100,
  },
  {
    name: 'December',
    currentYear: 2490,
    lastYear: 2300,
    amt: 2100,
  },
];

const useProcessedData = (clientInvoices, clientInvoicesLoading, isError, reportingOverview, preferredCurrency, forex) => {
  return useMemo(() => {
    if (clientInvoicesLoading || isError) return [];

    const currentYear = reportingOverview.year || new Date().getFullYear();
    const previousYear = currentYear - 1;

    const calculateROI = (invoices, year) => {
      const monthlyData = Array.from({ length: 12 }, (_, i) => ({
        name: new Date(year, i).toLocaleString('default', { month: 'long' }),
        budget: 0,
        cost: 0
      }));

      // projects.forEach(project => {
      //   const projectYear = new Date(project.startTime).getFullYear();
      //   const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';
      //   if (projectYear === year && !project?.project?.is_delete && reportingOverview.project.includes(project?.project?._id) && reportingOverview.currency.includes(project?.clientId?.preferred_currency || 'USD')) {
      //     const monthIndex = new Date(project.startTime).getMonth();
      //     monthlyData[monthIndex].budget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.project.budget, forex);
      //     const userCost = project.user.cost || 0;
      //     const userOverheadCost = project.user.overhead_cost || 0;
      //     const userTypeOfContract = project.user.typeOfContract || typeOfContract.Hourly_Rate;
      //     const paymentCurrency = project.user.payment_currency || 'USD';
      //     let finalCost;
      //     if (userTypeOfContract === typeOfContract.Hourly_Rate) {
      //       finalCost = userCost;
      //     } else {
      //       finalCost = userCost / 168; // Assuming a different type of contract with monthly rate divided by 168 hours
      //     }
      //     // const cost = (userCost + userOverheadCost) * (project.duration / 3600);
      //     const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
      //     monthlyData[monthIndex].cost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
      //   }
      // });

      invoices.forEach(invoice => {
        const projectYear = new Date(invoice.cli_inv_end_date).getFullYear();
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        const clientId = invoice.cli_inv_for._id;
        if (projectYear === year && !invoice.is_delete && clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true) && (reportingOverview.currency && reportingOverview.currency.includes(preferredClientCurrency))) {
          const monthIndex = new Date(invoice.cli_inv_end_date).getMonth();
          const paymentCurrency = invoice.cli_inv_currency || 'USD';
          monthlyData[monthIndex].cost += convertCurrencyHelper(paymentCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
        }
      });
      return monthlyData.map(month => ({
        name: month.name,
        roi: month.cost ? (month.cost).toFixed(2) : 0
      }));
    };

    const currentYearData = calculateROI(clientInvoices, currentYear);
    const previousYearData = calculateROI(clientInvoices, previousYear);

    return currentYearData.map((data, index) => ({
      name: data.name,
      currentYear: parseFloat(data.roi),
      lastYear: parseFloat(previousYearData[index].roi)
    }));
  }, [clientInvoices, clientInvoicesLoading, isError, reportingOverview, preferredCurrency, forex]);
};

const ReportClientComparionGraph = ({ clientInvoices, clientInvoicesLoading, clientInvoicesError }) => {
  const reportingOverview = useSelector((state) => state.reportingOverview);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const setReportingCurrency = useSelector((state) => state.reportingOverview.currency);
  var currencies = "USD";
  if(setReportingCurrency && setReportingCurrency.length===1){
     currencies = setReportingCurrency[0];
  }else{
    currencies = settings.currency;
  }

  const processedData = useProcessedData(clientInvoices, clientInvoicesLoading, clientInvoicesError, reportingOverview, currencies, forex);

  const currentYear = reportingOverview.year || new Date().getFullYear();
  const previousYear = currentYear - 1;
  const isCurrentYear = currentYear === new Date().getFullYear();

  if (clientInvoicesLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (clientInvoicesError) return <div>Error loading data.</div>;
  return (
    <div style={{ width: '100%', height: 542 }}>
      <ResponsiveContainer>
        <LineChart
          layout="horizontal"
          data={processedData}
          margin={{
            top: 20,
            right: 30,
            left: -15,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" type="category" padding={{ left: 50 }} tickMargin={20} />
          <YAxis type="number" />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", {style: "currency", currency: currencies,}).format(value)}`}/>
          <Legend />
          <Line dataKey="currentYear" name={isCurrentYear ? `Current Year (${currentYear})` : `Selected Year (${currentYear})`} stroke="#96A8BA" />
          <Line dataKey="lastYear" name={isCurrentYear ? `Last Year (${previousYear})` : `Previous Year (${previousYear})`} stroke="#6479F8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ReportClientComparionGraph;
