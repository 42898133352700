import React, { useEffect } from "react";
import "./ReportPersonal.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useUserById } from "../../TimeOff/timeOffApi";
import TimeWorkedFilter from "./ReportingTimeWorked/ReportTimeWorkedFilter";
import { useFetchTrackReportDetails } from "../../../features/reporting/reportingOverview/useReportingOverview";
import TimeWorkedTotalHours from "./ReportingTimeWorked/TotalHoursTimeWorked";
import TimeWorkedTotalUsers from "./ReportingTimeWorked/TotalUsersTimeWorked";
import UserHourGraphFilter from "./ReportingTimeWorked/UserLoggedHourGraphFilter";
import UserHourGraph from "./ReportingTimeWorked/UserLoggedHourGraph";
import TotalHoursPerUserGraphFilter from "./ReportingTimeWorked/GraphFilters/ReportTotalHoursPerUser/ReportTotalHoursPerUserFilter";

function TimeWorkedReport() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const { data: users = [], isLoading: userLoading, isError: usersError, refetch: userRefetch } = useUserById(token);
  const { data: trackReport = [], isLoading: trackReportLoading, isError: trackReportError } = useFetchTrackReportDetails(token);

  return (
    <div className="reporting-timeoff-request-outer-page client-report-outer">
      <div className="invoice-outer-invoice invoice-outer-invoice-reprt">
        <TimeWorkedFilter users={users} trackReport={trackReport} />
      </div>

      <div className="repot-tatal-box-sec">
        <div className="total-request-outer">
          <TimeWorkedTotalHours trackReport={trackReport} />
        </div>
        <div className="total-request-outer">
          <TimeWorkedTotalUsers trackReport={trackReport} />
        </div>
        <div className="total-request-outer">
        </div>
        <div className="total-request-outer">
        </div>
      </div>

      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report border-line-bottom">
          <div className="top-graph">
            <TotalHoursPerUserGraphFilter
              trackReport={trackReport}
              trackReportLoading={trackReportLoading}
              trackReportError={trackReportError}
            />
          </div>
          <UserHourGraph
            trackReport={trackReport}
            trackReportLoading={trackReportLoading}
            trackReportError={trackReportError}
          />
        </div>
      </div>
      <div className="button-sec-invoice">
        <Link to="/user/time-off">View All</Link>
      </div>
    </div>
  );
}

export default TimeWorkedReport;
