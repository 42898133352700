import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Dollar from "../../../images/icons/dollar-kit.svg";
import typeOfContract from '../../../constants/typeOfContract';
import {totalInvoiced} from '../ReportingOverview/Services/reportOverview.js';

// Functional component
function TotalBilled(props) {
  
  const reportingOverview = useSelector((state) => state.reportingOverview);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const clientInvoices = props.clientInvoices;
  const dashboardCurrencySum = useSelector((state) => state.invoice.dashboardCurrencySum);
  const setReportingCurrency = useSelector((state) => state.reportingOverview.currency);
  var currencies = "USD";
  if(setReportingCurrency && setReportingCurrency.length===1){
     currencies = setReportingCurrency[0];
  }else{
    currencies = settings.currency;
  }
  
  const invoiceTotal = totalInvoiced( clientInvoices, reportingOverview ,  currencies  , forex );
  useEffect(() => {
  }, [props.trackReport]);

  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">Total Billed</div>
          <div className="overview-icon">
            <img src={Dollar} alt="cLock" />
          </div>
        </div>
        <div className="overview-rice">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currencies,
          }).format(invoiceTotal)}
        </div>
      </div>
    </div>
  );
}

export default TotalBilled;