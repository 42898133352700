import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout, reset } from "../../../../../features/auth/authSlice.js";
import { reset as invoiceReset } from "../../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../../features/appSettings/appSettingsSlice.js";

const Department = ({ deptId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [department, setDepartment] = useState("");
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.auth);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/user-department/${deptId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setDepartment(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [deptId]);

  if (loading) {
    return (
      <ThreeDots
        height="18"
        width="20"
        radius="9"
        color="#6479f9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    );
  }

  return <span>{department.user_department}</span>;
};

export default Department;
