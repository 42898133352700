import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from "react-loader-spinner";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, Legend, LabelList } from 'recharts';
import { reportingFilterDateRangesConst } from '../../../../../../constants/reportingFilterDateRangesConst';
import { reportingFilterComparisonConst } from '../../../../../../constants/reportingFilterComparisonConst';
import { calculateUserHoursFilter } from '../../Services/reportTimeWorked';

const TotalHoursPerUserFilterGraph = ({
  trackReport,
  trackReportLoading,
  trackReportError,
  selectedDateRange,
  selectedComparison,
  selectedFrequency,
  selectedYearRange,
  selectedMonthRange,
  selectedComparisonYear,
  selectedComparisonMonth,
}) => {
  const reportingTimeWorked = useSelector((state) => state.reportingTimeWorked);

  const processedData = useMemo(() => {
    return calculateUserHoursFilter(
      trackReport,
      reportingTimeWorked,
      selectedDateRange,
      selectedComparison,
      selectedYearRange,
      selectedMonthRange,
      selectedComparisonYear,
      selectedComparisonMonth,
    ).map(item => ({
      ...item,
      currentPeriodTotalHours: item.currentPeriodBillableHours + item.currentPeriodNonBillableHours,
      comparisonPeriodTotalHours: item.comparisonPeriodBillableHours + item.comparisonPeriodNonBillableHours
    }));
  }, [
    trackReport,
    reportingTimeWorked,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
  ]);

  if (trackReportLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (trackReportError) return <div>Error loading data.</div>;

  const calculateXTicks = () => {
    if (!processedData.length) return [];

    const maxPositiveValue = Math.max(
      ...processedData.map(item => Math.max(item.currentPeriodTotalHours, item.comparisonPeriodTotalHours))
    );

    if (maxPositiveValue === 0) {
      return [0];
    }

    const desiredTickCount = 10;
    const tickInterval = Math.ceil(maxPositiveValue / desiredTickCount);

    const endTick = Math.ceil(maxPositiveValue / tickInterval) * tickInterval + tickInterval;

    const startTick = 0;

    const ticks = [];
    for (let i = startTick; i <= endTick; i += tickInterval) {
      ticks.push(i);
    }

    return ticks;
  };

  const xAxisTicks = calculateXTicks();

  const changeDurationFormat = (sec) => {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const getBarName = (isCurrent, isBillable) => {
    const rangeNames = {
      [reportingFilterDateRangesConst.This_Year]: 'This Year',
      [reportingFilterDateRangesConst.This_Quarter]: 'This Quarter',
      [reportingFilterDateRangesConst.First_Half]: 'First Half',
      [reportingFilterDateRangesConst.This_Month]: 'This Month',
      [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
      [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
    };

    const comparisonNames = {
      [reportingFilterComparisonConst.Last_Year]: 'Last Year',
      [reportingFilterComparisonConst.Last_Quarter]: 'Last Quarter',
      [reportingFilterComparisonConst.Second_Half]: 'Second Half',
      [reportingFilterComparisonConst.Last_Month]: 'Last Month',
      [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
      [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
    };

    const rangeName = rangeNames[selectedDateRange] || 'Current Range';
    const comparisonName = comparisonNames[selectedComparison] || 'Comparison Range';

    return isBillable
      ? `Billable hours ${isCurrent ? rangeName : comparisonName}`
      : `Non-billable hours ${isCurrent ? rangeName : comparisonName}`;
  };

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: 542 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13}
          margin={{
            top: 20,
            right: 42,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[Math.min(...xAxisTicks), Math.max(...xAxisTicks)]}
            tickFormatter={(tick) => changeDurationFormat(tick)}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            tickMargin={10}
          />
          <Tooltip formatter={(value) => changeDurationFormat(value)} />
          <ReferenceLine x={0} stroke="#7393B3" />
          <Legend />

          {/* Current Period Bar */}
          <Bar dataKey="currentPeriodNonBillableHours" name={getBarName(true, false)} stackId="a" fill="#C7B6F6">
            <LabelList dataKey="currentPeriodNonBillableHours" position="right" formatter={(value, entry) => {
              if (entry && entry.payload && entry.payload.currentPeriodBillableHours === 0) {
                return changeDurationFormat(value);
              }
              return null;
            }} />
          </Bar>
          <Bar dataKey="currentPeriodBillableHours" name={getBarName(true, true)} stackId="a" fill="#86ABE1">
            <LabelList dataKey="currentPeriodTotalHours" position="right" formatter={(value) => changeDurationFormat(value)} />
          </Bar>

          {/* Comparison Period Bar */}
          <Bar dataKey="comparisonPeriodNonBillableHours" name={getBarName(false, false)} stackId="b" fill="#B6A4F6">
            <LabelList dataKey="comparisonPeriodNonBillableHours" position="right" formatter={(value, entry) => {
              if (entry && entry.payload && entry.payload.comparisonPeriodBillableHours === 0) {
                return changeDurationFormat(value);
              }
              return null;
            }} />
          </Bar>
          <Bar dataKey="comparisonPeriodBillableHours" name={getBarName(false, true)} stackId="b" fill="#A0C5F6">
            <LabelList dataKey="comparisonPeriodTotalHours" position="right" formatter={(value) => changeDurationFormat(value)} />
          </Bar>

        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default TotalHoursPerUserFilterGraph;