import axios from "axios";
import "./UserNotification.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import { getAllISOCodes } from 'iso-country-currency';
import currencyCodes from "currency-codes";
import userTypeName from "../../../../constants/userType";
import userCategoryName from "../../../../constants/userCategory";
import { logout, reset } from "../../../../features/auth/authSlice";
import { toggleNotification } from '../../../../features/auth/authSlice';
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { setAppSettings } from "../../../../features/appSettings/appSettingsSlice.js";
import { useTimeZone } from "../../../TimeTracker/Timer/tracker/TimerDataApi.jsx";
import userCategory from "../../../../constants/userCategory";

const UserNotification = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isNotificationLoading, setIsNotificationLoading] = useState(false);
    const [isEmailNotificationLoading, setIsEmailNotificationLoading] = useState(false);
    const [isAdminTimeoffEmailNotificationLoading, setIsAdminTimeoffEmailNotificationLoading] = useState(false);
    const [isAdminInvoiceEmailNotificationLoading, setIsAdminInvoiceEmailNotificationLoading] = useState(false);
    const [userView, setUserView] = useState([]);
    const user = useSelector((state) => state.auth.user);
    // const [data, setData] = useState([]);
    // const [dataLoading, setDataLoading] = useState(false);
    const [timeZoneSearchTerm, setTimeZoneSearchTerm] = useState('');
    const [timeZones, setTimeZones] = useState([]);
    const [currencySearchTerm, setCurrencySearchTerm] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
    const [timeFormatDropdownOpen, settimeFormatDropdownOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [timeFormat, setTimeFormat] = useState(null);
    const currencyDropdownRef = useRef(null);
    const timeFormatDropdownRef = useRef(null);
    const [timezoneDropdownOpen, setTimezoneDropdownOpen] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const timezoneDropdownRef = useRef(null);
    const { appSettings } = useSelector((state) => state.appSettings);
    let token = null;
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    const { data: timeZoneData = [], isLoading: isTimeZoneDataLoading, refetch: refetchTimeZoneDatas, isError: timeZoneDataError } = useTimeZone(props && props.id, token);

    // const fetchData = async () => {
    //     try {
    //         setDataLoading(true); // Set loading to true before making the request
    //         const response = await axios.get(
    //             `/api/get/user-settings/${props.id}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         );
    //         setData(response.data);
    //     } catch (error) {
    //         console.log("Error", error);
    //         global.config.slackMessage(error.toString());
    //     } finally {
    //         setDataLoading(false); // Set loading to false after the request is completed
    //     }
    // };

    // const { data, isLoading: dataLoading, error, refetch } = useQuery('exampleQueryKey', fetchData);

    useEffect(() => {
        setSelectedCurrency(timeZoneData && timeZoneData.currency);
        setSelectedTimezone(timeZoneData && timeZoneData.timeZone);
        setTimeFormat(timeZoneData && timeZoneData.timeFormat);
    }, [timeZoneData]);

    useEffect(() => {
        refetchTimeZoneDatas();
    }, [selectedCurrency, selectedTimezone, props.id])

    const toggleCurrencyDropdown = () => {
        setCurrencyDropdownOpen(!currencyDropdownOpen);
    };
    const toggleTimeFormatDropdown = () => {
        settimeFormatDropdownOpen(!timeFormatDropdownOpen);
    };
    let userId = null;
    const selectTimeFormat = async (selectedFormat) => {
        setTimeFormat(selectedFormat);
        if (user._id === props.id) {
            const updatedSettings = { ...appSettings, timeFormat: selectedFormat };
            await dispatch(setAppSettings(updatedSettings));
            userId = user._id;
        } else {
            userId = props.id;
        }

        settimeFormatDropdownOpen(false);
        try {
            const response = await axios.post(
                `/api/post/admin/user-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    weekStartDay: null,
                    appId: props.id,
                    userId: userId,
                    timeFormat: selectedFormat
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Settings updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.removeItem('zoneChangeConfirm');
        } catch (error) {
            console.log(error);
            global.config.slackMessage(error.toString());
        }

    }
    const selectCurrencyOption = async (selectedCurrency) => {
        setSelectedCurrency(selectedCurrency);
        if (user._id === props.id) {
            const updatedSettings = { ...appSettings, currency: selectedCurrency };
            await dispatch(setAppSettings(updatedSettings));
            userId = user._id;
        } else {
            userId = props.id;
        }

        setCurrencyDropdownOpen(false);
        try {

            const response = await axios.post(
                `/api/post/admin/user-settings`,
                {
                    timeZone: null,
                    currency: selectedCurrency,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    weekStartDay: null,
                    userId: userId,
                    timeFormat: null
                    // appId: props.id,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        } catch (error) {
            console.log(error);
            global.config.slackMessage(error.toString());
        }

        toast.success("Settings updated successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const toggleTimezoneDropdown = () => {
        setTimezoneDropdownOpen(!timezoneDropdownOpen);
    };

    const selectTimezoneOption = async (selectedTimezone) => {

        // console.log(selectedTimezone,props);
        setSelectedTimezone(selectedTimezone);
        let userId = null;
        if (user._id === props.id) {
            const updatedSettings = { ...appSettings, timeZone: selectedTimezone };
            await dispatch(setAppSettings(updatedSettings));
            userId = user._id;
        } else {
            userId = props.id;
        }
        setTimezoneDropdownOpen(false);
        try {
            const response = await axios.post(
                `/api/post/admin/user-settings`,
                {
                    timeZone: selectedTimezone,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    weekStartDay: null,
                    // appId: props.id,
                    userId: userId,
                    timeFormat: null
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Settings updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.removeItem('zoneChangeConfirm');
        } catch (error) {
            console.log(error);
            global.config.slackMessage(error.toString());
        }
    };

    useEffect(() => {

        async function fetchUser() {
            setIsLoading(true);
            try {
                const response = await axios.get(`/api/user/view/${props.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserView(response.data);

                setIsLoading(false);
            } catch (error) {
                console.log(error);
                global.config.slackMessage(error.toString());
                if (error.response?.data?.status === "deleted") {
                    localStorage.removeItem("user");
                    dispatch(logout());
                    dispatch(reset());
                    dispatch(invoiceReset());
                    dispatch(headerReset());
                    dispatch(appSettingsReset());
                    toast.error('This user is deleted!', {
                        position: "bottom-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    navigate("/");
                    return;
                }
                alert("Something went wrong in fetching users");
            }
        }

        fetchUser().then(() => { });
    }, [props.id]);

    useEffect(() => {
        const fetchTimeZones = () => {
            // Get the time zone names using the Intl.DateTimeFormat object
            const allTimeZones = moment.tz.names();
            const allCurrencies = getAllISOCodes();

            // Set the time zones in the state
            setTimeZones(allTimeZones);
            setCurrencies(allCurrencies);
        };

        // Call the fetchTimeZones function
        fetchTimeZones();
    }, []);

    const handleCurrencyFilterChange = (e) => {
        setCurrencySearchTerm(e.target.value);
    };

    const codeToCurrency = (code) => {
        var currencyCode = code ? code : 'USD';
        try {
            var currencyCode1 = currencyCodes.code(currencyCode).currency;
            return currencyCode1.toString(); // Ensure it's a string
        } catch (error) {
            return code.toString(); // Ensure it's a string
        }
    }

    const filteredCurrencies = currencies.filter((currency) => {
        const currencyName = codeToCurrency(currency.currency && currency.currency);
        return currencyName.toLowerCase().includes(currencySearchTerm.toLowerCase());
    });

    const handleTimeZoneFilterChange = (e) => {
        setTimeZoneSearchTerm(e.target.value);
    };

    const filteredTimeZone = timeZones.filter((timeZone) => {
        return timeZone.toLowerCase().includes(timeZoneSearchTerm.toLowerCase());
    });

    const handleClickOutsideDropdown = (event) => {
        const isClickInsideTimezoneDropdown = timezoneDropdownRef.current && timezoneDropdownRef.current.contains(event.target);
        const isClickInsideCurrencyDropdown = currencyDropdownRef.current && currencyDropdownRef.current.contains(event.target);
        const isClickInsideTimeFormatDropdown = timeFormatDropdownRef.current && timeFormatDropdownRef.current.contains(event.target);

        if (!isClickInsideTimezoneDropdown) {
            setTimezoneDropdownOpen(false);
        }
        if (!isClickInsideCurrencyDropdown) {
            setCurrencyDropdownOpen(false);
        }
        if (!isClickInsideTimeFormatDropdown) {

            settimeFormatDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);

    const handleEnableEmailNotificationChange = async (id) => {
        try {
            setIsEmailNotificationLoading(true);
            const response = await axios.get(`/api/user/email/notification/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s email notification status updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s email notification status updated successfully`
            );
            dispatch(setNotification(Date.now()));
            setUserView((prevState) => {
                // Find the user object with the corresponding id
                if (prevState._id === id) {
                    // Update the is_active property of the user object
                    return { ...prevState, is_email_enabled: !prevState.is_email_enabled };
                }
                return prevState;
            });
        } catch (error) {
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing user status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing ${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s Email Notification Status`
            );
            console.log(error);
        } finally {
            setIsEmailNotificationLoading(false);
        }
    };

    const handleEnableAdminTimeoffEmailNotificationChange = async (id) => {
        try {
            setIsAdminTimeoffEmailNotificationLoading(true);
            const response = await axios.get(`/api/admin/user/time-off/email/notification/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" time off email notification status updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" time off email notification status updated successfully`
            );
            dispatch(setNotification(Date.now()));
            setUserView((prevState) => {
                // Find the user object with the corresponding id
                if (prevState._id === id) {
                    // Update the is_active property of the user object
                    return { ...prevState, is_admin_timeoff_email_enabled: !prevState.is_admin_timeoff_email_enabled };
                }
                return prevState;
            });
        } catch (error) {
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing admin email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" time off email notification status`
            );
            console.log(error);
        } finally {
            setIsAdminTimeoffEmailNotificationLoading(false);
        }
    };

    const handleEnableAdminInvoiceEmailNotificationChange = async (id) => {
        try {
            setIsAdminInvoiceEmailNotificationLoading(true);
            const response = await axios.get(`/api/admin/user/invoice/email/notification/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" invoice email notification status updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" Invoice email notification status updated successfully`
            );
            dispatch(setNotification(Date.now()));
            setUserView((prevState) => {
                // Find the user object with the corresponding id
                if (prevState._id === id) {
                    // Update the is_active property of the user object
                    return { ...prevState, is_admin_invoice_email_enabled: !prevState.is_admin_invoice_email_enabled };
                }
                return prevState;
            });
        } catch (error) {
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing admin email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing Admin "${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s" Invoice Email Notification Status`
            );
            console.log(error);
        } finally {
            setIsAdminInvoiceEmailNotificationLoading(false);
        }
    };

    const handleEnableNotificationChange = async (id) => {
        try {
            setIsNotificationLoading(true);
            await axios.get(`/api/user/notification/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s notification status updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s notification status updated successfully`
            );
            if (user._id === id) {
                dispatch(toggleNotification());
            }
            dispatch(setNotification(Date.now()));
            setUserView((prevState) => {
                if (prevState._id === id) {
                    return { ...prevState, is_notification_enabled: !prevState.is_notification_enabled };
                }
                return prevState;
            });
        } catch (error) {
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing user notification status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing ${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s Notification Status`
            );
            console.log(error);
        } finally {
            setIsNotificationLoading(false);
        }
    };

    return (
        <div>
            <div>
                <div>
                    <div className="security-outer noti-outer">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">Notifications</span>
                            <div class="toggle-spinner">

                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                Enable all in-app notifications
                            </span>
                            <div className="toggle-spinner">
                                {isLoading === true && (

                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                )}

                                {isNotificationLoading === true && (

                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />)}

                                {isLoading === false && (
                                    <label className="custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-switch-input"
                                            id="emailnotification"
                                            name="enable_emailnotification"
                                            value="1"
                                            style={{ cursor: "pointer" }}
                                            checked={userView.is_notification_enabled === true}
                                            onChange={() => handleEnableNotificationChange(userView._id)}
                                        />
                                        <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                    </label>)}
                            </div>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                Enable all email notification
                            </span>
                            <div className="toggle-spinner">
                                {isLoading === true && (

                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                )}

                                {isEmailNotificationLoading === true && (

                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />)}
                                {isLoading === false && (
                                    <label className="custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-switch-input"
                                            id="emailnotification"
                                            name="enable_emailnotification"
                                            value="1"
                                            checked={userView.is_email_enabled === true}
                                            onChange={() => handleEnableEmailNotificationChange(userView._id)}
                                        />
                                        <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                    </label>)}
                            </div>
                        </div>
                        {(userView?.role_id?._id === userTypeName.Super_Admin || userView?.role_id?._id === userTypeName.Admin) &&
                            <>
                                <div className="security-inner noti-enable">
                                    <span className="custom-switch-description">
                                        Enable TimeOff email notification
                                    </span>
                                    <div className="toggle-spinner">
                                        {isLoading === true && (

                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )}

                                        {isAdminTimeoffEmailNotificationLoading === true && (

                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />)}
                                        {isLoading === false && (
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-switch-input"
                                                    id="emailnotification"
                                                    name="enable_emailnotification"
                                                    value="1"
                                                    checked={userView.is_admin_timeoff_email_enabled === true}
                                                    onChange={() => handleEnableAdminTimeoffEmailNotificationChange(userView._id)}
                                                />
                                                <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                            </label>)}
                                    </div>
                                </div>
                                <div className="security-inner noti-enable">
                                    <span className="custom-switch-description">
                                        Enable Invoice email notification
                                    </span>
                                    <div className="toggle-spinner">
                                        {isLoading === true && (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )}

                                        {isAdminInvoiceEmailNotificationLoading === true && (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />)}
                                        {isLoading === false && (
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-switch-input"
                                                    id="emailnotification"
                                                    name="enable_emailnotification"
                                                    value="1"
                                                    checked={userView.is_admin_invoice_email_enabled === true}
                                                    onChange={() => handleEnableAdminInvoiceEmailNotificationChange(userView._id)}
                                                />
                                                <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                            </label>)}
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                    <div className="security-outer noti-outer profile-edit-profile">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">Profile</span>
                            <div class="toggle-spinner">

                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                Default Timezone
                            </span>
                            <div className="custom-dropdown">
                                {isTimeZoneDataLoading ? (
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    <div ref={timezoneDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${timezoneDropdownOpen ? 'open' : ''}`}>
                                        <div className="selected-option custom-dropdown-user-new" onClick={toggleTimezoneDropdown}>
                                            {selectedTimezone ? selectedTimezone : 'Select Timezone'}
                                        </div>
                                        {timezoneDropdownOpen && (
                                            <div className="custom-dropdown-new">
                                                <input
                                                    type="text"
                                                    className="search-bar"
                                                    placeholder="Search Timezone"
                                                    value={timeZoneSearchTerm}
                                                    onChange={handleTimeZoneFilterChange}
                                                />
                                                <ul className="type-list">
                                                    {filteredTimeZone.sort((a, b) => a.localeCompare(b)).map((timeZone, index) => (
                                                        <li onClick={() => selectTimezoneOption(timeZone)} key={index}>{timeZone}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                Default currency
                            </span>
                            <div className="custom-dropdown">
                                {isTimeZoneDataLoading ? (
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    <div ref={currencyDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${currencyDropdownOpen ? 'open' : ''}`}>
                                        <div className="selected-option custom-dropdown-user-new" onClick={toggleCurrencyDropdown}>
                                            {selectedCurrency ? selectedCurrency : 'Select Currency'}
                                        </div>
                                        {currencyDropdownOpen && (
                                            <div className="custom-dropdown-new">
                                                <input
                                                    type="text"
                                                    className="search-bar"
                                                    placeholder="Search Currency"
                                                    value={currencySearchTerm}
                                                    onChange={handleCurrencyFilterChange}
                                                />
                                                <ul className="type-list">
                                                    {filteredCurrencies
                                                        .reduce((uniqueCurrencies, currency) => {
                                                            const currencyName = codeToCurrency(currency.currency && currency.currency);
                                                            if (!uniqueCurrencies.find(item => item.name === currencyName)) {
                                                                uniqueCurrencies.push({ name: currencyName, currency: currency.currency }); // Push the currency object along with its name
                                                            }
                                                            return uniqueCurrencies;
                                                        }, [])
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((currency, index) => (
                                                            <li onClick={() => selectCurrencyOption(currency.currency)} key={index}>
                                                                {currency.name}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {(user && (user.userCategory !== userCategory.Vendor)) && (
                            <div className="security-inner noti-enable">
                                <span className="custom-switch-description">
                                    Time Format
                                </span>
                                <div className="custom-dropdown">
                                    {isTimeZoneDataLoading ? (
                                        <div className="outter-load-table">
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    ) : (
                                        <div ref={timeFormatDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${timeFormatDropdownOpen ? 'open' : ''}`}>
                                            <div className="selected-option custom-dropdown-user-new" onClick={toggleTimeFormatDropdown}>
                                                {timeFormat ? `${timeFormat} Hours` : 'Select Format'}
                                            </div>
                                            {timeFormatDropdownOpen && (
                                                <div className="custom-dropdown-new">
                                                    <ul className="type-list">
                                                        <li
                                                            onClick={() => selectTimeFormat(24)}
                                                            key="1"
                                                        >
                                                            24 Hours
                                                        </li>
                                                        <li
                                                            onClick={() => selectTimeFormat(12)}
                                                            key="2"
                                                        >
                                                            12 Hours
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserNotification;