import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { Column } from "primereact/column";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { ThreeDots } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { dateVenInvConvert } from "../../../helpers/dateHelper";
import { renderVendorsInvoicesCTA } from "../../../helpers/invoiceHelpers";
import currencySymbol from "../../../constants/currencies.js";
import payStatusConsts from "../../../constants/invPaymetConsts";
import { useFetchRecentVendorInvoices } from "./VendorDashboardCurrencyApi";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import PaymentStatusWise from "../../Invoices/elements/paymentStatusWise.jsx";
import { motion } from 'framer-motion';
import VendorPaymentStatus from "./elements/vendorPaymentStatusWise.jsx";

const useRenderMemberName = (rowData) => {
  const [showFullname, setShowFullname] = useState(false);
  const vendorName =
    (rowData.ven_inv_for.vendor_name || rowData.ven_inv_for.vendor_legalentity_name);
  const displayName = showFullname
    ? vendorName
    : vendorName.length <= 15
      ? vendorName
      : vendorName.slice(0, 15) + "...";

  return (
    <div
      className={`member-name-vendor member-name${showFullname ? " active" : ""}`}
      onMouseEnter={() => {
        if (vendorName.length > 15) {
          setShowFullname(true);
        }
      }}
      onMouseLeave={() => setShowFullname(false)}
    >
      <span>{displayName}</span>
    </div>
  );
};

const RecentVendorInvoices = (props) => {
  const currencySum = props.sum;
  const dispatch = useDispatch();
  const dashboradDate = useSelector(
    (state) => state.invoice.dashboardDateRange
  );
  const dashboradCurrency = useSelector(
    (state) => state.invoice.dashboardCurrency
  )
  const user = useSelector(
    (state) => state.auth.user
  )
  const token = useSelector(
    (state) => state.auth.token
  )

  const { appSettings } = useSelector((state) => state.appSettings);
  const { data: products = [], isLoading, refetch: refetchRecentInvoice } = useFetchRecentVendorInvoices(
    token,
    new Date(moment(dashboradDate[0]).tz(global.config.getTimeZone()).startOf('day')).toISOString(),
    new Date(moment(dashboradDate[1]).tz(global.config.getTimeZone()).endOf('day')).toISOString(),
    100,
    dashboradCurrency,
    currencySum
  );

  useEffect(() => {
    dispatch(setHeaderText("Dashboard"));
    refetchRecentInvoice();
  }, [dashboradDate, dashboradCurrency, currencySum, dispatch, token]);

  const renderPaymentStatus = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.ven_inv_payment_status === payStatusConsts.Payment_Processing) {
      statusClass = "payment-processeing";
      statusName = "Payment Processing";
    } else if (rowData.ven_inv_payment_status === payStatusConsts.Pending) {
      const startDate = moment().tz(global.config.getTimeZone());
      const endDate = moment(rowData.ven_inv_due_date).tz(global.config.getTimeZone());
      const diffInDays = endDate.diff(startDate, 'days');
      if (diffInDays < 0) {
        statusClass = "overdue";
        statusName = "Overdue";
      } else {
        statusClass = "pending";
        statusName = "Pending";
      }
    } else if (rowData.ven_inv_payment_status === payStatusConsts.Manually_Processed) {
      statusClass = "manual";
      statusName = "Manually processed";
    } else if (rowData.ven_inv_payment_status === payStatusConsts.Sucessfully_Processed) {
      statusClass = "sucessfully-processed";
      statusName = "Payment Processed";
    } else if (rowData.ven_inv_payment_status === payStatusConsts.Cancelled) {
      statusClass = "overdue";
      statusName = "Cancelled";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{global.config.locate(appSettings && appSettings.language, statusName)}</span>
    );
  };

  const ConvertCurrency = (code) => {
    const filteredCurrencies = Object.entries(currencySymbol).filter(([key, value]) => key === code);
    if (filteredCurrencies.length === 1) {
      var currency = filteredCurrencies[0][1];
      return currency;
    } else {
      return code;
    }
  }
  const renderAmount = (rowData) => {
    var totalAmount = rowData.ven_inv_total_amount.toLocaleString('en-US', { style: 'currency', currency: rowData.ven_inv_currency });
    return <span> {totalAmount}</span>;
  };
  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };
  return (
    <div className="invoice-outer">
      <div className="recent-title">{global.config.locate(appSettings && appSettings.language, 'Recent Invoices')}</div>
      <div className="card">
        {isLoading ? (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#405BFF"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        ) : (

          <motion.div
            ref={componentRef}
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.3 }}
          >

            <DataTable
              value={products.slice(0, 5)}
              removableSort
              tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
              paginator={false}
              paginatorClassName="recent-invoices-pagination"
              rows={5} // Set the number of rows per page
              emptyMessage="No invoices found"
            >
              <Column
                field="ven_inv_number"
                header={global.config.locate(appSettings && appSettings.language, 'Invoice ID')}
                sortable
                style={{ minWidth: "200px", paddingRight: "44px" }}
              ></Column>
              <Column
                field="ven_inv_due_date"
                body={dateVenInvConvert}
                header={global.config.locate(appSettings && appSettings.language, 'Due Date')}
                sortable
                style={{ minWidth: "230px", paddingRight: "44px" }}
              ></Column>
              <Column
                field="ven_inv_for.vendor_name"
                header={global.config.locate(appSettings && appSettings.language, 'User Name')}
                body={useRenderMemberName}
                sortable
                sortField="vendorName" // Use a different sortField to avoid confusion with the original field
                sortFunction={(e) => {
                  const dataCopy = [...products];
                  dataCopy.sort((rowData1, rowData2) => {
                    const vendorName1 =
                      (rowData1.ven_inv_for.vendor_name ||
                        rowData1.ven_inv_for.vendor_legalentity_name);

                    const vendorName2 =
                      (rowData2.ven_inv_for.vendor_name ||
                        rowData2.ven_inv_for.vendor_legalentity_name);

                    // Implement your custom comparison logic here
                    return e.order === 1
                      ? vendorName1.localeCompare(vendorName2)
                      : vendorName2.localeCompare(vendorName1);
                  });
                  return dataCopy;
                }}
                style={{ minWidth: "240px", paddingRight: "44px" }}
              ></Column>
              <Column
                field="ven_payment_status"
                header={global.config.locate(appSettings && appSettings.language, 'Payment Status')}
                body={(rowData) => (
                  <VendorPaymentStatus
                    inv_payment_details={rowData.ven_inv_payment_details}
                    invData={rowData}
                    createdAt={rowData.ven_created_at}
                    paymentStatus={rowData.ven_inv_payment_status}
                  />
                )}
                // sortable
                style={{ minWidth: "200px", paddingRight: "44px" }}
              ></Column>
              <Column
                field="ven_inv_total_amount"
                header={global.config.locate(appSettings && appSettings.language, 'Amount')}
                body={renderAmount}
                sortable
                style={{ padding: "20px" }}
              ></Column>
              <Column
                field="_id"
                body={renderVendorsInvoicesCTA}
                style={{}}
              ></Column>
            </DataTable>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default RecentVendorInvoices;