import "./ClientsOverview.css";
import 'react-phone-number-input/style.css';
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from "react";
import Edit from "../../images/icons/edit-pen.svg";
import DefultCompLogo from "../../images/icons/logo-default.svg";
import { Link } from 'react-router-dom';
import AES from "crypto-js/aes";
import { useFetchClientDetails } from "../TimeTracker/TimeTrackerClient/useClientDetails";

const ClientOverview = (props) => {
  const [updatedClientProfiles, setUpdatedClientProfiles] = useState([]);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  // const { data: clientProfiles = [], isLoading, refetch: refetchClientProfiles } = useFetchClientDetails(token);

  useEffect(() => {
    props.refetchClientProfiles();
    if (props.updateClientList) {
      props.refetchClientProfiles();
    }
    setUpdatedClientProfiles(props.clientProfiles);
  }, [props.updateClientList, props.clientProfiles]);

  const sortData = (data, selectedSortOption) => {
    switch (selectedSortOption) {
      case 'By name: A - Z':
        return data.slice().sort((a, b) => a.client_name.localeCompare(b.client_name));
      case 'By name: Z - A':
        return data.slice().sort((a, b) => b.client_name.localeCompare(a.client_name));
      case 'Date: old to new':
        return data.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      case 'Date: new to old':
        return data.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      default:
        return data;
    }
  };

  const sortedData = sortData(updatedClientProfiles, props.selectedSortOption);

  const filteredData = sortedData.filter((item) => {
    const searchTerm = props.filter.trim().toLowerCase();
    const hasMatch =
      item.client_name.toLowerCase().includes(searchTerm) &&
      (props.selectedStatus === "Active" ? item.is_active : true) &&
      (props.selectedStatus === "Inactive" ? !item.is_active : true);
    return hasMatch;
  });

  return (
    <div className="client-ls-outer">
      <div className={`client-ls-inner ${filteredData.length > 0 ? '' : 'client-ls-inner-no-data'}`}>
        {props.isLoading == true ? (
          <div className="outter-load-table">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#405BFF"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <Link to={`/client/details/${encodeURIComponent(AES.encrypt(item._id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} key={index}>
                  <div className="ls-box">
                    <div className="ls-box-logo">
                      {item.company_logo !== "null" &&
                        item.company_logo !== null ? (
                        <img
                          src={`/uploads/clientCompyLogo/lowQuality/${item.company_logo}`}
                          alt="Logo"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/uploads/clientCompyLogo/lowQuality/DefaultCompLogo.png';
                          }}
                        />
                      ) : (
                        <img
                          src={DefultCompLogo}
                          alt="Logo"
                        />
                      )}
                    </div>
                    <div className="client-name">{item.client_name}</div>
                  </div>
                </Link>
              ))
            ) : (
              !props.isLoading && (
                <div className="outter-load-table">
                  No results found
                </div>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ClientOverview;
