import React, { useRef, useState, useEffect } from 'react';
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux';
import "../ReportingOverview/ReprtingOverviewFilter.css";
import Modal from 'react-modal';
import TruncateFilterFieldView from "../../helpers/truncateFilterFieldView.js";
import DownloadImage from "../../../images/icons/download-icon.svg";
import { setReportingClientDetails, setReportingClientAccountManager, setReportingClientStatus } from '../../../features/reporting/reportingClient/reportingClientSlice.js';
import TruncateUserName from '../../helpers/truncateUserName.js';

function ClientReportFilter(props) {
  const dispatch = useDispatch();
  const clientDropdownRef = useRef(null);
  const accountManagerDropdownRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const clientModalDropdownRef = useRef(null);
  const accountManagerModalDropdownRef = useRef(null);
  const statusModalDropdownRef = useRef(null);

  const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
  const [isAccountManagerDropdownOpen, setIsAccountManagerDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isClientModalDropdownOpen, setIsClientModalDropdownOpen] = useState(false);
  const [isAccountManagerModalDropdownOpen, setIsAccountManagerModalDropdownOpen] = useState(false);
  const [isStatusModalDropdownOpen, setIsStatusModalDropdownOpen] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  // const [clientsFilter, setClientsFilter] = useState("All");
  const [selectAllClient, setSelectAllClient] = useState(true);
  const [selectedClients, setSelectedClients] = useState([]);
  // const [accountManagersFilter, setAccountManagersFilter] = useState("All");
  const [selectAllAccountManager, setSelectAllAccountManager] = useState(true);
  const [selectedAccountManager, setSelectedAccountManager] = useState([]);
  const [sortedAccountManagers, setSortedAccountManagers] = useState([]);
  // const [statusesFilter, setStatusesFilter] = useState("All");
  const [sortedStatuses, setSortedStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectAllStatus, setSelectAllStatus] = useState(true);
  const calendarRef = useRef(null);
  const reportingClient = useSelector((state) => state.reportingClient);
  const [filteredData, setFilteredData] = useState([]);

  // const dummyClients = [
  //   { _id: 1, client_name: 'Client A' },
  //   { _id: 2, client_name: 'Client B' },
  //   { _id: 3, client_name: 'Client C' }
  // ];

  // const dummyAccountManagers = [
  //   { _id: 1, accountManager: 'Braintrust' },
  //   { _id: 2, accountManager: 'TalenOn' },
  //   { _id: 3, accountManager: 'Gruporesidencial' },
  //   { _id: 4, accountManager: 'Mudhouse' }
  // ];

  const dummyStatuses = [
    { _id: 1, status: 'INR' },
    { _id: 2, status: 'DOllar' },
    { _id: 3, status: 'EURO' },
    { _id: 4, status: 'MYR' }
  ];

  // const dummyRanges = [
  //   { label: 'Today', value: new Date() },
  //   { label: 'Yesterday', value: new Date(new Date().setDate(new Date().getDate() - 1)) },
  //   { label: 'Last 7 Days', value: new Date(new Date().setDate(new Date().getDate() - 7)) }
  // ];

  useEffect(() => {
    const filteredClients = props.clients.filter(client => {
      const isClientDetailMatch = !reportingClient.clientDetails || reportingClient.clientDetails.includes(client._id);
      const isAccountManagerMatch = !reportingClient.clientAccountManager ||
        (client.acc_manager && reportingClient.clientAccountManager.includes(client.acc_manager._id));
      const isStatusMatch = !reportingClient.clientStatus || reportingClient.clientStatus.includes(client.is_active);
      return isClientDetailMatch && isAccountManagerMatch && isStatusMatch;
    });
    setFilteredData(filteredClients);
  }, [props.clients, reportingClient])

  const handleCheckboxAccountManagerChange = (accountManagerId) => {
    const updatedSelection = [...selectedAccountManager];
    const index = updatedSelection.indexOf(accountManagerId);

    if (index === -1) {
      updatedSelection.push(accountManagerId);
    } else {
      updatedSelection.splice(index, 1);
    }

    setSelectedAccountManager(updatedSelection);
    // Update selected clients based on the selected account managers
    const clientsUnderSelectedManagers = props.clients.filter(client =>
      updatedSelection.includes(client.acc_manager?._id)
    );

    setSelectedClients(clientsUnderSelectedManagers.map(client => client._id));
  };

  const handleSelectAllAccountManagerChange = () => {
    if (!selectAllAccountManager) {
      setSelectedAccountManager(sortedAccountManagers.map((accountManager) => accountManager._id));
      const allClientsUnderAllManagers = props.clients.filter(client =>
        sortedAccountManagers.map(accManager => accManager._id).includes(client.acc_manager?._id)
      );
      setSelectedClients(allClientsUnderAllManagers.map(client => client._id));
    } else {
      setSelectedAccountManager([]);
      setSelectedClients([]);
    }
    setSelectAllAccountManager(!selectAllAccountManager);
  };

  useEffect(() => {
    setSelectAllAccountManager(selectedAccountManager.length === sortedAccountManagers.length);
    if (selectedAccountManager.length === sortedAccountManagers.length) {
      // setAccountManagersFilter("All");
      selectAccountManager(selectedAccountManager);
    } else {
      // setAccountManagersFilter(selectedAccountManager);
      selectAccountManager(selectedAccountManager);
    }
  }, [selectedAccountManager, props.client]);

  const handleCheckboxClientChange = (clientId) => {
    const updatedSelection = [...selectedClients];
    const index = updatedSelection.indexOf(clientId);
    let filteredAccountManagers;

    if (index === -1) {
      // Add to selection
      updatedSelection.push(clientId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    const selectedClientObjects = props.clients.filter(client => updatedSelection.includes(client._id));
    const uniqueAccountManagersMap = new Map();
    selectedClientObjects.forEach(client => {
      if (client.acc_manager && !uniqueAccountManagersMap.has(client.acc_manager._id)) {
        uniqueAccountManagersMap.set(client.acc_manager._id, client.acc_manager);
      }
    });

    const uniqueAccountManagers = Array.from(uniqueAccountManagersMap.values());
    setSelectedAccountManager(uniqueAccountManagers.map(accManager => accManager._id));
    setSortedAccountManagers(uniqueAccountManagers);
    selectAccountManager(uniqueAccountManagers.map(accManager => accManager._id));
    // Update sorted statuses based on selected clients

    const uniqueStatuses = Array.from(new Set(selectedClientObjects.map(client => client.is_active)));
    setSortedStatuses(uniqueStatuses);
    setSelectedStatus(uniqueStatuses);
    selectStatus(uniqueStatuses);
    setSelectedClients(updatedSelection);
  };

  const handleSelectAllClientChange = () => {
    if (!selectAllClient) {
      const allClientIds = props.clients.map(client => client._id);
      let filteredAccountManagers;
      setSelectedClients(allClientIds);

      const uniqueAccountManagersMap = new Map();
      props.clients.forEach(client => {
        if (client.acc_manager && !uniqueAccountManagersMap.has(client.acc_manager._id)) {
          uniqueAccountManagersMap.set(client.acc_manager._id, client.acc_manager);
        }
      });

      const uniqueAccountManagers = Array.from(uniqueAccountManagersMap.values());
      setSelectedAccountManager(uniqueAccountManagers.map(accManager => accManager._id));
      setSortedAccountManagers(uniqueAccountManagers);
      selectAccountManager(uniqueAccountManagers.map(accManager => accManager._id));
      // Update sorted statuses based on all clients
      const uniqueStatuses = Array.from(new Set(props.clients.map(client => client.is_active)));
      setSortedStatuses(uniqueStatuses);
      setSelectedStatus(uniqueStatuses);
      selectStatus(uniqueStatuses);
    } else {
      setSelectedClients([]);
      setSelectedAccountManager([]);
      setSortedAccountManagers([]);
      setSelectedStatus([]);
      setSortedStatuses([]);
    }
    setSelectAllClient(!selectAllClient);
  };

  useEffect(() => {
    setSelectAllClient(selectedClients.length === props.clients.length);
    if (selectedClients.length === props.clients.length) {
      // setClientsFilter("All");
      selectClient(null);
    } else {
      // setClientsFilter(selectedClients);
      selectClient(selectedClients);
    }
  }, [selectedClients, props.clients]);

  const handleCheckboxStatusChange = (status) => {
    const updatedSelection = [...selectedStatus];
    const index = updatedSelection.indexOf(status);

    if (index === -1) {
      updatedSelection.push(status);
    } else {
      updatedSelection.splice(index, 1);
    }
    setSelectedStatus(updatedSelection);

    // Update selected clients based on the selected statuses
    const clientsUnderSelectedStatus = props.clients.filter(client =>
      updatedSelection.includes(client.is_active)
    );

    const uniqueAccountManagersMap = new Map();
    clientsUnderSelectedStatus.forEach(client => {
      if (client.acc_manager && !uniqueAccountManagersMap.has(client.acc_manager._id)) {
        uniqueAccountManagersMap.set(client.acc_manager._id, client.acc_manager);
      }
    });

    const uniqueAccountManagers = Array.from(uniqueAccountManagersMap.values());
    setSelectedAccountManager(uniqueAccountManagers.map(accManager => accManager._id));
    setSortedAccountManagers(uniqueAccountManagers);
    selectAccountManager(uniqueAccountManagers.map(accManager => accManager._id));

    setSelectedClients(clientsUnderSelectedStatus.map(client => client._id));
  };

  const handleSelectAllStatusChange = () => {
    if (selectedStatus.length === sortedStatuses.length) {
      setSelectedStatus([]);
      setSelectedClients([]);
      setSelectedAccountManager([]);
      setSortedAccountManagers([]);
    } else {
      setSelectedStatus(sortedStatuses);
      const allClientsUnderAllStatuses = props.clients.filter(client =>
        sortedStatuses.includes(client.is_active)
      );

      const uniqueAccountManagersMap = new Map();
      allClientsUnderAllStatuses.forEach(client => {
        if (client.acc_manager && !uniqueAccountManagersMap.has(client.acc_manager._id)) {
          uniqueAccountManagersMap.set(client.acc_manager._id, client.acc_manager);
        }
      });

      const uniqueAccountManagers = Array.from(uniqueAccountManagersMap.values());
      setSelectedAccountManager(uniqueAccountManagers.map(accManager => accManager._id));
      setSortedAccountManagers(uniqueAccountManagers);
      selectAccountManager(uniqueAccountManagers.map(accManager => accManager._id));

      setSelectedClients(allClientsUnderAllStatuses.map(client => client._id));
    }
  };

  useEffect(() => {
    setSelectAllStatus(selectedStatus.length === sortedStatuses.length);
    if (selectedStatus.length === sortedStatuses.length) {
      // setStatusesFilter("All");
      selectStatus(selectedStatus);
    } else {
      // setStatusesFilter(selectedStatus);
      selectStatus(selectedStatus);
    }
  }, [selectedStatus, props.clients]);

  const toggleClientDropdown = () => {
    setIsClientDropdownOpen(!isClientDropdownOpen);
  };

  const toggleAccountManagerDropdown = () => {
    setIsAccountManagerDropdownOpen(!isAccountManagerDropdownOpen);
  };

  const toggleStatusDropdown = () => {
    setIsStatusDropdownOpen(!isStatusDropdownOpen);
  };

  const toggleClientModalDropdown = () => {
    setIsClientModalDropdownOpen(!isClientModalDropdownOpen);
  };

  const toggleAccountManagerModalDropdown = () => {
    setIsAccountManagerModalDropdownOpen(!isAccountManagerModalDropdownOpen);
  };

  const toggleStatusModalDropdown = () => {
    setIsStatusModalDropdownOpen(!isStatusModalDropdownOpen);
  };

  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    const uniqueAccountManagersMap = new Map();
    props.clients.forEach(client => {
      if (client.acc_manager && !uniqueAccountManagersMap.has(client.acc_manager._id)) {
        uniqueAccountManagersMap.set(client.acc_manager._id, client.acc_manager);
      }
    });

    const uniqueAccountManagers = Array.from(uniqueAccountManagersMap.values());
    const uniqueStatuses = Array.from(new Set(props.clients.map(client => client.is_active)));
    setSelectedClients(props.clients.map((client) => client._id));
    setSelectedAccountManager(uniqueAccountManagers.map(accManager => accManager._id));
    setSelectedStatus(uniqueStatuses);
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const checkAndShowConfirmationModal = () => {
    if (selectedClients.length || selectedAccountManager.length) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const selectClient = async (client) => {
    await dispatch(setReportingClientDetails(client));
  }

  const selectAccountManager = async (accountManager) => {
    await dispatch(setReportingClientAccountManager(accountManager));
  }

  const selectStatus = async (status) => {
    await dispatch(setReportingClientStatus(status));
  }

  useEffect(() => {
    setSelectedClients(props.clients.map((client) => client._id));
    const uniqueAccountManagersMap = new Map();
    props.clients.forEach(client => {
      if (client.acc_manager && !uniqueAccountManagersMap.has(client.acc_manager._id)) {
        uniqueAccountManagersMap.set(client.acc_manager._id, client.acc_manager);
      }
    });

    const uniqueAccountManagers = Array.from(uniqueAccountManagersMap.values());
    // const uniqueAccountManagers = Array.from(new Set(props.clients.map(client => client.is_active)));
    setSortedAccountManagers(uniqueAccountManagers);
    setSelectedAccountManager(uniqueAccountManagers.map(accManager => accManager._id));

    const uniqueStatuses = Array.from(new Set(props.clients.map(client => client.is_active)));
    setSortedStatuses(uniqueStatuses);
    setSelectedStatus(uniqueStatuses);
  }, [props.clients]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideAccountManagerDropdown = accountManagerDropdownRef.current && accountManagerDropdownRef.current.contains(event.target);
    const isClickInsideAccountManagerModalDropdown = accountManagerModalDropdownRef.current && accountManagerModalDropdownRef.current.contains(event.target);
    const isClickInsideClientDropdown = clientDropdownRef.current && clientDropdownRef.current.contains(event.target);
    const isClickInsideClientModalDropdown = clientModalDropdownRef.current && clientModalDropdownRef.current.contains(event.target);
    const isClickInsideStatusDropdown = statusDropdownRef.current && statusDropdownRef.current.contains(event.target);
    const isClickInsideStatusModalDropdown = statusModalDropdownRef.current && statusModalDropdownRef.current.contains(event.target);

    if (!isClickInsideAccountManagerDropdown) {
      setIsAccountManagerDropdownOpen(false);
    }
    if (!isClickInsideAccountManagerModalDropdown) {
      setIsAccountManagerModalDropdownOpen(false);
    }
    if (!isClickInsideClientDropdown) {
      setIsClientDropdownOpen(false);
    }
    if (!isClickInsideClientModalDropdown) {
      setIsClientModalDropdownOpen(false);
    }
    if (!isClickInsideStatusDropdown) {
      setIsStatusDropdownOpen(false);
    }
    if (!isClickInsideStatusModalDropdown) {
      setIsStatusModalDropdownOpen(false);
    }
    // if (!isClickInsideCalendarModalDropdown) {
    //   setShowModalCalendar(false);
    // }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const modifiedLog = filteredData.map((item, index) => ({
        "Sl No": index + 1,
        "Client Name": item.client_name
          ? `${item.client_name}`
          : item.legal_entity_name,
        "Account Manager": item.acc_manager
          ? item.acc_manager.first_name
            ? `${item.acc_manager.first_name} ${item.acc_manager.middle_name ? item.acc_manager.middle_name : ''} ${item.acc_manager.last_name ? item.acc_manager.last_name : ''}`
            : item.acc_manager.company_first_name
              ? `${item.acc_manager.company_first_name} ${item.acc_manager.company_last_name ? item.acc_manager.company_last_name : ''}`
              : item.acc_manager.company_name
          : '',
        "Client Status": item.is_active === true ? "Active" : "Inactive",
        "Created At": moment(item.created_at).tz(global.config.getTimeZone()).format("DD/MM/YYYY, hh:mm:ss A"),
      }));

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);
      const colWidths = [
        { wpx: 25 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 90 },
        { wpx: 120 },
      ];
      worksheet["!cols"] = colWidths;
      // Add alignment to center align the first row and increase font size
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < colWidths.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center !important";
        style.alignment.vertical = "center !important";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }
      // Set border for active area and increase font size
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }
      global.config.activityLog(
        window.location.href,
        "Client Report",
        "Client report exported to Excel"
      );
      const fileName = `Client_Report_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Client Report");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Client Report",
        "Error occurred while exporting client report datas to Excel"
      );
      alert("Error occurred while exporting client report datas to Excel.");
    }
  };

  return (
    <>
      <div className='report-top-button'>
        <div className='button-inner-report'>
          <a onClick={exportToExcel}>
            <span className="icon-export">
              <img src={DownloadImage} alt="Export" />
            </span>
            Export Data
          </a>
        </div>
      </div>
      <div className="invoice-filter invoice-filter-report">
        <div className="invoice-filter-inner-section no-flter-all">
          <div className="filter-inner users">
            <label htmlFor="fname">Client</label>
            <div className="custom-dropdown1" ref={clientDropdownRef}>
              <div className="drop-down-inner">
                <div className="selected-options1" onClick={toggleClientDropdown}>
                  {selectedClients.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <div className="selected-option1" key={selectedClients[0]}>
                      {selectedClients.length === props.clients.length
                        ? 'All'
                        : (
                          <>
                            <TruncateFilterFieldView textContent={props.clients.find((item) => item._id === selectedClients[0])?.client_name} />
                            {selectedClients.length > 1 && selectedClients.length !== props.clients.length && (
                              <div className="selected-option-count">
                                {selectedClients.length - 1}+
                              </div>
                            )}
                          </>
                        )}
                    </div>
                  )}
                </div>
                {isClientDropdownOpen && (
                  <div className="dropdown-list1">
                    {props.clients.length > 0 && (
                      <>
                        <label className="checkbox-label1" key="selectAll">
                          <input
                            type="checkbox"
                            checked={selectAllClient}
                            onChange={handleSelectAllClientChange}
                          />
                          All
                        </label>
                        {props.clients
                          .slice()
                          .sort((a, b) =>
                            a.client_name.toLowerCase().localeCompare(b.client_name.toLowerCase())
                          )
                          .map((client) => (
                            <label className="checkbox-label1" key={client._id}>
                              <input
                                type="checkbox"
                                checked={selectedClients.includes(client._id)}
                                onChange={() => handleCheckboxClientChange(client._id)}
                              />
                              {client.client_name && `${client.client_name}`}
                            </label>
                          ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="filter-inner invoice-status">
            <label htmlFor="invoiceproject">Account Manager</label>
            <div className="custom-dropdown1" ref={accountManagerDropdownRef}>
              <div className="drop-down-inner">
                <div className="selected-options1" onClick={toggleAccountManagerDropdown}>
                  {selectedAccountManager.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <div className="selected-option1" key={selectedAccountManager[0]}>
                      {selectedAccountManager.length === sortedAccountManagers.length
                        ? 'All'
                        : (
                          <>
                            <TruncateUserName
                              textContent={sortedAccountManagers && Array.isArray(sortedAccountManagers) && sortedAccountManagers.find((item) => item._id === selectedAccountManager[0])?.first_name
                                ? `${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).first_name} ${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).middle_name
                                  ? sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).middle_name
                                  : ''
                                } ${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).last_name
                                  ? sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).last_name
                                  : ''
                                }`
                                : (
                                  sortedAccountManagers.find((item) => item._id === selectedAccountManager[0])?.company_first_name
                                    ? `${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).company_first_name} ${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).company_last_name
                                      ? sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).company_last_name
                                      : ''
                                    }`
                                    : `${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0])?.company_name} (Company)`
                                )} />
                            {selectedAccountManager.length > 1 && selectedAccountManager.length !== sortedAccountManagers.length && (
                              <div className="selected-option-count">
                                {selectedAccountManager.length - 1}+
                              </div>
                            )}
                          </>
                        )}
                    </div>
                  )}
                </div>
                {isAccountManagerDropdownOpen && (
                  <div className="dropdown-list1">
                    {sortedAccountManagers.length > 0 ? (
                      <>
                        <label className="checkbox-label1" key="selectAll">
                          <input
                            type="checkbox"
                            checked={selectAllAccountManager}
                            onChange={handleSelectAllAccountManagerChange}
                          />
                          All
                        </label>
                        {sortedAccountManagers
                          .slice()
                          .sort((a, b) => {
                            const nameA = a.first_name
                              ? `${a.first_name} ${a.middle_name || ''} ${a.last_name}`
                              : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
                            const nameB = b.first_name
                              ? `${b.first_name} ${b.middle_name || ''} ${b.last_name}`
                              : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
                            return nameA.localeCompare(nameB);
                          })
                          .map((accountManager) => (
                            <label className="checkbox-label1" key={accountManager._id}>
                              <input
                                type="checkbox"
                                checked={selectedAccountManager.includes(accountManager._id)}
                                onChange={() => handleCheckboxAccountManagerChange(accountManager._id)}
                              />
                              {accountManager.first_name ? (
                                <>
                                  {accountManager.first_name}{' '}
                                  {accountManager.middle_name && accountManager.middle_name !== 'null' ? accountManager.middle_name + ' ' : ''}
                                  {accountManager.last_name}{' '}
                                </>
                              ) : (
                                <>
                                  {accountManager.company_first_name
                                    ? `${accountManager.company_first_name} ${accountManager.company_last_name ? accountManager.company_last_name : ''}`
                                    : `${accountManager.company_name || ''}(Company)`}
                                </>
                              )}
                            </label>
                          ))}
                      </>
                    ) : (
                      <label className="checkbox-label1">
                        No Account Managers found
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="filter-inner invoice-status">
            <label htmlFor="invoiceCurrency">Status</label>
            <div className="custom-dropdown1" ref={statusDropdownRef}>
              <div className="drop-down-inner">
                <div className="selected-options1" onClick={toggleStatusDropdown}>
                  {selectedStatus.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <>
                      <div className="selected-option1">
                        {/* {selectedStatus.length === sortedStatuses.length ? 'All' : sortedStatuses.find(status => status === selectedStatus[0])} */}
                        {selectedStatus.length === sortedStatuses.length ? 'All' : (selectedStatus[0] ? 'Active' : 'Inactive')}
                      </div>
                      {selectedStatus.length > 1 && selectedStatus.length !== sortedStatuses.length && (
                        <div className="selected-option-count">
                          {selectedStatus.length - 1}+
                        </div>
                      )}
                    </>
                  )}
                </div>
                {isStatusDropdownOpen && (
                  <div className="dropdown-list1">
                    {sortedStatuses.length > 0 ? (
                      <>
                        <label className="checkbox-label1" key="selectAll">
                          <input
                            type="checkbox"
                            checked={selectedStatus.length === sortedStatuses.length}
                            onChange={handleSelectAllStatusChange}
                          />
                          All
                        </label>
                        {sortedStatuses.map((status, index) => (
                          <label className="checkbox-label1" key={index}>
                            <input
                              type="checkbox"
                              checked={selectedStatus.includes(status)}
                              onChange={() => handleCheckboxStatusChange(status)}
                            />
                            {status ? 'Active' : 'Inactive'}
                          </label>
                        ))}
                      </>
                    ) : (
                      <label className="checkbox-label1">
                        No statuses found
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="truncate-filters-outer">
          <TruncateFilterFieldView
            clients={dummyClients}
            selectedClients={selectedClients}
            selectedAccountManager={selectedAccountManager}
            invAccountManagerList={dummyAccountManageres}
            handleClientFilterRemoval={(clientId) => handleCheckboxClientChange(clientId)}
            handleAccountManagerFilterRemoval={(accountManagerId) => handleCheckboxAccountManagerChange(accountManagerId)}
            clearFilters={clearFilters}
          />
        </div> */}

        <div className="filter-invoice-right apply-fliter-right">
          <button onClick={toggleFilterModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="17"
              viewBox="0 0 13 17"
              fill="none"
            >
              <path
                d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                fill="#6479F8"
              />
              <path
                d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                fill="#6479F8"
              />
              <path
                d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                fill="#6479F8"
              />
            </svg>
            All Filters
          </button>
        </div>

        <Modal
          isOpen={showFilterModal}
          onRequestClose={toggleFilterModal}
          contentLabel="Filter Modal"
          className="new-filter-modal"
          style={{
            content: {
              height: "50%",
              width: "50%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-25%, -25%)",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              padding: "20px",
              // overflow: "auto",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <div className="new-nodal-invoice-outer-sec">
            <div className="new-modal-top-sec">
              <div className="new-modal-heading">
                All filters
              </div>
              <a className="close-image-clr1" onClick={checkAndShowConfirmationModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M1 13L13 1" stroke="black" />
                  <path d="M1 0.999999L13 13" stroke="black" />
                </svg>{" "}
              </a>
            </div>

            <div className="invoice-filter-inner-section">
              <div className="filter-inner users">
                <label htmlFor="fname">Client</label>
                <div className="custom-dropdown1" ref={clientModalDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleClientModalDropdown}>
                      {selectedClients.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedClients[0]}>
                            {selectedClients.length === props.clients.length
                              ? 'All'
                              : (
                                <>
                                  <TruncateFilterFieldView textContent={props.clients.find((item) => item._id === selectedClients[0])?.client_name} />
                                  {selectedClients.length > 1 && selectedClients.length !== props.clients.length && (
                                    <div className="selected-option-count">
                                      {selectedClients.length - 1}+
                                    </div>
                                  )}
                                </>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                    {isClientModalDropdownOpen && (
                      <div className="dropdown-list1">
                        {props.clients.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllClient}
                                onChange={handleSelectAllClientChange}
                              />
                              All
                            </label>
                            {props.clients
                              .slice()
                              .sort((a, b) =>
                                a.client_name.toLowerCase().localeCompare(b.client_name.toLowerCase())
                              )
                              .map((client) => (
                                <label className="checkbox-label1" key={client._id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedClients.includes(client._id)}
                                    onChange={() => handleCheckboxClientChange(client._id)}
                                  />
                                  {client.client_name && `${client.client_name}`}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner invoice-project">
                <label htmlFor="invoiceproject">Account Manager</label>
                <div className="custom-dropdown1" ref={accountManagerModalDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleAccountManagerModalDropdown}>
                      {selectedAccountManager.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedAccountManager[0]}>
                            {selectedAccountManager.length === sortedAccountManagers.length
                              ? 'All'
                              : (
                                <>
                                  <TruncateUserName
                                    textContent={sortedAccountManagers && Array.isArray(sortedAccountManagers) && sortedAccountManagers.find((item) => item._id === selectedAccountManager[0])?.first_name
                                      ? `${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).first_name} ${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).middle_name
                                        ? sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).middle_name
                                        : ''
                                      } ${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).last_name
                                        ? sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).last_name
                                        : ''
                                      }`
                                      : (
                                        sortedAccountManagers.find((item) => item._id === selectedAccountManager[0])?.company_first_name
                                          ? `${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).company_first_name} ${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).company_last_name
                                            ? sortedAccountManagers.find((item) => item._id === selectedAccountManager[0]).company_last_name
                                            : ''
                                          }`
                                          : `${sortedAccountManagers.find((item) => item._id === selectedAccountManager[0])?.company_name} (Company)`
                                      )} />
                                  {selectedAccountManager.length > 1 && selectedAccountManager.length !== sortedAccountManagers.length && (
                                    <div className="selected-option-count">
                                      {selectedAccountManager.length - 1}+
                                    </div>
                                  )}
                                </>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                    {isAccountManagerModalDropdownOpen && (
                      <div className="dropdown-list1">
                        {sortedAccountManagers.length > 0 ? (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllAccountManager}
                                onChange={handleSelectAllAccountManagerChange}
                              />
                              All
                            </label>
                            {sortedAccountManagers
                              .slice()
                              .sort((a, b) => {
                                const nameA = a.first_name
                                  ? `${a.first_name} ${a.middle_name || ''} ${a.last_name}`
                                  : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
                                const nameB = b.first_name
                                  ? `${b.first_name} ${b.middle_name || ''} ${b.last_name}`
                                  : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
                                return nameA.localeCompare(nameB);
                              })
                              .map((accountManager) => (
                                <label className="checkbox-label1" key={accountManager._id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedAccountManager.includes(accountManager._id)}
                                    onChange={() => handleCheckboxAccountManagerChange(accountManager._id)}
                                  />
                                  {accountManager.first_name ? (
                                    <>
                                      {accountManager.first_name}{' '}
                                      {accountManager.middle_name && accountManager.middle_name !== 'null' ? accountManager.middle_name + ' ' : ''}
                                      {accountManager.last_name}{' '}
                                    </>
                                  ) : (
                                    <>
                                      {accountManager.company_first_name
                                        ? `${accountManager.company_first_name} ${accountManager.company_last_name ? accountManager.company_last_name : ''}`
                                        : `${accountManager.company_name || ''}(Company)`}
                                    </>
                                  )}
                                </label>
                              ))}
                          </>
                        ) : (
                          <label className="checkbox-label1">
                            No Account Managers found
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner invoice-status">
                <label htmlFor="invoiceCurrency">Status</label>
                <div className="custom-dropdown1" ref={statusModalDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleStatusModalDropdown}>
                      {selectedStatus.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1">
                            {/* {selectedStatus.length === sortedStatuses.length ? 'All' : sortedStatuses.find(status => status === selectedStatus[0])} */}
                            {selectedStatus.length === sortedStatuses.length ? 'All' : (selectedStatus[0] ? 'Active' : 'Inactive')}
                          </div>
                          {selectedStatus.length > 1 && selectedStatus.length !== sortedStatuses.length && (
                            <div className="selected-option-count">
                              {selectedStatus.length - 1}+
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {isStatusModalDropdownOpen && (
                      <div className="dropdown-list1">
                        {sortedStatuses.length > 0 ? (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectedStatus.length === sortedStatuses.length}
                                onChange={handleSelectAllStatusChange}
                              />
                              All
                            </label>
                            {sortedStatuses.map((status, index) => (
                              <label className="checkbox-label1" key={index}>
                                <input
                                  type="checkbox"
                                  checked={selectedStatus.includes(status)}
                                  onChange={() => handleCheckboxStatusChange(status)}
                                />
                                {status ? 'Active' : 'Inactive'}
                              </label>
                            ))}
                          </>
                        ) : (
                          <label className="checkbox-label1">
                            No statuses found
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="filter-buttons">
              <button className="clear-all" onClick={clearFilters}>Clear All</button>
              <button className="apply-filter" onClick={applyFilters}>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                  <path d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z" fill="white" />
                  <path d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z" fill="white" />
                  <path d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z" fill="white" />
                </svg>
                Apply Filters
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={showConfirmationModal}
          onRequestClose={() => setShowConfirmationModal(false)}
          contentLabel="Confirmation Modal"
          ariaHideApp={false}
          className="confirmation-modal"
          overlayClassName="confirmation-modal-overlay"
        >
          <div className="modal-content">
            {/* <h2>Are you sure?</h2> */}
            <p>Are you sure you want to clear the filters?</p>
            <div className="modal-footer">
              <button className="cancel-btn" onClick={() => setShowConfirmationModal(false)}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={handleApplyConfirm}>
                Yes, Clear
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ClientReportFilter;
