import { useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import DateConverter from '../../../helpers/dateFormatChange';

const PaymentStatus = ({ inv_payment_details, invData, payStatus }) => {
  const [paymentDate, setPaymentDate] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  useEffect(() => {

    const fetchPaymentDetails = async () => {
      try {
        const response = await fetch('/api/invoices/paymentDetails', {
          method: 'POST',
          body: JSON.stringify({ _id: invData._id }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();

          if (data) {
            setPaymentData(data);
            const createdDate = data && data.created_at;
            setPaymentDate(createdDate);
          }

        } else {
          if (invData) {
            if (invData.inv_paid_date) {
              setPaymentDate(invData.inv_paid_date);
            }
          }
          // Handle error case
          console.error('Failed to fetch payment details');
        }
      } catch (error) {
        if (invData) {
          if (invData.inv_paid_date) {
            setPaymentDate(invData.inv_paid_date);
          }
        }
        global.config.slackMessage(error.toString());
        console.error('Error fetching payment details:', error);
      }
    };

    if (invData.inv_number && payStatus === 1) {
      fetchPaymentDetails();
    }
  }, [invData.inv_number]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/api/wise/payment/status-update', {
        method: 'POST',
        body: JSON.stringify(
          {
            profileId: paymentData.wise_profile_id,
            accountId: paymentData.bank_details,
            invoiceId: paymentData.inv_number,
            date: paymentData.created_at
          }
        ),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();


        setPaymentStatus(data.status)
      } else {
        // Handle error case
        console.error('Failed to fetch payment details');
      }
    }

    if (payStatus===1) {
      if (paymentData && typeof paymentData.in_wise !== 'undefined') {
        fetchData();
      }
    }


  }, [paymentData]);

  // Determine the class and name based on the payment_status value
  let statusClass;
  let statusName;
  if (!invData.inv_number) {
    statusClass = 'pending';
    statusName = 'Pending';
  } else if (!paymentDate) {
    statusClass = 'pending';
    statusName = 'Not updated';
  } else {
    statusClass = 'manual';
    statusName = <DateConverter date={paymentDate} />;
  }
  return <span className={`payment-status ${statusClass}`}>{statusName}</span>;
};

export default PaymentStatus;