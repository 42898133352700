import moment from "moment";
import axios from "axios";



async function activityLog(url, action, message) {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    const ip = data.ip;
    let id = null;
    let token = null;
    const user = localStorage.getItem("user");
    if (user) {
        const parsedUser = JSON.parse(user);
        if (parsedUser && parsedUser._id) {
            id = parsedUser._id;
            token = parsedUser.token;
        }
    }
    var datas = {
        message: message,
        ip: ip,
        user_id: id,
        action: action,
        url: url,
        domain: 'https://app.talenton.io/'
    }

    try {
        const response = await fetch('/api/activity-log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(datas),
        });

        if (response.ok) {

        } else {
            console.error('Failed to post data');
        }
    } catch (error) {
        console.error('Error posting data:', error);
        global.config.slackMessage(error.toString());
    }
}

function getTimeZone() {
    const settings = JSON.parse(localStorage.getItem("settings"));
    const date = new Date();
    const timeZone = Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;
    if (settings?.timeZone) {
        return settings.timeZone;
    } else {
        return timeZone;
    }
}


function getCurrency() {
    const settings = JSON.parse(localStorage.getItem("settings"));
    const date = new Date();
    const timeZone = Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;
    return settings ? settings.currency : null;
}

function getSettings() {

    const settings = JSON.parse(localStorage.getItem("settings"));
    return settings;

}

// function getTimerLock(){

//     const settings = JSON.parse(localStorage.getItem("settings"));
//     return settings ? settings.timerLock : null;
// }

function getTimerLock() {
    const settings = JSON.parse(localStorage.getItem("settings"));
    const timerLock = settings ? settings.timerLock : 3;
    const timerLockTime = settings ? settings.timerLockTime : "04:00 PM";
    return { timerLock, timerLockTime };
}

async function slackMessage(msg) {

    var base_url = window.location.origin;
    if (base_url === "https://app.talenton.iokk" || base_url === "https://staging2.talenton.iokk" ) {
      
            try {
                const message = msg;

                const response = await fetch(`/slack/message/push`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: { message,base_url },
                });
                if (response.ok) {
                    console.log('Message sent to Slack successfully!');
                } else {
                    console.error('Failed to send message to Slack.');
                }
                
            } catch (error) {
                console.error('Error:', error);
                global.config.slackMessage(error.toString());
            }
    }

}


function locate(lang, text) {

    const locale = [];
    const en = {
        "Timezone_and_Language": "Timezone and Language",
        "Default_Timezone": "Default Timezone",
        "Default_Currency": "Default currency",
        "Default_Language": "Default language",
        "Time_Tracking": "Time Tracking",
        "Lock_Time": "Lock Time",
        "SEO": "SEO",
        "SEO_Title": "SEO Title",
        "Meta_Description": "Meta Description",
        "Select_Timezone": "Select Timezone",
        "Search_Timezone": "Search Timezone",
        "Select_Currency": "Select Currency",
        "Search_Currency": "Search Currency",
        "Select_Language": "Select Language",
        "English": "English",
        "Spanish": "Spanish",
        "Work week start day": "Work week start day",
        "Select_Week_Start_Day": "Select Week Start Day",
        "Sunday": "Sunday",
        "Monday": "Monday",
        "Select_Lock_Time": "Select Lock Time",
        "Older_Than": "Older than",
        "Day": "Day",
        "Days": "Days",
        "at": "at",
        "Company_Profile": "Company Profile",
        "Payment_Options": "Payment Options",
        "Subscription": "Subscription",
        "Security": "Security",
        "General_Settings": "General Settings",
        "Notifications": "Notifications",
        "Profile_Settings": "Profile Settings",
        "Logout": "Log Out",
        "Notifications": "Notifications",
        "Mark_All_As_Read": "Mark All As Read",
        "Mark_as_Unread": "Mark as Unread",
        "Mark_as_Read": "Mark as Read",
        "Enable_all_in-app_notifications": "Enable all in-app notifications",
        "Enable_all_email_notification": "Enable all email notification",
        "Enable_TimeOff_email_notification": "Enable TimeOff email notification",
        "Enable_Invoice_email_notification": "Enable Invoice email notification",
        "Force_2-Factor_Authentication_for_all_users": "Force 2-Factor Authentication for all users",
        "New_Bank_Account": "New Bank Account",
        "Please_select_the_bank": "Please select the bank",
        "Step1": "Step 1",
        "Step2": "Step 2",
        "Continue": "Continue",
        "Please_fill_out_the_following_information": "Please fill out the following information",
        "Showing": "Showing",
        "of": "of",
        "Results": "Results",
        "Account_Details": "Account Details",
        "Bank": "Bank",
        "Name": "Name",
        "Registration_Number": "Registration Number",
        "Company_Type": "Company Type",
        "Company_Role": "Company Role",
        "Description_of_Business": "Description of Business",
        "Primary_Address": "Primary Address",
        "Webpage": "Webpage",
        "Description_Category": "Description Category",
        "Delete_Account": "Delete Account",
        "Confirm_Delete": "Confirm Delete",
        "Are_you_sure_you_want_to_delete_this_Account": "Are you sure you want to delete this Account?",
        "Sorry_This_Account_have_unpaid_invoices_please_complete_payment_or_remove_that_invoices": "Sorry! This Account have unpaid invoices. please complete payment or remove that invoices.",
        "Delete": "Delete",
        "Cancel": "Cancel",
        "Company_name": "Company name",
        "Company_Account": "Company Account",
        "Status": "Status",
        "ALL": "All",
        "Details": "Details",
        "Active": "Active",
        "Inactive": "Inactive",
        "Edit": "Edit",
        "Edit_Profile": "Edit Profile",
        "Company_Information": "Company Information",
        "Commercial_Name": "Commercial Name",
        "Legal_Entity_Name": "Legal Entity Name",
        "Email": "Email",
        "Company_ID_number": "Company ID number",
        "Address": "Address",
        "City": "City",
        "State": "State",
        "Country": "Country",
        "Postal_Code": "Postal Code",
        "Mobile_No": "Mobile No.",
        "Save_changes": "Save Changes",
        "Search": "Search",
        "Dashboard": "Dashboard",
        "Users": "Users",
        "My Team": "My Team",
        "Invoices": "Invoices",
        "Time_Tracker": "Time Tracker",
        "Time Tracker": "Time Tracker",
        "Logs": "Logs",
        "Activity Log": "Activity Log",
        "Time_Off": "Time Off",
        "Time Off": "Time Off",
        "Equipment": "Equipment",
        "Expenses": "Expenses",
        "CLIENTS": "CLIENTS",
        "Clients": "Clients",
        "Overview": "Overview",
        "Projects": "Projects",
        "Account_Settings": "Account Settings",
        "Account Settings": "Account Settings",
        "Total Payments": "Total Payments",
        "Total Outstanding": "Total Outstanding",
        "Total Overdue": "Total Overdue",
        "Total Invoices": "Total Invoices",
        "Invoices Overview": "Invoices Overview",
        "Currency": "Currency",
        "No Invoices Generated in this Account": "No Invoices Generated in this Account",
        "Sum all Currencies": "Sum all Currencies",
        "All Time": "All Time",
        "Recent Invoices": "Recent Invoices",
        "Invoice ID": "Invoice ID",
        "Due Date": "Due Date",
        "User Name": "User Name",
        "Payment Status": "Payment Status",
        "Amount": "Amount",
        "Manually processed": "Manually processed",
        "Rejected": "Rejected",
        "Payment Processing": "Payment Processing",
        "Overdue": "Overdue",
        "Pending": "Pending",
        "Payment Processed": "Payment Processed",
        "Cancelled": "Cancelled",
        "Refunded": "Refunded",
        "Select a client": "Select a client",
        "New Client": "New Client",
        "New Client Account": "New Client Account",
        "Client Name": "Client Name",
        "Email Address": "Email Address",
        "Company Website": "Company Website",
        "Company Logo": "Company Logo",
        "Account Manager": "Account Manager",
        "Select Account Manager": "Select Account Manager",
        "Select Country": "Select Country",
        "Additional Information": "Additional Information",
        "Create Client": "Create Client",
        "Delete Client": "Delete Client",
        "Sort By": "Sort By",
        "By name: A - Z": "By name: A - Z",
        "By name: Z - A": "By name: Z - A",
        "Date: old to new": "Date: old to new",
        "Date: new to old": "Date: new to old",
        "Are you sure you want to delete this client?": "Are you sure you want to delete this client?",
        "Add new tag": "Add new tag",
        "Edit Tag": "Edit Tag",
        "Tag Name": "Tag Name",
        "Are you sure you want to delete this Tag?": "Are you sure you want to delete this Tag?",
    };


    const es = {

        "Timezone_and_Language": "Zona horaria e idioma",
        "Default_Timezone": "Zona horaria predeterminada",
        "Default_Currency": "Moneda predeterminada",
        "Default_Language": "Idioma predeterminado",
        "Time_Tracking": "Seguimiento del tiempo",
        "Lock_Time": "Tiempo de bloqueo",
        "SEO": "SEO",
        "SEO_Title": "Título SEO",
        "Meta_Description": "Metadescripción",
        "Select_Timezone": "Selecciona la zona horaria",
        "Search_Timezone": "Buscar zona horaria",
        "Select_Currency": "Seleccione el tipo de moneda",
        "Search_Currency": "Buscar moneda",
        "Select_Language": "Seleccione el idioma",
        "English": "Inglés",
        "Spanish": "Español",
        "Work week start day": "Día de inicio de la semana laboral",
        "Select_Week_Start_Day": "Seleccione el día de inicio de la semana",
        "Sunday": "Domingo",
        "Monday": "Lunes",
        "Select_Lock_Time": "Seleccionar tiempo de bloqueo",
        "Older_Than": "Mas viejo que",
        "Day": "Día",
        "Days": "Días",
        "at": "a las",
        "Company_Profile": "Perfil de la empresa",
        "Payment_Options": "Opciones de pago",
        "Subscription": "Suscripción",
        "Security": "Seguridad",
        "General_Settings": "Configuración general",
        "Notifications": "Notificaciones",
        "Profile_Settings": "Configuración de perfil",
        "Logout": "Cerrar sesión",
        "Notifications": "Notificaciones",
        "Mark_All_As_Read": "Marcar todo como leido",
        "Mark_as_Unread": "Marcar como no leído",
        "Mark_as_Read": "Marcar como leído",
        "Enable_all_in-app_notifications": "Habilitar todas las notificaciones dentro de la aplicación",
        "Enable_all_email_notification": "Habilitar todas las notificaciones por correo electrónico",
        "Enable_TimeOff_email_notification": "Habilitar notificación por correo electrónico de tiempo libre",
        "Enable_Invoice_email_notification": "Habilitar notificación por correo electrónico de facturas",
        "Force_2-Factor_Authentication_for_all_users": "Fuerza la autenticación de 2 factores para todos los usuarios",
        "New_Bank_Account": "Nueva cuenta bancaria",
        "Please_select_the_bank": "Por favor seleccione el banco",
        "Step1": "Paso 1",
        "Step2": "Paso 2",
        "Continue": "Continuar",
        "Please_fill_out_the_following_information": "Por favor complete la siguiente información",
        "Showing": "Mostrando",
        "of": "de",
        "Results": "Resultados",
        "Account_Details": "detalles de la cuenta",
        "Bank": "Banco",
        "Name": "Nombre",
        "Registration_Number": "Número de registro",
        "Company_Type": "Tipo de compañía",
        "Company_Role": "Rol de la empresa",
        "Description_of_Business": "Descripción del negocio",
        "Primary_Address": "dirección primaria",
        "Webpage": "Página web",
        "Description_Category": "Descripción Categoría",
        "Delete_Account": "Borrar cuenta",
        "Confirm_Delete": "Confirmar eliminación",
        "Are_you_sure_you_want_to_delete_this_Account": "¿Está seguro de que desea eliminar esta cuenta?",
        "Sorry_This_Account_have_unpaid_invoices_please_complete_payment_or_remove_that_invoices": "¡Lo siento! Esta Cuenta tiene facturas impagas. complete el pago o elimine esas facturas.",
        "Delete": "Borrar",
        "Cancel": "Cancelar",
        "Company_name": "Nombre de empresa",
        "Company_Account": "Cuenta de la compañia",
        "Status": "Estado",
        "ALL": "Todo",
        "Details": "Detalles",
        "Active": "Activo",
        "Inactive": "Inactivo",
        "Edit": "Editar",
        "Edit_Profile": "Editar perfil",
        "Company_Information": "Información de la empresa",
        "Commercial_Name": "Nombre comercial",
        "Legal_Entity_Name": "nombre de entidad legal",
        "Email": "Correo electrónico",
        "Company_ID_number": "Número de identificación de la empresa",
        "Address": "Dirección",
        "City": "Ciudad",
        "State": "Estado",
        "Country": "País",
        "Postal_Code": "Código Postal",
        "Mobile_No": "Número de teléfono móvil",
        "Save_changes": "Guardar Cambios",
        "Search": "Buscar",
        "Dashboard": "Panel",
        "Users": "Usuarios",
        "My Team": "Mi Equipo",
        "Invoices": "Facturas",
        "Time_Tracker": "Rastreador de tiempo",
        "Time Tracker": "Rastreador de tiempo",
        "Logs": "Registros",
        "Activity Log": "Registro de actividades",
        "Time_Off": "Tiempo Tibre",
        "Time Off": "Tiempo Tibre",
        "Equipment": "Equipo",
        "Expenses": "Gastos",
        "CLIENTS": "CLIENTELA",
        "Clients": "Clientes",
        "Overview": "Descripción general",
        "Projects": "Proyectos",
        "Account_Settings": "Configuraciones de la cuenta",
        "Account Settings": "Configuraciones de la cuenta",
        "Total Payments": "Pagos Totales",
        "Total Outstanding": "Total Sobresaliente",
        "Total Overdue": "Total vencido",
        "Total Invoices": "Facturas totales",
        "Invoices Overview": "Resumen de facturas",
        "Currency": "Divisa",
        "No Invoices Generated in this Account": "No se generaron facturas en esta cuenta",
        "Sum all Currencies": "Sumar todas las monedas",
        "All Time": "Todo el tiempo",
        "Recent Invoices": "Facturas recientes",
        "Invoice ID": "ID de factura",
        "Due Date": "Fecha de vencimiento",
        "User Name": "Nombre de usuario",
        "Payment Status": "Estado de pago",
        "Amount": "Cantidad",
        "Manually processed": "Procesado manualmente",
        "Rejected": "Rechazado",
        "Payment Processing": "Procesando pago",
        "Overdue": "Atrasado",
        "Pending": "Pendiente",
        "Payment Processed": "Pago procesado",
        "Cancelled": "Cancelado",
        "Refunded": "Reintegrado",
        "Select a client": "Seleccione un cliente",
        "New Client": "Nuevo cliente",
        "New Client Account": "Nueva cuenta de cliente",
        "Client Name": "Nombre del Cliente",
        "Email Address": "Dirección de correo electrónico",
        "Company Website": "Página Web de la compañía",
        "Company Logo": "Logo de la compañía",
        "Account Manager": "Gerente de cuentas",
        "Select Account Manager": "Seleccionar administrador de cuentas",
        "Select Country": "Seleccionar país",
        "Additional Information": "Información Adicional",
        "Create Client": "Crear Cliente",
        "Delete Client": "Borrar Cliente",
        "Sort By": "Ordenar por",
        "By name: A - Z": "Por nombre: A - Z",
        "By name: Z - A": "Por nombre: Z - A",
        "Date: old to new": "Fecha: antigua a nueva",
        "Are you sure you want to delete this client?": "¿Está seguro de que desea eliminar este cliente?",
        "Add new tag": "Agregar nueva etiqueta",
        "Edit Tag": "Editar etiqueta",
        "Tag Name": "Nombre de etiqueta",
        "Are you sure you want to delete this Tag?": "¿Estás seguro de que quieres eliminar esta etiqueta?",

    }


    locale.push({ en: en, es: es });

    if (lang) {
        if (lang === "English") {
            lang = 'en';
        }
        if (lang === "Spanish") {
            lang = 'es';
        }
        return locale[0][lang][text];
    } else {
        return locale[0]['en'][text];
    }


}


var conf = global.config = {
    i18n: {
        welcome: {
            en: "Welcome",
            fa: "خوش آمدید"
        }
        // rest of your translation object
    }
    ,
    activityLog,
    getTimeZone,
    settings: getSettings,
    appCurrency: getCurrency,
    timerLock: getTimerLock,
    locate: locate,
    slackMessage: slackMessage
    // other global config variables you wish
};
export default conf;