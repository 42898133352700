import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import React, { useState, useEffect } from "react";
import { useActivateBankProfile, useDeactivateBankProfile, useDeactivatedProfiles } from "../bankApiQuery";

const BankStatusIndicator = ({ profileId }) => {
  const [isActive, setIsActiveState] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: deactivatedProfiles, isLoading, refetch: refetchDeactivatedProfiles } = useDeactivatedProfiles(token);
  const deactivateMutation = useDeactivateBankProfile(token);
  const activateMutation = useActivateBankProfile(token);
  const isProfileActive = !isLoading && !deactivatedProfiles.includes(profileId);

  useEffect(() => {
    if (isProfileActive) {
      setIsActiveState(isProfileActive);
    }
  }, [isProfileActive]);

  const bankDeactivate = async () => {
    try {
      setIsSubmitLoading(true);
      await deactivateMutation.mutateAsync(profileId);
      setIsActiveState(false);
      refetchDeactivatedProfiles();
      toast.success(`Bank profile ${profileId} deactivated successfully`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      toast.error(`Something went wrong while deactivation of bank profile ${profileId}`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const bankActivate = async () => {
    try {
      setIsSubmitLoading(true);
      await activateMutation.mutateAsync(profileId);
      setIsActiveState(true);
      refetchDeactivatedProfiles();
      toast.success(`Bank profile ${profileId} activated successfully`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      toast.error(`Something went wrong while activation of bank profile ${profileId}`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <span
      className={isActive ? "active" : "inactive"}
      onClick={() => {
        if (!isLoading) { // Check if isLoading is false before proceeding
          if (isActive) {
            bankDeactivate();
          } else {
            bankActivate();
          }
        }
      }}
      style={{ cursor: isLoading ? "default" : "pointer" }}
    >
      {isLoading || isSubmitLoading ? (
        <ThreeDots
          height="18"
          width="20"
          radius="9"
          color="#6479f9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      ) : (isActive ? "Active" : "InActive")}
    </span>
  );
};

export default BankStatusIndicator;