import React, { useEffect, useState } from 'react';
import "./ProjectOverviewListingRecenInvoices.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ThreeDots } from "react-loader-spinner";
import { createDateConvert, dateConvert } from "../../../../helpers/dateHelper";
import NewSprintModal from '../../helpers/NewSprintModal';
import { useSelector } from "react-redux";
import moment from "moment";
import SprintViewModal from "./modals/SprintViewModal";
import StatusModal from "./modals/StatusModal";
import { useDispatch } from 'react-redux';
import { setSprintView, setSprintData , setSprintStatusView } from "../../../../features/projects/projectSlice";
import { SprintCost, TrackedSprintHours } from '../../../../helpers/ProjectHelpers';


const SprintTable = (props) => {
    const [sprintModalOpen, setSprintModalOpen] = useState(false);
    const dispatch = useDispatch();

    const settings = JSON.parse(localStorage.getItem("settings"));
    const { forex } = useSelector((state) => state.forex);

    const convertCreatedDate = (rowData) => {
        const timestamp = rowData.created_at;
        const formattedDate = moment(timestamp).format('MMMM DD, YYYY');
        return formattedDate;
    }

    const ConvertDeliveryDate = (rowData) => {
        if (rowData.delivered_date && rowData.sprint_status === "Delivered") {
            const timestamp = rowData.delivered_date;
            return moment(timestamp).format('MMMM DD, YYYY');
        } else if (rowData.sprint_status === "In Progress") {
            return "In Progress";
        }
        else if (rowData.sprint_status === "Cancelled") {
            return "N/A";
        } else {
            return rowData.sprint_status;
        }
    };
    const ConvertStatus = (rowData) => {

        if (rowData.delivered_date && rowData.sprint_status === "Delivered") {
            const timestamp = rowData.delivered_date;
            return (<span className="delivered"  style={{ cursor: 'pointer' }} onClick={() => { handleOpenStatusModal(rowData); }} >Delivered</span>);
        } else if (rowData.sprint_status === "In Progress") {
            return (<span className="in-progress"  style={{ cursor: 'pointer' }} onClick={() => { handleOpenStatusModal(rowData) }}>In Progress</span>);
        }
        else if (rowData.sprint_status === "Cancelled") {
            return (<span className="cancelled"  style={{ cursor: 'pointer' }} onClick={() => { handleOpenStatusModal(rowData) }}>Cancelled</span>);
        } else {
            return (<span className="scheduled"  style={{ cursor: 'pointer' }} onClick={() => { handleOpenStatusModal(rowData) }}>Scheduled</span>);
        }
    }

    function detailCTA(rowData) {
        return (
            <div className="detail-button">
                <button className="cta-button" onClick={() => handleDetailClick(rowData)}>
                    Details
                </button>
            </div>
        );
    }

    const handleDetailClick = async (rowData) => {
        setSprintModalOpen(true);
        await dispatch(setSprintData(rowData));
        await dispatch(setSprintView(true))
    }

    // const TrackedHours = (rowData) => {
    //     console.log('track', rowData._id);
    // }

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }

    const [statusOpen , setStatusOpen] = useState(false);
    const [statusModalData, setStatusModalData] = useState(null);
    const handleOpenStatusModal = async (data) => {

        await dispatch(setSprintData(data));
        await dispatch(setSprintStatusView(true));
    //     setStatusOpen(!statusOpen);
    //    await setStatusModalData(data);
       
    };


    return (
        <>
            <div className="table-head-outer">
                <div className="table-head">
                    Sprints test133
                </div>
                {(props.isLoadingSprint || props.isLoadingProject) ? (
                    <div className="outter-load-table">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>

                ) : (
                    <NewSprintModal proId={props.project?._id} project={props.project} preferredCurrency={props.project?.client_name?.preferred_currency}/>
                )}
            </div>
            <div className="listing-recent-invoice-inner sprint-client-outer-section">
                {props.isLoadingSprint ? (
                    <div className="outter-load-table">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>

                ) : (
                    <DataTable
                        value={props.sprints}
                        removableSort
                        tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
                        paginator={false}
                        paginatorClassName="recent-invoices-pagination"
                        rows={5}
                        emptyMessage="No sprints found"
                    >
                        <Column
                            field="sprint_name"
                            header="Sprint"
                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="budget"
                            header={`Budget (${props.project?.client_name?.preferred_currency})`}
                            body={(rowData) => {
                                const { client_name, budget } = rowData;
                                if (props.project?.client_name?.preferred_currency) {
                                    return new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: settings.currency,
                                    }).format(ConvertHelper(props.project?.client_name?.preferred_currency, settings.currency, budget, forex));
                                } else {
                                    return new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: settings.currency,
                                    }).format(ConvertHelper("USD", settings.currency, budget, forex));
                                }
                            }}

                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="project.total_hours"
                            header="Tracked Hours"
                            body={TrackedSprintHours}
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="cost"
                            header="Cost"
                            body={SprintCost}
                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="project.created_at"
                            body={convertCreatedDate}
                            header="Date Created"
                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="delivered_date"
                            body={ConvertDeliveryDate}
                            header="Date Delivered"
                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="sprint_status"
                            header="Status"
                            body={ConvertStatus}
                            sortable
                            style={{ paddingRight: "20px" }}
                        ></Column>
                        <Column
                            field="_id"
                            body={detailCTA}
                            style={{ paddingRight: "20px" }}
                        ></Column>
                    </DataTable>
                )}
            </div>
            <SprintViewModal preferredCurrency={props.project?.client_name?.preferred_currency}/>
             <StatusModal data={statusModalData} statusOpen={statusOpen} />
        </>
    );
};
export default SprintTable;