import moment from "moment";
import css from "./RecentInvoices.css";
import React, { useEffect, useRef } from "react";
import { Column } from "primereact/column";
import "react-toastify/dist/ReactToastify.css";
import MemberName from "./elements/memberName";
import { DataTable } from "primereact/datatable";
import { ThreeDots } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { dateConvert } from "../../helpers/dateHelper";
import { renderCTA } from "../../helpers/invoiceHelpers";
import currencySymbol from "../../constants/currencies.js";
import payStatusConsts from "../../constants/invPaymetConsts";
import { useFetchRecentInvoices } from "./DashboardCurrencyApi";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import PaymentStatusWise from "../Invoices/elements/paymentStatusWise.jsx";
import { motion } from 'framer-motion';

const RecentInvoices = (props) => {

  const currencySum = props.sum;
  const dispatch = useDispatch();
  const dashboradDate = useSelector(
    (state) => state.invoice.dashboardDateRange
  );
  const dashboradCurrency = useSelector(
    (state) => state.invoice.dashboardCurrency
  )
  const user = useSelector(
    (state) => state.auth.user
  )
  const token = useSelector(
    (state) => state.auth.token
  )

  const { appSettings } = useSelector((state) => state.appSettings);
  const { data: products = [], isLoading, refetch: refetchRecentInvoice } = useFetchRecentInvoices(
    token,
    new Date(moment(dashboradDate[0]).tz(global.config.getTimeZone()).startOf('day')).toISOString(),
    new Date(moment(dashboradDate[1]).tz(global.config.getTimeZone()).endOf('day')).toISOString(),
    100,
    dashboradCurrency,
    currencySum
  );

  useEffect(() => {
    dispatch(setHeaderText("Dashboard"));
    refetchRecentInvoice();
  }, [dashboradDate, dashboradCurrency, currencySum, dispatch, token, user]);

  const renderPaymentStatus = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.inv_payment_status === payStatusConsts.Payment_Processing) {
      statusClass = "payment-processeing";
      statusName = "Payment Processing";
    } else if (rowData.inv_payment_status === payStatusConsts.Pending) {
      const startDate = moment().tz(global.config.getTimeZone());
      const endDate = moment(rowData.inv_due_date).tz(global.config.getTimeZone());
      const diffInDays = endDate.diff(startDate, 'days');
      if (diffInDays < 0) {
        statusClass = "overdue";
        statusName = "Overdue";
      } else {
        statusClass = "pending";
        statusName = "Pending";
      }
    } else if (rowData.inv_payment_status === payStatusConsts.Manually_Processed) {
      statusClass = "manual";
      statusName = "Manually processed";
    } else if (rowData.inv_payment_status === payStatusConsts.Sucessfully_Processed) {
      statusClass = "sucessfully-processed";
      statusName = "Payment Processed";
    } else if (rowData.inv_payment_status === payStatusConsts.Cancelled) {
      statusClass = "overdue";
      statusName = "Cancelled";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{global.config.locate(appSettings && appSettings.language, statusName)}</span>
    );
  };

  const ConvertCurrency = (code) => {
    const filteredCurrencies = Object.entries(currencySymbol).filter(([key, value]) => key === code);
    if (filteredCurrencies.length === 1) {
      var currency = filteredCurrencies[0][1];
      return currency;
    } else {
      return code;
    }
  }
  const renderAmount = (rowData) => {
    var totalAmount = rowData.inv_total_amount.toLocaleString('en-US', { style: 'currency', currency: rowData.inv_currency });
    return <span> {totalAmount}</span>;
  };
  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };
  return (
    <div className="invoice-outer">
      <div className="recent-title">{global.config.locate(appSettings && appSettings.language, 'Recent Invoices')}</div>
      <div className="card">
        {isLoading ? (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#405BFF"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        ) : (

          <motion.div
            ref={componentRef}
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.3 }}
          >

            <DataTable
              value={products.slice(0, 5)}
              removableSort
              tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
              paginator={false}
              paginatorClassName="recent-invoices-pagination"
              rows={5} // Set the number of rows per page
              emptyMessage="No invoices found"
            >
              <Column
                field="inv_number"
                header={global.config.locate(appSettings && appSettings.language, 'Invoice ID')}
                sortable
                style={{ minWidth: "200px", paddingRight: "44px" }}
              ></Column>
              <Column
                field="inv_due_date"
                body={dateConvert}
                header={global.config.locate(appSettings && appSettings.language, 'Due Date')}
                sortable
                style={{ minWidth: "230px", paddingRight: "44px" }}
              ></Column>
              <Column
                field="fullName"
                header={global.config.locate(appSettings && appSettings.language, 'User Name')}
                body={(rowData) => <MemberName userId={rowData.inv_for} />}
                // sortable
                sortField="fullName"
                style={{ minWidth: "240px", paddingRight: "44px" }}
              ></Column>
              <Column
                field="payment_status"
                header={global.config.locate(appSettings && appSettings.language, 'Payment Status')}
                body={(rowData) => (
                  <PaymentStatusWise
                    inv_payment_details={rowData.inv_payment_details}
                    invData={rowData}
                    invoiceId={rowData.inv_number}
                    createdAt={rowData.created_at}
                    paymentStatus={rowData.inv_payment_status}
                  />
                )}
                // sortable
                style={{ minWidth: "200px", paddingRight: "44px" }}
              ></Column>
              <Column
                field="inv_total_amount"
                header={global.config.locate(appSettings && appSettings.language, 'Amount')}
                body={renderAmount}
                sortable
                style={{ padding: "20px" }}
              ></Column>
              <Column
                field="_id"
                body={renderCTA}
                style={{}}
              ></Column>
            </DataTable>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default RecentInvoices;