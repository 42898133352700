import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    team: null,
    client: null,
    project: null,
    department: null,
    timerUpdate: false,
    tag: null,
    task: null,
    perPage: 10,
    date: null //new Date()
};

export const setTeam = createAsyncThunk("track/team", async (team) => {
    return team;
}
);

export const setClient = createAsyncThunk("track/client", async (client) => {
    return client;
}
);

export const setProject = createAsyncThunk("track/project", async (project) => {
    return project;
}
);

export const setDepartment = createAsyncThunk("track/department", async (department) => {
    return department;
}
);

export const setTag = createAsyncThunk("track/tag", async (tag) => {
    return tag;
}
);

export const setTask = createAsyncThunk("track/task", async (task) => {
    return task;
});

export const setDate = createAsyncThunk("track/date", async (date) => {

    if (date != null) {
        date.setHours(0, 0, 0, 0)
        return date.toISOString();
    } else {

        return null;
    }
}
);

export const setPerPage = createAsyncThunk("track/perPage", async (perPage) => {

    return perPage;
}
);

export const timerUpdate = createAsyncThunk("track/timerUpdate", async (timerUpdate) => {
    return timerUpdate;
}
);

export const trackSlice = createSlice({
    name: "tracker",
    initialState,
    reducers: {
        reset: (state) => {
            state.team = null;
            state.client = null;
            state.project = null;
            state.department = null;
            state.tag = null;
            state.task = null;
            state.timerUpdate = false;
            state.perPage = 10;
            state.date = new Date();
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setTeam.fulfilled, (state, action) => {
            state.team = action.payload;
        });
        builder.addCase(setClient.fulfilled, (state, action) => {
            state.client = action.payload;
        });
        builder.addCase(setProject.fulfilled, (state, action) => {
            state.project = action.payload;
        });
        builder.addCase(setDepartment.fulfilled, (state, action) => {
            state.department = action.payload;
        });
        builder.addCase(setTag.fulfilled, (state, action) => {
            state.tag = action.payload;
        });
        builder.addCase(setTask.fulfilled, (state, action) => {
            state.task = action.payload;
        });
        builder.addCase(setDate.fulfilled, (state, action) => {
            state.date = action.payload;
        });
        builder.addCase(setPerPage.fulfilled, (state, action) => {
            state.perPage = action.payload;
        });
        builder.addCase(timerUpdate.fulfilled, (state, action) => {
            state.timerUpdate = action.payload;
        });
    },
});

export const { reset } = trackSlice.actions;
export default trackSlice.reducer;