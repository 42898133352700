import axios from "axios";
import Modal from "react-modal";
import { AES } from 'crypto-js';
import Tooltip from "./Tooltip";
import { DatePicker } from "rsuite";
import moment from 'moment-timezone';
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import css from "./InvoiceCreateManual.css";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useRef } from "react";
import userCategory from "../../../constants/userCategory.js";
import userTypeConsts from "../../../constants/userTypeConsts";
import { logout, reset } from "../../../features/auth/authSlice";
import dletImage from "../../../images/singleinvoice/delete.svg";
import whatis from "../../../images/singleinvoice/whatisthis.svg";
import cancelImage from "../../../images/singleinvoice/cancel.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import addicoplus from "../../../images/singleinvoice/plus-add-icon.svg";
import CompanyDocumentField from "../../User/elements/CompanyDocumentField";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { getBankFromCompany } from "../InvoicesSingle/services/invoiceSingle.js";

const InvoiceCreateManual = () => {

  const [selectedValue, setSelectedValue] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [showBankIndex, setShowBankIndex] = useState(-1);
  const [selUser, setSelUser] = useState("");
  const [invType, setInvType] = useState("Regular");
  const [selUserCategory, setSelUserCategory] = useState("");
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [defaultbank, setDefaultBank] = useState(false);
  const [defaultbankAccount, setDefaultBankAccount] = useState(false);
  const [expenseCategory, setExpenseCategory] = useState([]);
  const recipient = { id: "recipient-id" };
  const [issueDate, setIssueDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0);
    return lastDayOfMonth;
  });
  const [title, setTitle] = useState("");
  const [subTotal, setSubTotal] = useState("00.00");
  const [taxTotal, setTaxTotal] = useState("00.00");
  const [total, setTotal] = useState("00.00");
  const [productDetails, setProductDetails] = useState([
    { description: "", projectId: "", cost: "", vat: "", price: "" },
  ]);
  const [invDocument, setInvDocument] = useState(null);
  const [invDocumentFileName, setInvDocumentFileName] = useState("");
  const [invFiles, setInvFiles] = useState([]);
  const [otherComnt, setOtherComnt] = useState("");
  const [selUserEmail, setSelUserEmail] = useState("");
  const [isBankLoading, setBankIsLoading] = useState(true);
  const [isFetched, setIsFetched] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [invNumber, setInvNumber] = useState("");
  const [invoiceBank, setInvoiceBank] = useState("");
  const [invoiceBankListName, setInvoiceBankListName] = useState("");
  const [invoiceBankCurrency, setInvoiceBankCurrency] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState([]);
  const [projectId, setProjectId] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [invTypeDropdownOpen, setInvTypeDropdownOpen] = useState(false);
  const [companyDropdownOpen, setCompanyDropdownOpen] = useState(false);
  const [invTypeWiseDropdownOpen, setInvTypeWiseDropdownOpen] = useState(false);
  const [searchBankAccountTerm, setSearchBankAccountTerm] = useState('');
  const [bankAccountDropdownOpen, setBankAccountDropdownOpen] = useState(false);
  const [showWxCompanyModal, setShowWxCompanyModal] = useState(false);
  const [userWiseProfile, setUserWiseProfile] = useState(null);
  const [dateRangeIsValid, setDateRangeIsValid] = useState(false);
  const dateRangeRef = useRef(null);
  const [issueDateIsValid, setIssueDateIsValid] = useState(false);
  const issueDateRef = useRef(null);
  const [dueDateIsValid, setDueDateIsValid] = useState(false);
  const dueDateRef = useRef(null);
  const [titleIsValid, setTitleIsValid] = useState(false);
  const titleRef = useRef(null);
  const [bankIsValid, setBankIsValid] = useState(false);
  const bankRef = useRef(null);
  const [descriptionIsValid, setDescriptionIsValid] = useState(false);
  const [expenseCategoryIsValid, setExpenseCategoryIsValid] = useState(false);
  const descriptionRef = useRef(null);
  const [costIsValid, setCostIsValid] = useState(false);
  const costRef = useRef(null);
  const [taxIsValid, setTaxIsValid] = useState(false);
  const taxRef = useRef(null);
  const [totalIsValid, setTotalIsValid] = useState(false);
  const [wxCompany, setWxCompany] = useState(null);
  const bankAccountDropdownRef = useRef(null);
  const invTypeDropdownRef = useRef(null);
  const companyeDropdownRef = useRef(null);
  const { appSettings } = useSelector((state) => state.appSettings);
  const [autoCurrency, setAutoCurrency] = useState([]);

  // const expCategoryDropdownRef = useRef(null);
  let token = null;
  let createdBy = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {

    if (user && user.token) {
      token = user.token;
      createdBy = user._id;
    }
  }

  const invoice = useSelector((state) => state.invoice);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleSelect = (ranges) => {
    setDateRange(ranges);
  };

  useEffect(() => {
    async function fetchInvoiceUserData() {
      try {
        var startDate = new Date(invoice.selectedUser && invoice.selectedUser[0][0]);
        var endDate = new Date(invoice.selectedUser && invoice.selectedUser[0][1]);
        var startDateFormatted = startDate.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        var [startMonth, startDay, startYear] = startDateFormatted.split("/");
        var formattedStartDateString = `${startDay}/${startMonth}/${startYear}`;
        var endDateFormatted = endDate.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        var [month, day, year] = endDateFormatted.split("/");
        const formattedEndDateString = `${day}/${month}/${year}`;
        var formattedDateRange = `${formattedStartDateString} - ${formattedEndDateString}`;
        setDateRange([startDate, endDate]);
        setSelUser(invoice.selectedUser && invoice.selectedUser[1]);
        setInvType(invoice.selectedUser && invoice.selectedUser[4]);
        setSelUserCategory(invoice.selectedUser && invoice.selectedUser[3]);
        setSelUserEmail(invoice.selectedUser && invoice.selectedUser[2]);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        alert("Something went wrong");
      }
    }
    fetchInvoiceUserData();
  }, [invoice]);
  const [wxCom, setWxCom] = useState(null);
  useEffect(() => {

    async function fetchUser() {
      try {
        const response = await axios.get(`/api/user2/${invoice.selectedUser && invoice.selectedUser[1]}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let userName = '';
        setWxCom(response.data.user_wx_comapny.wx_company);
        setAutoCurrency(response.data.payment_currency);
        if (response.data.first_name && response.data.first_name.toLowerCase() !== 'null') {
          userName = response.data.first_name;
          if (response.data.middle_name && response.data.middle_name.toLowerCase() !== 'null') {
            userName += ' ' + response.data.middle_name;
          }
          userName += ' ' + response.data.last_name;
        } else {
          userName = response.data.company_name;
        }
        setUserId(response.data);
        setWxCompany(response.data.user_wx_comapny.wx_company);
        setUserName(userName);
        setDefaultBankAccount(response.data.defaultBank);
        if (response.data.user_wx_comapny.wx_company) {

          let wxProfile = "";
          setBankIsLoading(true);

          if (response.data.user_wx_comapny.wx_company === "Any") {

            setBankDetails([]);

          } else {

            if (response.data.user_wx_comapny.wx_company === "WX") {
              wxProfile = 14760922;
            } else if (response.data.user_wx_comapny.wx_company === "Xpertos") {
              wxProfile = 10873693;
            }
            setWiseProfile(wxProfile);
            setBankIsLoading(true);
            const getAllBanks = await getBankFromCompany(wxProfile, response.data.email, token);
            setBankDetails(getAllBanks);


          }


          setBankIsLoading(false);
        }
      } catch (error) {
        global.config.slackMessage(error.toString());
        if (error.response && error.response.status === 403) {
          // Redirect to 404 page
          navigate("../../../404");
        } else if (error.response && error.response.status === 401) {
          // Redirect to login page
          navigate("/user/login");
        } else if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        } else {
          console.log(error);
          navigate("../../../404");
        }
      }
    }

    fetchUser();

    async function getUserWxCompany() {
      await axios
        .get(
          "/api/get-wx-comapies",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setUserWiseProfile(response.data.filter(
            (compny) =>
              compny.wx_company !== "Any"
          ));
          // if (response.data && response.data.wx_company === 1) {
          //   setWxCompany(response.data && response.data.wx_company);
          // }
        });
    }
    getUserWxCompany();

    async function fetchInvNumber() {
      try {
        const response = await axios.get("/api/invoice/invnumber", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setInvNumber(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong while fetching invoice number");
      }
    }
    fetchInvNumber();

    async function fetchProjectId() {
      try {
        const response = await axios.get("/api/all/project-details", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProjectId(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong while fetching project");
      }
    }
    fetchProjectId();

    async function fetchExpenseCategory() {
      try {
        const response = await axios.get("/api/expense-categories", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setExpenseCategory(response.data.data);

      } catch (error) {
        global.config.slackMessage(error.toString());
        console.log(error);
        alert("Something went wrong while fetching invoice number");
      }
    }

    fetchExpenseCategory();

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [invoice.selectedUser]);

  useEffect(() => {
    async function fetchBank() {
      try {
        let wxProfile = "";
        setBankIsLoading(true);


        if (wxCom && (wxCom === "Any")) {

          setBankDetails([]);

        } else {

          if (wxCom === "WX") {
            wxProfile = 14760922;
          } else if (wxCom === "Xpertos") {
            wxProfile = 10873693;
          }
          setWiseProfile(wxProfile);

          const getAllBanks = await getBankFromCompany(wxProfile, selUserEmail && selUserEmail, token);
          setBankDetails(getAllBanks);

        }


      } catch (error) {
        global.config.slackMessage(error.toString());
        if (error.response && error.response.status === 404) {
          setIsFetched(false);
        } else if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        } else {

        }
      }
    }

    setIsLoading(true);
    fetchBank();
    setIsLoading(false);
    setBankIsLoading(false);
  }, [selUser]);

  useEffect(() => {
    const targetObject = ((bankDetails && defaultbankAccount) && bankDetails.find(item => item.id === parseInt(defaultbankAccount)));
    setInvoiceBankListName((defaultbankAccount && bankDetails && targetObject) && `${defaultbankAccount}_${targetObject && targetObject.currency}`);
    setInvoiceBankCurrency((defaultbankAccount && bankDetails && targetObject) && targetObject.currency);
    setInvoiceBank((defaultbankAccount && bankDetails && targetObject) && defaultbankAccount);

  }, [bankDetails, defaultbankAccount]);

  function closeModal() {
    setIsOpen(false);
    navigate("/user/invoices");
  }

  function addBank() {
    if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
      const encryptedId = AES.encrypt(selUser, 'encryptionKey').toString();
      const encodedId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
      localStorage.setItem('activeTab', '1');
      navigate(`../profile/${encodedId}`);
    } else {
      localStorage.setItem('activeTab', '1');
      navigate(`../profile/settings`);
    }
  }

  function handleBankLinkClick(index) {
    setShowBankIndex((prevIndex) => (prevIndex === index ? -1 : index));
  }

  function handleRadioChange() {
    setDefaultBank(true);
  }

  const handleIssueDateChange = (date) => {

    if (!dueDate || new Date(date) <= new Date(dueDate)) {
      setIssueDate(date);
    } else {

      toast.error("Issue date must be before due date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const ranges = [
    {
      label: "today",
      value: new Date(), // Use a single date instead of an array
    },
    {
      label: "yesterday",
      value: subDays(new Date(), 1),
    },
  ];

  const handleDueDateChange = (date) => {
    if (!issueDate || new Date(date) >= new Date(issueDate)) {
      setDueDate(date);
    } else {
      toast.error("Due date must be after issue date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    const newProductDetails = [
      ...productDetails,
      { description: "", projectId: "", cost: "", vat: "", price: "" },
    ];
    setProductDetails(newProductDetails);
  };

  const handleRemoveRow = (indexToRemove, e) => {
    const newProductDetails = [...productDetails];
    newProductDetails.splice(indexToRemove, 1);
    setProductDetails(newProductDetails);
    const { subTotal, taxTotal, total } = calculateSubTotalAndTax(newProductDetails);
    setSubTotal(subTotal);
    setTaxTotal(taxTotal);
    setTotal(total);
  };

  const calculateSubTotalAndTax = (details) => {
    let subTotal = 0;
    let taxTotal = 0;
    let total = 0;
    details.forEach((detail) => {

      var cost = parseFloat(detail.cost) || 0;
      var tax = parseFloat(detail.vat) || 0;
      const amount = cost + cost * (tax / 100);
      subTotal += cost;
      taxTotal += amount - cost;
      total += amount;
    });
    return { subTotal: subTotal.toFixed(2), taxTotal: taxTotal.toFixed(2), total: total.toFixed(2) };
  };

  const handleInputChange = (event, index, field) => {

    const { name, value } = event.target;
    const newProductDetails = [...productDetails];
    newProductDetails[index][name] = value;
    if (field === "cost" || field === "vat") {
      const cost = parseFloat(newProductDetails[index].cost) || 0;
      const vat = parseFloat(newProductDetails[index].vat) || 0;
      if (!isNaN(cost) && !isNaN(vat)) {
        newProductDetails[index].amount = (cost + cost * (vat / 100)).toFixed(
          2
        );
      }
    }
    setProductDetails(newProductDetails);
    const tax = calculateTax();
    setTaxTotal(tax);
    const subtotal = calculateSubtotal();
    setSubTotal(subtotal);
    const total = calculateTotal();
    setTotal(total);

  };

  const handleProjIdInputChange = (event, index, field) => {
    const newProductDetails = [...productDetails];
    newProductDetails[index]["projectId"] = field;
    setProductDetails(newProductDetails);
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    productDetails.forEach((detail) => {
      const cost = parseFloat(detail.cost) || 0;
      subtotal += cost;
    });
    return subtotal.toFixed(2);
  };

  const calculateTax = () => {

    let tax = 0;
    productDetails.forEach((detail) => {
      const cost = parseFloat(detail.cost) || 0;
      const vat = parseFloat(detail.vat) || 0;
      const amount = cost + cost * (vat / 100);
      tax += amount - cost;
    });
    return tax.toFixed(2);

  };

  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const tax = parseFloat(calculateTax());
    return (subtotal + tax).toFixed(2);
  };

  const handleClosePicker = () => {
    setShowDateRangePicker(false);
  };

  const handleInvTypeToggleDropdown = () => {
    setInvTypeDropdownOpen(!invTypeDropdownOpen);
  };

  const handleInvTypeWiseToggleDropdown = () => {
    setInvTypeWiseDropdownOpen(!invTypeWiseDropdownOpen);
  };
  const handleCompanyToggleDropdown = () => {
    setCompanyDropdownOpen(!companyDropdownOpen);
  };

  const handleInvTypeSelectOption = (value) => {
    setInvType(value);
    setInvTypeDropdownOpen(false);
  };

  const handleToggleDropdown = () => {
    setSearchBankAccountTerm('');
    setBankAccountDropdownOpen(!bankAccountDropdownOpen);
  };

  const handleSelectOption = (invoiceBank) => {
    const bankSelect = invoiceBank.split("_");
    setInvoiceBankCurrency(bankSelect[1]);
    setInvoiceBank(bankSelect[0]);
    setInvoiceBankListName(invoiceBank);
    setBankAccountDropdownOpen(false);
  };

  const handleSearchInputChange = (e) => {
    setSearchBankAccountTerm(e.target.value);
  };

  var autoCurrencyFilteredBankDetails = autoCurrency && Object.values(bankDetails).filter(entry => entry.currency === autoCurrency);
  var filteredBankDetails = autoCurrencyFilteredBankDetails?.filter((bank) =>
    `${bank.details.accountNumber || ''} ${bank.details.IBAN || ''} ${bank.currency || ''} ${bank.country || ''}`
      .toLowerCase()
      .includes(searchBankAccountTerm.toLowerCase())
  );

  const openPaymentModal = (e) => {

    setIssueDateIsValid(false);
    setTitleIsValid(false);
    setBankIsValid(false);
    setDescriptionIsValid(false);
    setCostIsValid(false);
    setTaxIsValid(false);
    if (
      invNumber === "" ||
      dateRange === null ||
      issueDate === null ||
      dueDate === null ||
      title === "" ||
      selUserEmail === "" ||
      productDetails[0].description.length === 0 ||
      productDetails[0].cost.length === 0 ||
      productDetails[0].amount.length === 0 ||
      productDetails[0]?.vat?.length === 0 ||
      (invType === "Reimbursement" && productDetails[0]?.expenseCategory?.length === 0) ||
      selUser === "" ||
      invoiceBankCurrency === "" ||
      invType === "" ||
      subTotal === "00.00" ||
      subTotal <= 0 ||
      total === "00.00" ||
      total <= 0
    ) {
      if (dateRange === null) {
        setDateRangeIsValid(true);
        toast.error("Date range cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dateRangeRef.current.focus();
      } else if (issueDate === null) {
        setIssueDateIsValid(true);
        toast.error("Issue date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        issueDateRef.current.focus();
      } else if (dueDate === null) {
        setDueDateIsValid(true);
        toast.error("Due date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dueDateRef.current.focus();
      } else if (title === "") {
        setTitleIsValid(true);
        titleRef.current.focus();
      } else if (productDetails[0].description.length === 0) {
        setDescriptionIsValid(true);
        descriptionRef.current.focus();
      } else if (productDetails[0].cost.length === 0) {
        setCostIsValid(true);
        costRef.current.focus();
      } else if (productDetails[0]?.vat?.length === 0) {
        setTaxIsValid(true);
        taxRef.current.focus();
      } else if (subTotal === "00.00" || subTotal <= 0) {
        toast.error("Subtotal must be greater than 0!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (total === "00.00" || total <= 0) {
        toast.error("Total must be greater than 0!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      if (invType === "Reimbursement" && productDetails[0]?.expenseCategory?.length === 0) {
        setExpenseCategoryIsValid(true);
        expenseCategory.current.focus();
      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (
      productDetails.some(
        (product) =>
          product.description.length === 0 ||
          product.cost.length === 0 ||
          product.amount.length === 0 ||
          product?.vat?.length === 0
      )
    ) {
      // Handle empty product details
      if (productDetails[0].description.length === 0) {
        setDescriptionIsValid(true);
        descriptionRef.current.focus();
      } else if (productDetails[0].cost.length === 0) {
        setCostIsValid(true);
        costRef.current.focus();
      }

      toast.error("Please fill all required fields in details!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    handleSubmit(e);
  }


  function closeWXCompanyModal() {
    if (wxCompany && (wxCompany === "WX" || wxCompany === "Xpertos")) {
      setWxCompany("Any");
    }
    setShowWxCompanyModal(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIssueDateIsValid(false);
    setTitleIsValid(false);
    setBankIsValid(false);
    setDescriptionIsValid(false);
    setCostIsValid(false);
    setTaxIsValid(false);
    if (
      invNumber === "" ||
      dateRange === null ||
      issueDate === null ||
      dueDate === null ||
      title === "" ||
      selUserEmail === "" ||
      productDetails[0].description.length === 0 ||
      productDetails[0].cost.length === 0 ||
      productDetails[0].amount.length === 0 ||
      selUser === "" ||
      invoiceBankCurrency === "" ||
      invType === "" ||
      invoiceBank === "" ||
      subTotal === "00.00" ||
      subTotal <= 0 ||
      total === "00.00" ||
      total <= 0
    ) {
      if (dateRange === null) {
        setDateRangeIsValid(true);
        toast.error("Date range cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dateRangeRef.current.focus();
        return;
      } else if (issueDate === null) {
        setIssueDateIsValid(true);
        toast.error("Issue date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        issueDateRef.current.focus();
        return;
      } else if (dueDate === null) {
        setDueDateIsValid(true);
        toast.error("Due date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dueDateRef.current.focus();
        return;
      } else if (title === "") {
        setTitleIsValid(true);
        titleRef.current.focus();
        return;
      } else if (invoiceBank === "") {
        setBankIsValid(true);
        bankRef.current.focus();
        return;
      } else if (productDetails[0].description.length === 0) {
        setDescriptionIsValid(true);
        descriptionRef.current.focus();
        return;
      } else if (productDetails[0].cost.length === 0) {
        setCostIsValid(true);
        costRef.current.focus();
        return;
      } else if (subTotal === "00.00" || subTotal <= 0) {
        toast.error("Subtotal must be greater than 0!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (total === "00.00" || total <= 0) {
        toast.error("Total must be greater than 0!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      if (invType === "Reimbursement") {

        if (productDetails[0].expenseCategory.length === 0) {
          setExpenseCategoryIsValid(true);
          expenseCategory.current.focus();
          return;
        }

      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setIsLoading(true);
    const descriptions = productDetails.map((product) => product.description);
    const projectIds = productDetails.map((product) => product.projectId);
    var expenseCategory = [];
    if (invType === "Reimbursement") {
      expenseCategory = productDetails.map((product) => product.expenseCategory);
    }
    const prices = productDetails.map((product) => product.amount);
    const costs = productDetails.map((product) => product.cost);
    const taxes = productDetails.map((product) => product.vat);
    const invoiceData = {
      inv_number: invNumber,
      inv_strat_date: moment.utc(dateRange[0]).tz(global.config.getTimeZone()).format('YYYY-MM-DD'),
      inv_end_date: moment.utc(dateRange[1]).tz(global.config.getTimeZone()).format('YYYY-MM-DD'),
      inv_issue_date: moment.utc(issueDate).tz(global.config.getTimeZone()).format('YYYY-MM-DD'),
      inv_due_date: moment.utc(dueDate).tz(global.config.getTimeZone()).add(1, 'days').format('YYYY-MM-DD'),
      inv_title: title,
      inv_company_name: wxCompany,
      inv_email: selUserEmail,
      inv_description: JSON.stringify(descriptions),
      inv_projectId: JSON.stringify(projectIds),
      inv_category: JSON.stringify(expenseCategory),
      inv_price: JSON.stringify(prices),
      inv_cost: JSON.stringify(costs),
      inv_tax: JSON.stringify(taxes),
      inv_sub_total: subTotal,
      inv_for: selUser,
      inv_total_tax: taxTotal,
      inv_total_amount: total,
      inv_currency: invoiceBankCurrency,
      inv_type: invType,
      inv_bank: invoiceBank,
      inv_other_comments: otherComnt,
      inv_bank_profile: wiseProfile,
      is_automatic_inv: false,
      inv_created_by: createdBy,
    };

    // Create a new FormData object
    const formData = new FormData();
    for (let i = 0; i < invFiles.length; i++) {
      formData.append("inv_file", invFiles[i]);
    }
    // Append the other request data to the form data
    for (let key in invoiceData) {
      formData.append(key, invoiceData[key]);
    }

    if (invoiceBank) {
      try {
        const response = await axios.post("/api/invoice", formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        const data = response.data;

        if (data.status === "Error") {

          toast.error("Something went wrong in creating invoice!!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success("Invoice created successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "Invoice",
            `Invoice "${invNumber}" (manual) created Successfully`
          );
          navigate("/user/invoices");
        }
      } catch (error) {
        global.config.slackMessage(error.toString());
        if (error.response.data.status === "mailfailed") {
          toast.error("Invoice created successsfully! but something went wrong in sending mail!!", {
            position: "bottom-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          navigate("/user/invoices");
        } else if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        } else {
          console.log(error);
          alert("Something went wrong while creating invoice");
          global.config.activityLog(
            window.location.href,
            "Invoice",
            `Invoice "${invNumber}" creation having some error.`
          );
        }
      }
    } else {
      setShowWxCompanyModal(false);
      toast.error('Please select a bank account!', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsLoading(false);
  };

  const allowedExtensions = ["pdf", "doc", "docx", "msword", "xls", "xlsx", "csv", "jpeg", "jpg", "png", "gif"];
  const handleInvDocChange = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      if (selectedFiles.length + invFiles.length > 10) {
        // Display toast error message when the total number of files exceeds 10
        toast.error('Maximum of 10 files can be uploaded!', {
          position: 'bottom-right',
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const newFiles = Array.from(selectedFiles);

      // Validate file extensions and update state
      const validFiles = newFiles.filter((file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          return true;
        } else {
          toast.error(`Invalid file format for ${file.name}. Allowed formats are pdf, doc, docx, msword, xls, xlsx, csv.!!`, {
            position: 'bottom-right',
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      });

      // Update the state with the valid files
      setInvFiles((prevFiles) => [...prevFiles, ...validFiles]);

      // Clear the file input for multiple selections
      fileInputRef.current.value = '';
    }
  };

  const removeFile = (index) => {
    // Remove the file at the specified index
    setInvFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const handleDropdownChange = (event) => {
    selectBankAccount(event.target.value);
  };

  const selectBankAccount = (invoiceBank) => {
    const bankSelect = invoiceBank.split("_");
    setInvoiceBankCurrency(bankSelect[1]);
    setInvoiceBank(bankSelect[0]);
    setInvoiceBankListName(invoiceBank);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideInvTypeDropdown = invTypeDropdownRef.current && invTypeDropdownRef.current.contains(event.target);
    const isClickInsideBankAccountDropdown = bankAccountDropdownRef.current && bankAccountDropdownRef.current.contains(event.target);
    const isClickInsideCompanyDropdown = companyeDropdownRef.current && companyeDropdownRef.current.contains(event.target);
    if (!isClickInsideInvTypeDropdown) {
      setInvTypeDropdownOpen(false);
    }
    if (!isClickInsideBankAccountDropdown) {
      setBankAccountDropdownOpen(false);
    }
    if (!isClickInsideCompanyDropdown) {
      setCompanyDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleTooltipToggle = () => {
    setShowTooltip(!showTooltip);
  };
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  const [wiseCompany, setWiseCompany] = useState([]);
  const [wiseProfile, setWiseProfile] = useState(null);
  const [wiseProfileLabel, setWiseProfileLabel] = useState(null);
  useEffect(() => {

    async function getUserWiseProfile() {
      await axios
        .post(
          "/api/wise/user-wise-profile",
          {
            userId: `${invoice.selectedUser && invoice.selectedUser[1]}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setWiseCompany(response?.data);
        });
    }
    getUserWiseProfile();


  }, []);

  const handleCompanyselect = async (profile) => {
    setWiseProfile(profile);
    setBankDetails([]);
    // setInvTypeWiseDropdownOpen(false);
    setCompanyDropdownOpen(false);
    setBankIsLoading(true);
    const getAllBanks = await getBankFromCompany(profile, selUserEmail && selUserEmail, token);
    setBankDetails(getAllBanks);
    setBankIsLoading(false);

  }
  const encryptedId = AES.encrypt(invoice.selectedUser[1], 'encryptionKey').toString();
  const encodedId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');


  return (
    <div className="s1-containauto">
      <form>
        <div className="fs-head-autoinvo">
          <div className="bill-num-si">
            <Link className="invoice-num-01">{invNumber}</Link>
          </div>
          <div className="btn-aary">
            <Link to="/user/invoices" className="cancelbtn-link">
              <img src={cancelImage} alt="cancel" /> Cancel
            </Link>
          </div>
        </div>
        <div className="m-edit-main">
          <div className="edit-sheet-manual01">
            <div className="headder-outter-res-mainu">
              <div className="sheet-manual-header">
                <div className="type-dropdown-em">
                  <div className="dp-main-head-em">Type</div>
                  <div className="custom-dropdown">

                    <div ref={invTypeDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${invTypeDropdownOpen ? 'open' : ''}`}>
                      <div className="selected-option custom-dropdown-user-new" onClick={handleInvTypeToggleDropdown}>
                        {invType ? invType : 'Select Type'}
                      </div>
                      {invTypeDropdownOpen && (
                        <div className="custom-dropdown-new">
                          <ul className="type-list">
                            <li className={invType === 'Regular' ? 'selected-item' : ''} onClick={() => handleInvTypeSelectOption('Regular')}>Regular Invoice</li>
                            <li className={invType === 'Reimbursement' ? 'selected-item' : ''} onClick={() => handleInvTypeSelectOption('Reimbursement')}>Reimbursement Invoice</li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <span className="custom-arrow"></span>
                  </div>
                  <div className="tactic00i">
                    <img
                      src={whatis}
                      alt="tool-tip"
                      onMouseEnter={handleTooltipToggle}
                      onMouseLeave={handleTooltipToggle}
                    />
                  </div>
                </div>
                <div className="invo-date-rangeme">
                  <div className="invo-date-range-tile">Date Range</div>
                  <div className="invo-date-range-cal" ref={dateRangeRef}>
                    <div className="date-picker-outer date-picker-outer-all">
                      <div className="custom-picker-icon custom-picker-icon-all">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 14 14"
                          fill="currentColor"
                          aria-hidden="true"
                          focusable="false"
                          class="rs-picker-toggle-caret rs-icon"
                          aria-label="calendar"
                          data-category="legacy"
                        >
                          <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                        </svg>
                      </div>
                      <div className="custoom-icon-calender custoom-icon-calender-all">
                        <DateRangePicker
                          showOneCalendar
                          format="dd/MM/yyyy"
                          value={dateRange}
                          onChange={handleSelect}
                          isoWeek={appSettings.weekStartDay === 'Monday'}
                          timeZone={global.config.getTimeZone()}
                        />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="invo-date-issueme">
                  <div className="invo-date-issue-tile">Issue Date</div>
                  <div className="invo-date-due-cal">
                    <div className="calender-date-due-pic" ref={issueDateRef}>
                      <div className="date-picker-outer date-picker-outer-all">
                        <div className="custom-picker-icon custom-picker-icon-all">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 14 14"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class="rs-picker-toggle-caret rs-icon"
                            aria-label="calendar"
                            data-category="legacy"
                          >
                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                          </svg>
                        </div>
                        <div className="custoom-icon-calender custoom-icon-calender-all">
                          <DatePicker
                            oneTap
                            showOneCalendar
                            ranges={ranges}
                            format="dd/MM/yyyy"
                            value={issueDate}
                            onChange={handleIssueDateChange}
                            placement="auto"
                            required
                            isoWeek={appSettings.weekStartDay === 'Monday'}
                            timeZone={global.config.getTimeZone()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="date-due-manual-edm">
                  <div className="invo-date-issue-tile also-for-dued-cal">
                    Due Date
                  </div>
                  <div className="invo-date-due-cal">
                    <div className="calender-date-due-pic" ref={dueDateRef}>
                      <div className="date-picker-outer date-picker-outer-all">
                        <div className="custom-picker-icon custom-picker-icon-all">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 14 14"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class="rs-picker-toggle-caret rs-icon"
                            aria-label="calendar"
                            data-category="legacy"
                          >
                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                          </svg>
                        </div>
                        <div className="custoom-icon-calender custoom-icon-calender-all">
                          <DatePicker
                            oneTap
                            showOneCalendar
                            ranges={ranges}
                            format="dd/MM/yyyy"
                            value={dueDate}
                            onChange={handleDueDateChange}
                            placement="bottomEnd"
                            required
                            isoWeek={appSettings.weekStartDay === 'Monday'}
                            timeZone={global.config.getTimeZone()}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="sheed-sec-after-headem">
              <div className="tooltip-boxmain">
                {showTooltip && (
                  <Tooltip
                    className="custom-tooltip"
                    content={[
                      {
                        heading: "Regular Invoice",
                        content:
                          "Records the products or services delivered, the total amount due, and preferred payment method",
                        headingClass: "heading1tool",
                        contentClass: "content1tool",
                      },
                      {
                        heading: "Reinbursement Invoice",
                        content:
                          "Used when the subject is paid back for expenses.",
                        headingClass: "heading1tool second-toolhead",
                        contentClass: "content1tool",
                      },
                    ]}
                  />
                )}
              </div>
              <div className="inp-sec2-with2">
                <div className="input-field-inof">
                  <label htmlFor="invoice-title">User Name</label>
                  <input
                    type="text"
                    id="user-name"
                    placeholder="Enter User Name"
                    name="user-name"
                    value={userName}
                    className="read-only-input"
                    required
                    readOnly
                  />
                </div>
                <div className={`input-field-inof ${userId && userId?.user_wx_comapny?.wx_company === "Any" ? "inline-section" : ""}`}>
                  {userId && userId?.user_wx_comapny?.wx_company === "Any" && (
                    <div className="type-dropdown-em">
                      {/* <div className="dp-main-head-em">Company</div> */}
                      <label htmlFor="bank-account">Company</label>
                      <div className="custom-dropdown">
                        <div ref={companyeDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${companyDropdownOpen ? 'open' : ''}`}>
                          <div className="selected-option custom-dropdown-user-new" onClick={handleCompanyToggleDropdown}>
                            {wiseProfileLabel ? wiseProfileLabel : 'Select Company'}
                          </div>
                          {companyDropdownOpen && (
                            <div className="custom-dropdown-new">
                              <ul className="type-list">
                                {wiseCompany && wiseCompany?.id.map((item) => {
                                  return (<li className={invType === 'Regularw' ? 'selected-item' : ''} onClick={() => { handleCompanyselect(item.id); setWiseProfileLabel(item.fullName); }}>{item.fullName}</li>)

                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                        <span className="custom-arrow"></span>
                      </div>
                    </div>
                  )}

                  {isBankLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                      {bankDetails.length > 0 && wiseProfile ?

                        (
                          <div ref={bankAccountDropdownRef} className="custom-dropdown-user-new-outer">
                            <label htmlFor="bank-account">Bank Account </label>
                            <div className="selected-option custom-dropdown-user-new" onClick={handleToggleDropdown} ref={bankRef}>
                              {invoiceBankListName ? (
                                <>
                                  {`${bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.accountNumber || ''}`}
                                  {`${bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.accountNumber &&
                                    bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.IBAN
                                    ? ' '
                                    : ''
                                    }`}
                                  {`${bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.IBAN || ''} (${invoiceBankCurrency})`}
                                </>
                              ) : 'Select Bank'}
                            </div>
                            {bankAccountDropdownOpen && (
                              <div className="custom-dropdown-new">
                                {autoCurrencyFilteredBankDetails.length > 0 ? (
                                  <>
                                    <input
                                      type="text"
                                      className="search-bar"
                                      placeholder="Search..."
                                      value={searchBankAccountTerm}
                                      onChange={handleSearchInputChange}
                                    />
                                    <ul className="bank-account-list">
                                      {filteredBankDetails.length > 0 ? (
                                        filteredBankDetails.map((bank) => (
                                          <li
                                            key={`${bank.id}_${bank.currency}`}
                                            className={invoiceBankListName === `${bank.id}_${bank.currency}` ? 'selected-item' : ''}
                                            onClick={() => handleSelectOption(`${bank.id}_${bank.currency}`)}
                                          >
                                            {`${bank.details.accountNumber || ''}${bank.details.accountNumber &&
                                              bank.details.IBAN
                                              ? ' '
                                              : ''
                                              }${bank.details.IBAN || ''} (${bank.currency})`}
                                          </li>
                                        ))
                                      ) : (
                                        <li>No results found</li>
                                      )}
                                    </ul>
                                  </>
                                ) : (
                                  <div>No bank accounts available in the preferred currency</div>
                                )}
                              </div>
                            )}
                          </div>
                        )
                        :
                        (
                          <>
                            {(bankDetails.length === 0 && userName && wiseProfile) && (
                              <>


                                <div className="no-acccount-link">
                                  <label>Bank Account </label>
                                  No bank account is linked to this account. <Link onClick={addBank}>Click here to add one.</Link>
                                </div>


                                <Modal
                                  className="delete-modal"
                                  isOpen={modalIsOpen}
                                  onRequestClose={closeModal}
                                  contentLabel="Approve Modal"
                                  style={{
                                    content: {
                                      height: "41%",
                                      width: "30%",
                                      position: "fixed",
                                      top: "40%",
                                      left: "50%",
                                      transform: "translate(-17.5%, 0%)",
                                      parent: document.querySelector(".admin-outer.time.tracker"),
                                    },
                                  }}
                                >
                                  <div className="delete-modal-content">
                                    <div className="outter-modal-main">
                                      <a className="close-image-clr1" onClick={closeModal}>
                                        <img src={crssrImage} alt="Close Icon" />{" "}
                                      </a>
                                    </div>
                                    <h2>No Bank Registered</h2>
                                    <p>
                                      No Bank Registered in this Account
                                      Please add a bank account to proceed further
                                    </p>
                                    {isBankLoading ?
                                      (
                                        <div className="outter-load-table">
                                          <ThreeDots
                                            height="38"
                                            width="40"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                          />
                                        </div>
                                      )
                                      : (
                                        <div className="delete-modal-buttons">
                                          <button className="buttons-approved" onClick={addBank} disabled={isBankLoading}>
                                            Add Bank
                                          </button>

                                          <button onClick={closeModal}>
                                            Cancel
                                          </button>
                                        </div>
                                      )
                                    }
                                  </div>
                                </Modal>
                              </>
                            )}
                          </>

                        )}

                    </>)}
                  {bankIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Bank Details Cannot be empty
                    </span>
                  )}
                </div>
              </div>
              <div className="inp-sec2-with2 inp-sec2-with2-one">
                <div className="input-field-inof">
                  <label htmlFor="invoice-title">Invoice Title</label>
                  <input
                    type="text"
                    id="invoice-title"
                    placeholder="Enter invoice Title"
                    name="invoice-title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    required
                    className={titleIsValid ? "input-validation-error" : ""}
                    ref={titleRef}
                  />
                  {titleIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Title Cannot be empty
                    </span>
                  )}
                </div>
              </div>
              {invType === 'Regular' ? (
                <div className="detail-add-parteditmanual">
                  <table className="invoice-table-editmanual">
                    <thead className="the-abantant-edittxh">
                      <tr>
                        <th>Description</th>
                        <th>Cost</th>
                        <th>VAT %</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productDetails.map((product, index) => (
                        <tr key={index}>
                          <td className="description-manual">
                            <input
                              type="text"
                              list="projectOptions"
                              value={product.description}
                              name="description"
                              onChange={(e) => handleInputChange(e, index, "description")}
                              required
                              className={descriptionIsValid ? "input-validation-error" : ""}
                              placeholder="Description"
                              ref={descriptionRef}
                              autocomplete="off"
                            />
                            {selUserCategory !== userCategory.Vendor && (
                              <datalist id="projectOptions" style={{ position: 'absolute', top: '20%', left: 0 }}>
                                <option value="">Select Project</option>
                                {projectId
                                  .slice()
                                  .sort((a, b) => a.project_name.toLowerCase().localeCompare(b.project_name.toLowerCase()))
                                  .map((project) => (
                                    <option
                                      key={project._id}
                                      value={project.project_name}
                                    >
                                      {project.project_name}
                                    </option>
                                  ))}
                              </datalist>
                            )}
                          </td>
                          <td className="edit-manual-costtr">
                            <input
                              type="number"
                              step="any"
                              data-message="Please add Cost"
                              name="cost"
                              min="0"
                              data-no={index + 1}
                              required
                              placeholder="Cost"
                              onChange={(e) =>
                                handleInputChange(e, index, "cost")
                              }
                              className={costIsValid ? "input-validation-error" : ""}
                              value={product.cost}
                              ref={costRef}
                              onWheel={numberInputOnWheelPreventChange}
                            />
                          </td>
                          <td className="editmanual-vattd">
                            <input
                              type="number"
                              step="any"
                              data-message="Please add VAT"
                              name="vat"
                              min="0"
                              data-no={index + 1}
                              required
                              placeholder="VAT %"
                              onChange={(e) => handleInputChange(e, index, "vat")}
                              className={taxIsValid ? "input-validation-error" : ""}
                              value={product.vat}
                              ref={taxRef}
                              onWheel={numberInputOnWheelPreventChange}
                            />
                          </td>
                          <td className="editmanual-amount-tr">
                            <div className="amount-cell">
                              <button className="delete-icon">
                                {invoiceBankCurrency ? (
                                  <>
                                    {product.amount ? (
                                      <>
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: invoiceBankCurrency,
                                        }).format(product.amount)}
                                      </>
                                    ) : (
                                      <>
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: invoiceBankCurrency,
                                        }).format("00.00")}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {product.amount ? (
                                      <span>{product.amount}</span>
                                    ) : (
                                      <span>00.00</span>
                                    )}
                                  </>
                                )}
                                {productDetails.length !== 1 && (
                                  <button className="del-icon-main-sel"
                                    type="button"
                                    onClick={() => handleRemoveRow(index)}
                                  >
                                    <img src={dletImage} alt="delete-icon" />{" "}
                                  </button>
                                )}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button className="add-item-button" onClick={handleAddRow}>
                    <img src={addicoplus} alt="addicon" /> Add Item
                  </button>
                </div>)
                :
                (<div className="detail-add-parteditmanual">
                  <table className="invoice-table-editmanual">
                    <thead className="the-abantant-edittxh">
                      <tr>
                        <th>Descriptions</th>
                        <th>Category</th>
                        <th>VAT %</th>
                        <th>Cost</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productDetails.map((product, index) => (
                        <tr key={index}>
                          <td className="description-manual">
                            <input
                              type="text"
                              data-message="Please add Description"
                              name="description"
                              required
                              placeholder="Description"
                              onChange={(e) =>
                                handleInputChange(e, index, "description")
                              }
                              className={descriptionIsValid ? "input-validation-error" : ""}
                              value={product.description}
                              ref={descriptionRef}
                            />
                          </td>
                          <td className="description-manual">
                            <select
                              type="select"
                              value={product.expenseCategory}
                              name="expenseCategory"
                              onChange={(e) =>
                                handleInputChange(e, index, "expenseCategory")
                              }
                              required
                              className={expenseCategoryIsValid ? "input-validation-error" : ""}
                              placeholder="Expense Category"
                              ref={descriptionRef}
                            >
                              <option value="">Select Categoty</option>
                              {expenseCategory
                                ?.slice()
                                .sort((a, b) => a.exp_category_name.toLowerCase().localeCompare(b.exp_category_name.toLowerCase()))
                                .map((expense) => (
                                  <option
                                    key={expense._id}
                                    value={expense.exp_category_name}
                                  >
                                    {expense.exp_category_name}
                                  </option>
                                ))}
                            </select>

                          </td>
                          <td className="editmanual-vattd">
                            <input
                              type="number"
                              step="any"
                              data-message="Please add VAT"
                              name="vat"
                              min="0"
                              data-no={index + 1}
                              required
                              placeholder="VAT %"
                              onChange={(e) => handleInputChange(e, index, "vat")}
                              className={taxIsValid ? "input-validation-error" : ""}
                              value={product.vat}
                              ref={taxRef}
                              onWheel={numberInputOnWheelPreventChange}
                            />
                          </td>
                          <td className="edit-manual-costtr">
                            <input
                              type="number"
                              step="any"
                              data-message="Please add Cost"
                              name="cost"
                              min="0"
                              data-no={index + 1}
                              required
                              placeholder="Cost"
                              onChange={(e) =>
                                handleInputChange(e, index, "cost")
                              }
                              className={costIsValid ? "input-validation-error" : ""}
                              value={product.cost}
                              ref={costRef}
                              onWheel={numberInputOnWheelPreventChange}
                            />
                          </td>

                          <td className="editmanual-amount-tr">
                            <div className="amount-cell">
                              <button className="delete-icon">
                                {invoiceBankCurrency ? (
                                  <>
                                    {product.amount ? (
                                      <>
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: invoiceBankCurrency,
                                        }).format(product.amount)}
                                      </>
                                    ) : (
                                      <>
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: invoiceBankCurrency,
                                        }).format("00.00")}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {product.amount ? (
                                      <span>{product.amount}</span>
                                    ) : (
                                      <span>00.00</span>
                                    )}
                                  </>
                                )}
                                {productDetails.length !== 1 && (
                                  <button className="del-icon-main-sel"
                                    type="button"
                                    onClick={() => handleRemoveRow(index)}
                                  >
                                    <img src={dletImage} alt="delete-icon" />{" "}
                                  </button>
                                )}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button className="add-item-button" onClick={handleAddRow}>
                    <img src={addicoplus} alt="addicon" /> Add Item
                  </button>
                </div>
                )}
            </div>
            <div className="sec-after-tab-ls-part">
              <div className="sec-withother-and-add">
                <div className="other-secp1">
                  <div className="other-head1">Other comments</div>
                  <div className="other-subhead1">
                    <textarea
                      type="text"
                      className=""
                      placeholder="Other Comments"
                      value={otherComnt}
                      onChange={(e) => setOtherComnt(e.target.value)}
                      style={{ resize: "none" }}
                    ></textarea>
                  </div>
                </div>

                <div className="file-add-p1-attachment">
                  <div className="attachment-head">Attachments</div>
                  <div className="style-portion-browsetext" style={{ cursor: 'pointer' }}>
                    <label className="add-item-button-end-browse">
                      <img src={addicoplus} alt="addicon" /> Browse
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleInvDocChange}
                        multiple // Allow multiple file selection
                      />
                    </label>
                    <div className="upload-file-name-br">
                      {invFiles.map((file, index) => (
                        <div key={index} className="uploaded-file-item">
                          <span>{file.name}</span>
                          <a onClick={() => removeFile(index)}>Remove</a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="invo-total-specialcard">
                <div className="specialcard-outter">
                  <table className="to-ino-to-special">
                    <tr>
                      <th className="sub-lem-tb-fisl">Subtotal</th>
                      <td className="sub-price-tb-fisl">
                        {invoiceBankCurrency ? (
                          <>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoiceBankCurrency,
                            }).format(subTotal)}
                          </>
                        ) : (
                          <span>{subTotal}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="sub-vat-tb-fisl">VAT</th>
                      <td className="sub-vatpr-tb-fisl">
                        {invoiceBankCurrency ? (
                          <>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoiceBankCurrency,
                            }).format(taxTotal)}
                          </>
                        ) : (
                          <span>{taxTotal}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="sub-invoto-tb-fisl">Invoice Total</th>
                      <td className="sub-ivvoto-price-tb-fisl">
                        {invoiceBankCurrency ? (
                          <>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoiceBankCurrency,
                            }).format(total)}
                          </>
                        ) : (
                          <span>{total}</span>
                        )}
                      </td>
                    </tr>
                  </table>
                  {bankDetails.length > 0 && navigator.onLine &&
                    (<a
                      className="largecreate-invo-btn1"
                      onClick={openPaymentModal}
                    // disabled={isLoading}
                    >
                      {isLoading ? <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      /> : "Create Invoice"}
                    </a>)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Modal
            className="delete-modal"
            isOpen={showWxCompanyModal}
            onRequestClose={closeWXCompanyModal}
            contentLabel="Payment"
            style={{
              content: {
                height: "41%",
                width: "30%",
                position: "fixed",
                top: "40%",
                left: "50%",
                transform: "translate(-17.5%, 0%)",
                parent: document.querySelector(".admin-outer.time.tracker"),
              },
            }}
          >
            <div className="delete-modal-content">
              <div className="outter-modal-main">
                <a className="close-image-clr1" onClick={closeWXCompanyModal}>
                  <img src={crssrImage} alt="Close Icon" />{" "}
                </a>
              </div>
              <h2>Select Wise Account</h2>
              <p>Select Wise Account to Create this Invoice?</p>


              {/* {userWiseProfile && userWiseProfile.length === 2 && ( */}
              <div className="form-field-payment">
                <label htmlFor="client" className="form-label">
                  Select Wise Account
                </label>
                <select
                  required
                  onChange={(e) => setWxCompany(e.target.value)}
                  className="form-select"
                  value={wxCompany}
                >
                  <option value="">Select Wise Account </option>

                  {userWiseProfile?.length > 0 &&
                    userWiseProfile?.map((item) => {
                      return (
                        <option value={item.wx_company}>
                          {item.wx_company}
                        </option>
                      );
                    })}
                </select>
              </div>
              {/* )} */}
              <div className="delete-modal-buttons paynow-invoice-sec">

                {bankDetails.length > 0 && invoice.selectedUser && userWiseProfile && wxCompany && (wxCompany === "WX" || wxCompany === "Xpertos") ? (
                  <button
                    className="buttons-submit"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      "Create Invoice"
                    )}
                  </button>
                ) : (
                  <button
                    className="buttons-pay buttons-pay-disabled"
                    disabled
                  >
                    Create Invoice
                  </button>
                )}
                <button
                  className="buttons-pay-cancel"
                  onClick={closeWXCompanyModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </form>
    </div>
  );
};

export default InvoiceCreateManual;