import React from "react";
import TimeOffs from "../../components/TimeOff/TimeOffOverview";

function TimeOff()  {
    return(
        <div className="react-outer">
            <TimeOffs/>
        </div>
    )
}

export default TimeOff;