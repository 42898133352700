import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from "react-loader-spinner";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';
import { reportingFilterDateRangesConst } from '../../../../../constants/reportingFilterDateRangesConst';
import { reportingFilterComparisonConst } from '../../../../../constants/reportingFilterComparisonConst';

const useProcessedData = (
  trackReport,
  trackReportLoading,
  isError,
  reportingOverview,
  selectedDateRange,
  selectedComparison,
  selectedYearRange,
  selectedMonthRange,
  selectedComparisonYear,
  selectedComparisonMonth,
) => {
  return useMemo(() => {
    if (trackReportLoading || isError) return [];

    const getLastDayOfMonth = (year, month) => {
      return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
      switch (rangeId) {
        case reportingFilterDateRangesConst.This_Year:
          return [
            new Date(`${year}-01-01`),
            new Date(`${year}-12-31`)
          ];

        case reportingFilterDateRangesConst.This_Quarter: {
          const quarter = Math.floor((new Date().getMonth() + 3) / 3);
          const startMonth = (quarter - 1) * 3 + 1;
          const endMonth = startMonth + 2;
          const endDay = getLastDayOfMonth(year, endMonth);
          return [
            new Date(`${year}-${startMonth}-01`),
            new Date(`${year}-${endMonth}-${endDay}`)
          ];
        }

        case reportingFilterDateRangesConst.First_Half:
          return [
            new Date(`${year}-01-01`),
            new Date(`${year}-06-30`)
          ];

        case reportingFilterDateRangesConst.This_Month: {
          const month = new Date().getMonth() + 1;
          const endDay = getLastDayOfMonth(year, month);
          return [
            new Date(`${year}-${month}-01`),
            new Date(`${year}-${month}-${endDay}`)
          ];
        }

        case reportingFilterDateRangesConst.Year_Selected:
          if (selectedYearRange) {
            const selectedYear = selectedYearRange.getFullYear();
            return [
              new Date(`${selectedYear}-01-01`),
              new Date(`${selectedYear}-12-31`)
            ];
          } else {
            return [
              new Date(`${year}-01-01`),
              new Date(`${year}-12-31`)
            ];
          }

        case reportingFilterDateRangesConst.Month_Selected:
          if (selectedMonthRange) {
            const selectedMonth = selectedMonthRange.getMonth() + 1;
            const selectedYearForMonth = selectedMonthRange.getFullYear();
            const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
            return [
              new Date(`${selectedYearForMonth}-${selectedMonth}-01`),
              new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)
            ];
          } else {
            // Fallback to the current month if selectedMonthRange is not provided
            const currentMonth = new Date().getMonth() + 1;
            const endDay = getLastDayOfMonth(year, currentMonth);
            return [
              new Date(`${year}-${currentMonth}-01`),
              new Date(`${year}-${currentMonth}-${endDay}`)
            ];
          }

        default:
          return [
            new Date(`${year}-01-01`),
            new Date(`${year}-12-31`)
          ];
      }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
      switch (comparisonId) {
        case reportingFilterComparisonConst.Last_Year:
          return [
            new Date(`${year - 1}-01-01`),
            new Date(`${year - 1}-12-31`)
          ];

        case reportingFilterComparisonConst.Last_Quarter: {
          const quarter = Math.floor((new Date().getMonth() + 3) / 3);
          const lastQuarter = quarter === 1 ? 4 : quarter - 1;
          const startMonth = (lastQuarter - 1) * 3 + 1;
          const endMonth = startMonth + 2;
          const lastYear = quarter === 1 ? year - 1 : year;
          const endDay = getLastDayOfMonth(lastYear, endMonth);
          return [
            new Date(`${lastYear}-${startMonth}-01`),
            new Date(`${lastYear}-${endMonth}-${endDay}`)
          ];
        }

        case reportingFilterComparisonConst.Second_Half:
          return [
            new Date(`${year}-07-01`),
            new Date(`${year}-12-31`)
          ];

        case reportingFilterComparisonConst.Last_Month: {
          const lastMonth = new Date().getMonth(); // 0-indexed for previous month
          const lastYear = lastMonth === 0 ? year - 1 : year;
          const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
          const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
          return [
            new Date(`${lastYear}-${lastMonthFormatted}-01`),
            new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)
          ];
        }

        case reportingFilterComparisonConst.Year_Select:
          if (selectedComparisonYear) {
            const comparisonYear = selectedComparisonYear.getFullYear();
            return [
              new Date(`${comparisonYear}-01-01`),
              new Date(`${comparisonYear}-12-31`)
            ];
          } else {
            return [
              new Date(`${year - 1}-01-01`),
              new Date(`${year - 1}-12-31`)
            ];
          }

        case reportingFilterComparisonConst.Month_Select:
          if (selectedComparisonMonth) {
            const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
            const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
            const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
            return [
              new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`),
              new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)
            ];
          } else {
            const lastMonth = new Date().getMonth() || 12;
            const fallbackYear = lastMonth === 12 ? year - 1 : year;
            const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
            return [
              new Date(`${fallbackYear}-${lastMonth}-01`),
              new Date(`${fallbackYear}-${lastMonth}-${endDay}`)
            ];
          }

        default:
          return [
            new Date(`${year - 1}-01-01`),
            new Date(`${year - 1}-12-31`)
          ];
      }
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const calculateTotalDuration = (projects, startDate, endDate) => {
      const clientsData = projects.reduce((acc, project) => {
        const projectStart = new Date(project.startTime);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        if (
          projectStart >= startDate &&
          projectStart <= endDate &&
          !project?.project?.is_delete &&
          reportingOverview.project.includes(project?.project?._id) &&
          reportingOverview.currency.includes(project?.clientId?.preferred_currency || 'USD')
        ) {
          const clientName = project?.clientId?.client_name;
          if (!acc[clientName]) {
            acc[clientName] = { name: clientName, duration: 0 };
          }
          acc[clientName].duration += project.duration; // Assuming duration is already in hours
        }

        return acc;
      }, {});

      return Object.values(clientsData).map(client => ({
        name: client.name,
        duration: client.duration.toFixed(2),
      }));
    };

    const currentYearData = calculateTotalDuration(trackReport, startCurrentRange, endCurrentRange);
    const previousYearData = calculateTotalDuration(trackReport, startComparisonRange, endComparisonRange);

    // return currentYearData.map(data => ({
    //   name: data.name,
    //   currentYear: parseFloat(data.duration),
    //   lastYear: parseFloat(previousYearData.find(prevData => prevData.name === data.name)?.duration || 0),
    // }));
    const allClients = new Set([
      ...currentYearData.map(data => data.name),
      ...previousYearData.map(data => data.name)
    ]);

    return Array.from(allClients).map(clientName => {
      const currentData = currentYearData.find(data => data.name === clientName);
      const previousData = previousYearData.find(data => data.name === clientName);

      return {
        name: clientName,
        currentYear: currentData ? parseFloat(currentData.duration) : 0,
        lastYear: previousData ? parseFloat(previousData.duration) : 0,
      };
    });
  }, [trackReport, trackReportLoading, isError, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth,]);
};

const GraphData = ({
  trackReport,
  trackReportLoading,
  trackReportError,
  selectedDateRange,
  selectedComparison,
  selectedFrequency,
  selectedYearRange,
  selectedMonthRange,
  selectedComparisonYear,
  selectedComparisonMonth,
}) => {
  const reportingOverview = useSelector((state) => state.reportingOverview);
  const processedData = useProcessedData(
    trackReport,
    trackReportLoading,
    trackReportError,
    reportingOverview,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
  );

  const currentYear = reportingOverview.year || new Date().getFullYear();
  const previousYear = currentYear - 1;
  const isCurrentYear = currentYear === new Date().getFullYear();

  if (trackReportLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (trackReportError) return <div>Error loading data.</div>;

  const changeDurationFormat = (sec) => {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(...processedData.map(item => Math.max(item.currentYear, item.lastYear)));
    if (maxDataValue === 0) return [0];
    // const tickInterval = 10; // Set the interval between ticks
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  }

  const xAxisTicks = calculateXTicks();

  const getBarName = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
    const rangeNames = {
      [reportingFilterDateRangesConst.This_Year]: 'This Year',
      [reportingFilterDateRangesConst.This_Quarter]: 'This Quarter',
      [reportingFilterDateRangesConst.First_Half]: 'First Half',
      [reportingFilterDateRangesConst.This_Month]: 'This Month',
      [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
      [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
    };

    const comparisonNames = {
      [reportingFilterComparisonConst.Last_Year]: 'Last Year',
      [reportingFilterComparisonConst.Last_Quarter]: 'Last Quarter',
      [reportingFilterComparisonConst.Second_Half]: 'Second Half',
      [reportingFilterComparisonConst.Last_Month]: 'Last Month',
      [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
      [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
    };

    const rangeName = rangeNames[selectedRange] || 'Current Range';
    const comparisonName = comparisonNames[selectedComparison] || 'Comparison Range';

    return isCurrent
      ? `${rangeName}`
      : `${comparisonName}`;
  };

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: 542 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: 0, // Adjust the left margin to remove white space
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => changeDurationFormat(tick)}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            // width={150} // Ensure enough width for client names
            tickMargin={10}
          />
          <Tooltip formatter={(value) => changeDurationFormat(value)} />
          <Legend />
          <Bar dataKey="currentYear" name={getBarName(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} fill="#87ABE2">
            <LabelList dataKey="currentYear" position="right" formatter={(value) => value !== 0 ? changeDurationFormat(value) : null} />
          </Bar>
          <Bar dataKey="lastYear" name={getBarName(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} fill="#C7B6F6">
            <LabelList dataKey="lastYear" position="right" formatter={(value) => value !== 0 ? changeDurationFormat(value) : null} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default GraphData;