import React, { PureComponent, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer, LabelList } from 'recharts';
import typeOfContract from '../../../../constants/typeOfContract';
import { processedRevenueByService } from './Services/reportRevenue';

const data = [
  { name: 'United States', closedAmount: 44 },
  { name: 'Belgium', closedAmount: 55 },
  { name: 'Panama', closedAmount: 13 },
  { name: 'Germany', closedAmount: 43 },
  { name: 'Netherlands', closedAmount: 46 },
  { name: 'Mexico', closedAmount: 55 },
  { name: 'Country G', closedAmount: 32 },
];

const RevenuePerServiceGraph = ({ trackReport, trackReportLoading, trackReportError, sprints, projectCost }) => {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const setReportingCurrency = useSelector((state) => state.reportingRevenue.revenueCurrency);
  var currencies = "USD";
  if (setReportingCurrency && setReportingCurrency.length === 1) {
    currencies = setReportingCurrency[0];
  } else {
    currencies = settings.currency;
  }

  const processedData = useMemo(() => {
    return processedRevenueByService(
      trackReport,
      projectCost,
      sprints,
      reportingRevenue,
      currencies,
      forex,
      typeOfContract
    );
  }, [trackReport, projectCost, sprints, reportingRevenue, currencies, forex, typeOfContract]);

  if (trackReportLoading) return (
    <div className="outter-load-table">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#6479f9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );

  if (trackReportError) return <div>Error loading data.</div>;

  const calculateXTicks = () => {
    const dataValues = processedData.map(item => item.revenue);
    const maxDataValue = Math.max(...dataValues);
    const minDataValue = Math.min(...dataValues);

    // Determine if there are negative values
    const hasNegativeValues = minDataValue < 0;

    // Determine tick interval
    const tickInterval = Math.ceil(Math.max(Math.abs(maxDataValue), Math.abs(minDataValue)) / 10);
    const numOfTicks = Math.ceil(Math.max(Math.abs(maxDataValue), Math.abs(minDataValue)) / tickInterval);
    const ticks = [];

    if (hasNegativeValues) {
      // Add an extra step on the negative side for better visibility of bars
      const extraNegativeStep = tickInterval;
      for (let i = -numOfTicks - 1; i <= numOfTicks; i++) {
        ticks.push(i * tickInterval);
      }
      ticks.unshift(ticks[0] - extraNegativeStep);
    } else {
      // Start from 0 if there are no negative values
      for (let i = 0; i <= numOfTicks; i++) {
        ticks.push(i * tickInterval);
      }
    }

    return ticks;
  }

  const xAxisTicks = calculateXTicks();

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: 542 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13}
          margin={{
            top: 20,
            right: 50,
            left: -10,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies || 'USD' }).format(tick)}`}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            width={100} // Ensure enough width for client names
            tickMargin={10}
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies }).format(value)}`} />
          <ReferenceLine x={0} stroke="#7393B3" />
          <Bar dataKey="revenue" name="Revenue in default currency" fill="#87ABE2">
            <LabelList dataKey="revenue" position="right" fill="#282828" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies || 'USD' }).format(value)}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default RevenuePerServiceGraph;
