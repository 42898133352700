import React, { useEffect, useState } from "react";
import "./ReportExpense.css";
import ReportExpenseFilter from "./ReportExpense/ReportExpenseFilter";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import TotalExpenseReport from "../../Reporting/ReportingFinancial/ReportExpense/ReportTotalExpense";
import HighestExpenseReport from "../../Reporting/ReportingFinancial/ReportExpense/ReportHighestExpense";
import LowestExpenseReport from "../../Reporting/ReportingFinancial/ReportExpense/ReportingLowestExpense";
import OutstandingBalanceReport from "../../Reporting/ReportingFinancial/ReportExpense/ReportingOutstanding";
import ReportGrapExpensehHistoryFilter from "../../Reporting/ReportingFinancial/ReportExpense/ReportExpenseHistoryGraphFilter";
import ReportOverviewRoiOverviewPerClient from "../../Reporting/ReportingOverview/ReportingOverviewRoiOverviewPerClient";
import ReportExpenseHistory from "../../Reporting/ReportingFinancial/ReportExpense/ReportExpenseHistoryGraph";
import ReportTotalExpenseGraph from "../../Reporting/ReportingFinancial/ReportExpense/ReportTotalExpenseCompariosnGraph";
import ReportTotalExpenseGraphFilter from "../../Reporting/ReportingFinancial/ReportExpense/ReportTotalExpenseComparisonGraphFillter";
import ReportExpensePerLocationGraph from "../../Reporting/ReportingFinancial/ReportExpense/ReportExpensePerLocationGraph";
import ReportExpensePerLocationGraphFilter from "../../Reporting/ReportingFinancial/ReportExpense/ReportExpensePerLocationGraphFilter";
import ReportBudgetExpenseGraph from "../../Reporting/ReportingFinancial/ReportExpense/ReportBudgetVsExpenseGraph";
import ReportBudgetExpenseGraphFilter from "../../Reporting/ReportingFinancial/ReportExpense/ReportBudgetVsExpenseGraphFilter";
import ReportRecentExpense from "../../Reporting/ReportingFinancial/ReportExpense/ReportRecentExpenseTable";
import { useFetchProjectDetails } from "../../TimeTracker/TimeTrackerProject/useProjectDetails";
import { useGetClients } from "../../TimeTracker/TimeTrackerOverview/useTimeTracker";
import { useFetchTrackReportDetails } from "../../../features/reporting/reportingOverview/useReportingOverview";
import { Link } from "react-router-dom";
import { useFetchExpCategory } from "../../AppSettings/expenseCategoryApi";
import { useFetchExpenseReportDetails } from "../../../features/reporting/reportingFinancial/useReportingExpense";
import ReportExpensePerCategory from "./ReportExpense/ReportExpensePerCategory";
import ExpenseHistoryGraphFilter from "./ReportExpense/GraphFilters/ReportExpenseHistory/ReportExpenseHistoryFilter";
import TotalExpenseFilter from "./ReportExpense/GraphFilters/ReportTotalExpense/ReportTotalExpenseFilter";
import ExpensePerLocationGraphFilter from "./ReportExpense/GraphFilters/ReportExpensePerLocation/ReportExpensePerLocationFilter";
import BudgetExpenseGraphFilter from "./ReportExpense/GraphFilters/ReportBudgetVsExpense/ReportBudgetVsExpenseFilter";

function ExpenseReport() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isGraphLoaded, setIsGraphLoaded] = useState(false);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const {
    data: expenseData = [],
    isLoading: expenseDataLoading,
    isError: expenseDataError,
  } = useFetchExpenseReportDetails(token);
  const {
    data: expenseCategory = [],
    isLoading: isLoadingExpCategory,
    isError: expCategoryError,
  } = useFetchExpCategory(token);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsGraphLoaded(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="react-outer report-expense client-report-outer">
      <div className="invoice-outer-invoice invoice-outer-invoice-reprt">
        <ReportExpenseFilter
          expenseCategory={expenseCategory}
          expenseData={expenseData}
        />
      </div>
      <div className="repot-tatal-box-sec">
        <TotalExpenseReport expenseData={expenseData} />
        <HighestExpenseReport expenseData={expenseData} />
        <LowestExpenseReport expenseData={expenseData} />
        <OutstandingBalanceReport
          expenseCategory={expenseCategory}
          expenseData={expenseData}
        />
      </div>
      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report border-line-bottom">
          <div className="top-graph">
            <ExpenseHistoryGraphFilter
              expenseData={expenseData}
              expenseDataLoading={expenseDataLoading}
              expenseDataError={expenseDataError}
            />
          </div>
          {isGraphLoaded ? (
            <ReportExpenseHistory
              expenseData={expenseData}
              expenseDataLoading={expenseDataLoading}
              expenseDataError={expenseDataError}
            />
          ) : (
            <div className="outter-load-table">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#6479f9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </div>
        <div className="pie-chart-right">
          <ReportExpensePerCategory expenseData={expenseData} />
        </div>
      </div>
      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="top-graph">
            <TotalExpenseFilter
              expenseData={expenseData}
              expenseDataLoading={expenseDataLoading}
              expenseDataError={expenseDataError}
            />
          </div>
          <ReportTotalExpenseGraph
            expenseData={expenseData}
            expenseDataLoading={expenseDataLoading}
            expenseDataError={expenseDataError}
          />
        </div>
        <div className="pie-chart-right-no"></div>
      </div>
      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="top-graph">
            <ExpensePerLocationGraphFilter
              expenseData={expenseData}
              expenseDataLoading={expenseDataLoading}
              expenseDataError={expenseDataError}
            />
          </div>
          {isGraphLoaded ? (
            <ReportExpensePerLocationGraph
              expenseData={expenseData}
              expenseDataLoading={expenseDataLoading}
              expenseDataError={expenseDataError}
            />
          ) : (
            <div className="outter-load-table">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#6479f9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </div>
        <div className="pie-chart-right-no"></div>
      </div>

      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="top-graph">
            <BudgetExpenseGraphFilter
              expenseData={expenseData}
              expenseDataLoading={expenseDataLoading}
              expenseDataError={expenseDataError}
            />

          </div>
          <ReportBudgetExpenseGraph
            expenseData={expenseData}
            expenseDataLoading={expenseDataLoading}
            expenseDataError={expenseDataError}
          />
        </div>
        <div className="pie-chart-right-no"></div>
      </div>

      <div className="outer-report-graph-piechart client-report-inner">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="recent-inv-title">Recent Expenses</div>
          <div className="top-graph">
            <ReportRecentExpense
              expenseData={expenseData}
              expenseDataLoading={expenseDataLoading}
              expenseDataError={expenseDataError}
            />
          </div>
        </div>
      </div>
      <div className="button-sec-invoice">
        <Link to="/user/expense">View All</Link>
      </div>
    </div>
  );
}

export default ExpenseReport;
