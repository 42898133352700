import axios from "axios";
import "./UserAccount.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import NewBank from "../../Bank/newBank";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Close from "../../../../images/icons/close.svg";
import PlusIcon from "../../../../images/icons/plus-icon.svg";
import BankStatusIndicator from "./elements/BankStatusIndicator";
import BankComapny from "./elements/BankComapny.jsx";
import AccountDetail from "./UserAccountDetails/UserAccountDetails";
import { logout, reset } from "../../../../features/auth/authSlice";
import UpArrowImage from "../../../../images/icons/up-down-arrow.svg";
import DownArrowImage from "../../../../images/icons/up-down-arrow.svg";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { setAccountDetails } from "../../../../features/accountDetails/accountDetailsSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";

const UserAccount = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accountDetails, setAccoutDeatils] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isBankLoading, setBankIsLoading] = useState(true);
  const [isFetched, setIsFetched] = useState(true);
  const [userView, setUserView] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [selBank, setSelBank] = useState(null);
  const [newBank, setNewBank] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const accountDetailsRedux = useSelector((state) => state.accountDetails.accountDetails);
  const bankFetchUpdate = useSelector((state) => state.bankFetchUpdate.bankFetchUpdate);
  const showingDropdownRef = useRef(null);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  const notifi = useSelector((state) => state.notification.notificationState);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  useEffect(() => {

    async function fetchUser() {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/user/view/${props.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserView(response.data);
        setSelBank(response.data.defaultBank);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong in fetching users");
      }
    }

    fetchUser().then(() => { });
  }, [props.id,notifi]);

  function removeNonLetterCharacters(str) {
    if (!str) return "";
    return str.replace(/[^a-zA-Z]/g, '');
  }

  const fullName =
    (removeNonLetterCharacters(userView.first_name) || removeNonLetterCharacters(userView.company_name) || "").replace(/\s+/g, '') +
    " " +
    (userView.last_name ? removeNonLetterCharacters(userView.last_name) : "Company");


  useEffect(() => {

    async function fetchBank() {
      try {
        setBankIsLoading(true);
        const response = await axios.get(
          `/api/wise/user/banks/${userView.email}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        );
        if (response.status === 200) {

          
          setBankDetails(response.data);
          setIsFetched(true);
        } else if (response.status === 204) {
          setIsFetched(false);
        }
        setBankIsLoading(false);
        global.config.activityLog(window.location.href, 'bank', `Entered into "${userView.email}" banks page`);
      } catch (error) {
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        } else if (error.response && error.response.status === 404) {
          setIsFetched(false);
        } else {
          // Handle other errors
        }
      }
    }

    if (!isLoading) {
      fetchBank();
    }
  }, [isLoading, isFetched, userView.email, props.id, bankFetchUpdate, dispatch]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value)
    setSelectedPage(0);
  };

  const filteredData = bankDetails.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    if (!isLoading && isFetched) {
      const accountNumber = item.details?.accountNumber?.toLowerCase();
      const hasMatch =
        item.accountHolderName.toLowerCase().includes(searchTerm) ||
        item.currency.toLowerCase().includes(searchTerm) ||
        item.country.toLowerCase().includes(searchTerm) ||
        (accountNumber && accountNumber.includes(searchTerm));
      return hasMatch;
    }
  });

  const handleSetDefaultBank = (id) => {
    const postData = {
      userId: userView._id,
      bankId: id,
    };
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios.post('/api/user/bank/set-default', postData, axiosConfig)
      .then((res) => {
        setSelBank(id);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        global.config.slackMessage(err.toString());
        if (err.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          navigate("/");
          return;
        }
      });
  };

  const handleUnSetDefaultBank = (id) => {
    const postData = {
      userId: userView._id,
      bankId: id,
    };
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios.post('/api/user/bank/unset-default', postData, axiosConfig)
      .then((res) => {
        setSelBank(null);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        global.config.slackMessage(err.toString());
        if (err.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          navigate("/");
          return;
        }
      });
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideshowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
    if (!isClickInsideshowingDropdown) {
      setShowingDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const bankDeactivate = async (profileId) => {

    try {

      await axios.get(`/api/bank/profile/deactivate/${profileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setBankDetails((prevState) =>
        prevState.map((item) =>
          item.id === profileId ? { ...item, active: false } : item
        )
      );

      toast.success(`Bank profile ${profileId} deactivated successfully`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      toast.error(`Something went wrong while deactivation of bank profile ${profileId}`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setBankDetails(sortedData);
    setSortConfig({ key, direction });
  };

  const handleAccountDetailsClick = (accountId, index) => {
    setSelectedAccountId(accountId);
    setSelectedIndex(index);
    dispatch(setAccountDetails());
    setAccoutDeatils(true);
  };

  const handleCancelAccountDetails = () => {
    setAccoutDeatils(false);
  };

  const openCreateModal = () => {
    setNewBank(true);
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handlePageChange = (selected) => {
    setSelectedPage(selected.selected);
  };

  const totalResults = filteredData.length;

  const pageCount = Math.ceil(filteredData.length / perPage); // Total number of pages

  const offset = perPage * selectedPage; // Offset of the current page
  const currentPageData = filteredData.slice(offset, offset + perPage);

  return (
    <>
      {(accountDetails && accountDetailsRedux) ? (
        <>
          <AccountDetail accountId={selectedAccountId} index={selectedIndex} onCancel={handleCancelAccountDetails} />
        </>
      ) : (
        <>
          <div className="outer-account">
            <div className="account-top">
              <div className="button-sec-account">
                <a onClick={openCreateModal}>
                  <span className="icon-invoice">
                    <img src={PlusIcon} alt="Export" />
                  </span>
                  New Bank Account
                </a>
              </div>
              <div className="search-sec-account">
                <div className="search-container">
                  <input
                    type="text"
                    className="search-input"
                    onChange={handleFilterChange}
                    value={filter} placeholder="Search" />
                </div>
              </div>
            </div>
            <Modal
              className="userAccount-modal bank-account-mod"
              isOpen={isCreateModalOpen}
              onRequestClose={closeCreateModal}
              contentLabel="New Client Account"
              style={{
                content: {
                  height: "auto",
                  width: "70%",
                  position: "fixed",
                  top: "50%",
                  left: "60%",
                  transform: "translate(-50%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={closeCreateModal}>
                    <img src={Close} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="modal-client-head">
                  <h2 className="">New Bank Account</h2>
                </div>
                <div className="form-field-client-outer">
                  <NewBank userEmail={userView.email} userName={fullName} userView={userView && userView} closeCreateModal={closeCreateModal} />
                </div>
              </div>
            </Modal>
            <div className="showing-count">
              <div className="filter-inner showing-result">
                <label>Showing</label>

                <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                  <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
                    {totalResults < 10 ? totalResults.toString() : perPage.toString()}
                  </div>
                  {showingDropdownOpen && (
                    <div className="custom-dropdown-new">
                      <ul className="showing-options-list">
                        {totalResults >= 10 && <li className={perPage === 10 ? 'selected-item' : ''} onClick={() => { setPerPage(10); setSelectedPage(0); setShowingDropdownOpen(false); }}>10</li>}
                        {totalResults >= 20 && <li className={perPage === 20 ? 'selected-item' : ''} onClick={() => { setPerPage(20); setSelectedPage(0); setShowingDropdownOpen(false); }}>20</li>}
                        {totalResults >= 30 && <li className={perPage === 30 ? 'selected-item' : ''} onClick={() => { setPerPage(30); setSelectedPage(0); setShowingDropdownOpen(false); }}>30</li>}
                        {totalResults >= 40 && <li className={perPage === 40 ? 'selected-item' : ''} onClick={() => { setPerPage(40); setSelectedPage(0); setShowingDropdownOpen(false); }}>40</li>}
                        {totalResults >= 10 && <li className={totalResults >= 10 && perPage === totalResults ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setSelectedPage(0); setShowingDropdownOpen(false); }}>ALL</li>}
                        {/* Add more conditions as needed */}
                        {totalResults < 10 ? (
                          <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setSelectedPage(0); setShowingDropdownOpen(false); }}>{totalResults}</li>
                        ) : null}
                      </ul>
                    </div>
                  )}
                </div>
                <label>of {totalResults} Results</label>
              </div>
            </div>
            <div className="account-table">
              <table>
                <thead>
                  <tr>
                    <th onClick={() => sortTable("accountHolderName")}>
                      Account Holder Name{" "}
                      {sortConfig.key === "accountHolderName" && sortConfig.direction === "asc" ? (
                        <img src={UpArrowImage} alt="Up Arrow" />
                      ) : (
                        <img src={DownArrowImage} alt="Down Arrow" />
                      )}
                    </th>
                    <th onClick={() => sortTable("currency")}>
                      Currency{" "}
                      {sortConfig.key === "currency" &&
                        sortConfig.direction === "asc" ? (
                        <img src={UpArrowImage} alt="Up Arrow" />
                      ) : (
                        <img src={DownArrowImage} alt="Down Arrow" />
                      )}
                    </th>
                    <th onClick={() => sortTable("country")}>
                      Country{" "}
                      {sortConfig.key === "country" &&
                        sortConfig.direction === "asc" ? (
                        <img src={UpArrowImage} alt="Up Arrow" />
                      ) : (
                        <img src={DownArrowImage} alt="Down Arrow" />
                      )}
                    </th>
                    <th onClick={() => sortTable("company")}>
                      Company{" "}
                      {sortConfig.key === "country" &&
                        sortConfig.direction === "asc" ? (
                        <img src={UpArrowImage} alt="Up Arrow" />
                      ) : (
                        <img src={DownArrowImage} alt="Down Arrow" />
                      )}
                    </th>
                    <th>
                      Account IBAN Number
                    </th>
                    <th onClick={() => sortTable("active")}>
                      Status{" "}
                      {sortConfig.key === "active" &&
                        sortConfig.direction === "asc" ? (
                        <img src={UpArrowImage} alt="Up Arrow" />
                      ) : (
                        <img src={DownArrowImage} alt="Down Arrow" />
                      )}
                    </th>
                    <th>
                      Default Bank{" "}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {isBankLoading ? (
                    <tr>
                      <td colSpan="7">
                        <div className="outter-load-table">
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {currentPageData.length > 0 ? (
                        <>
                          {currentPageData.map((item, index) => (
                            <tr key={item.id}>
                              <td>
                                <div className="member-name">
                                  <span>{item.accountHolderName}</span>
                                </div>
                              </td>
                             
                              <td>{item.currency}</td>
                              <td>{item.country}</td>
                              <td>  <BankComapny profileId={item.business} /> </td>
                              <td>
                              
                                {item.details.accountNumber ? item.details.accountNumber : (item.details.iban || item.details.clabe)}
                              </td>
                              <td>
                                <BankStatusIndicator profileId={item.id} />
                              </td>
                              <td>
                                {Object.keys(userView).length > 0 && (
                                  <>
                                    {selBank ? (
                                      <>
                                        {selBank === item.id ? (
                                          <>
                                            <label className="custom-switch">
                                              <input
                                                type="checkbox"
                                                className="custom-switch-input"
                                                id="emailnotification"
                                                name="enable_emailnotification"
                                                value="1"
                                                checked={parseInt(selBank) === parseInt(item.id)}
                                                onChange={() => handleUnSetDefaultBank(item.id)}
                                              />
                                              <span className="custom-switch-slider" style={{ cursor: "pointer" }}></span>
                                            </label>
                                          </>
                                        )
                                          :
                                          (
                                            <>
                                              <label className="custom-switch">
                                                <input
                                                  type="checkbox"
                                                  className="custom-switch-input"
                                                  id="emailnotification"
                                                  name="enable_emailnotification"
                                                  value="1"
                                                  checked={parseInt(selBank) === parseInt(item.id)}
                                                  onChange={() => handleSetDefaultBank(item.id)}
                                                />
                                                <span className="custom-switch-slider" style={{ cursor: "pointer" }}></span>
                                              </label>
                                            </>
                                          )}
                                      </>
                                    )
                                      :
                                      (
                                        <>
                                          <label className="custom-switch">
                                            <input
                                              type="checkbox"
                                              className="custom-switch-input"
                                              id="emailnotification"
                                              name="enable_emailnotification"
                                              value="1"
                                              checked={parseInt(selBank) === parseInt(item.id)}
                                              onChange={() => handleSetDefaultBank(item.id)}
                                            />
                                            <span className="custom-switch-slider" style={{ cursor: "pointer" }}></span>
                                          </label>
                                        </>
                                      )
                                    }
                                  </>
                                )}
                              </td>
                              <td>
                                <a target="_blank" rel="noopener noreferrer" onClick={() => handleAccountDetailsClick(item.id, index + 1)}>
                                  Details
                                </a>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan="7">
                            {!isFetched ? (
                              <div className="outter-load-table">No Bank Registered in this Account</div>
                            ) : (
                              <>
                                {!isBankLoading && <div className="outter-load-table">No results found</div>}
                              </>
                            )}
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pagination-container">
              {isBankLoading ? (
                <>
                </>
              ) : (
                <>
                  {pageCount > 1 && (
                    <ReactPaginate
                      previousLabel={selectedPage > 0 ? "< Back" : null}
                      nextLabel={selectedPage < pageCount - 1 ? "Next >" : null}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination maininteampag"}
                      activeClassName={"active"}
                      forcePage={selectedPage}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserAccount;