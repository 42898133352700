import React, { PureComponent, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, Legend, ResponsiveContainer, LabelList } from 'recharts';
import typeOfContract from '../../../../constants/typeOfContract';
import { processedInvoicedOutstandingByClient } from './Services/reportRevenue';

const data = [
  { name: 'Rent', invoiced: 144, outstanding: 124 },
  { name: 'Utilities', invoiced: 155, outstanding: 139 },
  { name: 'Groceries', invoiced: 113, outstanding: 130 },
  { name: 'Transport', invoiced: 143, outstanding: 150 },
  { name: 'Entertainment', invoiced: 146, outstanding: 127 },
  { name: 'Healthcare', invoiced: 155, outstanding: 140 },
  { name: 'Miscellaneous', invoiced: 132, outstanding: 118 },
];

const InvoicedOutstanding = ({ clientInvoices, clientInvoicesLoading, clientInvoicesError }) => {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const setReportingCurrency = useSelector((state) => state.reportingRevenue.revenueCurrency);
  var currencies = "USD";
  if (setReportingCurrency && setReportingCurrency.length === 1) {
    currencies = setReportingCurrency[0];
  } else {
    currencies = settings.currency;
  }

  const processedData = useMemo(() => {
    return processedInvoicedOutstandingByClient(
      clientInvoices,
      reportingRevenue,
      currencies,
      forex,
    );
  }, [clientInvoices, reportingRevenue, currencies, forex]);

  if (clientInvoicesLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (clientInvoicesError) return <div>Error loading data.</div>;

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(...processedData.map(item => Math.max(item.invoiced, item.outstanding)));
    if (maxDataValue === 0) return [0];
    // const tickInterval = 10; // Set the interval between ticks
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  }

  const xAxisTicks = calculateXTicks();

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: 542 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: 0, // Adjust the left margin to remove white space
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies, }).format(tick)}`}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            width={80} // Ensure enough width for expense categories
            tickMargin={10}
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies || 'USD' }).format(value)}`} />
          <Legend />
          <ReferenceLine x={0} stroke="#D3D3D3" />
          <Bar dataKey="invoiced" name="Invoiced" fill="#87ABE2">
            <LabelList dataKey="invoiced" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies, }).format(value)}`} />
          </Bar>
          <Bar dataKey="outstanding" name="Outstanding Balance" fill="#C7B6F6">
            <LabelList dataKey="outstanding" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies, }).format(value)}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default InvoicedOutstanding;
