import React from "react";
import { useSelector } from 'react-redux';
import Dollar from "../../../../images/icons/dollar-kit.svg";

// Functional component
function TotalApprovedRequestsTimeOff({ timeoffReq }) {
  const reportingTimeoffRequest = useSelector((state) => state.reportingTimeoffRequest);

  const filteredTimeoffReqs = timeoffReq.filter(tor => {
    const isUserDetailMatch = !reportingTimeoffRequest.timeoffReqUser || reportingTimeoffRequest.timeoffReqUser.includes(tor.user._id);
    const isStatusDetailMatch = !reportingTimeoffRequest.timeoffReqReqStatus || reportingTimeoffRequest.timeoffReqReqStatus.includes(tor.status);
    const isNotDeleted = !tor.is_delete;
    const isYearMatch = !reportingTimeoffRequest.timeoffReqYear ||
      new Date(tor?.end_date).getFullYear() === reportingTimeoffRequest.timeoffReqYear;
    const isCategoryMatch = reportingTimeoffRequest.timeoffReqCategory && reportingTimeoffRequest.timeoffReqCategory.includes(tor.type_of_leave._id)
    return isUserDetailMatch && isStatusDetailMatch && isNotDeleted && isYearMatch && isCategoryMatch;
  });

  const approvedRequestCount = filteredTimeoffReqs.filter(tor => tor.status === 'Approved').length;
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">Total Approved</div>
          <div className="overview-icon">
            <img src={Dollar} alt="cLock" />
          </div>
        </div>

        <div className="overview-rice">
          {approvedRequestCount}
        </div>
      </div>
    </div>
  );
}

export default TotalApprovedRequestsTimeOff;
