import React, { useState, useEffect } from 'react';
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from 'react-redux';
// import { useTotalBudgetByClient, useTotalCostByClient, useTotalCostByProject, useTrackedClientHours, useTrackedProjectHours } from "../../../features/projects/ProjectList";
import { convertCurrencyHelper } from '../../../../../helpers/forexConvertor';
import { reportingFilterDateRangesConst } from '../../../../../constants/reportingFilterDateRangesConst';
import { reportingFilterComparisonConst } from '../../../../../constants/reportingFilterComparisonConst';

export function requestHistoryComparison(timeoffReq, reportingTimeoffRequest) {
    const currentYear = reportingTimeoffRequest.timeoffReqYear || new Date().getFullYear();
    const previousYear = currentYear - 1;

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const initialData = months.map(month => ({
        name: month,
        currentYear: 0,
        lastYear: 0,
    }));

    timeoffReq.forEach(tor => {
        const isUserDetailMatch = !reportingTimeoffRequest.timeoffReqUser || reportingTimeoffRequest.timeoffReqUser.includes(tor.user._id);
        const isStatusDetailMatch = !reportingTimeoffRequest.timeoffReqReqStatus || reportingTimeoffRequest.timeoffReqReqStatus.includes(tor.status);
        const isNotDeleted = !tor.is_delete;
        const isCategoryMatch = reportingTimeoffRequest.timeoffReqCategory && reportingTimeoffRequest.timeoffReqCategory.includes(tor.type_of_leave._id);

        if (isUserDetailMatch && isStatusDetailMatch && isNotDeleted && isCategoryMatch) {
            const endDate = new Date(tor.end_date);
            const monthIndex = endDate.getMonth();
            const year = endDate.getFullYear();

            if (year === currentYear) {
                initialData[monthIndex].currentYear += 1;
            } else if (year === previousYear) {
                initialData[monthIndex].lastYear += 1;
            }
        }
    });
    return initialData;
}

export function requestHistoryComparisonFilter(
    timeoffReq,
    reportingTimeoffRequest,
    selectedDateRange,
    selectedComparison,
    selectedFrequency,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
) {
    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [new Date(`${year}-${startMonth}-01`), new Date(`${year}-${endMonth}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [new Date(`${year}-01-01`), new Date(`${year}-06-30`)];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [new Date(`${year}-${month}-01`), new Date(`${year}-${month}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [new Date(`${selectedYear}-01-01`), new Date(`${selectedYear}-12-31`)];
                } else {
                    return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
                }
            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [new Date(`${selectedYearForMonth}-${selectedMonth}-01`), new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)];
                } else {
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [new Date(`${year}-${currentMonth}-01`), new Date(`${year}-${currentMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [new Date(`${lastYear}-${startMonth}-01`), new Date(`${lastYear}-${endMonth}-${endDay}`)];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [new Date(`${year}-07-01`), new Date(`${year}-12-31`)];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth();
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [new Date(`${lastYear}-${lastMonthFormatted}-01`), new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)];
            }
            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [new Date(`${comparisonYear}-01-01`), new Date(`${comparisonYear}-12-31`)];
                } else {
                    return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`), new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)];
                } else {
                    const lastMonth = new Date().getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [new Date(`${fallbackYear}-${lastMonth}-01`), new Date(`${fallbackYear}-${lastMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
        }
    };
    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const calculateRequestsData = (timeoffReq, startRange, endRange, frequency) => {
        const data = {};
        startRange.setHours(0, 0, 0, 0);
        endRange.setHours(23, 59, 59, 999);

        timeoffReq.forEach(tor => {
            const endDate = new Date(tor.end_date);
            if (endDate >= startRange && endDate <= endRange && !tor.is_delete) {
                const key = frequency === 'Monthly' ? endDate.toLocaleString('default', { month: 'long' }) :
                    frequency === 'Daily' ? endDate.toLocaleDateString() : '';

                if (!data[key]) {
                    data[key] = { name: key, totalRequests: 0 };
                }
                data[key].totalRequests += 1;
            }
        });

        return Object.values(data);
    };

    const currentPeriodData = calculateRequestsData(timeoffReq, startCurrentRange, endCurrentRange, selectedFrequency === '1' ? 'Monthly' : 'Daily',);
    const comparisonPeriodData = calculateRequestsData(timeoffReq, startComparisonRange, endComparisonRange, selectedFrequency === '1' ? 'Monthly' : 'Daily',);

    const allKeys = new Set([...currentPeriodData.map(item => item.name), ...comparisonPeriodData.map(item => item.name)]);

    let mergedData = Array.from(allKeys).map(name => ({
        name: name,
        currentYear: parseFloat(currentPeriodData.find(item => item.name === name)?.totalRequests || 0),
        lastYear: parseFloat(comparisonPeriodData.find(item => item.name === name)?.totalRequests || 0),
    }));

    if (selectedFrequency === '1') {
        const monthOrder = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        mergedData = mergedData.sort((a, b) => monthOrder.indexOf(a.name) - monthOrder.indexOf(b.name));
    } else {
        const parseDate = (dateString) => {
            const [day, month, year] = dateString.split('/').map(Number);
            return new Date(year, month - 1, day); // JavaScript months are 0-based
        };
        mergedData = mergedData.sort((a, b) => {
            const dateA = parseDate(a.name);
            const dateB = parseDate(b.name);
            return dateA - dateB;
        });
    }

    return mergedData;
}