import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Dollar from "../../../../images/icons/dollar-kit.svg";
import { calculateTotalReimbursement } from './Services/reportReimbursement';

// Functional component
function TotalReimburesentCard({ reimbursementInv }) {
  const [totalReimbursement, setTotalReimbursement] = useState(null);
  const reportingReimbursment = useSelector((state) => state.reportingReimbursment);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  useEffect(() => {
    const totalReimbursementAmount = calculateTotalReimbursement(reimbursementInv, reportingReimbursment, settings.currency, forex);
    setTotalReimbursement(totalReimbursementAmount);
  }, [reimbursementInv, reportingReimbursment, settings.currency, forex]);
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">Total Reimbursements</div>
          <div className="overview-icon">
            <img src={Dollar} alt="cLock" />
          </div>
        </div>

        <div className="overview-rice">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: settings.currency,
          }).format(totalReimbursement)}
        </div>
      </div>
    </div>
  );
}

export default TotalReimburesentCard;
