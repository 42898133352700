import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from "react-loader-spinner";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, Legend, LabelList } from 'recharts';
import { reportingFilterComparisonConst } from '../../../../../../constants/reportingFilterComparisonConst';
import { reportingFilterDateRangesConst } from '../../../../../../constants/reportingFilterDateRangesConst';
import typeOfContract from '../../../../../../constants/typeOfContract';
import { processedRevenueByClientFilter } from '../../Services/reportRevenue';

const RevenuePerClientFiterGraph = ({
  clientInvoices,
  clientInvoicesLoading,
  clientInvoicesError,
  trackReport,
  trackReportLoading,
  trackReportError,
  projectCost,
  expenses,
  selectedDateRange,
  selectedComparison,
  selectedFrequency,
  selectedYearRange,
  selectedMonthRange,
  selectedComparisonYear,
  selectedComparisonMonth,
}) => {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const processedData = useMemo(() => {
    return processedRevenueByClientFilter(
      trackReport,
      projectCost,
      clientInvoices,
      expenses,
      reportingRevenue,
      settings.currency,
      forex,
      typeOfContract,
      selectedDateRange,
      selectedComparison,
      selectedYearRange,
      selectedMonthRange,
      selectedComparisonYear,
      selectedComparisonMonth,
    );
  }, [
    trackReport,
    projectCost,
    clientInvoices,
    reportingRevenue,
    settings.currency,
    forex,
    typeOfContract,
    expenses,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
  ]);

  if (clientInvoicesLoading || trackReportLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (clientInvoicesError || trackReportError) return <div>Error loading data.</div>;

  const calculateXTicks = () => {
    if (!processedData.length) return [];

    // Calculate max positive and min negative values
    const maxPositiveValue = Math.max(0, ...processedData.map(item => Math.max(item.currentYear, item.lastYear)));
    const maxNegativeValue = Math.min(0, ...processedData.map(item => Math.min(item.currentYear, item.lastYear)));

    // Determine the tick interval based on the largest absolute value
    const tickInterval = Math.ceil(Math.max(Math.abs(maxPositiveValue), Math.abs(maxNegativeValue)) / 10);

    // Calculate the starting and ending tick values
    // const startTick = Math.floor(maxNegativeValue / tickInterval) * tickInterval - tickInterval;
    const startTick = Math.floor(maxNegativeValue / tickInterval) * tickInterval - 2 * tickInterval;
    const endTick = Math.ceil(maxPositiveValue / tickInterval) * tickInterval + tickInterval;

    // Generate ticks
    const ticks = [];
    for (let i = startTick; i <= endTick; i += tickInterval) {
      ticks.push(i);
    }

    return ticks;
  };

  // Calculate the ticks based on processed data
  const xAxisTicks = calculateXTicks();

  const getBarName = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
    const rangeNames = {
      [reportingFilterDateRangesConst.This_Year]: 'This Year',
      [reportingFilterDateRangesConst.This_Quarter]: 'This Quarter',
      [reportingFilterDateRangesConst.First_Half]: 'First Half',
      [reportingFilterDateRangesConst.This_Month]: 'This Month',
      [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
      [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
    };

    const comparisonNames = {
      [reportingFilterComparisonConst.Last_Year]: 'Last Year',
      [reportingFilterComparisonConst.Last_Quarter]: 'Last Quarter',
      [reportingFilterComparisonConst.Second_Half]: 'Second Half',
      [reportingFilterComparisonConst.Last_Month]: 'Last Month',
      [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
      [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
    };

    const rangeName = rangeNames[selectedRange] || 'Current Range';
    const comparisonName = comparisonNames[selectedComparison] || 'Comparison Range';

    return isCurrent
      ? `${rangeName}`
      : `${comparisonName}`;
  };

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: 542 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13}
          margin={{
            top: 20,
            right: 42,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[Math.min(...xAxisTicks), Math.max(...xAxisTicks)]}
            tickFormatter={(tick) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(tick)}`}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            // width={150} // Ensure enough width for expense categories
            tickMargin={10}
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(value)}`} />
          <ReferenceLine x={0} stroke="#7393B3" />
          <Bar dataKey="currentYear" name={getBarName(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} fill="#87ABE2">
            <LabelList dataKey="currentYear" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(value)}`} />
          </Bar>
          <Bar dataKey="lastYear" name={getBarName(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} fill="#C7B6F6">
            <LabelList dataKey="lastYear" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(value)}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default RevenuePerClientFiterGraph;