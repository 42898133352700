import axios from "axios";
import { AES, enc } from 'crypto-js';
import "./ClientInviteRegister.css";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import 'react-phone-number-input/style.css';
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import PhoneInput from 'react-phone-number-input';
import Tele from "../../../images/icons/tele.svg";
import { RotatingLines } from 'react-loader-spinner';
import Countries from "../../../constants/countries";
import CompanyUrl from "../../../images/icons/webpage.svg"
import EmailIcon from "../../../images/icons/email.svg";
import Tick from "../../../images/icons/tick-arrow.svg";
import location from "../../../images/icons/location.svg";
import React, { useEffect, useRef, useState } from "react";
import PostalCode from "../../../images/icons/postal-code.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { logout, reset } from "../../../features/auth/authSlice";
import profile_image from "../../../images/user/Default_pfp.png";
import PicEdit from "../../../images/icons/profile-pic-edit.svg";
import UserIcon from "../../../images/icons/single-user-icon.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";

export default function ClientInviteRegisteration() {
  const dispatch = useDispatch();
  const { cliInvEmail, encodedAppId } = useParams();
  const fileInputRef = useRef(null);
  const [clientName, setClientName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [fileSelectIsLoading, setFileSelectIsLoading] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState('');
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const countryDropdownRef = useRef(null);
  const decodedCliInvEmail = decodeURIComponent(
    atob(cliInvEmail.replace(/\-/g, "+").replace(/_/g, "/"))
  );
  const decodedAppId = decodeURIComponent(encodedAppId.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedAppId, 'encryptionKey');
  const appId = decryptedBytes.toString(enc.Utf8);
  const navigate = useNavigate();
  useEffect(() => {

    const verifyResetPassLink = async () => {
      const { cliInvEmail } = { cliInvEmail: decodedCliInvEmail };
      try {
        const response = await axios.post("/api/client/check/client-invite/validity", { cliInvEmail });
        if (response.data.status === "emailAlreadyRegistered") {
          // Reset link expired, redirect to forget-password page with alert message
          toast.error("This email is already registered.!!!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
        }
        if (response.data.status === "linkExpired") {
          // Reset link expired, redirect to forget-password page with alert message
          toast.error("Sorry!.Invite link expired", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        global.config.slackMessage(error.toString());
        toast.error("Something went wrong while verifying the reset link");
      }
    };
    verifyResetPassLink();

    const sortedCountries = Countries.slice().sort((a, b) => a.country.localeCompare(b.country));
    setCountriesList(sortedCountries);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitLoading(true);
    setIsValidUrl(false);
    const mobileNoRegex = /^[+\d]+$/;
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!mobileNoRegex.test(mobileNo)) {
      toast.error("It's not a valid phone number", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsSubmitLoading(false);
      return;
    }
    if (!pattern.test(companyWebsite)) {
      toast.error("It's not a valid URL.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsValidUrl(true);
      setIsSubmitLoading(false);
      return;
    }
    const requestData = {
      client_name: clientName,
      email: decodedCliInvEmail,
      company_website: companyWebsite,
      mobile_no: mobileNo,
      address,
      country,
      acc_manager: null,
      contact_point: null,
      city,
      pincode,
      appId,
    };

    if (!requestData.appId) {
      toast.error("Something went wrong with the appId", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsSubmitLoading(false);
      return;
    }

    // Create a new FormData object
    const formData = new FormData();
    // Append the profile image to the form data
    formData.append("company_logo", companyLogo);

    // Append the other request data to the form data
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }

    try {
      const response = await axios.post("/api/client/invite/registration", formData);
      const data = response.data;
      if (data.status === "exists") {
        toast.warning("Client already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Your client account is registered successfully. Please check your email", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate(`/`);
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error(`Error ${error.response?.data.message}`, {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleProfilePicChange = () => {
    setFileSelectIsLoading(true);
    // Open file input dialog
    fileInputRef.current.click();
  };

  const handleFileInputCancel = () => {
    setFileSelectIsLoading(false);
  };

  useEffect(() => {
    const inputElement = fileInputRef.current;

    const handleChange = () => {
      handleFileInputChange({ target: { files: inputElement.files } });
    };

    const handleCancel = () => {
      handleFileInputCancel();
    };

    inputElement.addEventListener('change', handleChange);
    inputElement.addEventListener('cancel', handleCancel);

    return () => {
      inputElement.removeEventListener('change', handleChange);
      inputElement.removeEventListener('cancel', handleCancel);
    };
  }, []);

  const handleFileInputChange = (event) => {
    setFileSelectIsLoading(false);
    const file = event.target.files[0];
    setCompanyLogo(event.target.files[0])
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setUploadedImageUrl(fileReader.result);
      };
      // if (removeProfileImage) {
      // setRemoveProfileImage(false);
      // }
      fileReader.readAsDataURL(file);
    }
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country.toLowerCase().includes(countrySearchTerm.toLowerCase());
  });

  const selectedCountry = countriesList.find((c) => c.country === country);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCountryDropdown = countryDropdownRef.current && countryDropdownRef.current.contains(event.target);
    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const countryData = countriesList.find((c) => c.country === country);
  return (
    <div className="inviter-register-outer">
      <div className="container">
        <div>
          <div className='tab-outer'>
            <ul className="tabs">
              <li className='active-tab'>
                Registration
              </li>
            </ul>
            <div className="tabs-content">
              <div className="profile-details-edit" >
                <form onSubmit={handleSubmit}>
                  <div className="profile-photo-outer">
                    <div className="profile-photo">
                      <div className="profile-photo-inner">
                        <div className="profile-photo-sec">




                          {uploadedImageUrl ? (
                            <div className="profile-image">
                              <img src={uploadedImageUrl} alt="Uploaded Profile" />
                            </div>
                          ) : (
                            <div className="profile-image">
                              <img src={profile_image} alt="User Profile" />
                            </div>
                          )}

                          <div className="profile-pic-edit">
                            <a href="#" className="pic-edit" onClick={!fileSelectIsLoading && handleProfilePicChange}>
                              {fileSelectIsLoading ? (
                                <RotatingLines
                                  strokeColor="grey"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="18"
                                  visible={true}
                                />
                              ) : (
                                <img src={PicEdit} alt="edit" />
                              )}
                            </a>
                          </div>
                        </div>
                        <div className="profile-name">
                          <div className="profile-name-sec">
                            {clientName ? clientName : "Client Name"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <div className="information-outer-section">

                    <div className="information-inner">
                      <div className="information">
                        <div className="information-title">
                          Company Information
                        </div>
                        <div className="information-outer">
                          <div className="information-left">

                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="Cname">Commercial Name *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input
                                    type="text"
                                    placeholder="Enter Commercial name"
                                    value={clientName}
                                    onChange={(e) => setClientName(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="information-right">
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={EmailIcon} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="email">Email</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input
                                    type="email"
                                    placeholder="Email"
                                    value={decodedCliInvEmail}
                                    readOnly
                                    required
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={CompanyUrl} alt="company-url" /></span>
                                <div className="inner-detail-inner">
                                  <label for="password">Company Url *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner pass-eye">
                                  <input
                                    type="text"
                                    placeholder="Company Url"
                                    value={companyWebsite}
                                    onChange={(e) => setCompanyWebsite(e.target.value)}
                                    required
                                  />
                                  {isValidUrl && (
                                    <span className="text-red-500 text-xs italic">
                                      Please enter a valid URL!!
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="information-inner">
                      <div className="information">
                        <div className="information-title">
                          Address
                        </div>
                        <div className="information-outer">
                          <div className="information-left">
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span className="icon">
                                  <img src={location} alt="Location Icon" />
                                </span>
                                <div className="inner-detail-inner">
                                  <label>Country *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span className="icon"></span>
                                <div className="inner-detail-inner">

                                  <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${countryDropdownOpen ? 'open' : ''}`} ref={countryDropdownRef}>
                                    <div className="selected-option custom-dropdown-user-new" onClick={() => setCountryDropdownOpen(!countryDropdownOpen)} style={{ cursor: 'pointer' }}>
                                      {country ? countriesList.find((c) => c.country === country)?.country : 'Select Country'}
                                    </div>
                                    {countryDropdownOpen && (
                                      <div className="custom-dropdown-new">
                                        <input
                                          type="text"
                                          className="search-bar"
                                          placeholder="Search..."
                                          value={countrySearchTerm}
                                          onChange={handleCountrySearchInputChange}
                                        />
                                        <ul className="country-list">
                                          {filteredCountries.length > 0 &&
                                            filteredCountries.map((c) => (
                                              <li
                                                key={c.country}
                                                className={country === c.country ? 'selected-item' : ''}
                                                onClick={() => {
                                                  setCountry(c.country);
                                                  setCountryDropdownOpen(false);
                                                }}
                                              >
                                                {c.country}
                                              </li>
                                            ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span className="icon">
                                  <img src={location} alt="Location Icon" />
                                </span>
                                <div className="inner-detail-inner">
                                  <label>City *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span className="icon"></span>
                                <div className="inner-detail-inner">
                                  <input type="text" id="city" value={city} onChange={(e) => setCity(e.target.value)} required />
                                </div>
                              </div>
                            </div>

                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={location} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="address">Address *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input
                                    type="text"
                                    placeholder="Address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="information-right">
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={PostalCode} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="postal_code">Postal Code </label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input
                                    type="text"
                                    placeholder="Post Code"
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={Tele} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="number">Mobile No. *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <PhoneInput
                                    placeholder="Enter phone number"
                                    value={mobileNo}
                                    onChange={setMobileNo}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="save-button-sec">
                    {isSubmitLoading ? (
                      <span className="cancel-btn disabled">Cancel</span>
                    ) : (
                      <Link to="/" className="cancel-btn">Cancel</Link>
                    )}
                    <button type="submit" className="save-btn" disabled={isSubmitLoading}>
                      {isSubmitLoading ?
                        <div className="outter-load-table">
                          <ThreeDots
                            height="38"
                            width="40"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div> :
                        <>
                          <img src={Tick} alt="arrow" />Save changes
                        </>
                      }
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div >
        </div >
      </div>
    </div>
  );
}