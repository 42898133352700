import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
const API_BASE_URL = '/api';

export const useFetchExpCategory = (token) => {
    return useQuery('expCategories', async () => {
        const response = await axios.get(`${API_BASE_URL}/expense-categories`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    });
};

// Delete a ExpenseCategory
export const useDeleteExpCategory = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (selectedId) =>
            axios.get(`/api/expense-category/delete/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('expCategories');
            },
        }
    );
};

// Create new ExpenseCategory
export const useCreateExpCategory = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (requestData) =>
            axios.post(`${API_BASE_URL}/expense-category/create`, requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('expCategories');
            },
        }
    );
};

// update ExpCategory
export const useUpdateExpCategory = (token) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ selectedId, exp_category_name, exp_cat_budget, exp_cat_currency }) =>
            axios.put(`${API_BASE_URL}/expense-category/update/${selectedId}`, { exp_category_name, exp_cat_budget, exp_cat_currency }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('expCategories');
            },
        }
    );
};