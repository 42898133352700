import axios from "axios";
import Modal from "react-modal";
import { AES, enc } from 'crypto-js';
import Tooltip from "./Tooltip";
import { DatePicker } from "rsuite";
import moment from 'moment-timezone';
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import css from "./VendorInvoiceCreate.css";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useRef } from "react";
import userCategory from "../../../../constants/userCategory.js";
import { logout, reset } from "../../../../features/auth/authSlice";
import dletImage from "../../../../images/singleinvoice/delete.svg";
import cancelImage from "../../../../images/singleinvoice/cancel.svg";
import addicoplus from "../../../../images/singleinvoice/plus-add-icon.svg";
import crssrImage from "../../../../images/singleinvoice/cross-red.svg";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { useUserById } from "../../../TimeOff/timeOffApi.jsx";
import { useFetchVendorProjectDetails, useVendorUser, useWxCompany } from "../useVendorInvoice.jsx";
import { useUserByVendor, useVendorView } from "../../../../pages/Vendors/VendorDetailsLayout/useVendorDetails.jsx";
import userTypeConsts from "../../../../constants/userTypeConsts.js";
import { getBankFromCompany } from "../../../Invoices/InvoicesSingle/services/invoiceSingle.js";

const VendorInvoiceCreate = () => {
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
  const id = decryptedBytes.toString(enc.Utf8);
  const [showTooltip, setShowTooltip] = useState(false);
  const [selWxCompany, setSelWxCompany] = useState("6422bb4cc0ca63c8330e5bd5");
  const [selWxCompanyDropdownOpen, setSelWxCompanyDropdownOpen] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const recipient = { id: "recipient-id" };
  const [issueDate, setIssueDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0);
    return lastDayOfMonth;
  });
  const [title, setTitle] = useState("");
  const [subTotal, setSubTotal] = useState("00.00");
  const [taxTotal, setTaxTotal] = useState("00.00");
  const [total, setTotal] = useState("00.00");
  // const [productDetails, setProductDetails] = useState([
  //   { description: "", cost: "", tax: "", price: "" },
  // ]);
  const [productDetails, setProductDetails] = useState([{ description: "", cost: "", tax: "", price: "", type: 'service' }]);
  const [invDocument, setInvDocument] = useState(null);
  const [invDocumentFileName, setInvDocumentFileName] = useState("");
  const [invFiles, setInvFiles] = useState([]);
  const [otherComnt, setOtherComnt] = useState("");
  const [selUserEmail, setSelUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [invNumber, setInvNumber] = useState("");
  const [venExpDropdownOpen, setVenExpDropdownOpen] = useState(false);
  const [currencyModalIsOpen, setCurrencyModalIsOpen] = useState(false);
  const [itemTypeModalIsOpen, setItemTypeModalIsOpen] = useState(false);
  const [invoiceBankCurrency, setInvoiceBankCurrency] = useState("");
  const [vendor, setVendor] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [address, setAddress] = useState("");
  // const [projectId, setProjectId] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isWiseCompanyLoading, setIsWiseCompanyLoading] = useState(true);
  const [wxCom, setWxCom] = useState("Any");
  const [autoCurrency, setAutoCurrency] = useState([]);
  const [companyDropdownOpen, setCompanyDropdownOpen] = useState(false);
  const [defaultbankAccount, setDefaultBankAccount] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);
  const [isFetched, setIsFetched] = useState(true);
  const [isBankLoading, setIsBankLoading] = useState(true);
  const [invoiceBank, setInvoiceBank] = useState("");
  const [invoiceBankListName, setInvoiceBankListName] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [wiseCompany, setWiseCompany] = useState([]);
  const [wiseProfile, setWiseProfile] = useState(null);
  const [wiseProfileLabel, setWiseProfileLabel] = useState(null);
  const [searchBankAccountTerm, setSearchBankAccountTerm] = useState('');
  const [bankAccountDropdownOpen, setBankAccountDropdownOpen] = useState(false);
  // const [searchExpCategoryTerm, setSearchExpCategoryTerm] = useState('');
  // const [expCategoryDropdownOpen, setExpCategoryDropdownOpen] = useState(false);
  // const [selectedCategory, setSelectedCategory] = useState('');
  const bankRef = useRef(null);
  const bankAccountDropdownRef = useRef(null);
  const companyeDropdownRef = useRef(null);
  const [selectWxCompanyIsValid, setSelectWxCompanyIsValid] = useState(false);
  const [dateRangeIsValid, setDateRangeIsValid] = useState(false);
  const dateRangeRef = useRef(null);
  const [issueDateIsValid, setIssueDateIsValid] = useState(false);
  const issueDateRef = useRef(null);
  const [dueDateIsValid, setDueDateIsValid] = useState(false);
  const dueDateRef = useRef(null);
  const [titleIsValid, setTitleIsValid] = useState(false);
  const titleRef = useRef(null);
  const [bankIsValid, setBankIsValid] = useState(false);
  const [descriptionIsValid, setDescriptionIsValid] = useState(false);
  const descriptionRef = useRef(null);
  const [costIsValid, setCostIsValid] = useState(false);
  const costRef = useRef(null);
  const [taxIsValid, setTaxIsValid] = useState(false);
  const taxRef = useRef(null);
  const [totalIsValid, setTotalIsValid] = useState(false);
  const selWxCompanyDropdownRef = useRef(null);
  const venExpDropdownRef = useRef(null);
  const { appSettings } = useSelector((state) => state.appSettings);
  const { forex } = useSelector((state) => state.forex);
  // const expCategoryDropdownRef = useRef(null);
  let token = null;
  let createdBy = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {

    if (user && user.token) {
      token = user.token;
      createdBy = user._id;
    }
  }

  const vendorInvoice = useSelector((state) => state.vendorInvoice);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleSelect = (ranges) => {
    setDateRange(ranges);
  };

  const { data: vendorView = [], isLoading: isVendorLoading, refetch: refetchVendorProfiles, isError: vendorViewError } = useVendorView(vendorInvoice.vendorInvoiceData && vendorInvoice.vendorInvoiceData[1], token);
  const { data: userView = [], isLoading: isUserLoading, refetch: refetchUserProfiles, isError: userViewError } = useUserByVendor(vendorInvoice.vendorInvoiceData && vendorInvoice.vendorInvoiceData[1], token);
  const { data: projectId = [], isLoading: isProjectLoading, refetch: refetchProjectList, isError: projectListError } = useFetchVendorProjectDetails(vendorInvoice.vendorInvoiceData && vendorInvoice.vendorInvoiceData[1], token);
  // const { data: vendorUsers = [], isLoading: isUserLoading } = useVendorUser(token);
  const { data: wxCompanyProfile = [], isLoading: isWxCompanyLoading } = useWxCompany(token);
  const encryptId = AES.encrypt(id, 'encryptionKey').toString();
  const encodedId = encodeURIComponent(encryptId).replace(/\//g, '%2F');

  useEffect(() => {
    async function fetchInvoiceUserData() {
      try {
        var startDate = new Date(vendorInvoice.vendorInvoiceData && vendorInvoice.vendorInvoiceData[0][0]);
        var endDate = new Date(vendorInvoice.vendorInvoiceData && vendorInvoice.vendorInvoiceData[0][1]);
        var startDateFormatted = startDate.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        var [startMonth, startDay, startYear] = startDateFormatted.split("/");
        var formattedStartDateString = `${startDay}/${startMonth}/${startYear}`;
        var endDateFormatted = endDate.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        var [month, day, year] = endDateFormatted.split("/");
        const formattedEndDateString = `${day}/${month}/${year}`;
        var formattedDateRange = `${formattedStartDateString} - ${formattedEndDateString}`;
        setDateRange([startDate, endDate]);
        // setSelWxCompany(invoice.selectedUser && invoice.selectedUser[1]);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        alert("Something went wrong");
      }
    }
    fetchInvoiceUserData();

    if (!isUserLoading) {
      setDefaultBankAccount(userView.defaultBank);
      async function getUserWiseProfile() {
        setIsWiseCompanyLoading(true); // Start loading

        try {
          const response = await axios.post(
            "/api/wise/user-wise-profile",
            {
              userId: `${userView && userView._id}`,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setWiseCompany(response?.data);
        } catch (error) {
          console.error('Error fetching user-wise profile:', error);
        } finally {
          setIsWiseCompanyLoading(false); // Stop loading
        }
      }
      getUserWiseProfile();
    }
  }, [vendorInvoice, userView]);

  const handleToggleSelWxCompanyDropdown = () => {
    setUserSearchTerm('');
    setSelWxCompanyDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectSelWxCompany = (selectedSelWxCompany) => {
    setSelWxCompany(selectedSelWxCompany);
    setUserSearchTerm('');
    setSelWxCompanyDropdownOpen(false);
  };

  const handleSelWxCompanySearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  // const filteredUsers = vendorUsers.filter((user) => {
  //   const fullName = user.first_name
  //     ? `${user.first_name} ${user.middle_name || ''} ${user.last_name}`
  //     : `${user.company_first_name ? user.company_first_name + (user.company_last_name && user.company_last_name) : user.company_name}`;
  //   return fullName.toLowerCase().includes(userSearchTerm.toLowerCase());
  // });

  useEffect(() => {
    let vendorName = '';
    if (vendorView.vendor_name && vendorView.vendor_name.toLowerCase() !== 'null') {
      vendorName = vendorView.vendor_name;
    } else {
      vendorName = vendorView.vendor_legalentity_name && vendorView.vendor_legalentity_name.toLowerCase() !== 'null' && vendorView.vendor_legalentity_name;
    }
    if (vendorView && !isVendorLoading && vendorView?.vendor_wx_comapny) {
      setWxCom(vendorView?.vendor_wx_comapny?.wx_company);
      setSelWxCompany(vendorView?.vendor_wx_comapny?._id);
    }
    setVendorName(vendorName);
    setVatNumber(vendorView.vat_number && vendorView.vat_number);
    setAddress(vendorView.address && vendorView.address);
    if (vendorView && !isVendorLoading) {
      if (vendorView.preferred_currency) {
        setInvoiceBankCurrency(vendorView.preferred_currency && vendorView.preferred_currency);
        setAutoCurrency(vendorView.preferred_currency && vendorView.preferred_currency);
      } else {
        setCurrencyModalIsOpen(true);
      }
    }
    setSelUserEmail(vendorView.email && vendorView.email);

    async function fetchInvNumber() {
      try {
        const response = await axios.get("/api/invoice/invnumber", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setInvNumber(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong while fetching invoice number");
      }
    }
    fetchInvNumber();

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [vendorView, projectId]);

  function closeCurrencyModal() {
    setCurrencyModalIsOpen(false);
    if (user && (user.userCategory === userCategory.Vendor)) {
      navigate(`/vendor/invoice`);
    } else {
      navigate(`/vendor/invoices/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`);
    }
  }

  function addCurrency() {
    navigate(`/vendor/profile/${encodeURIComponent(AES.encrypt(vendorInvoice.vendorInvoiceData && vendorInvoice.vendorInvoiceData[1], 'encryptionKey').toString().replace(/\//g, '%2F'))}`);
  }

  const handleCompanyToggleDropdown = () => {
    setCompanyDropdownOpen(!companyDropdownOpen);
  };

  const handleCompanyselect = async (profile) => {
    setWiseProfile(profile);
    setBankDetails([]);
    if (profile === 14760922) {
      setSelWxCompany("6422bb4cc0ca63c8330e5bd5");
    } else if (profile === 10873693) {
      setSelWxCompany("6422bb4cc0ca63c8330e5bd4");
    }
    // setInvTypeWiseDropdownOpen(false);
    setCompanyDropdownOpen(false);
    setIsBankLoading(true);
    const getAllBanks = await getBankFromCompany(profile, selUserEmail && selUserEmail, token);
    setBankDetails(getAllBanks);
    setIsBankLoading(false);
  }

  const handleToggleDropdown = () => {
    setSearchBankAccountTerm('');
    setBankAccountDropdownOpen(!bankAccountDropdownOpen);
  };

  const handleSelectOption = (invoiceBank) => {
    const bankSelect = invoiceBank.split("_");
    // setInvoiceBankCurrency(bankSelect[1]);
    setInvoiceBank(bankSelect[0]);
    setInvoiceBankListName(invoiceBank);
    setBankAccountDropdownOpen(false);
  };

  const handleSearchInputChange = (e) => {
    setSearchBankAccountTerm(e.target.value);
  };

  var autoCurrencyFilteredBankDetails = autoCurrency && Object.values(bankDetails).filter(entry => entry.currency === autoCurrency);
  var filteredBankDetails = autoCurrencyFilteredBankDetails?.filter((bank) =>
    `${bank.details.accountNumber || ''} ${bank.details.IBAN || ''} ${bank.currency || ''} ${bank.country || ''}`
      .toLowerCase()
      .includes(searchBankAccountTerm.toLowerCase())
  );

  useEffect(() => {
    async function fetchBank() {
      try {
        let wxProfile = "";
        setIsBankLoading(true);
        if (wxCom && (wxCom === "Any")) {
          // setBankDetails([]);
        } else {
          if (wxCom === "WX") {
            wxProfile = 14760922;
          } else if (wxCom === "Xpertos") {
            wxProfile = 10873693;
          }
          setWiseProfile(wxProfile);
          const getAllBanks = await getBankFromCompany(wxProfile, selUserEmail && selUserEmail, token);
          setBankDetails(getAllBanks);
        }
      } catch (error) {
        global.config.slackMessage(error.toString());
        if (error.response && error.response.status === 404) {
          setIsFetched(false);
        } else if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        } else {

        }
      }
    }
    setIsFetching(true);
    fetchBank();
    setIsFetching(false);
    setIsBankLoading(false);
  }, [selUserEmail]);

  useEffect(() => {
    const targetObject = ((bankDetails && defaultbankAccount) && bankDetails.find(item => item.id === parseInt(defaultbankAccount)));
    setInvoiceBankListName((defaultbankAccount && bankDetails && targetObject) && `${defaultbankAccount}_${targetObject && targetObject.currency}`);
    setInvoiceBank((defaultbankAccount && bankDetails && targetObject) && defaultbankAccount);
  }, [bankDetails, defaultbankAccount]);

  function closeModal() {
    setModalIsOpen(false);
    if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
      const encryptedId = AES.encrypt(id, 'encryptionKey').toString();
      const encodedId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
      navigate(`/vendor/banks/${encodedId}`);
    } else {
      localStorage.setItem('activeTab', '1');
      navigate(`/user/profile/settings`);
    }
  }

  function addBank() {
    if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
      const encryptedId = AES.encrypt(id, 'encryptionKey').toString();
      const encodedId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
      navigate(`/vendor/banks/${encodedId}`);
    } else {
      localStorage.setItem('activeTab', '1');
      navigate(`/user/profile/settings`);
    }
  }

  const handleIssueDateChange = (date) => {

    if (!dueDate || new Date(date) <= new Date(dueDate)) {
      setIssueDate(date);
    } else {

      toast.error("Issue date must be before due date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const ranges = [
    {
      label: "today",
      value: new Date(), // Use a single date instead of an array
    },
    {
      label: "yesterday",
      value: subDays(new Date(), 1),
    },
  ];

  const handleDueDateChange = (date) => {
    if (!issueDate || new Date(date) >= new Date(issueDate)) {
      setDueDate(date);
    } else {
      toast.error("Due date must be after issue date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
    const currencyFromRate = forex[currencyFrom];
    const currencyToRate = forex[currencyTo];
    const amountConvertedUSD = amount / currencyFromRate;
    const amountConvertTo = amountConvertedUSD * currencyToRate;
    return amountConvertTo;
  }

  const handleAddSeviceRow = (e) => {
    e.preventDefault();
    setItemTypeModalIsOpen(false);
    const newProductDetails = [
      ...productDetails,
      { description: "", cost: "", tax: "", price: "", type: 'service' },
    ];
    setProductDetails(newProductDetails);
  };

  const handleRemoveRow = (indexToRemove, e) => {
    const newProductDetails = [...productDetails];
    newProductDetails.splice(indexToRemove, 1);
    setProductDetails(newProductDetails);
    const { subTotal, taxTotal, total } = calculateSubTotalAndTax(newProductDetails);
    setSubTotal(subTotal);
    setTaxTotal(taxTotal);
    setTotal(total);
  };

  const calculateSubTotalAndTax = (details) => {
    let subTotal = 0;
    let taxTotal = 0;
    let total = 0;
    details.forEach((detail) => {

      var cost = parseFloat(detail.cost) || 0;
      var tax = parseFloat(detail.vat) || 0;
      const amount = cost + cost * (tax / 100);
      subTotal += cost;
      taxTotal += amount - cost;
      total += amount;
    });
    return { subTotal: subTotal.toFixed(2), taxTotal: taxTotal.toFixed(2), total: total.toFixed(2) };
  };

  const handleInputChange = (event, index, field) => {

    const { name, value } = event.target;
    const newProductDetails = [...productDetails];
    newProductDetails[index][name] = value;
    if (field === "cost" || field === "vat") {
      const cost = parseFloat(newProductDetails[index].cost) || 0;
      const vat = parseFloat(newProductDetails[index].vat) || 0;
      if (!isNaN(cost) && !isNaN(vat)) {
        newProductDetails[index].amount = (cost + cost * (vat / 100)).toFixed(
          2
        );
      }
    }
    setProductDetails(newProductDetails);
    const tax = calculateTax();
    setTaxTotal(tax);
    const subtotal = calculateSubtotal();
    setSubTotal(subtotal);
    const total = calculateTotal();
    setTotal(total);
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    productDetails.forEach((detail) => {
      const cost = parseFloat(detail.cost) || 0;
      subtotal += cost;
    });
    return subtotal.toFixed(2);
  };

  const calculateTax = () => {
    let tax = 0;
    productDetails.forEach((detail) => {
      const cost = parseFloat(detail.cost) || 0;
      const vat = parseFloat(detail.vat) || 0;
      const amount = cost + cost * (vat / 100);
      tax += amount - cost;
    });
    return tax.toFixed(2);
  };

  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const tax = parseFloat(calculateTax());
    return (subtotal + tax).toFixed(2);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIssueDateIsValid(false);
    setSelectWxCompanyIsValid(false);
    setTitleIsValid(false);
    setBankIsValid(false);
    setDescriptionIsValid(false);
    setCostIsValid(false);
    setTaxIsValid(false);
    if (
      invNumber === "" ||
      dateRange === null ||
      issueDate === null ||
      dueDate === null ||
      title === "" ||
      selUserEmail === "" ||
      (productDetails.length > 0 && productDetails[0].description.length === 0) ||
      (productDetails.length > 0 && productDetails[0].cost.length === 0) ||
      (productDetails.length > 0 && productDetails[0].amount.length === 0) ||
      invoiceBankCurrency === "" ||
      invoiceBank === "" ||
      !invoiceBank ||
      selWxCompany === "" ||
      subTotal === "00.00" ||
      subTotal <= 0 ||
      total === "00.00" ||
      total <= 0
    ) {
      if (selWxCompany === "") {
        setSelectWxCompanyIsValid(true);
        toast.error("Ventor field cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dateRangeRef.current.focus();
        return;
      } else if (dateRange === null) {
        setDateRangeIsValid(true);
        toast.error("Date range cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dateRangeRef.current.focus();
        return;
      } else if (issueDate === null) {
        setIssueDateIsValid(true);
        toast.error("Issue date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        issueDateRef.current.focus();
        return;
      } else if (dueDate === null) {
        setDueDateIsValid(true);
        toast.error("Due date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dueDateRef.current.focus();
        return;
      } else if (title === "") {
        setTitleIsValid(true);
        titleRef.current.focus();
        return;
      } else if (invoiceBank === "") {
        setBankIsValid(true);
        toast.error("Bank Details Cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        bankRef.current.focus();
        return;
      } else if (!invoiceBank) {
        setBankIsValid(true);
        toast.error("Bank Details Cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        bankRef.current.focus();
        return;
      } else if (productDetails.length > 0 && productDetails[0].description.length === 0) {
        setDescriptionIsValid(true);
        descriptionRef.current.focus();
        return;
      } else if (productDetails.length > 0 && productDetails[0].cost.length === 0) {
        setCostIsValid(true);
        costRef.current.focus();
        return;
      } else if (subTotal === "00.00" || subTotal <= 0) {
        toast.error("Subtotal must be greater than 0!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (total === "00.00" || total <= 0) {
        toast.error("Total must be greater than 0!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setIsLoading(true);
    const descriptions = productDetails.map((product) => product.description);
    const prices = productDetails.map((product) => product.amount);
    const costs = productDetails.map((product) => product.cost);
    const taxes = productDetails.map((product) => product.vat);
    const itemType = productDetails.map((product) => product.type);
    const invoiceData = {
      ven_inv_number: invNumber,
      ven_inv_start_date: moment.utc(dateRange[0]).tz(global.config.getTimeZone()).format('YYYY-MM-DD'),
      ven_inv_end_date: moment.utc(dateRange[1]).tz(global.config.getTimeZone()).format('YYYY-MM-DD'),
      ven_inv_issue_date: moment.utc(issueDate).tz(global.config.getTimeZone()).format('YYYY-MM-DD'),
      ven_inv_due_date: moment.utc(dueDate).tz(global.config.getTimeZone()).add(1, 'days').format('YYYY-MM-DD'),
      ven_inv_title: title,
      ven_inv_vendor: selWxCompany,
      ven_inv_email: selUserEmail,
      ven_inv_description: JSON.stringify(descriptions),
      ven_inv_price: JSON.stringify(prices),
      ven_inv_cost: JSON.stringify(costs),
      ven_inv_tax: JSON.stringify(taxes),
      ven_inv_item_type: JSON.stringify(itemType),
      ven_inv_sub_total: subTotal,
      ven_inv_for: vendorInvoice && vendorInvoice.vendorInvoiceData[1],
      ven_inv_from: appSettings && appSettings.app_ownerid,
      ven_inv_total_tax: taxTotal,
      ven_inv_total_amount: total,
      ven_inv_bank: invoiceBank,
      ven_inv_bank_profile: wiseProfile,
      ven_inv_currency: invoiceBankCurrency,
      ven_inv_other_comments: otherComnt,
      ven_inv_created_by: createdBy,
    };

    // Create a new FormData object
    const formData = new FormData();
    // formData.append("inv_file", invDocument);
    // Append the inv_files array to the form data
    for (let i = 0; i < invFiles.length; i++) {
      formData.append("ven_inv_file", invFiles[i]);
    }
    // Append the other request data to the form data
    for (let key in invoiceData) {
      formData.append(key, invoiceData[key]);
    }


    try {
      const response = await axios.post("/api/vendor/invoice", formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      const data = response.data;

      if (data.status === "Error") {

        toast.error("Something went wrong in creating invoice!!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Invoice created successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "Invoice",
          `Invoice "${invNumber}" (manual) created Successfully`
        );
        if (user && (user.userCategory === userCategory.Vendor)) {
          navigate(`/vendor/invoice`);
        } else {
          navigate(`/vendor/invoices/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`);
        }
      }
    } catch (error) {
      global.config.slackMessage(error.toString());
      if (error.response.data.status === "mailfailed") {
        toast.error("Invoice created successsfully! but something went wrong in sending mail!!", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (user && (user.userCategory === userCategory.Vendor)) {
          navigate(`/vendor/invoice`);
        } else {
          navigate(`/vendor/invoices/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`);
        }
      } else if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      } else {
        console.log(error);
        alert("Something went wrong while creating invoice");
        global.config.activityLog(
          window.location.href,
          "Invoice",
          `Invoice "${invNumber}" creation having some error.`
        );
      }
    }
    setIsLoading(false);
  };

  const allowedExtensions = ["pdf", "doc", "docx", "msword", "xls", "xlsx", "csv", "jpeg", "jpg", "png", "gif"];

  const handleInvDocChange = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      if (selectedFiles.length + invFiles.length > 10) {
        // Display toast error message when the total number of files exceeds 10
        toast.error('Maximum of 10 files can be uploaded!', {
          position: 'bottom-right',
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const newFiles = Array.from(selectedFiles);

      // Validate file extensions and update state
      const validFiles = newFiles.filter((file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          return true;
        } else {
          toast.error(`Invalid file format for ${file.name}. Allowed formats are pdf, doc, docx, msword, xls, xlsx, csv.!!`, {
            position: 'bottom-right',
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      });

      // Update the state with the valid files
      setInvFiles((prevFiles) => [...prevFiles, ...validFiles]);

      // Clear the file input for multiple selections
      fileInputRef.current.value = '';
    }
  };

  const removeFile = (index) => {
    // Remove the file at the specified index
    setInvFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideSelWxCompanyDropdown = selWxCompanyDropdownRef.current && selWxCompanyDropdownRef.current.contains(event.target);
    const isClickInsideVenExpDropdown = venExpDropdownRef.current && venExpDropdownRef.current.contains(event.target);
    const isClickInsideBankAccountDropdown = bankAccountDropdownRef.current && bankAccountDropdownRef.current.contains(event.target);
    const isClickInsideCompanyDropdown = companyeDropdownRef.current && companyeDropdownRef.current.contains(event.target);
    // const isClickInsideExpCategoryDropdown = expCategoryDropdownRef.current && expCategoryDropdownRef.current.contains(event.target);
    if (!isClickInsideSelWxCompanyDropdown) {
      setSelWxCompanyDropdownOpen(false);
    }
    if (!isClickInsideBankAccountDropdown) {
      setBankAccountDropdownOpen(false);
    }
    if (!isClickInsideVenExpDropdown) {
      setVenExpDropdownOpen(false);
    }
    if (!isClickInsideCompanyDropdown) {
      setCompanyDropdownOpen(false);
    }
    // if (!isClickInsideExpCategoryDropdown) {
    //   setExpCategoryDropdownOpen(false);
    // }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleTooltipToggle = () => {
    setShowTooltip(!showTooltip);
  };
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <div className="s1-containauto s1-containauto-vendor">
      <form onSubmit={handleSubmit}>
        <div className="fs-head-autoinvo">
          <div className="bill-num-si">
            <Link className="invoice-num-01">{invNumber}</Link>
          </div>
          <div className="btn-aary">
            {(user && (user.userCategory === userCategory.Vendor)) ? (
              <Link to={`/vendor/invoice`} className="cancelbtn-link">
                <img src={cancelImage} alt="cancel" /> Cancel
              </Link>
            ) : (
              <Link to={`/vendor/invoices/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className="cancelbtn-link">
                <img src={cancelImage} alt="cancel" /> Cancel
              </Link>
            )}
          </div>
        </div>
        <div className="m-edit-main">
          <div className="edit-sheet-manual01">
            <div className="headder-outter-res-mainu">
              <div className="sheet-manual-header">
                <div className="input-field-inof input-field-inof-new input-field-create">
                  <label htmlFor="invoice-title">Vendor</label>
                  {isVendorLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="25"
                        width="25"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <input
                      type="text"
                      id="user-name"
                      placeholder="Enter User Name"
                      name="user-name"
                      value={vendorName}
                      className="read-only-input"
                      required
                      readOnly
                    />
                  )}
                </div>
                <div className="invo-date-rangeme input-field-create">
                  <div className="invo-date-range-tile">Date Range</div>
                  <div className="invo-date-range-cal" ref={dateRangeRef}>
                    <div className="date-picker-outer date-picker-outer-all">
                      <div className="custom-picker-icon custom-picker-icon-all">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 14 14"
                          fill="currentColor"
                          aria-hidden="true"
                          focusable="false"
                          class="rs-picker-toggle-caret rs-icon"
                          aria-label="calendar"
                          data-category="legacy"
                        >
                          <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                        </svg>
                      </div>
                      <div className="custoom-icon-calender custoom-icon-calender-all">
                        <DateRangePicker
                          showOneCalendar
                          value={dateRange}
                          onChange={handleSelect}
                          format="dd/MM/yyyy"
                          isoWeek={appSettings.weekStartDay === 'Monday'}
                          timeZone={global.config.getTimeZone()}
                        />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="invo-date-issueme input-field-create">
                  <div className="invo-date-issue-tile">Issue Date</div>
                  <div className="invo-date-due-cal">
                    <div className="calender-date-due-pic" ref={issueDateRef}>
                      <div className="date-picker-outer date-picker-outer-all">
                        <div className="custom-picker-icon custom-picker-icon-all">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 14 14"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class="rs-picker-toggle-caret rs-icon"
                            aria-label="calendar"
                            data-category="legacy"
                          >
                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                          </svg>
                        </div>
                        <div className="custoom-icon-calender custoom-icon-calender-all readonly-calender">
                          <DatePicker
                            oneTap
                            showOneCalendar
                            ranges={ranges}
                            format="dd/MM/yyyy"
                            value={issueDate}
                            onChange={handleIssueDateChange}
                            placement="auto"
                            required
                            isoWeek={appSettings.weekStartDay === 'Monday'}
                            timeZone={global.config.getTimeZone()}
                            readOnly
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="date-due-manual-edm input-field-create">
                  <div className="invo-date-issue-tile also-for-dued-cal">
                    Due Date
                  </div>
                  <div className="invo-date-due-cal">
                    <div className="calender-date-due-pic" ref={dueDateRef}>
                      <div className="date-picker-outer date-picker-outer-all">
                        <div className="custom-picker-icon custom-picker-icon-all">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 14 14"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class="rs-picker-toggle-caret rs-icon"
                            aria-label="calendar"
                            data-category="legacy"
                          >
                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                          </svg>
                        </div>
                        <div className="custoom-icon-calender custoom-icon-calender-all">
                          <DatePicker
                            oneTap
                            showOneCalendar
                            ranges={ranges}
                            format="dd/MM/yyyy"
                            value={dueDate}
                            onChange={handleDueDateChange}
                            placement="bottomEnd"
                            required
                            isoWeek={appSettings.weekStartDay === 'Monday'}
                            timeZone={global.config.getTimeZone()}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              className="delete-modal"
              isOpen={currencyModalIsOpen}
              onRequestClose={closeCurrencyModal}
              contentLabel="currency Modal"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "40%",
                  left: "50%",
                  transform: "translate(-17.5%, 0%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="delete-modal-content">
                <div className="outter-modal-main">
                  <a className="close-image-clr1" onClick={closeCurrencyModal}>
                    <img src={crssrImage} alt="Close Icon" />{" "}
                  </a>
                </div>
                <h2>No Currency Added</h2>
                <p>
                  No currency is set in this vendor profile
                  Please add Currency in vendor profile to proceed further
                </p>
                {isVendorLoading ?
                  (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="38"
                        width="40"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="delete-modal-buttons">
                      <button className="buttons-approved" onClick={addCurrency} disabled={isVendorLoading}>
                        Add Currency
                      </button>

                      <button onClick={closeCurrencyModal}>
                        Cancel
                      </button>
                    </div>
                  )}
              </div>
            </Modal>
            <div className="sheed-sec-after-headem">
              <div className="vendor-invoice-bank-section">
                <div className={`input-field-inof ${vendorView && vendorView?.vendor_wx_comapny?.wx_company === "Any" ? "inline-section" : ""}`}>
                  {vendorView && (vendorView?.vendor_wx_comapny?.wx_company === "Any" || (wxCom && wxCom === "Any")) && (
                    <div className="type-dropdown-em">
                      {/* <div className="dp-main-head-em">Company</div> */}
                      <label htmlFor="bank-account">Company</label>
                      {(isVendorLoading || isUserLoading || isWiseCompanyLoading) ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="50"
                            width="50"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <div className="custom-dropdown1">
                          <div ref={companyeDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${companyDropdownOpen ? 'open' : ''}`}>
                            <div className="selected-option custom-dropdown-user-new" onClick={handleCompanyToggleDropdown}>
                              {wiseProfileLabel ? wiseProfileLabel : 'Select Company'}
                            </div>
                            {companyDropdownOpen && (
                              <div className="custom-dropdown-new">
                                <ul className="type-list">
                                  {wiseCompany && wiseCompany?.id.map((item) => {
                                    return (<li onClick={() => { handleCompanyselect(item.id); setWiseProfileLabel(item.fullName); }}>{item.fullName}</li>)
                                    // className={invType === 'Regularw' ? 'selected-item' : ''}
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                          <span className="custom-arrow"></span>
                        </div>
                      )}
                    </div>
                  )}

                  {((isBankLoading && bankDetails.length === 0) || isVendorLoading || isFetching || isUserLoading || isWiseCompanyLoading) ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                      {bankDetails.length > 0 && wiseProfile ?
                        (
                          <div ref={bankAccountDropdownRef} className="custom-dropdown-user-new-outer">
                            <label htmlFor="bank-account">Bank Account </label>
                            <div className="selected-option custom-dropdown-user-new" onClick={handleToggleDropdown} ref={bankRef}>
                              {invoiceBankListName ? (
                                <>
                                  {`${bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.accountNumber || ''}`}
                                  {`${bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.accountNumber &&
                                    bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.IBAN
                                    ? ' '
                                    : ''
                                    }`}
                                  {`${bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.IBAN || ''} (${invoiceBankCurrency})`}
                                </>
                              ) : 'Select Bank'}
                            </div>
                            {bankAccountDropdownOpen && (
                              <div className="custom-dropdown-new">
                                {autoCurrencyFilteredBankDetails.length > 0 ? (
                                  <>
                                    <input
                                      type="text"
                                      className="search-bar"
                                      placeholder="Search..."
                                      value={searchBankAccountTerm}
                                      onChange={handleSearchInputChange}
                                    />
                                    <ul className="bank-account-list">
                                      {filteredBankDetails.length > 0 ? (
                                        filteredBankDetails.map((bank) => (
                                          <li
                                            key={`${bank.id}_${bank.currency}`}
                                            className={invoiceBankListName === `${bank.id}_${bank.currency}` ? 'selected-item' : ''}
                                            onClick={() => handleSelectOption(`${bank.id}_${bank.currency}`)}
                                          >
                                            {`${bank.details.accountNumber || ''}${bank.details.accountNumber &&
                                              bank.details.IBAN
                                              ? ' '
                                              : ''
                                              }${bank.details.IBAN || ''} (${bank.currency})`}
                                          </li>
                                        ))
                                      ) : (
                                        <li>No results found</li>
                                      )}
                                    </ul>
                                  </>
                                ) : (
                                  <div>No bank accounts available in the preferred currency</div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <>
                            {(bankDetails.length === 0 && vendorName && wiseProfile) && (
                              <>
                                <div className="no-acccount-link" ref={bankRef}>
                                  <label>Bank Account </label>
                                  No bank account is linked to this account. <button onClick={addBank}>Click here to add one.</button>
                                </div>
                                <Modal
                                  className="delete-modal"
                                  isOpen={modalIsOpen}
                                  onRequestClose={closeModal}
                                  contentLabel="Approve Modal"
                                  style={{
                                    content: {
                                      height: "41%",
                                      width: "30%",
                                      position: "fixed",
                                      top: "40%",
                                      left: "50%",
                                      transform: "translate(-17.5%, 0%)",
                                      parent: document.querySelector(".admin-outer.time.tracker"),
                                    },
                                  }}
                                >
                                  <div className="delete-modal-content">
                                    <div className="outter-modal-main">
                                      <a className="close-image-clr1" onClick={closeModal}>
                                        <img src={crssrImage} alt="Close Icon" />{" "}
                                      </a>
                                    </div>
                                    <h2>No Bank Registered</h2>
                                    <p>
                                      No Bank Registered in this Account
                                      Please add a bank account to proceed further
                                    </p>
                                    {isBankLoading ?
                                      (
                                        <div className="outter-load-table">
                                          <ThreeDots
                                            height="38"
                                            width="40"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                          />
                                        </div>
                                      )
                                      : (
                                        <div className="delete-modal-buttons">
                                          <button className="buttons-approved" onClick={addBank} disabled={isBankLoading}>
                                            Add Bank
                                          </button>

                                          <button onClick={closeModal}>
                                            Cancel
                                          </button>
                                        </div>
                                      )
                                    }
                                  </div>
                                </Modal>
                              </>
                            )}
                          </>

                        )}

                    </>
                  )}
                  {bankIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Bank Details Cannot be empty
                    </span>
                  )}
                </div>
              </div>
              <div className="inp-sec2-with2 inp-sec2-with-client">
                {/* <div className="input-field-inof input-field-inof1">
                  <label htmlFor="invoice-title">Vendor Name</label>
                  {isWxCompanyLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="25"
                        width="25"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                      <div ref={selWxCompanyDropdownRef} className="custom-dropdown-user-new-outer">
                        <div className="selected-option custom-dropdown-user-new" onClick={handleToggleSelWxCompanyDropdown}>
                          {selWxCompany ? (
                            wxCompanyProfile?.find((wxCompany) => wxCompany._id === selWxCompany)?.wx_company
                          ) : 'Select vendor'}
                        </div>
                        {selWxCompanyDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <ul className="client-list">
                              {wxCompanyProfile.length > 0 ? (
                                wxCompanyProfile
                                  .slice()
                                  .sort((a, b) => {
                                    const nameA = a.wx_company;
                                    const nameB = b.wx_company;
                                    return nameA.localeCompare(nameB);
                                  })
                                  .map((item) => (
                                    <li key={item._id} className={selWxCompany === item._id ? 'selected-item' : ''} onClick={() => handleSelectSelWxCompany(item._id)}>
                                      {item.wx_company}
                                    </li>
                                  ))
                              ) : (
                                <li>No ventors registered yet.</li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                      {selectWxCompanyIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Ventor field Cannot be empty
                        </span>
                      )}
                    </>)}

                </div> */}

                <div className="input-field-inof input-field-inof1">
                  <label htmlFor="invoice-title">Invoice Title</label>
                  <input
                    type="text"
                    id="invoice-title"
                    placeholder="Enter invoice Title"
                    name="invoice-title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    required
                    className={titleIsValid ? "input-validation-error" : ""}
                    ref={titleRef}
                    autocomplete="off"
                  />
                  {titleIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Title Cannot be empty
                    </span>
                  )}
                </div>
                <div className="input-field-inof input-field-create">
                  <label htmlFor="invoice-title">Vendor VAT</label>
                  {isVendorLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="25"
                        width="25"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <input
                      type="text"
                      id="vat-number"
                      placeholder="Enter Vat Number"
                      name="vat-number"
                      value={vatNumber}
                      className="read-only-input"
                      required
                      readOnly
                    />
                  )}
                </div>
                <div className="input-field-inof input-field-inof3">
                  <label htmlFor="invoice-title">Vendor Address</label>
                  {isVendorLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="25"
                        width="25"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <input
                      type="text"
                      id="address"
                      placeholder="Enter Address"
                      name="address"
                      value={address}
                      className="read-only-input"
                      required
                      readOnly
                    />
                  )}
                </div>
              </div>
              {/* <div className="inp-sec2-with2 inp-sec2-with2-one">
                <div className="input-field-inof">
                  <label htmlFor="invoice-title">Invoice Title</label>
                  <input
                    type="text"
                    id="invoice-title"
                    placeholder="Enter invoice Title"
                    name="invoice-title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    required
                    className={titleIsValid ? "input-validation-error" : ""}
                    ref={titleRef}
                    autocomplete="off"
                  />
                  {titleIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Title Cannot be empty
                    </span>
                  )}
                </div>
              </div> */}
              <div className="detail-add-parteditmanual">
                {productDetails.length > 0 && (
                  <table className="invoice-table-editmanual">
                    <thead className="the-abantant-edittxh">
                      <tr>
                        <th>Description</th>
                        <th>Cost</th>
                        <th>VAT %</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productDetails.map((product, index) => (
                        <tr key={index}>
                          <td className="description-manual">

                            <input
                              type="text"
                              //list="projectOptions"
                              value={product.description}
                              name="description"
                              onChange={(e) => handleInputChange(e, index, "description")}
                              required
                              className={descriptionIsValid ? "input-validation-error" : ""}
                              placeholder="Description"
                              ref={descriptionRef}
                              autocomplete="off"
                              disabled={product.type === 'expense'}
                            />
                            {/* <datalist id="projectOptions" style={{ position: 'absolute', top: '20%', left: 0 }}>
                              <option value="">Select Project</option>
                              {projectId
                                .slice()
                                .sort((a, b) => a.project_name.toLowerCase().localeCompare(b.project_name.toLowerCase()))
                                .map((project) => (
                                  <option
                                    key={project._id}
                                    value={project.project_name}
                                  >
                                    {project.project_name}
                                  </option>
                                ))}
                            </datalist> */}
                          </td>
                          <td className="edit-manual-costtr">
                            <input
                              type="number"
                              step="any"
                              data-message="Please add Cost"
                              name="cost"
                              min="0"
                              data-no={index + 1}
                              required
                              placeholder="Cost"
                              onChange={(e) =>
                                handleInputChange(e, index, "cost")
                              }
                              className={costIsValid ? "input-validation-error" : ""}
                              value={product.cost}
                              ref={costRef}
                              autocomplete="off"
                              disabled={product.type === 'expense'}
                              onWheel={numberInputOnWheelPreventChange}
                            />
                          </td>
                          <td className="editmanual-vattd">
                            <input
                              type="number"
                              step="any"
                              data-message="Please add VAT"
                              name="vat"
                              min="0"
                              data-no={index + 1}
                              required
                              placeholder="VAT %"
                              onChange={(e) => handleInputChange(e, index, "vat")}
                              className={taxIsValid ? "input-validation-error" : ""}
                              value={product.vat}
                              ref={taxRef}
                              autocomplete="off"
                              onWheel={numberInputOnWheelPreventChange}
                            // disabled={product.type === 'expense'}
                            />
                          </td>
                          <td className="editmanual-amount-tr">
                            <div className="amount-cell">
                              <span className="delete-icon">
                                {invoiceBankCurrency ? (
                                  <>
                                    {product.amount ? (
                                      <>
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: invoiceBankCurrency,
                                        }).format(product.amount)}
                                      </>
                                    ) : (
                                      <>
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: invoiceBankCurrency,
                                        }).format("00.00")}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {product.amount ? (
                                      <span>{product.amount}</span>
                                    ) : (
                                      <span>00.00</span>
                                    )}
                                  </>
                                )}
                                {productDetails.length !== 1 && (
                                  <button className="del-icon-main-sel"
                                    type="button"
                                    onClick={() => handleRemoveRow(index)}
                                  >
                                    <img src={dletImage} alt="delete-icon" />{" "}
                                  </button>
                                )}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                <a className="add-item-button" onClick={handleAddSeviceRow}>
                  <img src={addicoplus} alt="addicon" /> Add Item
                </a>
              </div>
            </div>
            <div className="sec-after-tab-ls-part">
              <div className="sec-withother-and-add">
                <div className="other-secp1">
                  <div className="other-head1">Other comments</div>
                  <div className="other-subhead1">
                    <textarea
                      type="text"
                      className=""
                      placeholder="Other Comments"
                      value={otherComnt}
                      onChange={(e) => setOtherComnt(e.target.value)}
                      style={{ resize: "none" }}
                    ></textarea>
                  </div>
                </div>

                <div className="file-add-p1-attachment">
                  <div className="attachment-head">Attachments</div>
                  <div className="style-portion-browsetext" style={{ cursor: 'pointer' }}>
                    <label className="add-item-button-end-browse">
                      <img src={addicoplus} alt="addicon" /> Browse
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleInvDocChange}
                        multiple // Allow multiple file selection
                      />
                    </label>
                    <div className="upload-file-name-br">
                      {invFiles.map((file, index) => (
                        <div key={index} className="uploaded-file-item">
                          <span>{file.name}</span>
                          <a onClick={() => removeFile(index)}>Remove</a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="invo-total-specialcard">
                <div className="specialcard-outter">
                  <table className="to-ino-to-special">
                    <tr>
                      <th className="sub-lem-tb-fisl">Subtotal</th>
                      <td className="sub-price-tb-fisl">
                        {invoiceBankCurrency ? (
                          <>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoiceBankCurrency,
                            }).format(subTotal)}
                          </>
                        ) : (
                          <span>{subTotal}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="sub-vat-tb-fisl">VAT</th>
                      <td className="sub-vatpr-tb-fisl">
                        {invoiceBankCurrency ? (
                          <>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoiceBankCurrency,
                            }).format(taxTotal)}
                          </>
                        ) : (
                          <span>{taxTotal}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="sub-invoto-tb-fisl">Invoice Total</th>
                      <td className="sub-ivvoto-price-tb-fisl">
                        {invoiceBankCurrency ? (
                          <>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoiceBankCurrency,
                            }).format(total)}
                          </>
                        ) : (
                          <span>{total}</span>
                        )}
                      </td>
                    </tr>
                  </table>
                  <button
                    className="largecreate-invo-btn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    /> : "Create Invoice"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div >
      </form >
    </div >
  );
};

export default VendorInvoiceCreate;