import React from 'react';
import { useSelector } from 'react-redux';

// Functional component
function InactiveClient({ clients }) {
  const reportingClient = useSelector((state) => state.reportingClient);

  const filteredClients = clients.filter(client => {
    const isClientDetailMatch = !reportingClient.clientDetails || reportingClient.clientDetails.includes(client._id);
    const isAccountManagerMatch = !reportingClient.clientAccountManager ||
      (client.acc_manager && reportingClient.clientAccountManager.includes(client.acc_manager._id));
    const isStatusMatch = !reportingClient.clientStatus || reportingClient.clientStatus.includes(client.is_active);
    return isClientDetailMatch && isAccountManagerMatch && isStatusMatch;
  });

  const inactiveClientsCount = filteredClients.filter(client => !client.is_active).length;
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">
            Inactive Clients
          </div>
          <div className="overview-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
              <g filter="url(#filter0_d_416_49)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <defs>
                <filter id="filter0_d_416_49" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_416_49" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_416_49" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>

        <div className="overview-rice">
          {inactiveClientsCount}
        </div>
      </div>

    </div>
  );
}

export default InactiveClient;
