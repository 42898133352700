import axios from 'axios';
import { useQuery, useMutation } from 'react-query';

export function useFetchProjectDetails(token) {
  return useQuery('projectDetails', async () => {
    const response = await axios.get('/api/project-details', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useFetchProjectManager(token) {
  return useQuery('projectManagers', async () => {
    const response = await axios.get('/api/user/user-project-manager', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useFetchClientProjectManager(token, clientId) {
  return useQuery(['clientProjectManagers',clientId], async () => {
    const response = await axios.get(`/api/client/get-project-managers/${clientId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.project_managers;
  });
}

export function useFetchClientDetails(token) {
  return useQuery('clientDetails', async () => {
    const response = await axios.get('/api/client-details', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useFetchSprintProjectDetails(token, projectId) {
  return useQuery(['sprintProjectDetails', projectId], async () => {
    const response = await axios.get(`/api/sprintProject-details/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useDeleteProjectDetails(token, selectedId) {
  const mutation = useMutation(async () => {
    const response = await axios.get(`/api/project-details/delete/${selectedId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });

  return mutation;
}

export function useUpdateProjectDetails(token, selectedId, requestData) {
  const mutation = useMutation(async () => {
    const response = await axios.put(`/api/project-details/update/${selectedId}`, requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });

  return mutation;
}

export function useDeleteSprintDetails(token, selectedSprintId) {
  const mutation = useMutation(async () => {
    const response = await axios.get(`/api/sprint-details/delete/${selectedSprintId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });

  return mutation;
}

export function useUpdateSprintDetails(token, selectedSprintId, requestData) {
  const mutation = useMutation(async () => {
    const response = await axios.put(`/api/sprint-details/update/${selectedSprintId}`, requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });

  return mutation;
}