import { useSelector } from 'react-redux';
import React from "react";
import { useAllProfile } from "../bankApiQuery";

const BankComapny = ({ profileId }) => {
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const appId = useSelector((state) => state.appSettings.appSettings.appId);
  const { data: deactivatedProfiles, isLoading, refetch: refetchDeactivatedProfiles } = useAllProfile(token, appId);

  const filtered = deactivatedProfiles && deactivatedProfiles.id.filter(item => {
    return item.id === parseInt(profileId);
  });

  return (
    <>
      {filtered && filtered.length > 0 ? filtered[0].businessName : "Not Available"}
    </>
  );
};

export default BankComapny;