import { ThreeDots } from 'react-loader-spinner';
import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { renderClientProjectCTA } from "../../../../helpers/invoiceHelpers";
import { ProjectCost, Roi, TrackedProjectHours } from "../../../../helpers/ProjectHelpers";
import DateConverter from "../../../../helpers/dateFormatChange";

const ClientProjectsPrintTable = (props) => {

  const renderProjectStatus = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.is_billable === true) {
      statusClass = "yes";
      statusName = "Yes";
    } else if (rowData.is_billable === false) {
      statusClass = "no";
      statusName = "No";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{statusName}</span>
    );
  };

  return (
    <div className="outer-time-table">
      <div className="time-table" ref={props.componentRef}>
        {props.isLoading ? (
          <div className="outter-load-table">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <DataTable
            value={props.filteredData}
            removableSort
            tableStyle={{
              width: "100%", // Set the width of the table to 100% to ensure it fills the available space
              textAlign: "left",
              overflowX: "auto", // Add horizontal scrollbar if necessary
              fontSize: "12px", // Adjust font size for better readability
            }}
          >
            <Column
              field="project_name"
              header="Project"
              style={{ width: "15%", paddingRight: "20px" }} // Set column width as percentage
            ></Column>
            <Column
              field="created_at"
              body={(rowData) => (
                <DateConverter date={rowData.created_at} />
              )}
              header="Date Created"
              style={{ width: "15%", paddingRight: "20px" }} // Set column width as percentage
            ></Column>
            <Column
              field="tracked_hours"
              header="Tracked Hours"
              body={TrackedProjectHours}
              style={{ width: "15%", paddingRight: "20px" }} // Set column width as percentage
            ></Column>
            <Column
              field="budget"
              header="Budget"
              style={{ width: "10%", paddingRight: "20px" }} // Set column width as percentage
            ></Column>
            <Column
              field="cost"
              header="Cost"
              body={ProjectCost}
              style={{ width: "15%", paddingRight: "20px" }} // Set column width as percentage
            ></Column>
            <Column
              field="roi"
              header="ROI"
              body={Roi}
              style={{ width: "15%", paddingRight: "20px" }} // Set column width as percentage
            ></Column>
            <Column
              field="is_billable"
              header="Billable"
              body={(rowData) => renderProjectStatus(rowData)}
              style={{ width: "10%" }} // Set column width as percentage
            ></Column>
          </DataTable>
        )}
      </div>
    </div>
  );
};

export default ClientProjectsPrintTable;