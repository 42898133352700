import React, { useState, useRef, useEffect } from "react";
import "./ClientSetup.css";
import { AES, enc } from "crypto-js";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import LeftArrow from "../../../images/icons/blue-left-arrow.svg";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SingleUser from "../../../images/icons/single-user-icon.svg";
import profile_image from "../../../images/user/Default_pfp.png";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { useUserById } from "../../TimeOff/timeOffApi";
import {
  useClientView,
  useRemoveTeamMember,
  useUpdateAccManager,
  useUpdateProjManager,
} from "../../TimeTracker/TimeTrackerClient/useClientDetails.jsx";
import { useFetchProjectManager } from "../../TimeTracker/TimeTrackerProject/useProjectDetails.jsx";

const Setup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, "/"));
  const decryptedBytes = AES.decrypt(decodedId, "encryptionKey");
  const id = decryptedBytes.toString(enc.Utf8);
  const [accManager, setAccManager] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isProjMangSubmitLoading, setIsProjMangSubmitLoading] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [teamMemberSearchTerm, setTeamMemberSearchTerm] = useState("");
  const [selectedUserTeamMembers, setSelectedUserTeamMembers] = useState(
    Array.from({ length: 1 }, () => null)
  ); // Initial state with one project manager

  const [selectedUserTeamMembers1, setSelectedUserTeamMembers1] = useState(
    Array.from({ length: 1 }, () => null)
  ); // Initial state with one project manager

  const [userDropdownOpenAccountManager, setUserDropdownOpenAccountManager] =
    useState(false);
  const [userDropdownOpenTeamMembers, setUserDropdownOpenTeamMembers] =
    useState(Array.from({ length: 1 }, () => false)); // Initial state with one project manager dropdown open
  const userDropdownRefAccountManager = useRef(null);
  const userDropdownRefsTeamMembers = useRef([]);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: userIds = [], isLoading: isLoadingUser } = useUserById(token);
  const {
    data: teamMemberList = [],
    isLoading: teamMemberLoading,
    refetch: refetchTeamMember,
  } = useFetchProjectManager(token);
  const {
    data: clientView = [],
    isLoading,
    refetch: refetchClientProfiles,
    isError: clientViewError,
  } = useClientView(id, token);
  const updateAccManager = useUpdateAccManager(token);
  const updateTeamMembers = useUpdateProjManager(token);
  const removeTeamMember = useRemoveTeamMember(token);

  useEffect(() => {
    if (!isLoading && clientView) {
      if (clientView.acc_manager) {
        setAccManager(clientView.acc_manager._id);
      } else {
        setAccManager("");
      }
      if (clientView.project_managers) {
        setSelectedUserTeamMembers(clientView.project_managers);
      } else {
        setSelectedUserTeamMembers(Array.from({ length: 1 }, () => null));
      }
    }
  }, [isLoading, clientView]);

  const filteredUsers = userIds.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`
      : `${
          user.company_first_name
            ? user.company_first_name +
              (user.company_last_name && user.company_last_name)
            : user.company_name
        }`;
    return fullName.toLowerCase().includes(userSearchTerm.toLowerCase());
  });

  const filteredTeamMembers = teamMemberList.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`
      : `${
          user.company_first_name
            ? user.company_first_name +
              (user.company_last_name && user.company_last_name)
            : user.company_name
        }`;
    return fullName.toLowerCase().includes(teamMemberSearchTerm.toLowerCase());
  });

  const toggleUserDropdownAccountManager = () => {
    setUserSearchTerm("");
    setUserDropdownOpenAccountManager(!userDropdownOpenAccountManager);
  };

  const toggleUserDropdownTeamMember = (index) => {
    setTeamMemberSearchTerm("");
    const newDropdownState = [...userDropdownOpenTeamMembers];
    newDropdownState[index] = !newDropdownState[index];
    setUserDropdownOpenTeamMembers(newDropdownState);
  };

  const selectUserOptionAccountManager = async (selectedUser) => {
    setAccManager(selectedUser);
    setUserSearchTerm("");
    setUserDropdownOpenAccountManager(false);
    try {
      setIsSubmitLoading(true);
      await updateAccManager.mutateAsync({
        id,
        acc_manager: selectedUser,
      });
      if (!selectedUserTeamMembers.includes(selectedUser)) {
        await updateTeamMembers.mutateAsync({
          id,
          projectManagers: selectedUser,
        });
      }

      toast.success("Client account manager updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Client",
        `Client (${clientView.client_name}) updated successfully`
      );
      refetchClientProfiles();
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
      setUserDropdownOpenAccountManager(false);
    }
    setUserDropdownOpenAccountManager(false);
  };

  const handleAccManagerSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const handleTeamMemberSearchInputChange = (e) => {
    setTeamMemberSearchTerm(e.target.value);
  };

  const handleTeamMemberSelection = async (index, selectedUser) => {
    const updatedTeamMembers = [...selectedUserTeamMembers];
    const previousSelectedUser = updatedTeamMembers[index];
    updatedTeamMembers[index] = selectedUser;
    setSelectedUserTeamMembers(updatedTeamMembers);
    setTeamMemberSearchTerm("");
    setUserDropdownOpenTeamMembers(
      Array.from({ length: selectedUserTeamMembers.length }, () => false)
    ); // Close all project manager dropdowns
    try {
      setIsSubmitLoading(true);
      setIsProjMangSubmitLoading(true);
      await updateTeamMembers.mutateAsync({
        id,
        projectManagers: selectedUser,
        previousProjectManager: previousSelectedUser,
      });

      toast.success("Client team members updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Client",
        `Client (${clientView.client_name}) updated successfully`
      );
      refetchClientProfiles();
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
      setIsProjMangSubmitLoading(false);
      setUserDropdownOpenAccountManager(false);
    }
  };

  const handleTeamMemberRemoval = async (index, selectedUser) => {
    const updatedTeamMembers = [...selectedUserTeamMembers];
    updatedTeamMembers.splice(index, 1);
    setSelectedUserTeamMembers(updatedTeamMembers);
    try {
      setIsSubmitLoading(true);
      await removeTeamMember.mutateAsync({
        id,
        teamMember: selectedUser,
      });

      toast.success("Client team member removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Client",
        `Client (${clientView.client_name}) updated successfully`
      );
      refetchClientProfiles();
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
      setUserDropdownOpenAccountManager(false);
    }
  };

  const addTeamMember = () => {
    setSelectedUserTeamMembers((prevState) => [...prevState, null]);
    setUserDropdownOpenTeamMembers((prevState) => [...prevState, false]);
    userDropdownRefsTeamMembers.current.push(null);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideAccManagerSelectDropdown =
      userDropdownRefAccountManager.current &&
      userDropdownRefAccountManager.current.contains(event.target);
    // const isClickInsideProjMangSelectDropdown = userDropdownRefsTeamMembers.current && userDropdownRefsTeamMembers.current.contains(event.target);
    userDropdownRefsTeamMembers.current.forEach((ref, index) => {
      if (ref && !ref.contains(event.target)) {
        setUserDropdownOpenTeamMembers((prevState) => {
          const newState = [...prevState];
          newState[index] = false;
          return newState;
        });
      }
    });
    // const isClickInsideContactPointSelectDropdown = contactPointSelectDropdownRef.current && contactPointSelectDropdownRef.current.contains(event.target);

    if (!isClickInsideAccManagerSelectDropdown) {
      setUserDropdownOpenAccountManager(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <div className="client-setup-outer">
      <div className="text-arrow-outer">
        <div className="text-arrow-inner">
          <Link
            to={`/client/details/${encodeURIComponent(
              AES.encrypt(id, "encryptionKey").toString().replace(/\//g, "%2F")
            )}`}
          >
            <div className="text-arrow">
              <div className="left-arrow">
                <img src={LeftArrow} alt="left-arrow" />
              </div>
              <div className="top-text">Back to Overview</div>
            </div>
          </Link>
        </div>
      </div>
      <div className="setup-box-outer">
        <div className="account-box-outer">
          <div className="account-box-title">Account Manager</div>
          <div className="account-box">
            <div className="member-name-outer">
              <div className="member-label-outer">
                <div className="member-label-icon">
                  <img src={SingleUser} alt="single-user" />
                </div>
                <div className="member-label-text">User Name</div>
              </div>
              <div className="account-box-section">
                <div className="custom-dropdown1">
                  {isLoadingUser && isLoading ? (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div
                      ref={userDropdownRefAccountManager}
                      className={`custom-dropdown-user-new-outer custom-dropdown-select ${
                        userDropdownOpenAccountManager ? "open" : ""
                      }`}
                    >
                      <div
                        className="selected-option custom-dropdown-user-new"
                        onClick={toggleUserDropdownAccountManager}
                      >
                        {accManager && (
                          <span className="user-image-set">
                            {/* <img src={profile_image} alt="user" /> */}
                            {userIds?.find((user) => user._id === accManager)
                              ?.profile_image ? (
                              <img
                                src={`/uploads/lowProfileImage/${
                                  userIds?.find(
                                    (user) => user._id === accManager
                                  )?.profile_image
                                }`}
                                alt={
                                  userIds?.find(
                                    (user) => user._id === accManager
                                  )?.first_name ? (
                                    <>
                                      {
                                        userIds?.find(
                                          (user) => user._id === accManager
                                        )?.first_name
                                      }
                                    </>
                                  ) : (
                                    <>
                                      {
                                        userIds.find(
                                          (user) => user._id === accManager
                                        )?.company_name
                                      }
                                    </>
                                  )
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "/uploads/lowProfileImage/user_default.png";
                                }}
                              />
                            ) : (
                              <img
                                src={profile_image}
                                alt={
                                  userIds?.find(
                                    (user) => user._id === accManager
                                  )?.first_name ? (
                                    <>
                                      {
                                        userIds?.find(
                                          (user) => user._id === accManager
                                        )?.first_name
                                      }
                                    </>
                                  ) : (
                                    <>
                                      {
                                        userIds.find(
                                          (user) => user._id === accManager
                                        )?.company_name
                                      }
                                    </>
                                  )
                                }
                              />
                            )}
                          </span>
                        )}
                        {accManager ? (
                          userIds?.find((user) => user._id === accManager)
                            ?.first_name ? (
                            <>
                              {
                                userIds?.find((user) => user._id === accManager)
                                  ?.first_name
                              }{" "}
                              {userIds?.find((user) => user._id === accManager)
                                ?.middle_name &&
                              userIds?.find((user) => user._id === accManager)
                                ?.middle_name !== "null"
                                ? userIds?.find(
                                    (user) => user._id === accManager
                                  )?.middle_name + " "
                                : ""}
                              {
                                userIds?.find((user) => user._id === accManager)
                                  ?.last_name
                              }{" "}
                            </>
                          ) : (
                            <>
                              {userIds.find((user) => user._id === accManager)
                                ?.company_first_name
                                ? `${
                                    userIds.find(
                                      (user) => user._id === accManager
                                    )?.company_first_name
                                  } ${
                                    userIds.find(
                                      (user) => user._id === accManager
                                    )?.company_last_name
                                      ? userIds.find(
                                          (user) => user._id === accManager
                                        )?.company_last_name
                                      : ""
                                  }`
                                : `${
                                    userIds.find(
                                      (user) => user._id === accManager
                                    )?.company_name || ""
                                  }(Company)`}
                            </>
                          )
                        ) : (
                          "Select User"
                        )}
                      </div>
                      {userDropdownOpenAccountManager && (
                        <div className="custom-dropdown-new">
                          <input
                            type="text"
                            className="search-bar"
                            placeholder={global.config.locate(
                              appSettings && appSettings.language,
                              "Search"
                            )}
                            value={userSearchTerm}
                            onChange={handleAccManagerSearchInputChange}
                          />
                          <ul className="type-list">
                            {filteredUsers.length > 0 &&
                              filteredUsers
                                .slice()
                                .sort((a, b) => {
                                  const nameA = a.first_name
                                    ? `${a.first_name} ${a.middle_name || ""} ${
                                        a.last_name
                                      }`
                                    : `${
                                        a.company_first_name
                                          ? a.company_first_name +
                                            (a.company_last_name &&
                                              a.company_last_name)
                                          : a.company_name
                                      }`;
                                  const nameB = b.first_name
                                    ? `${b.first_name} ${b.middle_name || ""} ${
                                        b.last_name
                                      }`
                                    : `${
                                        b.company_first_name
                                          ? b.company_first_name +
                                            (b.company_last_name &&
                                              b.company_last_name)
                                          : b.company_name
                                      }`;
                                  return nameA.localeCompare(nameB);
                                })
                                .map((item) => (
                                  <li
                                    key={item._id}
                                    className={
                                      accManager === item._id
                                        ? "selected-item"
                                        : ""
                                    }
                                    onClick={() =>
                                      selectUserOptionAccountManager(item._id)
                                    }
                                  >
                                    <span className="user-image-set">
                                      {item.profile_image ? (
                                        <img
                                          src={`/uploads/lowProfileImage/${item.profile_image}`}
                                          alt={
                                            item.first_name || item.company_name
                                          }
                                          className="member-image"
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                              "/uploads/lowProfileImage/user_default.png";
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={profile_image}
                                          alt={
                                            item.first_name || item.company_name
                                          }
                                          className="member-image"
                                        />
                                      )}
                                    </span>
                                    {item.first_name ? (
                                      <>
                                        {item.first_name}{" "}
                                        {item.middle_name &&
                                        item.middle_name !== "null"
                                          ? item.middle_name + " "
                                          : ""}
                                        {item.last_name}{" "}
                                      </>
                                    ) : (
                                      <>
                                        {item.company_first_name
                                          ? `${item.company_first_name} ${
                                              item.company_last_name
                                                ? item.company_last_name
                                                : ""
                                            }`
                                          : `${
                                              item.company_name || ""
                                            }(Company)`}
                                      </>
                                    )}
                                  </li>
                                ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="account-box-outer project-manager-outer">
          <div className="account-box-title">Team Members</div>
          {selectedUserTeamMembers.map((selectedUser, index) => (
            <div key={index} className="account-box">
              {/* Project Manager dropdown and content */}
              <div className="member-name-outer">
                <div className="member-label-outer">
                  <div className="member-label-icon">
                    <img src={SingleUser} alt="single-user" />
                  </div>
                  <div className="member-label-text">User Name</div>
                </div>
                <div className="account-box-section">
                  <div className="custom-dropdown1">
                    {teamMemberLoading && isLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div
                        ref={(el) =>
                          (userDropdownRefsTeamMembers.current[index] = el)
                        }
                        className={`custom-dropdown-user-new-outer custom-dropdown-select ${
                          userDropdownOpenTeamMembers[index] ? "open" : ""
                        }`}
                      >
                        <div
                          className={
                            selectedUser !== accManager
                              ? "selected-option custom-dropdown-user-new"
                              : "selected-option custom-dropdown-user-new"
                          }
                          onClick={() => toggleUserDropdownTeamMember(index)}
                        >
                          {selectedUser && (
                            <span className="user-image-set">
                              {/* <img src={profile_image} alt="user" /> */}
                              {teamMemberList?.find(
                                (user) => user._id === selectedUser
                              )?.profile_image ? (
                                <img
                                  src={`/uploads/lowProfileImage/${
                                    teamMemberList?.find(
                                      (user) => user._id === selectedUser
                                    )?.profile_image
                                  }`}
                                  alt={
                                    teamMemberList?.find(
                                      (user) => user._id === selectedUser
                                    )?.first_name ? (
                                      <>
                                        {
                                          teamMemberList?.find(
                                            (user) => user._id === selectedUser
                                          )?.first_name
                                        }
                                      </>
                                    ) : (
                                      <>
                                        {
                                          teamMemberList?.find(
                                            (user) => user._id === selectedUser
                                          )?.company_name
                                        }
                                      </>
                                    )
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "/uploads/lowProfileImage/user_default.png";
                                  }}
                                />
                              ) : (
                                <img
                                  src={profile_image}
                                  alt={
                                    teamMemberList?.find(
                                      (user) => user._id === selectedUser
                                    )?.first_name ? (
                                      <>
                                        {
                                          teamMemberList?.find(
                                            (user) => user._id === selectedUser
                                          )?.first_name
                                        }
                                      </>
                                    ) : (
                                      <>
                                        {
                                          teamMemberList.find(
                                            (user) => user._id === selectedUser
                                          )?.company_name
                                        }
                                      </>
                                    )
                                  }
                                />
                              )}
                            </span>
                          )}
                          {selectedUser ? (
                            teamMemberList?.find(
                              (user) => user._id === selectedUser
                            )?.first_name ? (
                              <>
                                {
                                  teamMemberList?.find(
                                    (user) => user._id === selectedUser
                                  )?.first_name
                                }{" "}
                                {teamMemberList?.find(
                                  (user) => user._id === selectedUser
                                )?.middle_name &&
                                teamMemberList?.find(
                                  (user) => user._id === selectedUser
                                )?.middle_name !== "null"
                                  ? teamMemberList?.find(
                                      (user) => user._id === selectedUser
                                    )?.middle_name + " "
                                  : ""}
                                {
                                  teamMemberList?.find(
                                    (user) => user._id === selectedUser
                                  )?.last_name
                                }{" "}
                              </>
                            ) : (
                              <>
                                {teamMemberList.find(
                                  (user) => user._id === selectedUser
                                )?.company_first_name
                                  ? `${
                                      teamMemberList.find(
                                        (user) => user._id === selectedUser
                                      )?.company_first_name
                                    } ${
                                      teamMemberList.find(
                                        (user) => user._id === accManager
                                      )?.company_last_name
                                        ? teamMemberList.find(
                                            (user) => user._id === selectedUser
                                          )?.company_last_name
                                        : ""
                                    }`
                                  : `${
                                      teamMemberList.find(
                                        (user) => user._id === selectedUser
                                      )?.company_name || ""
                                    }(Company)`}
                              </>
                            )
                          ) : (
                            "Select User"
                          )}
                        </div>
                        {selectedUser !== accManager &&
                          userDropdownOpenTeamMembers[index] && (
                            <div className="custom-dropdown-new">
                              <input
                                type="text"
                                className="search-bar"
                                placeholder={global.config.locate(
                                  appSettings && appSettings.language,
                                  "Search"
                                )}
                                value={teamMemberSearchTerm}
                                onChange={handleTeamMemberSearchInputChange}
                              />
                              <ul className="type-list">
                                {filteredTeamMembers.length > 0 &&
                                  filteredTeamMembers
                                    .filter(
                                      (item) =>
                                        !selectedUserTeamMembers.includes(
                                          item._id
                                        )
                                    )
                                    .slice()
                                    .sort((a, b) => {
                                      const nameA = a.first_name
                                        ? `${a.first_name} ${
                                            a.middle_name || ""
                                          } ${a.last_name}`
                                        : `${
                                            a.company_first_name
                                              ? a.company_first_name +
                                                (a.company_last_name &&
                                                  a.company_last_name)
                                              : a.company_name
                                          }`;
                                      const nameB = b.first_name
                                        ? `${b.first_name} ${
                                            b.middle_name || ""
                                          } ${b.last_name}`
                                        : `${
                                            b.company_first_name
                                              ? b.company_first_name +
                                                (b.company_last_name &&
                                                  b.company_last_name)
                                              : b.company_name
                                          }`;
                                      return nameA.localeCompare(nameB);
                                    })
                                    .map((item) => (
                                      <li
                                        onClick={() =>
                                          handleTeamMemberSelection(
                                            index,
                                            item._id
                                          )
                                        }
                                      >
                                        <span className="user-image-set">
                                          {item.profile_image ? (
                                            <img
                                              src={`/uploads/lowProfileImage/${item.profile_image}`}
                                              alt={
                                                item.first_name ||
                                                item.company_name
                                              }
                                              className="member-image"
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                  "/uploads/lowProfileImage/user_default.png";
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={profile_image}
                                              alt={
                                                item.first_name ||
                                                item.company_name
                                              }
                                              className="member-image"
                                            />
                                          )}
                                        </span>
                                        {item.first_name ? (
                                          <>
                                            {item.first_name}{" "}
                                            {item.middle_name &&
                                            item.middle_name !== "null"
                                              ? item.middle_name + " "
                                              : ""}
                                            {item.last_name}{" "}
                                          </>
                                        ) : (
                                          <>
                                            {item.company_first_name
                                              ? `${item.company_first_name} ${
                                                  item.company_last_name
                                                    ? item.company_last_name
                                                    : ""
                                                }`
                                              : `${
                                                  item.company_name || ""
                                                }(Company)`}
                                          </>
                                        )}
                                      </li>
                                    ))}
                              </ul>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                  {selectedUser !== accManager && (
                    <button
                      className="remove-user-btn remove-user-btn-client"
                      onClick={() =>
                        handleTeamMemberRemoval(index, selectedUser)
                      }
                      disabled={isSubmitLoading}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="22"
                        viewBox="0 0 20 22"
                        fill="none"
                      >
                        <path
                          d="M16.9576 19.6445L17.4362 4.37763H19.577V3.85209H14.0107V2.45942C14.0107 1.69739 13.4314 1.09302 12.701 1.09302H7.96587C7.23545 1.09302 6.65615 1.69739 6.65615 2.45942V3.85209H1.08984V4.37763H3.23073L3.70928 19.6445C3.73447 20.354 4.28858 20.9058 4.96863 20.9058H15.6731C16.3783 20.9058 16.9324 20.354 16.9576 19.6445ZM7.15989 2.45942C7.15989 1.98643 7.51251 1.61856 7.96587 1.61856H12.701C13.1544 1.61856 13.507 1.98643 13.507 2.45942V3.85209H7.15989V2.45942ZM4.21302 19.6182L3.73447 4.37763H16.9324L16.4539 19.6182C16.4287 20.0387 16.1012 20.3803 15.6983 20.3803H4.96863C4.56564 20.3803 4.23821 20.0387 4.21302 19.6182Z"
                          fill="#405BFF"
                          stroke="#282828"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M15.6736 21H4.96874C4.23877 21 3.64643 20.4064 3.61944 19.6486L3.14359 4.4723H1V3.75907H6.56631V2.46024C6.56631 1.64144 7.18114 1 7.96598 1H12.7011C13.486 1 14.1008 1.64144 14.1008 2.46024V3.75907H19.6671V4.4723H17.5231L17.0504 19.5515H17.0508L17.0472 19.6486C17.0202 20.4064 16.4166 21 15.6732 21H15.6736ZM1.17991 4.28414H3.3181L3.79935 19.6416C3.82273 20.2976 4.33637 20.8118 4.96874 20.8118H15.6736C16.3267 20.8118 16.8376 20.3149 16.8673 19.6538V19.6416L17.349 4.28414H19.4872V3.9463H13.9209V2.45978C13.9209 1.74608 13.3852 1.18722 12.7011 1.18722H7.96598C7.28189 1.18722 6.74622 1.74608 6.74622 2.45978V3.9463H1.17991V4.28414ZM4.96874 20.4745C4.52347 20.4745 4.15196 20.101 4.12363 19.6242V19.6214L3.64193 4.28367H17.0256L16.5439 19.6214C16.5151 20.101 16.1441 20.474 15.6984 20.474H4.96874V20.4745ZM4.30308 19.6139C4.32647 19.9912 4.61837 20.2868 4.96874 20.2868H15.6984C16.0492 20.2868 16.3415 19.9907 16.364 19.6125L16.8394 4.47137H3.82723L4.30263 19.6134L4.30308 19.6139ZM13.5971 3.9463H7.07005V2.45978C7.07005 1.93565 7.46359 1.52507 7.96598 1.52507H12.7011C13.2035 1.52507 13.5971 1.93565 13.5971 2.45978V3.9463ZM7.24995 3.7586H13.4172V2.45978C13.4172 2.04075 13.1028 1.71276 12.7011 1.71276H7.96598C7.56434 1.71276 7.24995 2.04075 7.24995 2.45978V3.7586Z"
                          fill="#405BFF"
                          stroke="#282828"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M10.0811 8.37158H10.5848V16.2284H10.0811V8.37158Z"
                          fill="#405BFF"
                          stroke="#282828"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M10.6749 16.3223H9.99121V8.27783H10.6749V16.3223ZM10.1711 16.1346H10.4949V8.46552H10.1711V16.1346Z"
                          fill="#405BFF"
                          stroke="#282828"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M7.05859 8.37158H7.56233V16.2284H7.05859V8.37158Z"
                          fill="#405BFF"
                          stroke="#282828"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M7.65239 16.3223H6.96875V8.27783H7.65239V16.3223ZM7.14866 16.1346H7.47249V8.46552H7.14866V16.1346Z"
                          fill="#405BFF"
                          stroke="#282828"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M13.1045 8.37158H13.6082V16.2284H13.1045V8.37158Z"
                          fill="#405BFF"
                          stroke="#282828"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M13.6983 16.3223H13.0146V8.27783H13.6983V16.3223ZM13.1946 16.1346H13.5184V8.46552H13.1946V16.1346Z"
                          fill="#405BFF"
                          stroke="#282828"
                          strokeWidth="0.5"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="add-row-button">
            <button onClick={addTeamMember} disabled={isSubmitLoading}>
              {isProjMangSubmitLoading ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <circle
                      cx="11.9551"
                      cy="12.0449"
                      r="11.9551"
                      fill="#6479F8"
                    />
                    <path
                      d="M11.7637 6V17.5281"
                      stroke="white"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M17.5273 11.7641L5.9992 11.7641"
                      stroke="white"
                      strokeWidth="1.5"
                    />
                  </svg>
                  Add Row
                </>
              )}
            </button>
          </div>
        </div>

        {/* <div className="project-box"></div> */}
      </div>
    </div>
  );
};

export default Setup;
