import React, { PureComponent, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, } from "recharts";
import { requestHistoryComparison } from './Services/reportTimeOffRequest';

// const data = [
//   {
//     name: "January",
//     currentYear: 12000,
//     lastYear: 42000,
//     amt: 2400,
//   },
//   {
//     name: "February",
//     currentYear: 15000,
//     lastYear: 50000,
//     amt: 2210,
//   },
//   {
//     name: "March",
//     currentYear: 40000,
//     lastYear: 25000,
//     amt: 2290,
//   },
//   {
//     name: "April",
//     currentYear: 75000,
//     lastYear: 12000,
//     amt: 2000,
//   },
//   {
//     name: "May",
//     currentYear: 58000,
//     lastYear: 25000,
//     amt: 2181,
//   },
//   {
//     name: "June",
//     currentYear: 75000,
//     lastYear: 60000,
//     amt: 2500,
//   },
//   {
//     name: "July",
//     currentYear: 47000,
//     lastYear: 40000,
//     amt: 2100,
//   },
//   {
//     name: "August",
//     currentYear: 58000,
//     lastYear: 25000,
//     amt: 2100,
//   },
//   {
//     name: "September",
//     currentYear: 40000,
//     lastYear: 40000,
//     amt: 2100,
//   },
//   {
//     name: "October",
//     currentYear: 30000,
//     lastYear: 25000,
//     amt: 2100,
//   },
//   {
//     name: "November",
//     currentYear: 30000,
//     lastYear: 40000,
//     amt: 2100,
//   },
//   {
//     name: "December",
//     currentYear: 25000,
//     lastYear: 30000,
//     amt: 2100,
//   },
// ];

const RequestHistoryGraph = ({ timeoffReq, timeoffReqLoading, timeoffReqError }) => {
  const reportingTimeoffRequest = useSelector((state) => state.reportingTimeoffRequest);

  const processedData = useMemo(() => {
    return requestHistoryComparison(
      timeoffReq,
      reportingTimeoffRequest,
    );
  }, [timeoffReq, reportingTimeoffRequest]);

  const currentYear = reportingTimeoffRequest.timeoffReqYear || new Date().getFullYear();
  const previousYear = currentYear - 1;
  const isCurrentYear = currentYear === new Date().getFullYear();

  if (timeoffReqLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (timeoffReqError) return <div>Error loading data.</div>;
  return (
    <div style={{ width: "100%", height: 542 }}>
      <ResponsiveContainer>
        <LineChart
          layout="horizontal"
          data={processedData}
          margin={{
            top: 20,
            right: 30,
            left: -15,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            type="category"
            padding={{ left: 50 }}
            tickMargin={20}
          />
          <YAxis type="number" />
          <Tooltip />
          <Legend />
          <Line
            dataKey="currentYear"
            name={isCurrentYear ? `Current Year (${currentYear})` : `Selected Year (${currentYear})`}
            stroke="#96A8BA"
          />
          <Line dataKey="lastYear" name={isCurrentYear ? `Last Year (${previousYear})` : `Previous Year (${previousYear})`} stroke="#6479F8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RequestHistoryGraph;
