import "./newBank.css";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Currencies from "../../../constants/currencies";
import React, { useEffect, useState, useRef } from 'react';
import { ThreeDots } from "react-loader-spinner";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { setBankFetchUpdate } from '../../../features/bankFetchUpdate/bankFetchUpdateSlice';
import { getBankFromCompany } from "../../Invoices/InvoicesSingle/services/invoiceSingle.js";

const NewBank = (props) => {

  const dispatch = useDispatch();
  const [currency, setCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [req, setReq] = useState([]);
  const [type, setType] = useState(null);
  const [fields, setFields] = useState([])
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [accountHolderName, setAccountHolderName] = useState(props.userName);
  const [wxCom, setWxCom] = useState(null);
  const [wiseCompany, setWiseCompany] = useState([]);
  // const [currencySearchTerm, setCurrencySearchTerm] = useState('');
  // const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  // const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  // const currencyDropdownRef = useRef(null);
  // const typeDropdownRef = useRef(null);
  const navigate = useNavigate();
  const token = useSelector(
    (state) => state.auth.token
  )
  const wxCompany = props?.userView?.user_wx_comapny?.wx_company;

  // setWxCom(wxCompany);

  useEffect(() => {

    async function getUserWiseProfile() {
      await axios
        .post(
          "/api/wise/user-wise-profile",
          {
            userId: `${props?.userView?._id}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setWiseCompany(response?.data?.id);
        });
    }

   
      getUserWiseProfile();
    


  }, [props?.userView]);

  useEffect(() => {

    const wxCompany = props?.userView?.user_wx_comapny?.wx_company;
    if (wxCompany === "WX") {
      setWxCom(14760922);
    } else {

      setWxCom(10873693);
    }


    global.config.activityLog(window.location.href, 'Bank', `Entered into add bank page`);
    setCurrency(Currencies.sourceCurrencies[0].targetCurrencies);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

  }, []);

  // const handleCurrencyChange = (currencyCode) => {
  //   setSelectedCurrency(currencyCode);
  //   fetchBankRequirments(currencyCode);
  //   setType([]);
  //   setReq([]);
  //   setFields([]);
  // };

  // const handleCurrencySearchInputChange = (e) => {
  //   setCurrencySearchTerm(e.target.value);
  // };

  // const filteredOptions = currency.filter((option) =>
  //   option.currencyCode.toLowerCase().includes(currencySearchTerm.toLowerCase())
  // );

  // const handleTypeChange = (selectedType) => {
  //   setType(selectedType);
  //   fetchRequirments(selectedType, req);
  // };

  // const handleClickOutsideDropdown = (event) => {
  //   const isClickInsideCurrencyDropdown = currencyDropdownRef.current && currencyDropdownRef.current.contains(event.target);
  //   const isClickInsideTypeDropdown = typeDropdownRef.current && typeDropdownRef.current.contains(event.target);
  //   if (!isClickInsideCurrencyDropdown) {
  //     setCurrencyDropdownOpen(false);
  //   }
  //   if (!isClickInsideTypeDropdown) {
  //     setTypeDropdownOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutsideDropdown);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutsideDropdown);
  //   };
  // }, []);

  async function fetchBankRequirments(currency) {
    setErrors([]);
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://api.sandbox.transferwise.tech/v1/account-requirements?source=USD&target=${currency}&sourceAmount=1000`
      };
      await axios.request(config)
        .then(async (response) => {
          setReq(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error('Something went worng in fetching bank details !', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  async function fetchRequirments(getType, getReq) {

    setErrors([]);
    getReq.map((item) => {

      if (item.type === getType) {
        setFields(item.fields);
      }
    });
  }

  async function submitBank(event) {
    event.preventDefault();

    try {

      var a = document.forms["bankForm"].getElementsByTagName("input");
      var b = document.forms["bankForm"].getElementsByTagName("select");
      var c = document.forms["bankForm"].getElementsByTagName("radio");
      var inputs = {};

      for (let [key, value] of Object.entries(a)) {
        var fieldName = value.name;
        inputs[fieldName] = event.target[fieldName].value;
      }

      // inputs['accountHolderName'] = event.target[accountHolderName];
      inputs['email'] = props.userEmail;

      for (let [key, value] of Object.entries(b)) {
        var fieldName = value.name;
        inputs[fieldName] = event.target[fieldName].value;
      }

      for (let [key, value] of Object.entries(c)) {
        var fieldName = value.name;
        inputs[fieldName] = event.target[fieldName].value;
      }

      inputs['wxCompany'] = wxCom;

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/api/wise/add-new-bank',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: inputs
      };
      setIsLoading(true);
      axios.request(config)
        .then(async (response) => {

          if (response.data === true) {
            toast.success('New bank account added successfully!', {
              position: 'bottom-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            global.config.activityLog(
              window.location.href,
              "User Bank",
              `A new bank account for ${props && props.userName}'s has been successfully added.`
            );
            setSelectedCurrency(null)

            setReq([]);
            setType(null);
            setFields([]);
            setErrors([]);
            await dispatch(setBankFetchUpdate());
            props.closeCreateModal();
          } else {

            setErrors(response.data.message);
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          global.config.slackMessage(error.toString());
        });

    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error('Something went worng adding the bank!', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      global.config.activityLog(
        window.location.href,
        "User Bank",
        `Something went worng while adding the bank for ${props && props.userName}`
      );
    } finally {

    }
  }

  return (
    <div className='bank-add-form'>

      {wxCompany === "Any" && (
        <div className="form-field-client">
          <label htmlFor="Type" className="form-label">
            Company
          </label>
          <select
            id="country"
            name="currency"
            className="form-select"
            value={wxCom}
            onChange={(e) => {
              setWxCom(e.target.value);

            }}
            required
          >
            <option value="">Select Company</option>
            {wiseCompany && wiseCompany.map((item) => {

              return (<option value={item.id}>{item.fullName}</option>);

            })}


          </select>
        </div>)}


      <form autoComplete="off" name="bankForm" onSubmit={submitBank}>
       
        <div>
          <div className="rounded-t mb-0 px-6 py-6">
            {errors.map((item, index) => (
              <p style={{ color: "red" }}>{index + 1} . {item.message}</p>
            ))}
          </div>
          <div className="form-field-client">
            <label htmlFor="accountHolderName" className="form-label">
              Account Holder Name (Firstname  Lastname)
            </label>
            <input
              id="accountHolderName"
              name="accountHolderName"
              className="form-input"
              autoComplete="off"
              value={accountHolderName}
              onChange={(e) => {

                if (!/\d|\./.test(e.target.value)) {
                  setAccountHolderName(e.target.value);
                }

              }}
              required
            />
          </div>
          <div className="form-field-client">
            <label htmlFor="countryCode" className="form-label">
              Currency Code
            </label>
            <select
              id="country"
              name="currency"
              className="form-select"
              autoComplete="off"
              value={selectedCurrency}
              onChange={(e) => {
                setSelectedCurrency(e.target.value)
                fetchBankRequirments(e.target.value);
                setType([]);
                setReq([]);
                setFields([]);
              }}
              required
            >
              <option value="">Select Currency</option>
              {currency.length > 0 ? (
                <>
                  {currency.map((item, index) => (
                    <option key={Math.random().toString().replace("0.", "")} value={item.currencyCode}>{item.currencyCode}</option>
                  ))}
                </>
              ) : (
                <option>---</option>
              )}
            </select>

          </div>
          <div className="form-field-client">
            <label htmlFor="Type" className="form-label">
              Type
            </label>
            <select
              id="type"
              name="type"
              className="form-select"
              autoComplete="off"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
                fetchRequirments(e.target.value, req);
              }}
              required
            >
              <option value="">Select Type of Account</option>
              {req.length > 0 ? (
                <>
                  {req.map((item, index) =>
                  (
                    <option value={item.type} key={Math.random().toString().replace("0.", "")}>{item.title}</option>
                  )
                  )}
                </>
              ) : (
                <option>--</option>
              )}
            </select>

          </div>
          {fields.length > 0 ? (
            <>
              {fields.map((fieldItem) =>
              (
                <div key={Math.random().toString().replace("0.", "")}>
                  <div className="form-field-client">
                    <label className="form-label" htmlFor="grid-password">
                      {fieldItem.name}{"  "}{fieldItem.group[0].required && "*"}
                    </label>
                    <>
                      {fieldItem.group[0].type === 'select' &&
                        <select autoComplete="off" name={fieldItem.group[0].key.replaceAll('.', '_')} className="form-select" >

                          {fieldItem.group[0].valuesAllowed.map((item) => (

                            <option key={Math.random().toString().replace("0.", "")} value={item.key}>{item.name}</option>
                          ))};
                        </select>

                      }
                      {fieldItem.group[0].type === 'text' &&
                        <input autoComplete="off" name={fieldItem.group[0].key.replaceAll('.', '_')} className="form-input" type="text" placeholder={fieldItem.group[0].name} />
                      }
                      {fieldItem.group[0].type === 'date' &&
                        <input autoComplete="off" name={fieldItem.group[0].key.replaceAll('.', '_')} className="form-input" type="date" placeholder={fieldItem.group[0].name} />
                      }
                      {fieldItem.group[0].type === 'radio' &&
                        <>
                          <div className='form-field-client-radio-outer'>
                            {fieldItem.group[0].valuesAllowed.map((item) => (
                              <>
                                <div className='form-field-client'>
                                  <label key={Math.random().toString().replace("0.", "")} className="form-label" >{item.name}</label>
                                  <input autoComplete="off" key={Math.random().toString().replace("0.", "")} type="radio" name={fieldItem.group[0].key.replaceAll('.', '_')} value={item.key} required />
                                </div>
                              </>
                            ))}
                          </div>
                        </>
                      }
                    </>
                  </div>
                </div>
              )
              )}
            </>
          ) : (
            <p className='hide-this'></p>
          )}
          <button type="submit" disabled={isLoading || (wxCom && wxCom === "Any") || (wxCom && wxCom === "")}>
            {isLoading ?
              <div className="outter-load-table">
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div> : "Submit"}


          </button>
        </div>
      </form>
    </div>
  )
}

export default NewBank;