import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { reportingFilterDateRangesConst } from '../../../../../constants/reportingFilterDateRangesConst';
import { reportingFilterComparisonConst } from '../../../../../constants/reportingFilterComparisonConst';

// Functional component
function CostPerServiceFilterGraphFilter({
    selectedDateRange,
    selectedComparison,
    selectedFrequency,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth
}) {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    const getComparisonTitle = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
        const rangeTitles = {
            [reportingFilterDateRangesConst.This_Year]: `Current Year (${currentYear})`,
            [reportingFilterDateRangesConst.This_Quarter]: `This Quarter`,
            [reportingFilterDateRangesConst.First_Half]: `First Half`,
            [reportingFilterDateRangesConst.This_Month]: `This Month`,
            [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
            [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
        };

        const comparisonTitles = {
            [reportingFilterComparisonConst.Last_Year]: `Last Year (${previousYear})`,
            [reportingFilterComparisonConst.Last_Quarter]: `Last Quarter`,
            [reportingFilterComparisonConst.Second_Half]: `Second Half`,
            [reportingFilterComparisonConst.Last_Month]: `Last Month`,
            [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
            [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
        };

        return isCurrent ? rangeTitles[selectedRange] : comparisonTitles[selectedComparison];
    };
    return (
        <div className='top-outer-graph-report top-hours-per-client'>
            <div className="top-inner-graph">
                <div className="graph-title">
                    <div className='comparison-type'>
                        <div className='comparison-type-inner'>
                            <div className='comparison-type-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                    <circle cx="3" cy="3" r="3" transform="matrix(-1 0 0 1 6 0)" fill="#86ABE1" />
                                </svg>
                            </div>
                            <div className='comparison-type-title'>
                                {getComparisonTitle(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)}
                            </div>
                        </div>
                        <div className='comparison-type-inner'>
                            <div className='comparison-type-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                    <circle cx="3" cy="3" r="3" transform="matrix(-1 0 0 1 6 0)" fill="#C7B6F6" />
                                </svg>
                            </div>
                            <div className='comparison-type-title'>
                                {getComparisonTitle(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="icon-graph">
                    <div className="filter-comparison-graph">
                        <a >YEAR / MONTH</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CostPerServiceFilterGraphFilter;