import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import TruncateFilterFieldView from "../../../helpers/truncateFilterFieldView.js";
import DownloadImage from "../../../../images/icons/download-icon.svg";
import Currencies from "../../../../constants/currenciesList.js";
import {
  setReportingExpCategory,
  setReportingExpCurrency,
  setReportingExpYear,
} from "../../../../features/reporting/reportingFinancial/reportingExpenseSlice.js";
import { setDashboardDate, setDashboardCurrencyRx, setDashboardCurrencySum } from "../../../../features/invoice/invoiceSlice";
import { setForex } from "../../../../features/forex/forexSlice.js";

function ExpenseFilter({ expenseCategory, expenseData }) {
  const dispatch = useDispatch();
  const expCategoryDropdownRef = useRef(null);
  const currencyDropdownRef = useRef(null);
  const expCategoryModalDropdownRef = useRef(null);
  const currencyModalDropdownRef = useRef(null);

  const [isExpCategoryDropdownOpen, setIsExpCategoryDropdownOpen] =
    useState(false);
  const [isCurrencyDropdownOpen, setIsCurrencyDropdownOpen] = useState(false);
  const [isExpCategoryModalDropdownOpen, setIsExpCategoryModalDropdownOpen] =
    useState(false);
  const [isCurrencyModalDropdownOpen, setIsCurrencyModalDropdownOpen] =
    useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  // const [expCategoriesFilter, setExpCategoriesFilter] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredExpCategories, setFilteredExpCategories] = useState([]);
  const [selectAllExpCategory, setSelectAllExpCategory] = useState(true);
  const [selectedExpCategories, setSelectedExpCategories] = useState([]);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(new Date(currentYear, 0, 1));
  // const [currenciesFilter, setCurrenciesFilter] = useState("All");
  const [sortedCurrencies, setSortedCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [selectAllCurrency, setSelectAllCurrency] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const reportingFinancialExpense = useSelector(
    (state) => state.reportingExpense
  );
  const { appSettings } = useSelector((state) => state.appSettings);
  const [allCurrency, setAllCurrency] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const [selectedForex, setSelectedForex] = useState([]);

  // const dummyExpCategoryies = [
  //   { _id: 1, expCategory_name: 'ExpCategory A' },
  //   { _id: 2, expCategory_name: 'ExpCategory B' },
  //   { _id: 3, expCategory_name: 'ExpCategory C' }
  // ];

  const dummyCurrencies = [
    { _id: 1, currency: "INR" },
    { _id: 2, currency: "DOllar" },
    { _id: 3, currency: "EURO" },
    { _id: 4, currency: "MYR" },
  ];

  // const dummyRanges = [
  //   { label: 'Today', value: new Date() },
  //   { label: 'Yesterday', value: new Date(new Date().setDate(new Date().getDate() - 1)) },
  //   { label: 'Last 7 Days', value: new Date(new Date().setDate(new Date().getDate() - 7)) }
  // ];

  const handleCheckboxExpCategoryChange = (expCategoryId) => {
    const updatedSelection = [...selectedExpCategories];
    const index = updatedSelection.indexOf(expCategoryId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(expCategoryId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    // const selectedExpCategoryObjects = filteredExpCategories.filter((expCategory) =>
    //   updatedSelection.includes(expCategory._id)
    // );
    // const uniqueCurrencies = Array.from(
    //   new Set(selectedExpCategoryObjects.map((expCategory) => expCategory.preferred_currency))
    // );
    // setSortedCurrencies(uniqueCurrencies);
    // setSelectedCurrency(uniqueCurrencies);
    // selectCurrency(uniqueCurrencies);
    setSelectedExpCategories(updatedSelection);
  };

  const handleSelectAllExpCategoryChange = () => {
    if (!selectAllExpCategory) {
      const allExpCategoryIds = filteredExpCategories.map(
        (expCategory) => expCategory._id
      );
      setSelectedExpCategories(allExpCategoryIds);

      // Update sorted currencies based on all filteredExpCategories
      // const uniqueCurrencies = Array.from(
      //   new Set(filteredExpCategories.map((expCategory) => expCategory.preferred_currency))
      // );
      // setSortedCurrencies(uniqueCurrencies);
      // setSelectedCurrency(uniqueCurrencies);
      // selectCurrency(uniqueCurrencies);
    } else {
      setSelectedExpCategories([]);
      // setSelectedCurrency([]);
      // setSortedCurrencies([]);
    }
    setSelectAllExpCategory(!selectAllExpCategory);
  };

  useEffect(() => {
    setSelectAllExpCategory(
      selectedExpCategories.length === filteredExpCategories.length
    );
    if (selectedExpCategories.length === filteredExpCategories.length) {
      // setExpCategoriesFilter("All");
      selectExpCategory(selectedExpCategories);
    } else {
      // setExpCategoriesFilter(selectedExpCategories);
      selectExpCategory(selectedExpCategories);
    }
  }, [selectedExpCategories, filteredExpCategories]);

  const handleCheckboxCurrencyChange = (currency) => {
    const updatedSelection = [...selectedCurrency];
    const index = updatedSelection.indexOf(currency);

    if (index === -1) {
      updatedSelection.push(currency);
    } else {
      updatedSelection.splice(index, 1);
    }
    setSelectedCurrency(updatedSelection);
  };

  const handleSelectAllCurrencyChange = () => {
    if (!selectAllCurrency) {
      setSelectedCurrency(
        sortedCurrencies.map((expCurrency) => expCurrency.currencyCode)
      );
    } else {
      setSelectedCurrency([]);
    }
    setSelectAllCurrency(!selectAllCurrency);
  };

  useEffect(() => {
    setSelectAllCurrency(selectedCurrency.length === sortedCurrencies.length);
    if (selectedCurrency.length === sortedCurrencies.length) {
      // setCurrenciesFilter("All");
      selectCurrency(selectedCurrency);
    } else {
      // setCurrenciesFilter(selectedCurrency);
      selectCurrency(selectedCurrency);
    }
  }, [selectedCurrency, expenseCategory]);

  const toggleExpCategoryDropdown = () => {
    setIsExpCategoryDropdownOpen(!isExpCategoryDropdownOpen);
  };

  const toggleCurrencyDropdown = () => {
    setIsCurrencyDropdownOpen(!isCurrencyDropdownOpen);
  };

  const toggleExpCategoryModalDropdown = () => {
    setIsExpCategoryModalDropdownOpen(!isExpCategoryModalDropdownOpen);
  };

  const toggleCurrencyModalDropdown = () => {
    setIsCurrencyModalDropdownOpen(!isCurrencyModalDropdownOpen);
  };

  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    setSelectedExpCategories(
      filteredExpCategories.map((expCategory) => expCategory._id)
    );
    setSelectedCurrency(
      sortedCurrencies.map((expCurrency) => expCurrency.currencyCode)
    );
    setSelectedDate(null);
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const checkAndShowConfirmationModal = () => {
    if (selectedExpCategories.length || selectedDate) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const selectExpCategory = async (expCategory) => {
    await dispatch(setReportingExpCategory(expCategory));
  };

  const selectYear = async (year) => {
    await dispatch(setReportingExpYear(year));
  };

  const selectCurrency = async (currency) => {
    await dispatch(setReportingExpCurrency(currency));
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const filteredExpenseCategories = expenseCategory.filter((expCat) =>
      expenseData.some((expense) => expense.exp_category._id === expCat._id)
    );
    setFilteredExpCategories(filteredExpenseCategories);
    setSelectedExpCategories(
      filteredExpenseCategories.map((expCategory) => expCategory._id)
    );
    // setSortedCurrencies(Currencies.sourceCurrencies[0].targetCurrencies);
    // setSelectedCurrency(Currencies.sourceCurrencies[0].targetCurrencies.map((expCurrency) => expCurrency.currencyCode));
    const firstSourceCurrency = Currencies.sourceCurrencies[0];
    if (firstSourceCurrency) {
      const filteredCurrencies = firstSourceCurrency.targetCurrencies.filter(
        (expCur) =>
          expenseData.some(
            (expense) => expense.exp_currency === expCur.currencyCode
          )
      );

      setSortedCurrencies(filteredCurrencies);
      setSelectedCurrency(
        filteredCurrencies.map((expCur) => expCur.currencyCode)
      );
    }
    selectYear(currentYear);
  }, [expenseCategory, expenseData]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideExpCategoryDropdown =
      expCategoryDropdownRef.current &&
      expCategoryDropdownRef.current.contains(event.target);
    const isClickInsideExpCategoryModalDropdown =
      expCategoryModalDropdownRef.current &&
      expCategoryModalDropdownRef.current.contains(event.target);
    const isClickInsideCurrencyDropdown =
      currencyDropdownRef.current &&
      currencyDropdownRef.current.contains(event.target);
    const isClickInsideCurrencyModalDropdown =
      currencyModalDropdownRef.current &&
      currencyModalDropdownRef.current.contains(event.target);

    if (!isClickInsideExpCategoryDropdown) {
      setIsExpCategoryDropdownOpen(false);
    }
    if (!isClickInsideExpCategoryModalDropdown) {
      setIsExpCategoryModalDropdownOpen(false);
    }
    if (!isClickInsideCurrencyDropdown) {
      setIsCurrencyDropdownOpen(false);
    }
    if (!isClickInsideCurrencyModalDropdown) {
      setIsCurrencyModalDropdownOpen(false);
    }
    // if (!isClickInsideCalendarModalDropdown) {
    //   setShowModalCalendar(false);
    // }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    if (expenseData && reportingFinancialExpense) {
      const filtered = expenseData
        .filter(
          (expense) =>
            !expense.is_delete &&
            reportingFinancialExpense.expCategory.includes(
              expense.exp_category._id
            ) &&
            new Date(expense.exp_date).getFullYear() ===
              reportingFinancialExpense.expYear &&
              reportingFinancialExpense.expCurrency && reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
        )
        .map((expense) => ({
          exp_id: expense.exp_id,
          exp_category_name: expense.exp_category.exp_category_name,
          client_name: expense.exp_client
            ? expense.exp_client.client_name ||
              expense.exp_client.legal_entity_name
            : "Non Billable",
          exp_date: expense.exp_date,
          exp_is_billable: expense.exp_is_billable ? "Yes" : "Non-Billable",
          amount: expense.exp_amount,
          currency: expense.exp_currency,
          created_at: expense.created_at,
        }));
      const sortedData = filtered.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setFilteredData(sortedData);
    }
  }, [expenseData, reportingFinancialExpense]);

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const modifiedLog = filteredData.map((item, index) => ({
        "Sl No": index + 1,
        "Expense ID": item.exp_id,
        Category: item.exp_category_name ? item.exp_category_name : "",
        "Client Name": item.client_name ? item.client_name : "",
        Date: moment(item.exp_date)
          .tz(global.config.getTimeZone())
          .format("DD/MM/YYYY"),
        "Is Billable": item.exp_is_billable,
        Amount: item.amount.toLocaleString("en-US", {
          style: "currency",
          currency: item.currency,
        }),
        "Created At": moment(item.created_at)
          .tz(global.config.getTimeZone())
          .format("DD/MM/YYYY, hh:mm:ss A"),
      }));

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);
      const colWidths = [
        { wpx: 25 },
        { wpx: 80 },
        { wpx: 105 },
        { wpx: 105 },
        { wpx: 90 },
        { wpx: 80 },
        { wpx: 95 },
        { wpx: 110 },
      ];
      worksheet["!cols"] = colWidths;
      // Add alignment to center align the first row and increase font size
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < colWidths.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center !important";
        style.alignment.vertical = "center !important";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }
      // Set border for active area and increase font size
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }
      global.config.activityLog(
        window.location.href,
        "Expense Report",
        "Expense report exported to Excel"
      );
      const fileName = `Expense_Report_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Expense Report");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Client Report",
        "Error occurred while exporting expense report datas to Excel"
      );
      alert("Error occurred while exporting expense report datas to Excel.");
    }
  };

  const sumAllCurrencies = async (val) => {
    setAllCurrency(val);
    setSelectedCurrency(global.config.appCurrency());
    await dispatch(setDashboardCurrencyRx(global.config.appCurrency()));
    await dispatch(setDashboardCurrencySum(val));
  }

  const getForex = async (currency) => {

    const response = await axios.post('/api/forex/get', { base_currency: "USD" }, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.status === 200) {
      return response.data ? response.data.json : null;
    } else {
      console.error(`Request failed with status code: ${response.status}`);
    }

  }


  return (
    <>
      <div className="report-top-button">
        <div className="button-inner-report button-inner-report-all-currency">
          <a onClick={exportToExcel}>
            <span className="icon-export">
              <img src={DownloadImage} alt="Export" />
            </span>
            Export Data
          </a>
          {/* sum of all currency */}

            <p className="all-currency-sec-inner">{global.config.locate(appSettings && appSettings.language, 'Sum all Currencies')}
              <input type="checkbox" checked={allCurrency && (sortedCurrencies.length ===selectedCurrency.length)} name="allCurrency" value={allCurrency} onChange={async (e) => {
                
                sumAllCurrencies(!allCurrency);
                if (!allCurrency === true) {
                 
                  setSelectedCurrency([sortedCurrencies]);
                  handleSelectAllCurrencyChange();
                  
                  

                } else {
                
                 
                  const forex = await getForex(appSettings && appSettings.currency);
                  setSelectedForex(forex);
                  await dispatch(setForex(forex));

                  setSelectedCurrency([global.config.appCurrency()]);
                 

                  // const allClientIds = [
                  //   ...new Set(
                  //     props.clients
                  //       .filter((client) => global.config.appCurrency() === client.preferred_currency
                  //       )
                  //       .map((client) => client._id)
                  //   ),
                  // ];

                  // setSelectedClients(allClientIds);
                  // // Update selected projects and sorted projects based on all clients
                  // const allProjects = props.project.filter((project) =>
                  //   allClientIds.includes(project.client_name._id)
                  // );
                  // const allProjectIds = allProjects.map((project) => project._id);

                  // setSelectedProject(allProjectIds);
                  // setSortedProjects(allProjects);
                  // selectProject(allProjectIds);
                  

                }

              }} />
            </p>


            {/* sum of all currency */}
        </div>
      </div>
      <div className="invoice-filter invoice-filter-report">
        <div className="invoice-filter-inner-section no-flter-all">
          <div className="filter-inner users">
            <label htmlFor="fname">Expense Type</label>
            <div className="custom-dropdown1" ref={expCategoryDropdownRef}>
              <div className="drop-down-inner">
                <div
                  className="selected-options1"
                  onClick={toggleExpCategoryDropdown}
                >
                  {selectedExpCategories.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <div
                      className="selected-option1"
                      key={selectedExpCategories[0]}
                    >
                      {selectedExpCategories.length ===
                      filteredExpCategories.length ? (
                        "All"
                      ) : (
                        <>
                          <TruncateFilterFieldView
                            textContent={
                              filteredExpCategories.find(
                                (item) => item._id === selectedExpCategories[0]
                              )?.exp_category_name
                            }
                          />
                          {selectedExpCategories.length > 1 &&
                            selectedExpCategories.length !==
                              filteredExpCategories.length && (
                              <div className="selected-option-count">
                                {selectedExpCategories.length - 1}+
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  )}
                </div>
                {isExpCategoryDropdownOpen && (
                  <div className="dropdown-list1">
                    {filteredExpCategories.length > 0 && (
                      <>
                        <label className="checkbox-label1" key="selectAll">
                          <input
                            type="checkbox"
                            checked={selectAllExpCategory}
                            onChange={handleSelectAllExpCategoryChange}
                          />
                          All
                        </label>
                        {filteredExpCategories
                          .filter((expCat) =>
                            expenseData.some(
                              (expense) =>
                                expense.exp_category._id === expCat._id
                            )
                          )
                          .slice()
                          .sort((a, b) =>
                            a.exp_category_name
                              .toLowerCase()
                              .localeCompare(b.exp_category_name.toLowerCase())
                          )
                          .map((expCat) => (
                            <label className="checkbox-label1" key={expCat._id}>
                              <input
                                type="checkbox"
                                checked={selectedExpCategories.includes(
                                  expCat._id
                                )}
                                onChange={() =>
                                  handleCheckboxExpCategoryChange(expCat._id)
                                }
                              />
                              {expCat.exp_category_name &&
                                `${expCat.exp_category_name}`}
                            </label>
                          ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="filter-inner due-date">
            <label htmlFor="dueDate" className="calendar-icon">
              Timeframe
            </label>
            <div className="date-picker-outer date-picker-outer-all">
              <div className="custom-picker-icon custom-picker-icon-all">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  className="rs-picker-toggle-caret rs-icon"
                  aria-label="calendar"
                  data-category="legacy"
                >
                  <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                </svg>
              </div>
              <div className="custoom-icon-calender custoom-icon-calender-all">
                <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                  <DatePicker
                    selected={year}
                    onChange={(date) => {
                      const selectedYear = date.getFullYear();
                      const selectedDateYear = new Date(
                        date.getFullYear(),
                        0,
                        1
                      );
                      setYear(selectedDateYear);
                      selectYear(selectedYear);
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="filter-inner invoice-status currency-select">
            <label htmlFor="invoiceCurrency">Currency</label>
            <div className="custom-dropdown1" ref={currencyDropdownRef}>
              <div className="drop-down-inner">
                <div
                  className="selected-options1"
                  onClick={toggleCurrencyDropdown}
                >
                  {selectedCurrency.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <>
                      <div className="selected-option1">
                        {selectedCurrency.length === sortedCurrencies.length ? (
                          "All"
                        ) : (
                          <>
                            <TruncateFilterFieldView
                              textContent={
                                sortedCurrencies.find(
                                  (item) =>
                                    item.currencyCode === selectedCurrency[0]
                                )?.currencyName
                              }
                            />
                            {selectedCurrency.length > 1 &&
                              selectedCurrency.length !==
                                sortedCurrencies.length && (
                                <div className="selected-option-count">
                                  {selectedCurrency.length - 1}+
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {isCurrencyDropdownOpen && (
                  <div className="dropdown-list1">
                    {sortedCurrencies.length > 0 ? (
                      <>
                        <label className="checkbox-label1" key="selectAll">
                          <input
                            type="checkbox"
                            // checked={selectedCurrency.length === sortedCurrencies.length}
                            checked={selectAllCurrency}
                            onChange={handleSelectAllCurrencyChange}
                          />
                          Sum All
                        </label>
                        {sortedCurrencies
                          // .filter(expCur => expenseData.some(expense => expense.exp_currency === expCur.currencyCode))
                          .slice()
                          .sort((a, b) =>
                            a.currencyName
                              .toLowerCase()
                              .localeCompare(b.currencyName.toLowerCase())
                          )
                          .map((expCur, index) => (
                            <label
                              className="checkbox-label1"
                              key={expCur.currencyCode}
                            >
                              <input
                                type="checkbox"
                                checked={selectedCurrency.includes(
                                  expCur.currencyCode
                                )}
                                onChange={() =>
                                  handleCheckboxCurrencyChange(
                                    expCur.currencyCode
                                  )
                                }
                              />
                              {expCur.currencyCode &&
                                `${expCur.currencyCode} (${expCur.currencyName})`}
                            </label>
                          ))}
                      </>
                    ) : (
                      <label className="checkbox-label1">
                        No currencies found
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

            


        </div>

        <div className="filter-invoice-right apply-fliter-right">
          <button onClick={toggleFilterModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="17"
              viewBox="0 0 13 17"
              fill="none"
            >
              <path
                d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                fill="#6479F8"
              />
              <path
                d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                fill="#6479F8"
              />
              <path
                d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                fill="#6479F8"
              />
            </svg>
            All Filters
          </button>
        </div>

        <Modal
          isOpen={showFilterModal}
          onRequestClose={toggleFilterModal}
          contentLabel="Filter Modal"
          className="new-filter-modal"
          style={{
            content: {
              height: "50%",
              width: "50%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-25%, -25%)",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              padding: "20px",
              // overflow: "auto",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <div className="new-nodal-invoice-outer-sec">
            <div className="new-modal-top-sec">
              <div className="new-modal-heading">All filters</div>
              <a
                className="close-image-clr1"
                onClick={checkAndShowConfirmationModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path d="M1 13L13 1" stroke="black" />
                  <path d="M1 0.999999L13 13" stroke="black" />
                </svg>{" "}
              </a>
            </div>

            <div className="invoice-filter-inner-section">
              <div className="filter-inner users">
                <label htmlFor="fname">Expense Type</label>
                <div
                  className="custom-dropdown1"
                  ref={expCategoryModalDropdownRef}
                >
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleExpCategoryModalDropdown}
                    >
                      {selectedExpCategories.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <div
                          className="selected-option1"
                          key={selectedExpCategories[0]}
                        >
                          {selectedExpCategories.length ===
                          filteredExpCategories.length ? (
                            "All"
                          ) : (
                            <>
                              <TruncateFilterFieldView
                                textContent={
                                  filteredExpCategories.find(
                                    (item) =>
                                      item._id === selectedExpCategories[0]
                                  )?.exp_category_name
                                }
                              />
                              {selectedExpCategories.length > 1 &&
                                selectedExpCategories.length !==
                                  filteredExpCategories.length && (
                                  <div className="selected-option-count">
                                    {selectedExpCategories.length - 1}+
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    {isExpCategoryModalDropdownOpen && (
                      <div className="dropdown-list1">
                        {filteredExpCategories.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllExpCategory}
                                onChange={handleSelectAllExpCategoryChange}
                              />
                              All
                            </label>
                            {filteredExpCategories
                              .filter((expCat) =>
                                expenseData.some(
                                  (expense) =>
                                    expense.exp_category._id === expCat._id
                                )
                              )
                              .slice()
                              .sort((a, b) =>
                                a.exp_category_name
                                  .toLowerCase()
                                  .localeCompare(
                                    b.exp_category_name.toLowerCase()
                                  )
                              )
                              .map((expCat) => (
                                <label
                                  className="checkbox-label1"
                                  key={expCat._id}
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectedExpCategories.includes(
                                      expCat._id
                                    )}
                                    onChange={() =>
                                      handleCheckboxExpCategoryChange(
                                        expCat._id
                                      )
                                    }
                                  />
                                  {expCat.exp_category_name &&
                                    `${expCat.exp_category_name}`}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner due-date">
                <label htmlFor="dueDate" className="calendar-icon">
                  Timeframe
                </label>
                <div className="date-picker-outer date-picker-outer-all">
                  <div className="custom-picker-icon custom-picker-icon-all">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 14 14"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      className="rs-picker-toggle-caret rs-icon"
                      aria-label="calendar"
                      data-category="legacy"
                    >
                      <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                    </svg>
                  </div>
                  <div className="custoom-icon-calender custoom-icon-calender-all">
                    <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                      <DatePicker
                        selected={year}
                        onChange={(date) => {
                          const selectedYear = date.getFullYear();
                          const selectedDateYear = new Date(
                            date.getFullYear(),
                            0,
                            1
                          );
                          setYear(selectedDateYear);
                          selectYear(selectedYear);
                        }}
                        showYearPicker
                        dateFormat="yyyy"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-inner invoice-status">
                <label htmlFor="invoiceCurrency">Currency</label>
                <div
                  className="custom-dropdown1"
                  ref={currencyModalDropdownRef}
                >
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleCurrencyModalDropdown}
                    >
                      {selectedCurrency.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <div className="selected-option1">
                          {selectedCurrency.length ===
                          sortedCurrencies.length ? (
                            "Sum All"
                          ) : (
                            <>
                              <TruncateFilterFieldView
                                textContent={
                                  sortedCurrencies.find(
                                    (item) =>
                                      item.currencyCode === selectedCurrency[0]
                                  )?.currencyName
                                }
                              />
                              {selectedCurrency.length > 1 &&
                                selectedCurrency.length !==
                                  sortedCurrencies.length && (
                                  <div className="selected-option-count">
                                    {selectedCurrency.length - 1}+
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    {isCurrencyModalDropdownOpen && (
                      <div className="dropdown-list1">
                        {sortedCurrencies.length > 0 ? (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                // checked={selectedCurrency.length === sortedCurrencies.length}
                                checked={selectAllCurrency}
                                onChange={handleSelectAllCurrencyChange}
                              />
                              Sum All
                            </label>
                            {sortedCurrencies
                              // .filter(expCur => expenseData.some(expense => expense.exp_currency === expCur.currencyCode))
                              .slice()
                              .sort((a, b) =>
                                a.currencyName
                                  .toLowerCase()
                                  .localeCompare(b.currencyName.toLowerCase())
                              )
                              .map((expCur, index) => (
                                <label className="checkbox-label1" key={index}>
                                  <input
                                    type="checkbox"
                                    checked={selectedCurrency.includes(
                                      expCur.currencyCode
                                    )}
                                    onChange={() =>
                                      handleCheckboxCurrencyChange(
                                        expCur.currencyCode
                                      )
                                    }
                                  />
                                  {expCur.currencyCode &&
                                    `${expCur.currencyCode} (${expCur.currencyName})`}
                                </label>
                              ))}
                          </>
                        ) : (
                          <label className="checkbox-label1">
                            No currencies found
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>



            {/* sum of all currency */}

            <p>{global.config.locate(appSettings && appSettings.language, 'Sum all Currencies')}
            <input type="checkbox" checked={allCurrency && (sortedCurrencies.length ===selectedCurrency.length)} name="allCurrency" value={allCurrency} onChange={async (e) => {

              sumAllCurrencies(!allCurrency);
              
              if (!allCurrency === true) {

                handleSelectAllCurrencyChange();
                setSelectedCurrency(sortedCurrencies);

              } else {

                const forex = await getForex(appSettings && appSettings.currency);
                setSelectedForex(forex);
                await dispatch(setForex(forex));

                setSelectedCurrency([global.config.appCurrency()]);
                // const allClientIds = [
                //   ...new Set(
                //     props.clients
                //       .filter((client) => global.config.appCurrency() === client.preferred_currency
                //       )
                //       .map((client) => client._id)
                //   ),
                // ];

                // setSelectedClients(allClientIds);
                // // Update selected projects and sorted projects based on all clients
                // const allProjects = props.project.filter((project) =>
                //   allClientIds.includes(project.client_name._id)
                // );
                // const allProjectIds = allProjects.map((project) => project._id);

                // setSelectedProject(allProjectIds);
                // setSortedProjects(allProjects);
                // selectProject(allProjectIds);
              }

            }} />
          </p>


            {/* sum of all currency */}

            </div>

            <div className="filter-buttons">
              <button className="clear-all" onClick={clearFilters}>
                Clear All
              </button>
              <button className="apply-filter" onClick={applyFilters}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                >
                  <path
                    d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                    fill="white"
                  />
                  <path
                    d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                    fill="white"
                  />
                  <path
                    d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                    fill="white"
                  />
                </svg>
                Apply Filters
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={showConfirmationModal}
          onRequestClose={() => setShowConfirmationModal(false)}
          contentLabel="Confirmation Modal"
          ariaHideApp={false}
          className="confirmation-modal"
          overlayClassName="confirmation-modal-overlay"
        >
          <div className="modal-content">
            <h2>Are you sure?</h2>
            <p>Are you sure you want to clear the filters?</p>
            <div className="modal-footer">
              <button
                className="cancel-btn"
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </button>
              <button className="confirm-btn" onClick={handleApplyConfirm}>
                Yes, Clear
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ExpenseFilter;
