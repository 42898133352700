import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Logo from "../../../images/logo/to-logo.svg";
import EyeIcon from "../../../images/icons/eye-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import LoginImage from "../../../images/login/lg-img.png";
import { loginTemp } from "../../../features/auth/authSlice";
import Spinner from "../../../components/Animations/Spinner";
import EyeCrossed from "../../../images/icons/eye-crossed.svg";
import LoginImageMobile from "../../../images/login/lg-img-mob.png";
import { setOnboard, setOnboardUserType } from "../../../features/onBoard/onBoardSlice";
import { sendVerificationMail } from "../services/services.js";

function LandingPage() {

        const dispatch = useDispatch();
        const navigate = useNavigate();
        const [email, setEmail] = useState(null);
        const [loading, setLoading] = useState(false);
        const [emailSuccess, setEmailSuccess] = useState(false);
        const [inviteStatus, setInviteStatus] = useState("");

        const { userType } = useSelector(
                (state) => state.onBoard
        );
        useEffect(() => {
                if (userType === null) {
                        navigate('/on-boarding');
                }
        }, [userType, navigate]);

        const handleEmailSubmit = async () => {

                try {
                        setLoading(true);
                        const inviteRes = await sendVerificationMail(email, userType);
                        console.log(inviteRes);
                        if (inviteRes.status === "invited") {

                                setInviteStatus("invited");
                                toast.success("User invited successfully", {
                                        position: "bottom-right",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                });

                        } else {

                                setInviteStatus("already");
                                toast.warning("User already Invited", {
                                        position: "bottom-right",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                });

                        }
                        setEmailSuccess(true);
                        setLoading(false);

                } catch (e) {
                        toast.error("Somthing went wrionge");
                }
        }

        return (
                <div>
                        <div className="login-outer onboarding email-verifiction">
                                <div className="login-inner">
                                        <div className="top-logo-mobile">
                                                <img src={Logo} alt="Talent On" />
                                        </div>
                                        <div className="lg-lft">
                                                <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
                                                <img src={LoginImageMobile} alt="mobile" className="mobile-lg-img" />
                                        </div>
                                        <div className="lg-rgt">
                                                <div className="top-logo">
                                                        <img src={Logo} alt="Talent On" />
                                                </div>
                                                <div className="login-form-sec">

                                                        {!emailSuccess ? <form onSubmit="">

                                                                <h3>Create your Talenton account.</h3>

                                                                <div className="form-outer email">
                                                                        <label>Email</label>
                                                                        <input
                                                                                type="email"
                                                                                name="email"
                                                                                id="email"
                                                                                placeholder="email@gmail.com"
                                                                                value={email}
                                                                                onChange={(e) => setEmail(e.target.value)}
                                                                                required={true}
                                                                        // onChange={onChange}
                                                                        // className={errorMessage && (errorMessage.field === 'username' || errorMessage.field === "both") && "error"}
                                                                        />
                                                                </div>
                                                                <span className="terms-msg">
                                                                        By signing up, I agree to the Talenton <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a>
                                                                </span>
                                                                <button className="login-button"
                                                                        disabled={loading}
                                                                        onClick={(e) => {

                                                                                e.preventDefault();
                                                                                if (email) {
                                                                                        handleEmailSubmit();
                                                                                }

                                                                        }}
                                                                >
                                                                        {loading ?
                                                                                <div className="outter-load-table">
                                                                                        <ThreeDots
                                                                                                height="38"
                                                                                                width="40"
                                                                                                radius="9"
                                                                                                color="white"
                                                                                                ariaLabel="three-dots-loading"
                                                                                                wrapperStyle={{}}
                                                                                                wrapperClassName=""
                                                                                                visible={true}
                                                                                        />
                                                                                </div> : "Continue"
                                                                        }
                                                                </button>

                                                        </form> :
                                                                <div className="submit-message-onboard">
                                                                        {inviteStatus === "invited" &&
                                                                                (<> <h3>Please verify your email address.</h3>
                                                                                        <h4>Get started with Talenton! continue with Google to verify</h4></>)
                                                                        }
                                                                        {inviteStatus === "already" &&
                                                                                (<> <h3>This email ID is already registered.</h3>
                                                                                        <h4>Please contact support team.</h4></>)
                                                                        }
                                                                        <p>{email}</p>
                                                                </div>

                                                        }

                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
        );


}

export default LandingPage;