import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef } from 'react';
import { AES, enc } from 'crypto-js';
import { useParams } from "react-router-dom";
import ProjectDetails from "../details/modules/ProjectOverviewListingSingleDetails";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { useProjectDetails, useSprints } from "../../../features/projects/ProjectList.jsx";
import { useClientView } from "../../../components/TimeTracker/TimeTrackerClient/useClientDetails.jsx";
import { setSideBarText } from "../../../features/sideBarText/sideBarTextSlice.js";

export default function Deatils() {

  const dispatch = useDispatch();
  const { encryptedId, projectId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
  const id = decryptedBytes.toString(enc.Utf8);
  const { projectAdded } = useSelector((state) => state.project);

  let token = null;
  const user = useSelector(
    (state) => state.auth.user
  )
  const notifyUpdate = useSelector(
    (state) => state.notification.notificationState
  )
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const componentRef = useRef(null);


  const { data: project = [], isLoading: isLoadingProject, refetch: refetchProject } = useProjectDetails(token, projectId);
  const { data: sprints = [], isLoading: isLoadingSprint, refetch: refetchSprint } = useSprints(token, projectId);
  const { data: client = [], isLoading, refetch: refetchClientProfiles, isError: clientViewError } = useClientView(id, token);

  useEffect(() => {
    refetchClientProfiles();
    if (!isLoading) {
      dispatch(setHeaderText(client?.client_name || client?.legal_entity_name || "Client Overview"));
      dispatch(setSideBarText("Client Overview"));
    }
    componentRef.current.scrollTop = 0;
    refetchProject();
  }, [dispatch, user, id, isLoading,notifyUpdate]);

  useEffect(() => {
    refetchProject();
    refetchSprint();
  }, [projectAdded, projectId, project]);

  return (
    <div ref={componentRef}>
      <>
        <div className="clint-managment client-management-project">
          <ProjectDetails id={id} project={project} isLoadingProject={isLoadingProject} sprints={sprints} isLoadingSprint={isLoadingSprint}/>
        </div>
        {/* <div className="client-activity">
          <ClientActivities id={id} />
        </div> */}
        {/*  <div className="clint-latest-project">
          <ClientLatestProject id={id} />
        </div>
        <div className='client-recent-invoice'>
          <RecentInvoices id={id} />
        </div>
        <div className='client-recent-documents'>
          <RecentDocuments id={id} />
        </div> */}
      </>
    </div>
  )
}