import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
// components
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import AdminNavbar from "../components/Navbar/AdminNavbar";

export default function Admin() {
  const navigate = useNavigate();

  const headerText = useSelector(
    (state) =>
      state.headerText.headerTextState &&
      state.headerText.headerTextState    //toLowerCase()
  );

  const sideBarText = useSelector(
    (state) => state.sideBarText.sideBarTextState
  );
  const timerData = useSelector((state) => state.timerData.timerDataState);
  var start = localStorage?.getItem("startTime") !== "undefined" ? JSON.parse(localStorage?.getItem("startTime")) : null;
  var isRunning = localStorage?.getItem("startTime") !== "undefined" ? JSON.parse(localStorage?.getItem("isRunning")) : null;
  useEffect(() => {
    let token = null;
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      if (parsedUser && parsedUser.token) {
        token = parsedUser.token;
      }
    }

    if (!token) {
      navigate("/");
    }
  }, []);
  const isClientPage = () => {
    return window.location.pathname.startsWith("/client");
  };
  const isVendorPage = () => {
    return window.location.pathname.startsWith("/vendor");
  };
  useEffect(() => {
    let intervalId;

    const updateTitleAndTimer = () => {
      const runningTime = moment().diff(moment(start), "seconds");
      const hours = Math.floor(runningTime / 3600);
      const minutes = Math.floor((runningTime % 3600) / 60);
      const seconds = runningTime % 60;

      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      document.title = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    if (isRunning) {
      // Update title immediately
      updateTitleAndTimer();

      // Set interval to update more frequently (e.g., every 100 milliseconds)
      intervalId = setInterval(updateTitleAndTimer, 100);
    } else {
      document.title = "Talenton";
    }

    // Cleanup the interval when component unmounts or when timer is stopped
    return () => clearInterval(intervalId);
  }, [isRunning, start, timerData]);

  const location = useLocation();
  return (
    <>
      <div className={`admin-outer ${headerText}`}>
        <div className="admin-inner">
          <div className="sidebar-nav">
            <Sidebar />
          </div>
          <div className="outer-nav">
            <AdminNavbar />
            <div
              className={`content-outer ${headerText} ${isVendorPage() ? "vendors" : ""} ${isClientPage() ? "clients" : ""}`}
            >
              <div className="content-inner">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  );
}
