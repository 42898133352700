import axios from "axios";
import { useQuery } from "react-query";


export async function fetchInvoices(startDate, endDate, token) {
        try {
            const response = await axios.post(
                "/api/invoices",
                { startDate, endDate },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            return response.data;
        } catch (e) {
            console.log('Error', e);
            throw e;  // Rethrow the error to handle it in useQuery
        }
    }


export function useFetchInvoices(startDate, endDate, token) {
        return useQuery(
            ["invoices", startDate, endDate],  // Cache key
            () => fetchInvoices(startDate, endDate, token),  // Fetch function
            {
                // You can set additional options here, like retry, staleTime, etc.
                staleTime: 5 * 60 * 1000,  // 5 minutes
                cacheTime: 10 * 60 * 1000,  // 10 minutes
                onError: (error) => {
                    console.error("Error fetching invoices:", error);
                },
            }
        );
    }