import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { projectRevenueOverviewPerProject } from './Services/reportRevenue';
import typeOfContract from '../../../../constants/typeOfContract';

function RevenueOverviewPerProject({ trackReport, projectCost, sprints }) {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const setReportingCurrency = useSelector((state) => state.reportingRevenue.revenueCurrency);
  var currencies = "USD";
  if (setReportingCurrency && setReportingCurrency.length === 1) {
    currencies = setReportingCurrency[0];
  } else {
    currencies = settings.currency;
  }

  // const processedData = projectRevenueOverviewPerProject(trackReport, reportingOverview, settings.currency, forex, typeOfContract);
  const processedData = useMemo(() => {
    return projectRevenueOverviewPerProject(
      trackReport,
      projectCost,
      sprints,
      reportingRevenue,
      currencies,
      forex,
      typeOfContract,
    );
  }, [trackReport, projectCost, reportingRevenue, currencies, forex, typeOfContract]);
  // Dummy data
  // const series = [44, 55, 13, 43, 46, 55, 32, 30, 34, 12, 11, 12, 13];
  // const labels = [
  //   'Project A', 'Project B', 'Project C', 'Project D', 'Project E', 'Project F',
  //   'Project G', 'Project H', 'Project I', 'Project J', 'Project K', 'Project L', 'Project M'
  // ];
  const series = processedData.map(project => parseFloat(project.revenue));
  const labels = processedData.map(project => project.name);

  // Colors array for all projects
  const allColors = [
    '#8AA9D2', '#234266', '#6D98CF', '#4263A5',
    '#6BA9E2', '#9ACBFB', '#75AECF', '#3f4b57',
    '#6A9DCB', '#A4C4E6', '#9AC9F3', '#000',
    '#89A7C4', '#8EA9C6', '#BBCFE7', '#B7D4F0',
    '#A6C0D6', '#4B6F98', '#A2B5CB', '#86A0BC'
  ];

  // Function to get top 3 series and corresponding labels and colors
  const getTop3Series = () => {
    // Create an array of objects { value, index } to keep track of original indices
    const seriesWithIndices = series.map((value, index) => ({ value, index }));

    // Sort by value in descending order
    seriesWithIndices.sort((a, b) => b.value - a.value);

    // Extract top 3 series values and labels
    const top3Series = seriesWithIndices.slice(0, 3);
    const top3Labels = top3Series.map(item => labels[item.index]);
    const top3Colors = top3Series.map(item => allColors[item.index]);

    return { top3Labels, top3Colors };
  };

  const { top3Labels, top3Colors } = getTop3Series();

  const options = {
    chart: {
      width: 193.845,
      height: 193.845,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: -45,
        endAngle: 315,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
    },
    legend: {
      show: false, // Hide the default legend
    },
    labels: labels, // All projects
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + '%';
        },
      },
      custom: function ({ series, seriesIndex, w }) {
        const project = labels[seriesIndex];
        const hoursLogged = series[seriesIndex];
        const totalHours = series.reduce((total, val) => total + val, 0);
        const percentage = ((hoursLogged / totalHours) * 100).toFixed(2);
        return `<div class="apexcharts-tooltip-custom" style="background: #FFFFFF; box-shadow: 0px 1px 8px rgba(110, 110, 110, 0.1); border-radius: 0px;height: 44pxxpx;padding: 5px 10px;">
                  <span class="apexcharts-tooltip-marker"></span>
                  <span class="apexcharts-tooltip-text" style="font-weight: 300; font-size: 11px; line-height: 13.42px; letter-spacing: 0.5px; color: #282828;">${project}</span>
                  <span class="apexcharts-tooltip-value" style="font-weight: 300; font-size: 11px; line-height: 13.42px; letter-spacing: 0.5px; color: #282828;">${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies || 'USD', }).format(hoursLogged)} - ${percentage}%</span>
                </div>`;
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    colors: allColors, // Colors for each segment (all projects)
  };

  // Custom Legend Component
  const CustomLegend = () => {
    return (
      <div className='custom-legend'>
        <div className='custom-legend-title'>Top 3 Projects</div>
        {top3Labels.map((label, index) => (
          <div key={label} className='custom-legend-item'>
            <div className='custom-legend-value'>
              <span className='custom-legend-marker' style={{ backgroundColor: top3Colors[index] }}></span>
              <span className='custom-legend-text'>{label}</span>
            </div>
            <div className='custom-llegend-value'>{new Intl.NumberFormat("en-US", { style: "currency", currency: currencies || 'USD', }).format(series[labels.indexOf(label)])}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='outer-piechart-report'>
      <div className='outer-piechart-title outer-piechart-title-desktop'>
        Revenue Overview per Project
      </div>
      <div className='outer-piechart-section'>
        <div className='outer-piechart-title outer-piechart-title-mobile'>
          Revenue Overview per Project
        </div>
        <ReactApexChart
          options={options}
          series={series}
          type='donut'
          width={200}
          height={235}
        />
      </div>
      <CustomLegend />
    </div>
  );
}

export default RevenueOverviewPerProject;
