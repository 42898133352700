import React, { useState, useEffect } from 'react';
import "./ProjectOverviewListingSingleActivities.css";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { DateRangePicker } from "rsuite";
import AES from "crypto-js/aes";
import { Link, useParams } from 'react-router-dom';
import TimeIccon from "../../../../images/icons/clock.svg";
import DollarIccon from "../../../../images/icons/billable.svg";
import CloseDollarIccon from "../../../../images/icons/non-billable.svg";
import PurseMmoney from "../../../../images/icons/purses-money.svg";
import NotepadIcon from "../../../../images/icons/notepad.svg";
import DollarReverse from "../../../../images/icons/dollar-reverse.svg";
import { useTotalBudgetByProject, useTotalCostByProject, useTrackedProjectHours } from '../../../../features/projects/ProjectList';

const ClientActivities = (props) => {
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const { projectId } = useParams();
    // const [totalCost, setTotalCost] = useState(null);
    // const [totalCostLoading, setTotalCostLoading] = useState(true);
    const token = useSelector(
        (state) => state.auth.token
    )
    const notifyUpdate = useSelector((state) => state.notification.notificationState)
    const { projectAdded } = useSelector((state) => state.project);

    const { data: totalCost = [], isLoading: totalCostLoading, refetch: refetchTotalCost, isError } = useTotalCostByProject(projectId, token, forex, settings.currency);
    const { data: totalBudget = [], isLoading: budgetLoading, refetch: refetchTotalBudget, isError: budgetIsError } = useTotalBudgetByProject(projectId, token);
    const { data: totalHours = [], isLoading: totalHoursLoading, refetch: refetchTotalHours, isError: totalHoursFetchError } = useTrackedProjectHours(projectId, token);

    useEffect(() => {
        refetchTotalCost();
        refetchTotalHours();
        refetchTotalBudget();
        // const fetchTotalCost = async () => {
        //     try {
        //         setTotalCostLoading(true);
        //         const response = await axios.post('/api/time-track/total-cost-by-project', { projectId: projectId }, {
        //             headers: {
        //                 Authorization: `Bearer ${token}`,
        //             },
        //         });
        //         setTotalCost(response.data.totalCost);
        //         setTotalCostLoading(false);
        //     } catch (error) {
        //         console.error('Error fetching total cost:', error);
        //         global.config.slackMessage(error.toString());
        //     } finally {
        //         setTotalCostLoading(false);
        //     }
        // };

        // if (props.project?._id) {
        //     fetchTotalCost();
        // }
    }, [projectId, notifyUpdate, projectAdded]);

    const changeDurationFormat = (sec) => {
        let second = sec;
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDuration;
    }

    const Roi = (Cbudget, cCost) => {
        const budget = Cbudget;
        const cost = cCost;
        if (cost === 0 && budget === 0) {
            return "0%";
        } else if (cost === 0 && budget !== 0) {
            return "N/A";
        } else {
            const rev = budget - cost;
            const Rvalue = (rev / cost) * 100;
            return isNaN(Rvalue) ? "0%" : Rvalue.toFixed(2) + "%";
        }
    }

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }

    // const budget = totalBudget ? totalBudget.toFixed(2) : 0;
    const preferredCurrency = props.project?.client_name?.preferred_currency || 'USD';
    const convertedBudget = ConvertHelper(preferredCurrency, settings.currency, totalBudget, forex);
    // const convertedCost = ConvertHelper(preferredCurrency, settings.currency, totalCost, forex);

    return (
        <>
            <div className="client-activities-outer">

                <div className="client-activity-columns">
                    <div className="activity-column">
                        <div className="activity-with-image">
                            <div className="activity-name">
                                Total Hours
                            </div>
                            <img src={TimeIccon} alt="time-iccon" />
                        </div>
                        <div className="activity-count">
                            {totalHoursLoading ? (
                                <div className="outter-load-table">
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                            ) : (
                                <span>{totalHours ? changeDurationFormat(totalHours) : '00:00:00'}</span>
                            )}
                        </div>
                    </div>
                    <div className="activity-column">
                        <div className="activity-with-image">
                            <div className="activity-name">
                                Total Billable
                            </div>
                            <img src={DollarIccon} alt="dollar-iccon" />
                        </div>
                        <div className="activity-count">
                            {totalHoursLoading && props.isLoadingProject ? (
                                <div className="outter-load-table">
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                            ) : (
                                <span>
                                    {props.project.is_billable && totalHours ? changeDurationFormat(totalHours) : '00:00:00'}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="activity-column">
                        <div className="activity-with-image">
                            <div className="activity-name">
                                Total Non-Billable
                            </div>
                            <img src={CloseDollarIccon} alt="close-dollar-iccon" />
                        </div>
                        <div className="activity-count">
                            {totalHoursLoading && props.isLoadingProject ? (
                                <div className="outter-load-table">
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                            ) : (
                                <span>
                                    {!props.project.is_billable && totalHours ? changeDurationFormat(totalHours) : '00:00:00'}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="activity-column">
                        <div className="activity-with-image">
                            <div className="activity-name">
                                Total Budget
                            </div>
                            <img src={PurseMmoney} alt="purses-of-money" />
                        </div>
                        {props.isLoadingProject ? (
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <div className="activity-count">
                                {(props.project?.client_name?.preferred_currency || budgetLoading) ? (
                                    <>
                                        {totalBudget ? (
                                            <>
                                                {/* {props.project?.budget.toFixed(2)} */}
                                                {new Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: settings.currency,
                                                }).format(ConvertHelper(props.project?.client_name?.preferred_currency, settings.currency, totalBudget, forex))}
                                            </>
                                        ) : (
                                            // "00.00"
                                            <>
                                                {new Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: settings.currency,
                                                }).format("00.00")}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {totalBudget ? (
                                            <>
                                                {/* {props.project?.budget.toFixed(2)} */}
                                                {new Intl.NumberFormat("en-US", {
                                                    style: "currency",
                                                    currency: settings.currency,
                                                }).format(ConvertHelper("USD", settings.currency, totalBudget, forex))}
                                            </>
                                        ) : (
                                            "00.00"
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="activity-column">
                        <div className="activity-with-image">
                            <div className="activity-name">
                                Total Cost
                            </div>
                            <img src={NotepadIcon} alt="notepad-iccon" />
                        </div>
                        <div className="activity-count">
                            {totalCostLoading ? (
                                <div className="outter-load-table">
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                            ) : (
                                props.project?.client_name?.preferred_currency ? (
                                    totalCost ?
                                        // totalCost.toFixed(2)
                                        new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: settings.currency,
                                        }).format(totalCost)
                                        :
                                        // '0.00'
                                        new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: settings.currency,
                                        }).format("0.00")
                                ) : (
                                    totalCost ? totalCost.toFixed(2) : '0.00'
                                )
                            )}
                        </div>
                    </div>
                    <div className="activity-column">
                        <div className="activity-with-image">
                            <div className="activity-name">
                                ROI
                            </div>
                            <img src={DollarReverse} alt="time-iccon" />
                        </div>
                        <div className="activity-count">
                            {/* 23% */}
                            {(totalCostLoading || props.isLoadingProject) ? (
                                <div className="outter-load-table">
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                            ) : (
                                // Roi(props.project?.budget ? props.project?.budget : 0, totalCost ? totalCost : 0)
                                Roi(totalBudget ? convertedBudget : 0, totalCost ? totalCost : 0)
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </>


    )
};

export default ClientActivities; 