import React, { useEffect } from "react";
import "./ReportingClients.css";
import ClientReportFilter from "../../components/Reporting/ClientReport/ClientReportFilter";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import ClientReportingActiveClient from "../../components/Reporting/ClientReport/ClientReportActiveClients";
import ClientReportingInactiveClient from "../../components/Reporting/ClientReport/ClientReportInactiveClients";
import ClientReportingTable from "../../components/Reporting/ClientReport/ClientReportRecentClients";
import { Link } from "react-router-dom";
import { useGetClients } from "../../components/TimeTracker/TimeTrackerOverview/useTimeTracker";
import { useFetchClientsInvoicesDetails } from "../../features/reporting/reportingClient/useReportingClient";

function ReportingClient() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: clients = [], isLoading: clientLoading, isError: clientError } = useGetClients(token);
  useEffect(() => {
    dispatch(setHeaderText("Clients Report"));
  }, []);
  return (
    <div className="react-outer client-report-outer">
      <div className="invoice-outer-invoice invoice-outer-invoice-reprt">
        <ClientReportFilter clients={clients}/>
      </div>
      <div className="repot-tatal-box-sec">
        <ClientReportingActiveClient clients={clients}/>
        <ClientReportingInactiveClient clients={clients}/>
        <div></div>
        <div></div>
      </div>

      <div className="outer-report-graph-piechart client-report-inner">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="recent-inv-title">Clients</div>
          <div className="top-graph">
            <ClientReportingTable clients={clients} clientLoading={clientLoading}/>
          </div>
        </div>
      </div>
      <div className="button-sec-invoice">
        <Link to="/client/overview">View All</Link>
      </div>
    </div>
  );
}

export default ReportingClient;
