import React, { useEffect } from "react";
import "./ReportExpense.css";
import ReportingFinancialOverviewFilter from "./ReportOverview/ReportingFinancialOverviewFilter";
import { useDispatch, useSelector } from "react-redux";
import TotalClientReportOverview from "./ReportOverview/ReportTotalClient";
import ReportTotalRevenue from "./ReportOverview/ReportTotalRevenue";
import ReportTotalCost from "./ReportOverview/ReportTotalCost";
import ReportTotalRoi from "./ReportOverview/ReportTotalRoi";
import CostPerClientOverview from "../../Reporting/ReportingFinancial/ReportOverview/CostPerClientOverview";
import CostPerProjectOverview from "../../Reporting/ReportingFinancial/ReportOverview/CostPerProjectOverview";
import { useFetchProjectDetails } from "../../TimeTracker/TimeTrackerProject/useProjectDetails";
import { useGetClients } from "../../TimeTracker/TimeTrackerOverview/useTimeTracker";
import { useFetchExpenses, useFetchProjectCosts, useFetchSprints, useFetchTrackReportDetails } from "../../../features/reporting/reportingOverview/useReportingOverview";
import { Link } from "react-router-dom";

function ReportingFinancialOverview() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: projects = [], isLoading: projectLoading, refetch: refetchProjects } = useFetchProjectDetails(token);
  const { data: clients = [], isLoading: clientLoading, isError: clientError } = useGetClients(token);
  const { data: trackReport = [], isLoading: trackReportLoading, isError: trackReportError } = useFetchTrackReportDetails(token);
  const { data: projectCost = [], isLoading: projectCostLoading, isError: projectCostError } = useFetchProjectCosts(token);
  const { data: sprints = [], isLoading: sprintLoading, isError: sprintError } = useFetchSprints(token);
  const { data: expenses = [], isLoading: expensesLoading, isError: expensesError } = useFetchExpenses(token);

  // useEffect(() => {
  //   dispatch(setHeaderText("Financial Reports"));
  // }, []);
  return (
    <div className="react-outer report-expense client-report-outer">
      <div className="invoice-outer-invoice invoice-outer-invoice-reprt">
        <ReportingFinancialOverviewFilter projects={projects} clients={clients} trackReport={trackReport} projectCost={projectCost} sprints={sprints} expenses={expenses} />
      </div>
      <div className="repot-tatal-box-sec">
        <TotalClientReportOverview clients={clients} />
        <ReportTotalRevenue trackReport={trackReport} projects={projects} projectCost={projectCost} sprints={sprints} expenses={expenses} />
        <ReportTotalCost trackReport={trackReport} projectCost={projectCost} expenses={expenses} />
        <ReportTotalRoi trackReport={trackReport} projects={projects} projectCost={projectCost} sprints={sprints} expenses={expenses} />
      </div>

      <div className="outer-report-graph-piechart client-report-inner">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="recent-inv-title">Cost per client overview</div>
          <div className="top-graph">
            <CostPerClientOverview clients={clients} clientLoading={clientLoading} clientError={clientError} />
          </div>
        </div>
      </div>

      <div className="outer-report-graph-piechart client-report-inner">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="recent-inv-title">Cost per project overview</div>
          <div className="top-graph">
            <CostPerProjectOverview projects={projects} projectLoading={projectLoading} refetchProjects={refetchProjects} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportingFinancialOverview;
