import "./TimeTrackerTeamMembers.css";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Department from "./Elements/Department.jsx";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import typeOfContract from "../../../../constants/typeOfContract.js";
import { logout, reset } from "../../../../features/auth/authSlice.js";
import ticno from "../../../../images/team-member-tab/table-no.svg";
import profile_image from "../../../../images/user/Default_pfp.png";
import UpArrowImage from "../../../../images/icons/up-down-arrow.svg";
import ticyes from "../../../../images/team-member-tab/table-yes.svg";
import {
  useFetchUsers,
  useFetchUsersTimer,
  useUpdateBillableRate,
} from "./teamMemberApi.jsx";
import iconsearcht from "../../../../images/icons/search-team-icon.svg";
import DownArrowImage from "../../../../images/icons/up-down-arrow.svg";
import editimgblue from "../../../../images/team-member-tab/edit-btn-blue.svg";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import TruncateUserName from "../../../helpers/truncateUserName.js";

const TimeTrackerTeamMembers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedPage, setSelectedPage] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [billableRateEdit, setBillableRateEdit] = useState("");
  const billableRateEditRef = useRef(null);
  const [userFilter, setUserFilter] = useState("All");
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [sortedUserProfiles, setSortedUserProfiles] = useState([]);
  let token = null;
  const userDropdownRef = useRef(null);
  const showingDropdownRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const { data: userProfiles = [], isLoading } = useFetchUsersTimer(token);

  const updateBillableRateMutation = useUpdateBillableRate(token);
  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedProfiles = [...userProfiles].sort((a, b) => {
      const aValue =
        key === "billableRate"
          ? a.billable_cost
          : key === "cost"
          ? a.cost
          : a[key];
      const bValue =
        key === "billableRate"
          ? b.billable_cost
          : key === "cost"
          ? b.cost
          : b[key];

      if (key === "department") {
        // Handle sorting for department
        return direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    // Update the local state with sorted data
    setSortedUserProfiles(sortedProfiles);
  };
  useEffect(() => {
    setSortedUserProfiles(userProfiles);
  }, [userProfiles]);

  const handleUserFilterChange = (e) => {
    setUserFilter(e.target.value);
    setSelectedPage(0);
  };
  const handleUserNameEmailFilterChange = (e) => {
    setFilter(e.target.value);
    setSelectedPage(0); // Reset page to the first page
  };

  const filteredData = sortedUserProfiles.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    const fullName =
      (item.first_name ||
        (item.company_first_name
          ? `${item.company_first_name} ${
              item.company_last_name ? item.company_last_name : ""
            }`
          : `${item.company_name} (Company)`)) +
      (item.middle_name && item.middle_name !== "null"
        ? " " + item.middle_name
        : "") +
      " " +
      item.last_name;

    // Check if userFilter is "All" or item._id matches the userFilter
    if (userFilter !== "All" && item._id !== userFilter) {
      return false;
    }

    // Check if searchTerm matches item's first_name or email
    if (
      searchTerm !== "" &&
      !(
        fullName.toLowerCase().includes(searchTerm) ||
        item.email.toLowerCase().includes(searchTerm)
      )
    ) {
      return false;
    }
    return true;
  });

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.key !== "") {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (sortConfig.key === "department") {
        // Handle sorting for department
        return sortConfig.direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (sortConfig.key === "first_name") {
        const nameA = a.first_name
          ? `${a.first_name} ${a.middle_name ? a.middle_name : ""} ${
              a.last_name
            }`
          : `${
              a.company_first_name
                ? a.company_first_name +
                  (a.company_last_name && a.company_last_name)
                : a.company_name
            }`;
        const nameB = b.first_name
          ? `${b.first_name} ${b.middle_name ? b.middle_name : ""} ${
              b.last_name
            }`
          : `${
              b.company_first_name
                ? b.company_first_name +
                  (b.company_last_name && b.company_last_name)
                : b.company_name
            }`;
        return sortConfig.direction === "asc"
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      }

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  const [editableRow, setEditableRow] = useState(-1);
  const handleEdit = (item, index) => {
    setEditableRow(index);
    setSelectedId(item._id);
    setBillableRateEdit(item.billable_cost);
  };

  const cancelChanges = () => {
    setEditableRow(-1);
  };

  const handleToggleDropdown = () => {
    setSearchTerm("");
    setUserDropdownOpen(!userDropdownOpen);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = userProfiles.filter((user) =>
    user.first_name
      ? `${user.first_name} ${user.middle_name ? user.middle_name : ""} ${
          user.last_name
        }`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      : `${
          user.company_first_name
            ? user.company_first_name +
              (user.company_last_name && user.company_last_name)
            : user.company_name
        }`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
  );

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (billableRateEdit === "") {
      toast.error("Billable cost cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      billableRateEditRef.current.focus();
      return;
    }

    try {
      setIsUpdateLoading(true); // Set loading state to true
      const response = await updateBillableRateMutation.mutateAsync({
        selectedId,
        billable_cost: billableRateEdit,
      });
      const data = response.data;

      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Billable rate updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "Leave Type",
          `Leave Type updated successfully`
        );
        setEditableRow(-1);
        dispatch(setNotification(Date.now()));
        setSortedUserProfiles((prevState) => {
          // Find the invoice request object with the corresponding id
          const updatedInvoiceView = prevState.map((item) => {
            if (item._id === selectedId) {
              // Update the status property of the invoice request object
              return { ...item, billable_cost: billableRateEdit };
            }
            return item;
          });
          return updatedInvoiceView;
        });
      }
    } catch (error) {
      console.log(error); // print server error message
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsUpdateLoading(false); // Set loading state back to false
    }
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideUserDropdown =
      userDropdownRef.current && userDropdownRef.current.contains(event.target);
    const isClickInsideShowingDropdown =
      showingDropdownRef.current &&
      showingDropdownRef.current.contains(event.target);
    if (!isClickInsideUserDropdown) {
      setUserDropdownOpen(false);
    }
    if (!isClickInsideShowingDropdown) {
      setShowingDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const handlePageChange = (selected) => {
    setSelectedPage(selected.selected);
  };

  const totalResults = sortedData.length;
  const pageCount = Math.ceil(sortedData.length / perPage); // Total number of pages
  const offset = perPage * selectedPage; // Offset of the current page
  const currentPageData = sortedData.slice(offset, offset + perPage);
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <div className="member-main-outter">
      <div className="search-userandmail">
        <div className="custom-dropdown-all">
          <label htmlFor="memberSelect">User</label>

          <div
            ref={userDropdownRef}
            className={`custom-dropdown-user-new-outer custom-dropdown-select ${
              userDropdownOpen ? "open" : ""
            }`}
          >
            <div
              className="selected-option custom-dropdown-user-new"
              onClick={handleToggleDropdown}
              style={{ cursor: "pointer" }}
            >
              {userFilter === "All" ? (
                "All"
              ) : userProfiles.find((user) => user._id === userFilter)
                  ?.first_name ? (
                <>
                  {
                    userProfiles.find((user) => user._id === userFilter)
                      ?.first_name
                  }{" "}
                  {userProfiles.find((user) => user._id === userFilter)
                    ?.middle_name &&
                  userProfiles.find((user) => user._id === userFilter)
                    ?.middle_name !== "null"
                    ? userProfiles.find((user) => user._id === userFilter)
                        ?.middle_name + " "
                    : ""}
                  {
                    userProfiles.find((user) => user._id === userFilter)
                      ?.last_name
                  }{" "}
                </>
              ) : (
                <>
                  {userProfiles.find((user) => user._id === userFilter)
                    ?.company_first_name
                    ? `${
                        userProfiles.find((user) => user._id === userFilter)
                          ?.company_first_name
                      } ${
                        userProfiles.find((user) => user._id === userFilter)
                          ?.company_last_name || ""
                      }`
                    : `${
                        userProfiles.find((user) => user._id === userFilter)
                          ?.company_name || ""
                      }(Company)`}
                </>
              )}
            </div>
            {userDropdownOpen && (
              <div className="custom-dropdown-new">
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                />
                <ul className="user-list">
                  <li
                    onClick={() => {
                      setUserFilter("All");
                      setUserDropdownOpen(false);
                      setSelectedPage(0);
                    }}
                  >
                    All
                  </li>
                  {filteredUsers.map((user) => (
                    <li
                      key={user._id}
                      className={userFilter === user._id ? "selected-item" : ""}
                      onClick={() => {
                        setUserFilter(user._id);
                        setUserDropdownOpen(false);
                        setSelectedPage(0);
                      }}
                    >
                      {user.first_name ? (
                        <>
                          {user.first_name}{" "}
                          {user.middle_name && user.middle_name !== "null"
                            ? user.middle_name + " "
                            : ""}
                          {user.last_name}{" "}
                        </>
                      ) : (
                        <>
                          {user.company_first_name
                            ? `${user.company_first_name} ${
                                user.company_last_name
                                  ? user.company_last_name
                                  : ""
                              }`
                            : `${user.company_name || ""}(Company)`}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="search-bar-team">
          <button type="button">
            <img src={iconsearcht} alt="search" />
          </button>
          <input
            type="text"
            placeholder="Search by name or email"
            onChange={handleUserNameEmailFilterChange}
            value={filter}
          />
        </div>
      </div>
      <div className="overview-top-right">
        <div className="showing-count">
          <div className="filter-inner showing-result show-result-team-fill">
            <label>Showing</label>

            <div
              ref={showingDropdownRef}
              className="select-modal-mainM custom-dropdown-user-new-outer"
            >
              <div
                className="selected-option custom-dropdown-user-new"
                onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}
              >
                {totalResults < 10
                  ? totalResults.toString()
                  : perPage.toString()}
              </div>
              {showingDropdownOpen && (
                <div className="custom-dropdown-new">
                  <ul className="showing-options-list">
                    {totalResults >= 10 && (
                      <li
                        className={perPage === 10 ? "selected-item" : ""}
                        onClick={() => {
                          setPerPage(10);
                          setSelectedPage(0);
                          setShowingDropdownOpen(false);
                        }}
                      >
                        10
                      </li>
                    )}
                    {totalResults >= 20 && (
                      <li
                        className={perPage === 20 ? "selected-item" : ""}
                        onClick={() => {
                          setPerPage(20);
                          setSelectedPage(0);
                          setShowingDropdownOpen(false);
                        }}
                      >
                        20
                      </li>
                    )}
                    {totalResults >= 30 && (
                      <li
                        className={perPage === 30 ? "selected-item" : ""}
                        onClick={() => {
                          setPerPage(30);
                          setSelectedPage(0);
                          setShowingDropdownOpen(false);
                        }}
                      >
                        30
                      </li>
                    )}
                    {totalResults >= 40 && (
                      <li
                        className={perPage === 40 ? "selected-item" : ""}
                        onClick={() => {
                          setPerPage(40);
                          setSelectedPage(0);
                          setShowingDropdownOpen(false);
                        }}
                      >
                        40
                      </li>
                    )}
                    {totalResults >= 10 && (
                      <li
                        className={
                          totalResults >= 10 && perPage === totalResults
                            ? "selected-item"
                            : ""
                        }
                        onClick={() => {
                          setPerPage(totalResults);
                          setSelectedPage(0);
                          setShowingDropdownOpen(false);
                        }}
                      >
                        ALL
                      </li>
                    )}
                    {/* Add more conditions as needed */}
                    {totalResults < 10 ? (
                      <li
                        className={totalResults < 10 ? "selected-item" : ""}
                        onClick={() => {
                          setPerPage(totalResults);
                          setSelectedPage(0);
                          setShowingDropdownOpen(false);
                        }}
                      >
                        {totalResults}
                      </li>
                    ) : null}
                  </ul>
                </div>
              )}
            </div>
            <label>of {totalResults} Results</label>
          </div>
        </div>
      </div>
      <div className="datatable-team-maintb">
        <table>
          <thead className="head-tb-of-team-member">
            <tr>
              <th
                className="sortable-header"
                onClick={() => sortTable("first_name")}
              >
                User{" "}
                {sortConfig.key === "first_name" &&
                sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th
                className="sortable-header"
                onClick={() => sortTable("email")}
              >
                Email{" "}
                {sortConfig.key === "email" &&
                sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th
                className="sortable-header"
                onClick={() => sortTable("billableRate")}
              >
                Billable Rate (USD){" "}
                {sortConfig.key === "billableRate" &&
                sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th className="sortable-header" onClick={() => sortTable("cost")}>
                Cost Rate{" "}
                {sortConfig.key === "cost" && sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th
                className="sortable-header"
                onClick={() => sortTable("job_title")}
              >
                Job Title{" "}
                {sortConfig.key === "job_title" &&
                sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th className="sortable-header">Department </th>
            </tr>
          </thead>
          <tbody className="body-tb-of-team-member">
            {isLoading ? (
              <tr>
                <td colSpan="6">
                  <div className="outter-load-table">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {currentPageData.length > 0 ? (
                  <>
                    {currentPageData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="member-name">
                            {item.profile_image !== null &&
                            item.profile_image !== "null" ? (
                              <img
                                src={`/uploads/lowProfileImage/${item.profile_image}`}
                                alt={item.first_name}
                                className="member-image"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "/uploads/lowProfileImage/user_default.png";
                                }}
                              />
                            ) : (
                              <img
                                src={profile_image}
                                alt={item.first_name}
                                className="member-image"
                              />
                            )}
                            <span>
                              <TruncateUserName
                                textContent={
                                  item.first_name ? (
                                    <>
                                      {item.first_name}{" "}
                                      {item.middle_name &&
                                      item.middle_name !== "null"
                                        ? item.middle_name + " "
                                        : ""}
                                      {item.last_name}{" "}
                                    </>
                                  ) : (
                                    <>
                                      {item.company_first_name
                                        ? `${item.company_first_name} ${
                                            item.company_last_name
                                              ? item.company_last_name
                                              : ""
                                          }`
                                        : `${item.company_name || ""}(Company)`}
                                    </>
                                  )
                                }
                              />
                            </span>
                          </div>
                        </td>
                        <td>{item.email}</td>
                        <td>
                          <div className="edit-value-changebtn-tic">
                            {editableRow === item._id ? (
                              <input
                                type="number"
                                value={billableRateEdit}
                                onChange={(e) =>
                                  setBillableRateEdit(e.target.value)
                                }
                                ref={billableRateEditRef}
                                onWheel={numberInputOnWheelPreventChange}
                              />
                            ) : (
                              item.billable_cost
                            )}
                            {editableRow === item._id ? (
                              <>
                                <div className="hello-boom-open">
                                  {isUpdateLoading ? (
                                    <ThreeDots
                                      height="18"
                                      width="20"
                                      radius="9"
                                      color="#6479f9"
                                      ariaLabel="three-dots-loading"
                                      wrapperStyle={{}}
                                      wrapperClassName=""
                                      visible={true}
                                    />
                                  ) : (
                                    <>
                                      <button
                                        className="sep-pop-btn"
                                        onClick={handleUpdateSubmit}
                                      >
                                        <img src={ticyes} alt="yes" />
                                      </button>
                                      <button
                                        className="sep-pop-btn"
                                        onClick={() => cancelChanges()}
                                      >
                                        <img src={ticno} alt="no" />
                                      </button>
                                    </>
                                  )}
                                </div>
                              </>
                            ) : (
                              <button
                                className="main-edit-btn-parent"
                                onClick={() => handleEdit(item, item._id)}
                              >
                                <img src={editimgblue} alt="edit" />
                                Edit
                              </button>
                            )}
                          </div>
                        </td>
                        {item.typeOfContract === typeOfContract.Hourly_Rate ? (
                          <td>
                            {item.cost}/hr ({item.payment_currency})
                          </td>
                        ) : (
                          <td>
                            {item.cost} ({item.payment_currency})
                          </td>
                        )}
                        <td>{item.job_title}</td>
                        <td>
                          <Department deptId={item.department} />
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="6">
                      {!isLoading && (
                        <div className="outter-load-table">
                          No results found
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        {isLoading ? (
          <></>
        ) : (
          <>
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel={selectedPage > 0 ? "< Back" : null}
                nextLabel={selectedPage < pageCount - 1 ? "Next >" : null}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                onPageChange={handlePageChange}
                containerClassName={"pagination maininteampag"}
                activeClassName={"active"}
                forcePage={selectedPage}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TimeTrackerTeamMembers;
