import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import Dollar from "../../../images/icons/dollar-kit.svg"
// import { convertCurrencyHelper } from '../../../helpers/forexConvertor';
import typeOfContract from '../../../constants/typeOfContract';
import { clientRioHigh } from '../ReportingOverview/Services/reportOverview.js';


// const calculateHighestRoiClient = (trackReport, reportingOverview, preferredCurrency, forex) => {

//   const clientData = {};
//   trackReport.forEach(entry => {
//     const { project, clientId, user, startTime, duration } = entry;
//     const year = reportingOverview.year || new Date().getFullYear();
//     const projectYear = new Date(startTime).getFullYear();
//     const projectId = project &&  project._id;

//     if (projectYear === year && project && !project.is_delete && reportingOverview.project.includes(projectId) && reportingOverview.currency.includes(clientId?.preferred_currency ||'USD')) {

//       const preferredClientCurrency = clientId?.preferred_currency || 'USD';
//       if (!clientData[clientId?._id]) {
//         clientData[clientId?._id] = {
//           budget: 0,
//           cost: 0,
//           details: clientId
//         };
//       }

//       clientData[clientId?._id].budget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget, forex);
//       const userCost = user.cost || 0;
//       const userOverheadCost = user.overhead_cost || 0;
//       const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;
//       const paymentCurrency = user.payment_currency || 'USD';

//       let finalCost;
//       if (userTypeOfContract === typeOfContract.Hourly_Rate) {
//         finalCost = userCost;
//       } else {
//         finalCost = userCost / 168; // Assuming a different type of contract with monthly rate divided by 168 hours
//       }

//       const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
//       clientData[clientId?._id].cost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
//     }
//   });
//   let highestROIClient = null;

//   for (const clientId in clientData) {
//     const client = clientData[clientId];
//     const roi = ((client.budget - client.cost) / client.cost) * 100;
//     client.roi = roi;
//     if (!highestROIClient || roi > highestROIClient.roi) {
//       highestROIClient = client;
//     }
//   }
//   return highestROIClient;
// };

// Functional component
function HighestRoi(props) {
  const [highestRoiClient, setHighestRoiClient] = useState(null);
  const reportingOverview = useSelector((state) => state.reportingOverview);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const clientInvoices = props.clientInvoices;
  const trackReport = props.trackReport;
  const projectCost = props.projectCost;
  const expenses = props.expenses;

  const setReportingCurrency = useSelector((state) => state.reportingOverview.currency);
  var currencies = "USD";
  if(setReportingCurrency && setReportingCurrency.length===1){
     currencies = setReportingCurrency[0];
  }else{
    currencies = settings.currency;
  }


  useEffect(() => {
    // const highestClient = calculateHighestRoiClient(props.trackReport, reportingOverview, settings.currency, forex);
    const highestClient = clientRioHigh(trackReport, projectCost, clientInvoices, reportingOverview, currencies, forex, typeOfContract, expenses);

    setHighestRoiClient(highestClient);
  }, [reportingOverview, forex, currencies, trackReport, projectCost, expenses, clientInvoices]);
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">
            Highest ROI
            <div className='client-name-report'>{highestRoiClient ? highestRoiClient.details.client_name : 'N/A'}</div>
          </div>
          <div className="overview-icon">
            <img src={Dollar} alt="cLock" />
          </div>
        </div>

        <div className="overview-rice">
          {highestRoiClient ? `${highestRoiClient.roi.toFixed(2)}%` : 'N/A'}
        </div>
      </div>

    </div>
  );
}

export default HighestRoi;